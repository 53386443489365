import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class EstateRightsModuleService extends BaseService {
   private static _estateRightsModuleService: EstateRightsModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): EstateRightsModuleService {
     return this._estateRightsModuleService || (this._estateRightsModuleService = new this('EstateRightsModule'));
   }
 

   public async getEstateRightsModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addEstateRightsModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addEstateRightsModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

  
}

export const EstateRightsModuleApi = EstateRightsModuleService.Instance;