import React,{useState} from 'react';
import { actionCreators} from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory } from 'react-router-dom'
import {AdminApi} from '../../api';
import { BkButton, Spinner } from '../../components'
import AdminMenu from './child-components/AdminMenu'

type ToolsProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly isAdmin: boolean;
};

const Tools: React.FC<ToolsProps> = ({ 
   isAuthenticated, 
   isAdmin,
   logoutUser
 }) => {
   const [isLoading, setIsLoading] = useState(false);
   const history = useHistory();

   if(!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if(!isAdmin) {
      history.push("/projects");
   }
    
   const convertSimpleCalculations = () => {
      setIsLoading(true);
      AdminApi.ConvertSimpleCalculation()
      .then(
      (response) => {    
         setIsLoading(false);
      },
      (error) => {
         console.log(error);
         setIsLoading(false);
      });
   }

   return (
      <>
       <div className="admin-container">
            <AdminMenu activeMenuItem={'Meldinger'}></AdminMenu>
            
            <div className="admin-frame">
               <div className='col-md-10 mx-auto'>
                  <br></br>
                  <BkButton caption='Konverter detaljert kalkulasjon' onClickHandler={convertSimpleCalculations}/>   
               </div>
            </div>
            <Spinner isLoading={isLoading} />
         </div>
      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
 });
 
 export default connect(mapStateToProps, actionCreators)(Tools);