import React, {useEffect, useCallback, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import {useDropzone} from 'react-dropzone'
import { DescriptionTemplateRowApi, UploadApi } from '../../../api';


const keyPressTimer = 1000;
let inputTimer = null;

const DescriptionTemplateRow = ({descriptionRow, handleChangeCb, handleMoveUpCb, handleMoveDownCb, handleDeleteCb }) => {
  const [description, setDescription] = React.useState({
    id:0,
    title:'',
    text:'',
    imageUrl:''
  });

  // Add a ref so that its possible to access the data in a callback
  const stateRef = useRef(description);
  stateRef.current = description;


  const id=descriptionRow.id;

  useEffect(() => {
    setDescription(descriptionRow);
  }, [descriptionRow])

  
  const handleChange = function(e) {
    const { name, value } = e.target;
    let changedDescription = description;

    if (name === "text"){
      changedDescription.text = value;
    }
    else {
      changedDescription.title = value;
    }
    setDescription(description =>({...description, [name]:value}));

    if (inputTimer !== null){
      clearTimeout(inputTimer);
    }

    inputTimer = setTimeout(() => {
      updateDescriptionRow(changedDescription);
    }, keyPressTimer); 
  }


  const handleBlur = function(e) {
    // Dont wait for timer to expire. Just save
    
    if (inputTimer !== null){
      clearTimeout(inputTimer);
      updateDescriptionRow(description);
    }
  }

  const updateDescriptionRow = function(description) {
    
    DescriptionTemplateRowApi.update(description)
    .then (
      (response) => {
        handleChangeCb(description);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleMoveUp = function() {
    handleMoveUpCb(description.id);
  }

  const handleMoveDown = function() {
    handleMoveDownCb(description.id);
  }

  const handleDelete = function() {
    handleDeleteCb(description.id);
  }

  const onDrop = useCallback(acceptedFiles => {
      var data = new FormData();

      if (acceptedFiles.length > 0) {
        data.append("file", acceptedFiles[0]);
      } else {
        return;
      }

      UploadApi.upload(data)
     .then(
       (response) => {
         setDescription({...stateRef.current, imageUrl:response.data});
         let changedDescription = stateRef.current;
         changedDescription.imageUrl = response.data;
         updateDescriptionRow(changedDescription);
       },
       (error) => {
         console.log(error);
       }
     )
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})



  const getPicture = function(imageUrl) {
    if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
      return (
        <div className="image-frame" title="Klikk for å endre, eller dra inn nytt bilde" {...getRootProps()}>
          <input {...getInputProps()} />
          <img src={"/upload/" + imageUrl} />
        </div>
      )
    }
    else {
      return ( 
        <div className="simple-calc-description-upload-frame" {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="frame-header">Dra og slipp</div>
          <div className="frame-text">eller klikk her for å laste opp bilde</div>
        </div>);
    }
  }


  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleMoveUp}>Flytt opp</MenuItem>
            <MenuItem onClick={handleMoveDown}>Flytt ned</MenuItem>
            <Divider />
            <MenuItem onClick={handleDelete}>Slett underpunkt</MenuItem>
          </Menu>
        </div>;

  }
  

  return (
    <>
    <div key={'SCS1_' + description.id} className="simple-calc-section-row">
      <div className="simple-calc-description-title">Seksjon:</div>
      <div className="simple-calc-description-type">
        <input name="title" className="form-control" type="text" value={description.title} onChange={handleChange} onBlur={handleBlur}/>
      </div>
      <div className="simple-calc-action-menu" title="Alternativer">
        <ActionMenu />
      </div>
    </div>
    <div key={'SCS2_' + description.id} className="simple-calc-section-row">
      <div className="simple-calc-description-title">Beskrivelse:</div>
      <div className="simple-calc-description-type">
        <div className="simple-calc-description-type-text"><textarea name="text" className="form-control" rows={7} value={description.text} onChange={handleChange}  onBlur={handleBlur}/></div>
        {getPicture(description.imageUrl)}
      </div>
      <div className="simple-calc-action-menu">
      </div>
    </div>
  </>
  );

}

export default React.memo(DescriptionTemplateRow);