import React, { useState, useEffect, useRef } from 'react'
import { FinanceTableApi } from '../api';
import { FinanceTable } from '.'
import { ConfirmationDialog, AlertDialog } from './Dialogs'
import { NumericInput, SectionHeader } from '.'

type FinanceTablesProps = {
   readonly moduleId: number;
   readonly subId: number;
   readonly descriptionTitle: string;
   readonly heading?: string;
   readonly valueTitle: string;
   readonly sumTitle: string;
   readonly infoText: string;
   readonly hideInput?: boolean;
   readonly showComment?: boolean;
   readonly allowMultiple?: boolean;
   readonly onValueChangeCb?: (value: number) => void;
};

function FinanceTables(props: FinanceTablesProps) {

   const [financeTables, setFinanceTables] = useState([]);
   const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
   const [displayAlertDialog, setAlertConfirmDialog] = useState(false);
   const [idToDelete, setIdToDelete] = useState(0);
   
   useEffect(() => {
      //Fetch comments for this module
      loadData()
   }, [props.moduleId]);


   const loadData = () => {
      FinanceTableApi.getAll(props.moduleId, props.subId)
      .then(
         (response) => {
            setFinanceTables(response.data);
         },
         (error) => {
            console.log(error);
         })

   }
   const onValueChange = () => {
      loadData()
   }

   const onTableChanged = (id, heading) => {
      let newTable = financeTables.map((table, index) => {
         if (table.financeTableId == id) {
            table = { ...table, heading: heading };
         }
         return table
      });

      setFinanceTables(newTable);

   }

   const onTableAdded = (id) => {
      var data = {
         moduleId: props.moduleId,
         subId: findNextSubId(),
         financeTableId: 0,
         heading: null
      }
      FinanceTableApi.update(data)
         .then(
            (response) => {
               setFinanceTables([...financeTables, response.data])
            },
            (error) => {
               console.log(error);
            })
   }

   const onTableDeleted = (id) => {

      if (financeTables.length <= 1) {
         setAlertConfirmDialog(true);
         return;
      }
      setIdToDelete(id);
      setDisplayConfirmDialog(true);

   }

   const findNextSubId = () => {
      var subId = 1;
      financeTables.map((table, index) => {
         if (table.subId >= subId) {
            subId = table.subId + 1;
         }
      });
      return subId;
   }

   const handleAlertDialogClose = function () {
      setAlertConfirmDialog(false);
      return;
   }

   const handleConfirmDialogClose = function () {
      if (idToDelete > 1) {
         FinanceTableApi.delete(idToDelete)
            .then(
               (response) => {
                  setFinanceTables(financeTables.filter(table => table.financeTableId !== idToDelete));
                  //Todo if this was the last one
               },
               (error) => {
                  console.log(error);
               })
      }
      setDisplayConfirmDialog(false);
   }

   const calcSingleRowSum = function(array) {
      var sum = 0
      if (array != null){
         array.map((row,index) => {
            sum += row.value
     })
      }
      return sum

   }

   const calcTotalSum = () => {
      var totalSum = 0
      financeTables.map((table) =>{
         totalSum += calcSingleRowSum(table.rows)
      })
      return totalSum
   }

   var tablesHtml = null;
   if (financeTables) {
      tablesHtml = financeTables.map((table, index) => {
         return <>
            <FinanceTable
               moduleId={table.moduleId}
               subId={table.subId}
               descriptionTitle={props.descriptionTitle}
               valueTitle={props.valueTitle}
               sumTitle={props.sumTitle}
               showHeader={true}
               header={props.heading ? props.heading : 'Kalkulasjon:'}
               displayIcons={true}
               infoText={props.infoText}
               order={index}
               hideInput={props.hideInput}
               showComment={props.showComment}
               allowMultiple={props.allowMultiple}
               onValueChangeCb={onValueChange}
               onTableChangedCb={onTableChanged}
               onTableAddedCb={onTableAdded}
               onTableDeletedCb={onTableDeleted}
            />
         </>
      });
   }

   var sumsHtml = null;
   if (financeTables){
      sumsHtml = financeTables.map((table,index) =>{
            return <>
            <div className="module-row">
               <div className="flex-4 module-row-title-big "><span className='padding-right-20'>{table.heading != null  ? 'Kalkulasjon: ' + table.heading : 'Kalkulasjon: '}</span></div>
                     <div className="flex-1 module-row-title">
                        <div className='module-row-input-with-suffix'>
                           <i>,-</i>
                           <NumericInput disabled={true} value={calcSingleRowSum(table.rows)} placeholder={'0'} class={'align-right'} ></NumericInput>
                        </div>
               </div>
               <div className='delete-icon'></div>
            </div> 
            </>
      })
   }

   return <>
      {tablesHtml}

      {financeTables.length > 1 ?
         <>
            <div className='module-section'>
               <SectionHeader title={"Oppsummering av kalkulasjoner "}></SectionHeader>
               <div className='content-frame'>

                  {sumsHtml}

                  <div className="module-row">
                     <div className="flex-4 module-row-title-big align-right bold"><span className='padding-right-20'>{props.sumTitle}</span></div>
                     <div className="flex-1 module-row-title">
                        <div className='module-row-input-with-suffix'>
                           <i>,-</i>
                           <NumericInput disabled={true} value={calcTotalSum()} placeholder={'0'} class={'align-right bold-important'} ></NumericInput>
                        </div>
                     </div>
                     
                     <div className='delete-icon'></div> 
                  </div> 
                  <div className='align-right infoText'>{props.infoText}</div>

               </div>
            </div>


         </>
         :
         <></>}

      <ConfirmationDialog
         title="Slette?"
         text="Er du sikker på at du vil slette kalkulasjonen"
         open={displayConfirmDialog}
         id={idToDelete}
         onClose={handleConfirmDialogClose}
      />

      <AlertDialog
         title="Kan ikke slette kalkulasjon"
         text="Det må alltid være minst en kalkulasjon i denne rapporten"
         open={displayAlertDialog}
         onClose={handleAlertDialogClose}
      />
   </>
}

export default FinanceTables;