import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { TechnicalValue} from '../containers/Modules/TechnicalValueModule/types'

class TechnicalValueModuleService extends BaseService {
   private static _technicalvalueModuleService: TechnicalValueModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): TechnicalValueModuleService {
     return this._technicalvalueModuleService || (this._technicalvalueModuleService = new this('technicalValueModule'));
   }
 

   public async getTechnicalValueModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addTechnicalValueModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addTechnicalValueModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateTechnicalValue(data: TechnicalValue): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateTechnicalValue.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 


    public async updateBuilding(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateBuilding.`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateBuilding',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async addBuilding(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for addBuilding.`); 
        Promise.reject();
      }
      
      await this.$http.post('AddBuilding',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    
   public async getCalculations(moduleId:number, buildingType:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    if (!moduleId){
      this.handleAllErrors(` moduleId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.get('getCalculations/' + moduleId + '/' + buildingType, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
 }
}

export const TechnicalValueModuleApi = TechnicalValueModuleService.Instance;