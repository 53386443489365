import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom'
import { RoutesConfig } from '../../../config/routes.config';

type LoginControlsProps = {
  readonly rememberMe: boolean;
  readonly handleRememberMeCheck: (checked: boolean) => void;
};




const LoginControls = React.memo<LoginControlsProps>(
  ({ rememberMe, handleRememberMeCheck }) => {

  const history = useHistory();
  const handleForgotPasswordClick = () => {
    history.push(RoutesConfig.Forgot.path)
  }

  return (
    <Fragment>
      <div className='container'>
     
      <div className='row '>
      <div className='col-md-6 login-text'>
        <Checkbox
          label='Husk meg'
          checked={rememberMe}
          onCheck={handleRememberMeCheck}
          
        />
      </div>

      <div className='col-md-6' id='login-forgot'>
          <div onClick={handleForgotPasswordClick}>Glemt passord?</div>
      </div>
      
      </div>

      <div className='row justify-content-center'>
        <div className='col-lg-3 mt-4 mb-4'>
        <button className="login-button" type="submit">Logg inn</button>
        </div>
      
      </div>

      </div>

    </Fragment>
  )}
);

LoginControls.displayName = 'LoginControls';

export default LoginControls;
