import React, {useEffect, useState} from 'react'

function RadioBox(props) {

   const [selectedValue, setSelectedValue] = useState(0);

   useEffect(() => {
      setSelectedValue(props.value);
   }, [props])

   const handleChange=(e)=>{
      const { value } = e.target;
      var valueToSet = value;
      if (value == selectedValue) {
         valueToSet="0";
      }
      setSelectedValue(valueToSet);
      props.onValueChangeCb(parseInt(valueToSet,10));
   }

   return <>
      <input disabled={props.readOnly} className="radio-box" type="radio" value={1} id="value-1" checked={selectedValue == 1} onClick={handleChange}  />
      <input disabled={props.readOnly} className="radio-box" type="radio" value={2} id="value-2" checked={selectedValue == 2} onClick={handleChange}  />
      { props.numberOfOptions > 2 &&
         <input disabled={props.readOnly} className="radio-box" type="radio" value={3} id="value-3" checked={selectedValue == 3} onClick={handleChange}  />
      }
   </>
}
  
export default RadioBox;