import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { BkButton } from '..'
import { ContactButton } from '..'
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const CameraDialog = ({open, handleSaveCb, handleCancelCb}) => {
   const [openDialog, setOpenDialog] = useState(false);

   useEffect(() => {
      setOpenDialog(open);
   }, [open]);

   const handleClose = () => {
      handleCancelCb();
   };

   const handleSaveAndClose = () => {
      handleCancelCb();
   };

   function handleTakePhoto (dataUri) {
      handleSaveCb(dataUri);
    }


  return (
    <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title" keepMounted={true}>
    <DialogTitle id="form-dialog-title" className="dialog-header">Legg til bilde
    </DialogTitle>
    <DialogContent>
       {openDialog &&
           <Camera
           onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
         /> 
       }

    </DialogContent>
    <DialogActions  className="upload-buttons">

      <ContactButton caption='Avbryt' onClickHandler={handleClose}/>
      <BkButton caption='Lagre'  onClickHandler={handleSaveAndClose} />

    </DialogActions>
  </Dialog>
  )
}

export default React.memo(CameraDialog);