import React, {useState, useEffect} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BkButton, AutoTextBox,ContactButton } from '../../../components';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);



export interface NotesDialogProps {
  title:string;
  open: boolean;
  onClose: (notes) => void;
  notes:string;
}

export default function EditProjectDialog(props: NotesDialogProps) {
  const { title, onClose,  open, notes} = props;
  const [text, setText] = useState('');





  useEffect(() => {
   setText(notes);
  }, [notes])

  const handleClose = () => {
    onClose(null);
  };


  const handleSaveAndClose = () => {
      onClose(text);
  };


  const onTextChange = function(value) {
    setText(value);
  }

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth = {'sm'} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialog-header">{title}</DialogTitle>
        <DialogContent>
          <div className="project-note-dialog">
    
            <AutoTextBox text={text} defaultRows={5} onValueChangeCb={onTextChange}  ></AutoTextBox>
          </div>
        </DialogContent>
        <DialogActions>

          <ContactButton caption='Avbryt' onClickHandler={handleClose}/>
          <BkButton caption='Lagre notat'  onClickHandler={handleSaveAndClose} />
        </DialogActions>
      </Dialog>
    </>
  );
}