import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class DocumentService extends BaseService {
  private static _documentService: DocumentService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): DocumentService {
    return this._documentService || (this._documentService = new this('document'));
  }

  public async getDocument(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('get/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


}



export const DocumentApi = DocumentService.Instance;