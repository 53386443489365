export const clickEventHelper = (e) => {
    if (e && e.preventDefault){
        e.preventDefault();
    }

    if (e.target)
    {
        return e.target.value
    }

    return null;
}

export const clickEventHelperNumber = (e) => {
    const value = clickEventHelper(e);
    if (!value)
        return;

    return parseInt(value);
}

export const clickEventHelperFloat = (e) => {
    const value = clickEventHelper(e);

    let tempValue = 0;
    
    if (!value) 
        tempValue = 0;
    else {
        tempValue = parseFloat(value.toString());
    }

    return tempValue;
}