
const toXmlSafeText = (val: string): string => {
  var regex = /((?:[\0-\x08\x0B\f\x0E-\x1F\uFFFD\uFFFE\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]))/g;
  val = String(val || '').replace(regex, '');
  return val;

};

export {
  toXmlSafeText
};


