import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class ReportService extends BaseService {
  private static _calculationService: ReportService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): ReportService {
    return this._calculationService || (this._calculationService = new this('report'));
  }


  public async getReport(id): Promise<AxiosResponse> {
    var axiosResponse;
    if (!id){
        this.handleAllErrors(` id arg. was not provided, when getReport.`); 
        Promise.reject();
      }

    await this.$http.get('get/' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error); throw(error) });
   
    return axiosResponse;
  }

  public async getPdf(id, mode): Promise<boolean>  {
    var url = `/api/report/getPdf/${id}/${mode}`;
    window.location.href = url;
    return Promise.resolve(true);
  }

  public async getPublicPdf(guid): Promise<boolean>  {
   var url = `/api/report/getPublicPdf/${guid}`;
   window.location.href = url;
   return Promise.resolve(true);
 }

  public async getPublicData(guid): Promise<AxiosResponse>  {
      var axiosResponse;
      if (guid == null){
         this.handleAllErrors(` id arg. was not provided, when getPublicData.`); 
         Promise.reject();
      }

      await this.$http.get('getPublic/' + guid)
         .then((response) => {
            axiosResponse = response;
         }, 
         (error) => {this.handleAllErrors(error); throw(error) });
   
      return axiosResponse;
   }
   

   public async updateReport(data): Promise<AxiosResponse> {
      var axiosResponse;
      
      await this.$http.post('UpdateReport',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
  
      return axiosResponse;
    }
  
    public async updateSubscription(data): Promise<AxiosResponse> {
      var axiosResponse;
      
      await this.$http.post('UpdateSubscription',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
  
      return axiosResponse;
    }
}

export const ReportApi = ReportService.Instance;