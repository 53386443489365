import React, {useState, useEffect} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { ContactButton } from '../../components';
import { TechnicalValueModuleApi } from '../../api';
import { formatValue, formatDateTime } from '../../utils/'


export interface CalculationDialogProps {
   moduleId:number;
   buildingId:number;
   buildingType:number;
   open: boolean;
   onClose: (cost: number, reduction:number, description:string) => void;
}

export default function CalculationDialog(props: CalculationDialogProps) {
   const { moduleId, buildingId, onClose, open, buildingType} = props;
   
   const [calculations, setCalculations] = useState([]);
   const [selectedCalculationId, setSelectedCalculationId] = useState(0);

   useEffect(() => {
      loadData();
   }, [props]);


   const loadData = () => {
      TechnicalValueModuleApi.getCalculations(moduleId, buildingType)
      .then((response) => {
         setCalculations(response.data.calculations)
       }, 
       (error) => { 
         console.log(error);
         throw (error) 
       });
   }

   const handleCancel = () => {
      onClose(0,0, '');
   };

   const handleSelected = (calculation) => {
      onClose(calculation.buildingCost,calculation.valueReduction, getCalculationName(calculation));
   };

   const getCalculationName = (calculation) => {
      var res = calculation.name;
      if (calculation.description != null && calculation.description != '') {
         res = res + ' - ';
         if (calculation.description.length > 10) {
            res = res + calculation.description.slice(0,10) + '...';
         }
         else {
            res = res + calculation.description;
         }
      }
      return res;
   }

   var calculationsHtml = null;
   if (calculations) {
      calculationsHtml = calculations.map((calculation, index) => {
         return <>
               <div className="calculation-dialog-row" onClick={() => handleSelected(calculation)}>
                  <div className='flex-1'>{formatDateTime(calculation.createdDate)}</div>
                  <div className='flex-1'>{getCalculationName(calculation)}</div>
                  <div className='flex-1'>{calculation.buildYear>0?calculation.buildYear : '-'}</div>
                  <div className='flex-1'>{formatValue(calculation.buildingCost, 0 , ' kr')}</div>
                  <div className='flex-1'>{formatValue(calculation.valueReduction, 0 , ' kr')}</div>
               </div>
            </>
      });

   }



  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="calculation-dialog-title"
      open={open}
      onClose={handleCancel}
    >
      <DialogTitle id="calculation-dialog-title"  className="dialog-header">Hent verdier fra kalkulasjon</DialogTitle>
      <DialogContent dividers>
         <div className="calculation-dialog-frame">
            <div className="calculation-dialog-header-row" >
               <div className='flex-1'>Opprettet dato</div>
               <div className='flex-1'>Type</div>
               <div className='flex-1'>Byggeår</div>
               <div className='flex-1'>Byggekostnad</div>
               <div className='flex-1'>Verdireduksjon</div>
            </div>
            {calculationsHtml}
         </div>
      </DialogContent>
      <DialogActions>
        <ContactButton caption='Avbryt' onClickHandler={handleCancel}/>
        {/* <BkButton caption='Ok'  onClickHandler={handleSelected} /> */}
      </DialogActions>
    </Dialog>
  );
}
