import React from 'react'
import {CategoryHeader} from '.'
import parse from 'html-react-parser';

function FixedTextBox(props) {

   return <>
         <div className='introduction-header'>{props.title}</div>
         <div className="text">
            {parse(props.text)}
         </div>
   </>
}
  
export default FixedTextBox;