import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class GenericListModuleService extends BaseService {
   private static _genericListModuleService: GenericListModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): GenericListModuleService {
     return this._genericListModuleService || (this._genericListModuleService = new this('genericList'));
   }
 

   public async getGenericListModule(moduleId: number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId) {
        this.handleAllErrors(`moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, { withCredentials: true })
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addGenericListModule(data: { moduleId: number }): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data) {
         this.handleAllErrors(`Data was not provided for addGenericListModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add', data, { withCredentials: true })
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateGenericListModuleRow(data): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data) {
         this.handleAllErrors(`Data was not provided for updateGenericListModuleRow.`); 
         Promise.reject();
      }
      
      await this.$http.post('UpdateRow', data, { withCredentials: true })
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async deleteGenericListModuleRow(data): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data) {
         this.handleAllErrors(`Data was not provided for deleteGenericListModuleRow.`); 
         Promise.reject();
      }
      
      await this.$http.post('DeleteRow', data, { withCredentials: true })
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }
}

export const GenericListModuleApi = GenericListModuleService.Instance;