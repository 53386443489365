import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class BudgetProgressModuleService extends BaseService{
    private static _budgetProgressModuleService: BudgetProgressModuleService;

    private constructor(controllerName: string){
        super(controllerName);
    }

    public static get Instance(): BudgetProgressModuleService {
        return this._budgetProgressModuleService || (this._budgetProgressModuleService = new this('budgetProgress'));
    }

    public async getBudgetProgress(moduleId:number): Promise<AxiosResponse | null> {
        var axiosResponse;

        if (!moduleId){
            this.handleAllErrors(` moduleId arg. was not provided.`); 
            Promise.reject();
        }

        await this.$http.get('get/' + moduleId, {withCredentials: true})
        .then((response) => {
            axiosResponse = response;
        }, 
        (error) => {
            this.handleAllErrors(error); 
            throw(error);
        });

        return axiosResponse;
    }

    public async addBudgetProgress(data: {moduleId:number}): Promise<AxiosResponse> {
        var axiosResponse;

        if (!data){
            this.handleAllErrors(` Data was not provided for addBudgetProgress.`); 
            Promise.reject();
        }

        await this.$http.post('Add',data, {withCredentials: true})
        .then((response) => {
            axiosResponse = response;
        }, 
        (error) => {
            this.handleAllErrors(error); 
            throw (error);
        });

        return axiosResponse;
    }

    public async updateBudgetProgressRow(data): Promise<AxiosResponse> {
        var axiosResponse;

        if (!data){
            this.handleAllErrors(` Data was not provided for updateBudgetProgress.`); 
            Promise.reject();
        }

        await this.$http.post('Update',data, {withCredentials: true})
        .then((response) => {
            axiosResponse = response;
        }, 
        (error) => {
            this.handleAllErrors(error); 
            throw (error);
        });

        return axiosResponse;
    }

    public async deleteBudgetProgressRow(id): Promise<AxiosResponse> {
        var axiosResponse;
        await this.$http.delete('Delete/' + id, {withCredentials: true})
        .then((response) => {
            axiosResponse = response;
        }, 
        (error) => {
            this.handleAllErrors(error); 
            throw (error);
        });

        return axiosResponse;
    }
}

export const BudgetProgressModuleApi = BudgetProgressModuleService.Instance;