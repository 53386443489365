import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class PlotService extends BaseService {
  private static _plotService: PlotService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): PlotService {
    return this._plotService || (this._plotService = new this('plot'));
  }


  public async getPlot(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('get/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async getCost(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('getCost/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }



  public async addPlot(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('post', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async updatePlot(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.put('put', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async deletePlot(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('delete/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });;

    return axiosResponse;
  }


  public async updateProperty(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('updateProperty',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async deleteProperty(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('deleteProperty',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }




   public async updateDescription(data): Promise<AxiosResponse> {
      var axiosResponse;
      
      await this.$http.post('UpdateDescription',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });

      return axiosResponse;
   }


   public async getPdf(id): Promise<boolean> {
//    var url = `${window.location.origin}/api/plot/GetPdf?calculationId=${id}`;
    var url = `/api/plot/GetPdf?calculationId=${id}`;
    window.location.href = url;
    return Promise.resolve(true);
   }








}



export const PlotApi = PlotService.Instance;