import React, {useState} from 'react';
import { useHistory } from 'react-router-dom'

const StatisticsCard = ({stats}) => {

   const history = useHistory();

   


   return (
      <>
         <div className="admin-card-frame">
            <div className="admin-card-title mt-2">{stats.name}</div>
            <div className="admin-card-main-number">{stats.total}</div>
            <div className="display-flex">
               <div className="flex-1"></div>
               <div className="flex-2 admin-card-text">Nye idag:</div>
               <div className="flex-1 admin-card-number">{stats.day}</div>
               <div className="flex-1"></div>
            </div>
            <div className="display-flex">
               <div className="flex-1"></div>
               <div className="flex-2 admin-card-text">Siste uke:</div>
               <div className="flex-1 admin-card-number">{stats.week}</div>
               <div className="flex-1"></div>
            </div>
            <div className="display-flex">
               <div className="flex-1"></div>
               <div className="flex-2 admin-card-text">Siste måned:</div>
               <div className="flex-1 admin-card-number">{stats.month}</div>
               <div className="flex-1"></div>
            </div>
            <div className="display-flex mb-4">
               <div className="flex-1"></div>
               <div className="flex-2 admin-card-text">Siste år:</div>
               <div className="flex-1 admin-card-number">{stats.year}</div>
               <div className="flex-1"></div>
            </div>
            
         </div>
      </>
   )
}


export default React.memo(StatisticsCard);
