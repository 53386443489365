import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {BkButton} from '../../../components'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {isNullOrEmpty } from '../../../utils'
const SelectTemplateDialog = ({open, handleSelectedCb, templateRows}) => {
  const [templates,setTemplates]= useState([ {
     description:'',
     title:'',
     id:0,
     selected:false
  }]);
  

   useEffect(() => {
      setTemplates(templateRows);
   }, [templateRows]);

   const handleSaveAndClose= () => {   
      var res = [];
      templates.map((template) => {
         if (template.selected== true) {
            res.push(template.id);
         }
      })
      handleSelectedCb(res);
   }

   const handleClose = () => {
      handleSelectedCb(null);
   }

   const handleSelected = (e) => {
      setTemplates(templates.map(template => template.id == e.target.name? {...template,selected : e.target.checked}:template));
   }


   let templatesHtml = null;
   if (templates) {
   templatesHtml = templates.map((template, index) =>
    {
      var title = template.description;
      if (isNullOrEmpty(title)) {
         title = template.title;
      }
      return (
         <>
            <FormControlLabel
            control={<Checkbox checked={template.selected?template.selected:false} onChange={handleSelected} name={template.id.toString()} />}
            label= {title}
          />

         </>

        )
      })
  }
  
  return (
    <>
         <Dialog open={open} fullWidth={true} maxWidth = {'sm'} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className="dialog-header">Velg fra mal</DialogTitle>
            <DialogContent>
            <div className="contact-dialog simple-calc-section">
               <FormGroup>
                  {templatesHtml}
                  </FormGroup>
                  </div>
            </DialogContent>
            <DialogActions>
               <BkButton caption='OK' onClickHandler={handleSaveAndClose}/> 
            </DialogActions>
         </Dialog>

    </>
  )
}

export default React.memo(SelectTemplateDialog);