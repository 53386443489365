import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {BkButton,ContactButton} from '../../../components'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const SimpleCalcPrintDialog = ({open, handlePrintCb}) => {
   const [value, setValue] = React.useState(0);

   const handleClose = () => {
      handlePrintCb(0);
   }

   const handlePrintAndClose = () => {
      handlePrintCb(value);
   }

   const handleChange = (event) => {
      setValue(event.target.value);
   };

   const handleExampleClick= (number) => {
   var pdf = "/Content/images/utskrift-" + number + ".pdf"
   window.open(pdf);
   };

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth = {'md'} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialog-header">Skriv ut</DialogTitle>
        <DialogContent>
           <br></br>
            <FormControl component="fieldset">
               <FormLabel component="legend">Velg en utskriftstype:</FormLabel>
               <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                  <FormControlLabel value="1" control={<Radio />} label={<>1. Vis alle detaljer <span className="simple-calc-example-link" onClick={() => handleExampleClick(1)}>(eksempel)</span></>} /> 
                  <FormControlLabel value="2" control={<Radio />} label={<>2. Vis kolonne sum materiell, sum arbeid og sum post <span className="simple-calc-example-link" onClick={() => handleExampleClick(2)}>(eksempel)</span></>} /> 
                  <FormControlLabel value="3" control={<Radio />} label={<>3. Vis kolonne Sum post <span className="simple-calc-example-link" onClick={() => handleExampleClick(3)}>(eksempel)</span></>} /> 
                  <FormControlLabel value="4" control={<Radio />} label={<>4. Vis kategori sum materiell, sum arbeid og sum post. Med poster <span className="simple-calc-example-link" onClick={() => handleExampleClick(4)}>(eksempel)</span></>} /> 
                  <FormControlLabel value="5" control={<Radio />} label={<>5. Vis kategori sum post. Med sum poster <span className="simple-calc-example-link" onClick={() => handleExampleClick(5)}>(eksempel)</span></>} /> 
                  <FormControlLabel value="6" control={<Radio />} label={<>6. Vis kategori sum materiell, sum arbeid og sum post. Skjul poster <span className="simple-calc-example-link" onClick={() => handleExampleClick(6)}>(eksempel)</span></>} /> 
                  <FormControlLabel value="7" control={<Radio />} label={<>7. Vis kategori sum post. Skjul poster <span className="simple-calc-example-link" onClick={() => handleExampleClick(7)}>(eksempel)</span></>} /> 
                  <FormControlLabel value="8" control={<Radio />} label= {<>8. Vis mengde materiell <span className="simple-calc-example-link" onClick={() => handleExampleClick(8)}>(eksempel)</span></>} /> 
               </RadioGroup>
            </FormControl>
            <br></br>
            <div className="modal-tip">Tips: </div>
            <div className="modal-tip">Jo mer detaljer som synliggjøres jo flere spørsmål risikerer du å få fra kunden.</div>
            <div className="modal-tip">Ikke synliggjør mere en det som er nødvendig</div>
         </DialogContent>
         <DialogActions >
            <ContactButton caption='Avbryt' onClickHandler={handleClose}/>
            <BkButton caption='Skriv ut' onClickHandler={handlePrintAndClose}/>
         </DialogActions>
      </Dialog>
    </>
  )
}

export default React.memo(SimpleCalcPrintDialog);