import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class ProjectOwnerService extends BaseService {
  private static _projectOwnerService: ProjectOwnerService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): ProjectOwnerService {
    return this._projectOwnerService || (this._projectOwnerService = new this('projectOwner'));
  }


  public async addContact(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('post', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async deleteContact(contactId, projectId): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('delete/'+ contactId + '/' + projectId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


}

export const ProjectOwnerApi = ProjectOwnerService.Instance;