const formatDateTime = (date: string, nullValue:string=''): string => {

   if (date === undefined || date === null) {
      // Could be for a new record
      return nullValue;
    }
    let thedate = new Date(date);
    var options = {year: 'numeric', month: '2-digit', day: '2-digit' } as const;
    return thedate.toLocaleDateString('nb-NO', options);

 };


 const formatDateTimeLongFormat = (date: string, nullValue:string=''): string => {

  if (date === undefined || date === null) {
     // Could be for a new record
     return nullValue;
   }
   let thedate = new Date(date);
   var dayOptions = {year: 'numeric', month: '2-digit', day: '2-digit'} as const;
   var timeOptions = {hour:'2-digit', minute:'2-digit' } as const;
   return thedate.toLocaleDateString('nb-NO', dayOptions) + ' ' + thedate.toLocaleTimeString('nb-NO',timeOptions);

};
 
 export {
   formatDateTime,
   formatDateTimeLongFormat
 };
 
 
 


