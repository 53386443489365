import React, {useState, useEffect} from 'react';
import { AutoTextBox, UploadDialog, ApprovalMarkPicture, SectionHeaderModule, UploadImage} from '../../components'
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { actionCreators} from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { MyProfileApi } from '../../api/';

type ProfileProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly role: number;
   readonly userName: string;
   readonly isAdmin:boolean;
};

const Profile: React.FC<ProfileProps> = ({ 
   isAuthenticated, 
   role,
   userName,
   isAdmin,
   logoutUser
 }) => {

   const [userInfo,setUserInfo]= useState({
      userId:0,
      FirstName:'',
      LastName:'',
      Company:'',
      Address:''
   });

   const [userData,setUserData]= useState({
      about:'',
      profileUrl:'',
      companyUrl:'',
      signatureUrl:''
   });

   const [approvalMarks, setApprovalMarks] = useState([]);

   const params = useParams();
   const id = (params as any).id;
   // Used for edit project data
   const history = useHistory();


   useEffect(() => {
      const fetchData = async () => {
         MyProfileApi.get()
         .then((response) => {
            setUserData(response.data);
            setUserInfo(response.data.info);
            setApprovalMarks(response.data.approvalMarks);
            addBlankPicture();
          }, 
          (error) => { 
            console.log(error);
            throw (error) 
          })
      }

      fetchData();
      
   }, []);

   if(!isAuthenticated) {
       logoutUser();
       history.push("/login");
   }

   const onTextChange = (text) => {
      setUserData(userData =>({...userData, about:text}));
      updateUserData(text, userData.profileUrl,userData.signatureUrl, userData.companyUrl);
   }

   const updateUserData = (text, profileUrl, signatureUrl, companyUrl) => {
      var data = {
         userId:userInfo.userId,
         about:text,
         profileUrl:profileUrl,
         companyUrl:companyUrl,
         signatureUrl:signatureUrl
      }
      MyProfileApi.Update(data)
      .then(
         (response) => { 
         },
         (error) => {
            console.log(error);
         })
   }


   const handleProfilePictureUpdated = function(newUrl) {
      setUserData(userData =>({...userData, profileUrl:newUrl}));
      updateUserData(userData.about, newUrl,userData.signatureUrl, userData.companyUrl);
   }
  

   const handleSignaturePictureUpdated = function(newUrl) {
      setUserData(userData =>({...userData, signatureUrl:newUrl}));
      updateUserData(userData.about, userData.profileUrl, newUrl, userData.companyUrl);
   }
  
    const handleCompanyPictureUpdated = function(newUrl) {
      setUserData(userData =>({...userData, companyUrl:newUrl}));
      updateUserData(userData.about,userData.profileUrl, userData.signatureUrl, newUrl);
   }
 

   const addBlankPicture = () => {
      var freePicture = {
         url: null,
         description:'',
         approvalMarkId:0,
         order:0
      }
      setApprovalMarks(approvalMarks => [...approvalMarks, freePicture]);
   }

   const handleSavePicture = function(id, url, subtitle) {

      var model = {
         approvalMarkId: id,
         url:url,
         description:subtitle,
         order:0
      }
     
      MyProfileApi.SaveApprovalMark(model)
      .then (
         (response) => {
            model.approvalMarkId = response.data;

            if (model.url==null || model.url == '') {
               //Deleted
               setApprovalMarks(approvalMarks.filter(picture =>picture.approvalMarkId !== model.approvalMarkId));
            }
            else {
               // Update
               setApprovalMarks(
                  approvalMarks.map(approvalMark => 
                     approvalMark.approvalMarkId === id 
                     ? {...approvalMark, url : url, description:subtitle, approvalMarkId:response.data} 
                     : approvalMark 
                  ))
            }

            if (id == 0) {
               //new picture
               addBlankPicture();
            }
         },
         (error) => {
           console.log(error);
         }
       );
    }



    var approvalMarksHtml = null;
    if (approvalMarks) {
      approvalMarksHtml = approvalMarks.map((mark, index) => {
          return <>
            <div className='col-md-4'>
               <ApprovalMarkPicture id={mark.approvalMarkId} url={mark.url} description={mark.description} savePictureCb={handleSavePicture}></ApprovalMarkPicture>
            </div>
          </>
       });
    }


   return (
      <>
      <div className="container">
         <div className="bk-row-header bk-row-header-title">
            <div className="row mb-3">
               <div className="col-auto">
                  <div className='mt-2'> {userName} </div>
               </div>
            </div>
          </div>
         <div className='report-content'>
            <div className="bk-module">
               <div className="module-header">Bygningssakkyndig</div>
               <div className='module-section'>
                  <SectionHeaderModule title='Informasjon om bygningssakkyndig'></SectionHeaderModule>
                  <div className='content-frame'>
                     <div className='module-row'>
                        <div className='flex-3'>
                           <AutoTextBox text={userData.about} defaultRows={6} onValueChangeCb={onTextChange}></AutoTextBox>
                        </div>
                        <div className='flex-1 margin-left'>
                           <UploadImage 
                              pictureUrl={userData.profileUrl}
                              onUrlChangedCb={handleProfilePictureUpdated}
                           />
                        </div>
                     </div>
                  </div>

                  <SectionHeaderModule title='Godkjenningsmerker / logoer'></SectionHeaderModule>
                  <div className='content-frame'>
                     <div className='module-row row'>
                        {approvalMarksHtml}
                     </div>
                  </div>

                  <SectionHeaderModule title='Signatur'></SectionHeaderModule>
                  <div className="content-frame display-flex">
                     <div className='flex-3'>
                        <div className='profile-box'>
                           <div className='profile-picture'>
                              <UploadImage pictureUrl={userData.signatureUrl} onUrlChangedCb={handleSignaturePictureUpdated}></UploadImage>
                           </div>
                        </div>
                     </div>
                     <div className='flex-6'></div>
                  </div>

                  <SectionHeaderModule title='Firmalogo'></SectionHeaderModule>
                  <div className="content-frame display-flex">
                     <div className='flex-3'>
                     <div className='profile-box'>
                           <div className='profile-picture'>
                              <UploadImage pictureUrl={userData.companyUrl} onUrlChangedCb={handleCompanyPictureUpdated}></UploadImage>
                           </div>
                        </div>
                     </div>
                     <div className='flex-6'></div>
                  </div>
                  <br></br>
                  <br></br>
               </div>
            </div> 
         </div>
         </div>

      </>
   )
}
const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   role: state.auth.role,
   userName: state.auth.userName,
   isAdmin: state.auth.isAdmin
 });
 
 export default connect(mapStateToProps, actionCreators)(Profile);