import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';


class AreaSpecificationNewService extends BaseService {
  private static _areaSpecificationService: AreaSpecificationNewService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): AreaSpecificationNewService {
    return this._areaSpecificationService || (this._areaSpecificationService = new this('areaSpecificationNewModule'));
  }

  public async getAreaSpecification(moduleId:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    if (!moduleId){
      this.handleAllErrors(` moduleId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.get('get/' + moduleId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }

  public async addAreaSpecification(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for addAreaSpecification.`); 
      Promise.reject();
    }
    
    await this.$http.post('Add',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw (error);
    });

    return axiosResponse;
  }

  public async updateAreaSpecification(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for updateAreaSpecification.`); 
      Promise.reject();
    }
    
    await this.$http.post('Update',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  }

  public async addBuilding(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for addBuilding.`); 
      Promise.reject();
    }
    
    await this.$http.post('AddBuilding',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw (error);
    });

    return axiosResponse;
  }


  public async updateBuilding(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateBuilding',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error); throw(error)});

    return axiosResponse;
  }

  public async updateFloor(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateBuildingFloor',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error);});

    return axiosResponse;
  }

  public async AddFloor(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('AddBuildingFloor', data, 
    {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  }
  
  
  public async deleteBuilding(id:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    if (!id){
      this.handleAllErrors(`id was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.post('deleteBuilding', {id}, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }

    
  public async deleteFloor(data): Promise<AxiosResponse | null> {
    var axiosResponse;
       
    if (!data){
      this.handleAllErrors(`id was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.post('deleteBuildingFloor', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }
       
  public async getRoomTypes(key:number): Promise<AxiosResponse | null> {
    var axiosResponse;

    await this.$http.get('GetRoomTypes?key='+ key, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }

}

export const AreaSpecificationNewApi = AreaSpecificationNewService.Instance;