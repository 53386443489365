import React, {useState, useEffect, useRef} from 'react';

function OwnerFilter(props) {

    const onOwnerFilterChanged = function(e){

        if (!e){
            return;
        }

        let { value } = e.target;
        value = parseInt(value);

        if (value => 0){
            props.onOwnerFilterCb(value);
        }

    }

    const getSelectItems = () => {
        var options = [];
        var owners = props.owners.map((owner) => {
            return  <option value={owner.userId}>{owner.name}</option>
        });
        options.push(owners);
        return (
            <select className='form-control' onChange={(e) => onOwnerFilterChanged(e)}>
                {options}
            </select>
         );
    }

    return(
        <>
            {getSelectItems()}
        </>
    )
}
export default React.memo(OwnerFilter);