import React from 'react';
import ReactTooltip from 'react-tooltip';
import {renderToString } from 'react-dom/server'
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'


const ValueReduction = ({
  index, 
  calculationId, 
  valueReductions,
  valueReductionTypeCostFactorEntities,
  onChanged, 
  onBuildYearClicked, 
  }) => {

  var options = [];

  const handleValueReductionChange = function(e, reduction) {
    const {value } = e.target;
    onChanged(calculationId, reduction.valueReductionId, value);
  }

  const handleBuildYearClick = function() {
    onBuildYearClicked(calculationId);
  }

  const initialOptions = function(caption) {
    return (
      <option key={0} value={0}>{caption}</option>
    )
  }

  const displayNumericValue = function(value) {
    if (value !== 0) {
      return value;
    }
    return "";
  }

  const getName = function() {
    if (index === 0){
      return "Opprinnelig bygning";
    }
    else {
      return "Tilbygg " + index;
    }
  }

  const getValueReductions = function (valueReductionTypeCostFactorEntities, calculationId){   
    if (!valueReductionTypeCostFactorEntities)
    {
      return;
    }

   let result = [];
   result.push(initialOptions('-'));

    for(let i = 0; i < valueReductionTypeCostFactorEntities.length; i++) {
      let option =  valueReductionTypeCostFactorEntities[i];
      result.push(<option key={calculationId + '.' + option.costFactorId} value={option.costFactorId}>{option.name}</option>);
    }

   return result;
  
  }

  const getValueReductionItem =function(reduction) {

    return (
      <select className="form-control form-control-large"  value={reduction.selectedId} onChange={(e)=>handleValueReductionChange(e,reduction)}>
        {options}
      </select>
    );

  }
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  options = useMemo(()=> getValueReductions(valueReductionTypeCostFactorEntities, calculationId), [valueReductionTypeCostFactorEntities, calculationId]);

  let valueReductionsHtml = null;
  if (valueReductions) {
    valueReductionsHtml = valueReductions.map((reduction, index) =>
    {
      if (reduction.controlTypeId === 0 && reduction.visible) {
        // Select
        return (
          <div className="display-flex calculation-row" key={calculationId + '.' + index}>
            <div className="flex-6 calculation-cell">{reduction.description}</div>
            <div className="flex-3 calculation-cell calculation-input-frame">
              <div className="input-icon input-icon-right inline-block ">
                {getValueReductionItem(reduction)}
              </div>
            </div>
            <div className="flex-1"></div>
          </div>
        );
      }
      else if (reduction.controlTypeId === 3 && reduction.visible) {
        // numeric
        return (
          <div className="display-flex calculation-row" key={calculationId + '.' + index}>
            <div className="flex-6 calculation-cell">{reduction.description}</div>
            <div className="flex-3 calculation-cell calculation-input-frame">
              <div className="input-icon input-icon-right inline-block ">
                <i>kr</i>
                <input className="form-control form-control-large area-table-header-input" placeholder="0" type="text" value={displayNumericValue(reduction.numericValue)} onChange={(e)=>handleValueReductionChange(e,reduction)} />
              </div>
            </div>
            <div className="flex-1"></div>
          </div>
        );
      }        
      else if (reduction.controlTypeId === 4 && reduction.visible) {
        // text
        return (
          <div className="display-flex calculation-row" key={calculationId + '.' + index}>
            <div className="flex-9 calculation-note-row">
              <div className="calculation-cell">{reduction.description}</div>
              <div className="calculation-cell">
                <div className="input-icon input-icon-right">
                  <textarea className="form-control form-control-note" rows={3}  placeholder="Kort Forklaring" value={reduction.textValue} onChange={(e)=>handleValueReductionChange(e,reduction)}></textarea>
                </div>
              </div>
            </div>
            <div className="flex-1"></div>
          </div>
        );
      } 
      else 
        return null;
    });
  }


  const getValueReductionHelpText = function() {
    return  (
      <>
        <h5><b>Verdireduksjon</b></h5>
        <div className="helptext-list-item">
            <p>Velg byggeår på bygningsdel</p>
        </div>
        <div className="helptext-list-item">
            <p>Dersom alle bygningsdeler er fra byggeår trykk «Bruk byggeår»</p>
        </div>
        <div className="helptext-list-item">
            <p>Dersom det er gjort oppgraderinger på bygningen etter byggeår velges alder på bygningsdelen</p>
        </div>
        <div className="helptext-list-item">
            <p>Vurderes bygningsdelen å ha mer (eller mindre) slitasje i forhold til det som kan forventes fra byggeår må antall år justeres (skjønn)</p>
        </div>
        <div className="helptext-list-item">
            <p>TEK og levetidstabeller er hensyntatt i sum for verdireduksjon.</p>
        </div>
        <div className="helptext-list-item">
            <p>Ekstraordinær verdireduksjon</p>
        </div>
        <div className="helptext-list-item-level2">
            <p>Denne posten skal man aller helst IKKE bruke!!!</p>
        </div>
        <div className="helptext-list-item-level2">
                <p>Brukes kun for ekstraordinære verdireduksjon ut over det som medtas i de andre postene.</p>
        </div>
      </>
    )
  }


  return (
    <>
      <div className="container">
      <div className="bk-section">
        <div className="row">
        <div className="col-md-10">
          <div className=' bk-small-header mt-1'> Verdireduksjon - {getName()}
          <FontAwesomeIcon icon={faQuestionCircle} className=" ms-2 mt-1 bk-header-icon"  data-for={"index"+ index} data-tip={renderToString(getValueReductionHelpText())}/>
          </div>
          <ReactTooltip place="left" id={"index"+ index}  type="light" html={true} effect="solid"  className="helptext-custom"/>
        </div>
        <div className='col-md-2'>
        <div className="area-table-button ">
          <input name="UseBuildYear" type="submit" className="value-reduction-button-green" value="Bruk Byggeår" title="Trykk her for å sette alder til byggeår for alle feltene" 
          onClick={handleBuildYearClick}/>
          </div>
        </div>
        
      
      </div>
      
      
      <div className="calculation-frame">
        {valueReductionsHtml}
      </div>
      

      
        </div>
      </div>
    </>
  )
}


export default React.memo(ValueReduction);