import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { Description} from '../containers/Modules/DescriptionModule/types'

class DescriptionModuleService extends BaseService {
   private static _descriptionModuleService: DescriptionModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): DescriptionModuleService {
     return this._descriptionModuleService || (this._descriptionModuleService = new this('descriptionModule'));
   }
 

   public async getDescriptionModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addDescriptionModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;
      if (!data){
         this.handleAllErrors(` Data was not provided for addDescriptionModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateDescriptionModule(data: Description): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateDescriptionModule.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 


   public async addCategory(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Id was not provided for addCategory.`); 
        Promise.reject();
      }
      
      var model= {
        id:id
      }
      await this.$http.post('AddCategory',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
   } 

   public async deleteCategory(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Id was not provided for deleteCategory.`); 
        Promise.reject();
      }
      
      var model= {
        id:id
      }
      await this.$http.post('DeleteCategory',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
   } 

   public async updateCategory(data): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for updateCategory.`); 
         Promise.reject();
      }

      await this.$http.post('UpdateCategory',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });

      return axiosResponse;
   } 


   public async getSubCategories(categoryId:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    if (!categoryId){
      this.handleAllErrors(` categoryId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.get('getSubCategories/' + categoryId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
 }

   public async addSubCategory(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Id was not provided for addSubCategory.`); 
        Promise.reject();
      }
      
      var model= {
        id:id
      }
      await this.$http.post('AddSubCategory',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
   } 

   public async deleteSubCategory(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Id was not provided for deleteSubCategory.`); 
        Promise.reject();
      }
      
      var model= {
        id:id
      }
      await this.$http.post('DeleteSubCategory',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
   } 

   public async updateSubCategory(data): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for updateSubCategory.`); 
         Promise.reject();
      }

      await this.$http.post('UpdateSubCategory',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });

      return axiosResponse;
   } 

   public async getPictures(subCategoryId:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    if (!subCategoryId){
      this.handleAllErrors(` subCategoryId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.get('getPictures/' + subCategoryId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
 }

   public async savePicture(data): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for savePicture.`); 
         Promise.reject();
      }

      await this.$http.post('savePicture',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });

      return axiosResponse;
   } 

   public async deletePicture(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('deletePicture/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

}

export const DescriptionModuleApi = DescriptionModuleService.Instance;