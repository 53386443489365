import React, {useState} from 'react';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
//import AlertDialog from '../../../components/Dialogs';

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ProjectApi } from '../../../api';
import {ConfirmationDialog} from '../../../components/Dialogs'
import {Spinner} from '../../../components'
// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

const ProjectRow = ({project, index, onDeleteCb, onCopyCb, loginName}) => {
  const [open, setOpen] = useState(false);
  const [zoom, setZoom] = useState(false);
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageZoomOpen = () => {
    setZoom(true);
  }

  const handleImageZoomClose = () =>  {
    setZoom(false);
  }

  const history = useHistory();


  const formatDateTime = function(date) {
    if (date === undefined || date === null) {
      // Could be for a new record
      date = new Date();
    }
    let thedate = new Date(date);
    var options = {year: 'numeric', month: '2-digit', day: '2-digit' } as const;
    return thedate.toLocaleDateString('nb-NO', options);
  }

  const formatAddress = function(project) {
    return project.address + ', ' + project.zipCode + ' ' + project.city;
  }


  const getRowClass = function(index) {
    if (index % 2 === 0) {
      return "main-table-grid main-table-data-row white-row"
    }
    else {
      return "main-table-grid main-table-data-row grey-row"
    }
  }

  const getStatusClass = function(status) {
    if (status === 1){
      return "status-icon status-icon-active"
    }
    if (status === 2){
      return "status-icon status-icon-closed"
    }
    if (status === 3){
      return "status-icon status-icon-deleted"
    }

    return "status-icon";
  }

  const id = project.projectId;

  const handleEditProject = event => {
    event.preventDefault();
    const url = "/project/" + id;
    history.push(url);
  }

  const handlePrintProject = event => {
    event.preventDefault();
    setIsLoading(true);
    ProjectApi.getPdf(id);
    setTimeout(stopSpinner, 1500);
  }

  const stopSpinner = function() {
    setIsLoading(false)
  }

  const handleCopyProject = event => {
    event.preventDefault();
    onCopyCb(id);
  }

  const handleDeleteProject = event => {
    setDisplayConfirmDialog(true);
  }

  const handleConfirmDialogClose = function(id) {
    if (id > 0) {
      onDeleteCb(id);
    }
    setDisplayConfirmDialog(false);
  }




  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
              elevation={0}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleEditProject}>Rediger</MenuItem>
            <MenuItem onClick={handlePrintProject}>Skriv ut</MenuItem>
            <MenuItem onClick={handleCopyProject}>Kopier</MenuItem>
            <MenuItem onClick={handleDeleteProject}>Slett</MenuItem>
          </Menu>
        </div>;
  }


    const getPictureUrl = function(url) {
    if (url !== null && url !== 'undefined' && url !== '') {
      return "/upload/" + url;
    }
    else {
      return "/images/design/no-image.png";
    }
  }

  const getPicture = function(imageUrl) {
    if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
      return (
        <div className='project-picture'>
          <img
                className="projects-image"
                alt={formatAddress(project.address)}
                src={getPictureUrl(imageUrl)}
                width="500"
                onClick={handleImageZoomOpen}

              />

          {zoom && (
            <Lightbox image={getPictureUrl(imageUrl)} title={formatAddress(project.address)} onClose={handleImageZoomClose}></Lightbox>

          )}

      </div>      )
    }
    else {
      return ( 
        <div className=" project-picture " >
        </div>);
    }
  }

  const showOwner = function() {
    if (project.userName != loginName){
      return project.userName
    }
  }

  return (
    <>
      <div className ={getRowClass(index)}>

          {getPicture(project.imageUrl)}
     
          <div className='status-cell'><div className={getStatusClass(project.state)}></div></div>
          <div onClick={handleEditProject}>{formatDateTime(project.created)}</div>
          <div onClick={handleEditProject}>{formatDateTime(project.updated)}</div>
          <div className="bk-project-row-align-left text-trim" onClick={handleEditProject}>{formatAddress(project.address)}</div>
          <div onClick={handleEditProject}>{project.address.gnr}</div>
          <div onClick={handleEditProject}>{project.address.bnr}</div>
          <div onClick={handleEditProject}>{project.address.fnr}</div>
          <div onClick={handleEditProject}>{project.address.snr}</div>
          <div onClick={handleEditProject}>{project.address.lnr}</div>
          <div onClick={handleEditProject}>{project.address.andelsnr}</div>
          <div onClick={handleEditProject}>{showOwner()}</div>
          <div>
              <ActionMenu />
          </div>
      </div>


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Kommer snart"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tjenesten kommer snart
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
          {/* <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
          title="Slette?"
          text="Er du sikker på at du vil slette prosjektet"
          open={displayConfirmDialog}
          id={id}
          onClose={handleConfirmDialogClose}
      />

      <Spinner
        isLoading={isLoading}
      />
    </>
  )
}

ProjectRow.displayName = 'ProjectRow';

export default React.memo(ProjectRow);
