import React, {useState, useEffect} from 'react'
import ImageWithSubTitle from './ImageWithSubTitle';
import {isNullOrEmpty } from '../../utils'

export interface ImageContainerProps {
   readonly pictures: any,
   readonly loaded: boolean,
   readonly readOnly: boolean,
   readonly columns:number,
   readonly displayHeader:boolean,
   readonly key?:string,
   readonly onPictureUpdated?: (id:number, url:string, subTitle: string, mainTitle: string) =>  Promise<number>;
   readonly onPictureAdded?: (id:number, url:string, subTitle: string, mainTitle: string) =>  Promise<number>;
   readonly onPictureDeleted?: (id:number) =>  Promise<number>;
 }



function ImageContainer(props:ImageContainerProps) {
   const [pictures, setPictures] = useState([]);
   const [nextIndex, setNextIndex] = useState(1);
   const [key, setKey] = useState('small-picture');

   useEffect(() => {
      if (props.loaded ) {
         setPictures(
            props.pictures.map(picture => {
               picture.tag = 'i_' + picture.id;
               return picture;
            }));
         addBlankPicture();
      }
      if (props.key != undefined) {
         setKey(props.key)
      }
   },[props.pictures]);


   const addBlankPicture = () => {
      var picture = {
         id:0,
         url:'',
         subTitle:'',
         mainTitle:'',
         tag:'t_'+ nextIndex
      }
      setPictures(pictures => [...pictures, picture]);
      setNextIndex( nextIndex+1);
   }

   // const checkForEmptyPictures = () => {
   //    var copy = pictures;
   //    if (copy.length == 0 ||
   //       (copy[copy.length-1].url != null && copy[copy.length-1].url != '') ||
   //       (copy[copy.length-1].subTitle != null && copy[copy.length-1].subTitle != '')||
   //       (copy[copy.length-1].mainTitle != null && copy[copy.length-1].mainTitle != ''))
   //    {
   //       addBlankPicture()
   //    } 
      
   // }


   const updatePicture = async (id, url, subTitle, mainTitle) => {
      if (isNullOrEmpty(url) && isNullOrEmpty(subTitle) && isNullOrEmpty(mainTitle) && id != 0) {
         //delete
         setPictures(pictures.filter(sig =>sig.id !== id));
         await props.onPictureDeleted(id);
      }
      else if (id == 0 && !isNullOrEmpty(url) || id == 0 && !isNullOrEmpty(subTitle) || id == 0 && !isNullOrEmpty(mainTitle)) {
         //add
         await props.onPictureAdded(id, url, subTitle, mainTitle);
      }
      else if (!isNullOrEmpty(url) && id != 0 || !isNullOrEmpty(subTitle) && id != 0 || !isNullOrEmpty(mainTitle) && id != 0) {
         //update
         await props.onPictureUpdated(id, url, subTitle,mainTitle);
      }
   }

   const getClassForDiv = () => {
      if (props.columns == 3) {
         return 'col-4'
      }
      else if (props.columns == 2) {
         return 'col-6'
      }
      else {
         return 'col-12'
      }
   }

   const getClassForPicture = () => {
      if (props.columns == 3) {
         return 'picture-module-picture-3-cols'
      }
      else if (props.columns == 2) {
         return 'picture-module-picture-2-cols'
      }
      else {
         return 'picture-module-picture-1-col'
      }
   }



   var smallPicturesHtml = null;
   if (pictures) {
      smallPicturesHtml = pictures.map((picture, index) => {               
         return <>
            <div className={getClassForDiv()}>
               <ImageWithSubTitle key={key + '-' + picture.tag} class={getClassForPicture()} picture={picture} readOnly={props.readOnly}
                  allowEdit={props.readOnly} updatePictureCb={updatePicture} showTitle={props.displayHeader}></ImageWithSubTitle>
            </div>
            </>
      });
   }

   return (
       <>
         <div className='row bk-text-blue' key={key}>
            {smallPicturesHtml}
         </div>         
       </>
    )

                      
}
  
export default ImageContainer;