import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class CountyService extends BaseService {
  private static _countyService: CountyService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): CountyService {
    return this._countyService || (this._countyService = new this('county'));
  }

  public async getCounties(): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('get', {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }
 
}



export const CountyApi = CountyService.Instance;