import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class DetailedDescriptionModuleService extends BaseService {
  private static _detailedDescriptionModuleService: DetailedDescriptionModuleService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): DetailedDescriptionModuleService {
    return this._detailedDescriptionModuleService || (this._detailedDescriptionModuleService = new this('DetailedDescriptionModule'));
  }

  public async getDetailedDescriptionModule(moduleId:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    if (!moduleId){
      this.handleAllErrors(` moduleId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.get('get/' + moduleId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
 }

 public async addDetailedDescriptionModule(data: {moduleId:number}): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
       this.handleAllErrors(` Data was not provided for addDetailedDescriptionModule.`); 
       Promise.reject();
    }
    
    await this.$http.post('Add',data, {withCredentials: true})
    .then((response) => {
       axiosResponse = response;
    }, 
    (error) => {
          this.handleAllErrors(error); 
          throw (error);
    });

    return axiosResponse;
 }

 public async updateDetailedDescription(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for Update.`); 
      Promise.reject();
    }
    
    await this.$http.post('Update',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  } 

  public async updateMainPoint(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for UpdateMainPoint.`); 
      Promise.reject();
    }
    
    await this.$http.post('UpdateMainPoint',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  } 

  public async updateSubPoint(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for UpdateSubPoint.`); 
      Promise.reject();
    }
    
    await this.$http.post('UpdateSubPoint',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  } 

  public async AddFromTemplate(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for AddFromTemplate.`); 
      Promise.reject();
    }
    
    await this.$http.post('addFromTemplate',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  } 
  

  public async AddTemplate(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for AddTemplate.`); 
      Promise.reject();
    }
    
    await this.$http.post('addTemplate',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  } 
  

}




export const DetailedDescriptionModuleApi = DetailedDescriptionModuleService.Instance;