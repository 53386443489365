import React, {useRef}from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RoutesConfig } from '../../config/routes.config';
import { actionCreators } from '../../store/auth';
import { useHistory } from 'react-router-dom'
import { AuthApi } from '../../api';
import Form from 'react-bootstrap/Form';
import { ContactButton } from '../../components';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';
import { faTrashAlt, faPlus, faBars, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const Forgot: React.FC = () => {
   const [values, setValues] = React.useState({
      email: ''
    });

   const history = useHistory();

   const toastIdRef = useRef<string | number>('');

   const handleChange = name => event => {
      setValues({ ...values, [name]: event.target.value });
    };
  
   function isValidEmail(email) {
   return /\S+@\S+\.\S+/.test(email);
   }

   const handleSubmit = () => {
      if (!isValidEmail(values.email)) {
         if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.error(
              renderToastifyMsg("Kontroller at epost adressen er gyldig")
            );
          }
         return;
      }
      var dataToSend= {
        email: values.email,
      }
  
      AuthApi.ForgotPassword(dataToSend)
      .then(
         (response) => {
            history.push(RoutesConfig.ForgotConfirmation.path)
         },
         (error) => {
            console.log("forgot password failed");
            history.push(RoutesConfig.ForgotConfirmation.path)
         });
      }


   return (
      <div className="container">
            <div className="row mt-5">
            <div className="col-5 mx-auto register-container mt-2 shadow">
               <Form onSubmit={handleSubmit} className="mt-3">
               <Form.Text className="register-text">
               Skriv inn epost adressen knyttet til bruker
               </Form.Text>

               <Form.Control type="text" placeholder="E-post" required className="register-control mt-3 mb-3" 
               onChange={handleChange('email')} />

               <Form.Text className="register-text-muted ">
               Du vil få en e-post med en midlertidig passord
               </Form.Text>
            
               <div className="row justify-content-center">
                     <div className='col-lg-3 mt-4 mb-4'>
                     <ContactButton caption='Send' onClickHandler={handleSubmit}/>
               </div>
               </div>
               </Form>
            </div>
            </div>         
         </div>
   );
   };


   const mapStateToProps = (state: IApplicationState) => state.auth;

export default connect(mapStateToProps, actionCreators)(Forgot);
