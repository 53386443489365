import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators} from '../../store/auth';
import { useHistory } from 'react-router-dom'
import {TextField , Button} from '@material-ui/core'
import { HomeApi } from '../../api';
import { RoutesConfig } from '../../config/routes.config';
import { BkButton, SectionHeader } from '../../components';
import { ContactButton } from '../../components';
import parse from 'html-react-parser';
import { consoleLog } from '../../utils/consoleHelper';

type AcceptProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly role: number;
  readonly userName: string;
};


const Accept: React.FC<AcceptProps> = ({ 
      isAuthenticated, 
      userName, 
      role,
      logoutUser
   }) => {

   const [message,setMessage]= useState({
      messageId:0,
      messageText:'',
      content:'',
      heading:'',
      termsHeading:'',
      termsContent:''
   });

   useEffect(() => {
      const fetchData = async () => {
         HomeApi.getAcceptMessage()
         .then(
         (response) => {    
            setMessage(response.data);
         },
         (error) => {
            console.log(error);
            logoutUser();
            history.push(RoutesConfig.Home.path);
         });
      }

      fetchData();
   }, []);


   const history = useHistory();
   if(!isAuthenticated) {
      logoutUser();
      history.push(RoutesConfig.Login.path);
   }
    

   const handleAccept= () => {
      HomeApi.sendAcceptResponse(message.messageId, true)
      .then(
         (response) => {
            history.push(RoutesConfig.Invoice.path);
         },
         (error) => {
            console.log(error);
         });
   }

   const handleDecline= () => {
      HomeApi.sendAcceptResponse(message.messageId, false)
      .then(
         (response) => {
            logoutUser();
            history.push("/login");
         },
         (error) => {
            console.log(error);
        });
   }

   var contentHtml = parse(message.content);
   var termsHtml = parse(message.termsContent);

   return (
      <>
         <div className="container">
            
               <div className="row mt-3">
                  <div className='col-md-8'>
                     <div className="bk-row-header bk-row-header-title ms-1 mb-2">
                        {message.termsHeading}
                     </div>
                  </div>
               </div>
               
            <div className="report-content">      
               <div className="row mx-3">

                  <div className="bk-text-blue">
                     {termsHtml}
                  </div>  
                  
                  <div className='mt-3 mb-4'><SectionHeader title={message.heading}></SectionHeader> </div>
                  <br />
                  <div className="bk-text-blue ms-2">{contentHtml}</div>

               </div>         

               <div className="row justify-content-end mb-2 me-4">
                  <div className='col-lg-2'> <ContactButton caption='Aksepter' onClickHandler={handleAccept}/></div>
                  <div className='col-lg-2'> <BkButton caption='Avslå'  onClickHandler={handleDecline} /></div>
               </div>

            </div>
         </div>
      </>
   )
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   role: state.auth.role,
   userName: state.auth.userName,
});
 
export default connect(mapStateToProps, actionCreators)(Accept);