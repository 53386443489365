import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class UploadService extends BaseService {
   private static _uploadService: UploadService;


   private constructor(controllerName: string) {
      super(controllerName);
   }


   public static get Instance(): UploadService {
      return this._uploadService || (this._uploadService = new this('upload'));
   }


   public async upload(data): Promise<AxiosResponse> {
      var axiosResponse;
      
      const config = {   
         withCredentials: true,  
         headers: { 'content-type': 'multipart/form-data' }
      }
   
      await this.$http.post('upload',data, config)
         .then((response) => {
            axiosResponse = response;
         }, 
         (error) => {this.handleError(error); });

      return axiosResponse;
   }

   public async Transform(fileName, rotation): Promise<AxiosResponse> {
      var axiosResponse;

      const model = {
         url:fileName,
         rotation:rotation
      }
   
      await this.$http.post('transform',model, {withCredentials: true})
         .then((response) => {
            axiosResponse = response;
          }, 
         (error) => {this.handleError(error); });

      return axiosResponse;
   }

   public async SaveImage(data): Promise<AxiosResponse> {
      var axiosResponse;
      
      await this.$http.post('saveImage',data,  {withCredentials: true})
         .then((response) => {
            axiosResponse = response;
         }, 
         (error) => {this.handleError(error); });

      return axiosResponse;
   }

}



export const UploadApi = UploadService.Instance;