import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { actionCreators} from '../../store/auth';
import { OwnershipApi, ResidentApi, ProjectApi } from '../../api';
import {formatValue, isNullOrEmpty } from '../../utils'
import {ConfirmationDialog} from '../../components/Dialogs'
import {ProjectLink, SectionHeader, CategoryHeader, CategoryHeaderModule} from '../../components'

type OwnershipProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly role: number;
  readonly userName: string;
};


const keyPressTimer = 1000;
let ownershipTimer = null;
let residentTimer = null;


const Ownership: React.FC<OwnershipProps> = ({ isAuthenticated, userName, role }) => {

   const [ownership,setOwnership]= useState({
      projectId:0,
      calculationTypeId:1,
      marketValue:0,
      marketValueMonth:0,
      value:0,
      comment:'',
      description:''
   });
   const [project,setProject]= useState({
      address: {
         address:'',
         zipCode:'',
         city:''
      }
   });
   const [residents,setResidents]= useState([]);
   const [relevantResident, setRelevantResident]= useState({
      name:'',
      age:0,
      remainingYears:0,
      gender:0,
      factor:0
   });
   const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
   const [selectedResidentId, setSelectedResidentIdg] = useState(0);
   


   const history = useHistory();
   const params = useParams()

   const id = (params as any).id;

   var typeOptions = [];
   var genderOptions = [];
   var dayOptions = [];
   var monthOptions = [];
   var yearOptions = [];
   const isFirstRun = useRef(true);

   useEffect(() => {
      const fetchData = async () => {
         OwnershipApi.get(id)
            .then(
            (response) => {            
               setOwnership(response.data);
               getResidents(response.data);
               getProject(response.data.projectId);
            },
            (error) => {
               console.log(error);
               actionCreators.logoutUser();
               history.push("/login");           
            });  
      }
      fetchData();
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      if (isFirstRun.current) {
         isFirstRun.current = false;
         return;
       }

  
   }, [residents]);

   const getResidents = function(ownership) {
      ResidentApi.getResidents(ownership.ownershipId)
         .then(
         (response) => {      
            setResidents(response.data);
            if (response.data.length > 0) {
               var selected = response.data.find(r=>r.residentId == ownership.relevantResidentId);

               if (selected !== null && selected !== undefined) {
                  setRelevantResident(selected)
               }
            }
            // Add a blank row
            addBlankResidentRow();
         },
         (error) => {
            console.log(error);
            actionCreators.logoutUser();
            history.push("/login");        
         });  
   }

   const getProject = function(projectId) {
      ProjectApi.getProject(projectId)
      .then(
      (response) => {            
         setProject(response.data.data);
      },
      (error) => {
         console.log(error);
         actionCreators.logoutUser();
         history.push("/login");     
      }); 
   }

   const handleProjectClick = function() {
      history.push('/project/' + ownership.projectId);
   }

   const getOption = function(key, value, caption) {
      return (
        <option key={key} value={value}>{caption}</option>
      )
   }

   const fillTypeOptions = function () {
      typeOptions.push(getOption('type-1',1,'Helårsbolig'));
      typeOptions.push(getOption('type-4',4,'Fritidsbolig'));
   }

   const fillGenderOptions = function () {
      genderOptions.push(getOption('Velg',-1,'Velg kjønn'));
      genderOptions.push(getOption('gender-male',0,'Mann'));
      genderOptions.push(getOption('gender-female',1,'Kvinne'));
   }

   const fillDayOptions = function () {
      dayOptions.push(getOption('Velg',0,'Velg dag'));
      for (var i=1;i <= 31 ; i++) {
         dayOptions.push(getOption('day-' + i,i,i.toString()));
      }
   }

   const fillMonthOptions = function () {
      monthOptions.push(getOption('Velg',0,'Velg måned'));
      monthOptions.push(getOption('month-1',1,'Januar'));
      monthOptions.push(getOption('month-2',2,'Februar'));
      monthOptions.push(getOption('month-3',3,'Mars'));
      monthOptions.push(getOption('month-4',4,'April'));
      monthOptions.push(getOption('month-5',5,'Mai'));
      monthOptions.push(getOption('month-6',6,'Juni'));
      monthOptions.push(getOption('month-7',7,'Juli'));
      monthOptions.push(getOption('month-8',8,'August'));
      monthOptions.push(getOption('month-9',9,'September'));
      monthOptions.push(getOption('month-10',10,'Oktober'));
      monthOptions.push(getOption('month-11',11,'November'));
      monthOptions.push(getOption('month-12',12,'Desember'));
   }

   const fillYearOptions = function () {
      yearOptions.push(getOption('Velg',0,'Velg år'));
      var currentYear = new Date().getFullYear();
      for (var i=1900;i <= currentYear ; i++) {
         yearOptions.push(getOption('year-' + i,i,i));
      }
   }


   /* Resident functions */

   const handleResidentTextChange = function(e, resident) {
      const { name, value } = e.target;

      var newResidents = residents.map(res => 
         res.residentId === resident.residentId 
         ? {...res, [name] : value} 
         : res
      );

      setResidents(newResidents);
      var changedResident = newResidents.find(r=>r.residentId ==resident.residentId);
      if (residentTimer !== null){
         clearTimeout(residentTimer);
       }
       residentTimer = setTimeout(() => {
         updateResident(changedResident);;
       }, keyPressTimer);  
   }

   const updateResident = function(resident) {

      if (resident.residentId > 0) {
         ResidentApi.updateResident(resident)
         .then(
            (response) => { 
               var newResidents = residents.map(res => 
                  res.residentId === resident.residentId 
                  ? response.data 
                  : res
               );
               setResidents(newResidents);

               // Get the new relevant
               getRelevantResident(newResidents);
               // Recalculate
               getValue();

               checkIfBlankResidentShouldBeAdded();
            },
            (error) => {
               console.log(error);
            })
      }
      else {
         ResidentApi.addResident(resident)
         .then(
            (response) => { 
               // Update the Id to the table
               var newResidents = residents.map(res => 
                  res.residentId === 0 
                  ? response.data
                  : res
               );
               setResidents(newResidents);
               // recalculate
               checkIfBlankResidentShouldBeAdded();
            },
            (error) => {
               console.log(error);
            }) 
      }
   }

   const getRelevantResident = function(newResidents) {
      OwnershipApi.getRelevantId(id)
      .then(
         (response) => {
            var selected = newResidents.find(r=>r.residentId == response.data);
            if (selected !== null && selected !== undefined) {
               setRelevantResident(selected)
            }
         },
         (error) => {
            console.log(error);
         }
      )
   }

   const getValue = function() {
      OwnershipApi.getValue(id)
      .then(
         (response) => {
            // response data contains the value
            setOwnership(ownership =>({...ownership, value:parseInt(response.data)}));            
         },
         (error) => {
            console.log(error);
         }
      )
   }


   const handleResidenSelectChange = function(e, resident) {
      const { name, value } = e.target;

      var intVal = parseInt(value);

      var newResidents = residents.map(res => 
         res.residentId === resident.residentId 
         ? {...res, [name] : intVal} 
         : res
      );

      setResidents(newResidents);

      var changedResident = newResidents.find(r=>r.residentId ==resident.residentId);
      updateResident(changedResident);
   }


    const handleConfirmDialogClose = function(id) {
      if (id > 0) {
         ResidentApi.deleteResident(id)
         .then(
         (response) => { 
            // Update the Id to the table
            setResidents(residents.filter(resident =>resident.residentId !== id));
   
            // Get the new relevant
            getRelevantResident(residents);
            // Recalculate
            getValue();
   
            // recalculate
            checkIfBlankResidentShouldBeAdded();
         },
         (error) => {
            console.log(error);
         }) 
      }
      setDisplayConfirmDialog(false);
    }



   const handleDeleteResident = function(residentId) {   
      setSelectedResidentIdg(residentId);
      setDisplayConfirmDialog(true);
   }

   const checkIfBlankResidentShouldBeAdded = function() {
      var addBlank = false;
      if (residents.length > 0) {
         var lastRow = residents[residents.length-1];
         if ( !isNullOrEmpty(lastRow.name) || !(lastRow.year == 0) || !(lastRow.month == 0) || !(lastRow.day== 0) || !(lastRow.gender== -1)) {
            addBlank = true;
         }

      }
      else {
         addBlank = true;
      }

      if (addBlank) {
         addBlankResidentRow();
      }
   }

   const addBlankResidentRow = function() {
      var blankRow = {
         residentId:0,
         ownershipId: parseInt(id),
         name:'',
         year:0,
         month:0,
         day:0,
         age:0,
         remainingYears:0,
         gender:-1,
         factor:0
      }
      setResidents(residents => [...residents, blankRow])
   }

   const displayNumericValue = function(value) {
      if (value !== 0) {
        return value;
      }
      return "";
    }


   const handleOwnershipTextChange = function(e) {
      const { name, value } = e.target;

      var changed = ownership;
      if (name == 'marketValue') {
         if (isNaN(value)) {
           return;
         }
         changed.marketValue = parseInt(value);
         setOwnership(ownership =>({...ownership, [name]:parseInt(value)}));
      }
      if (name == 'marketValueMonth') {
         if (isNaN(value)) {
            return;
         }
         changed.marketValueMonth = parseInt(value);
         setOwnership(ownership =>({...ownership, [name]:parseInt(value)}));
      }
      if (name == 'comment') {
         changed.comment = value;
         setOwnership(ownership =>({...ownership, [name]:value}));
      }

      if (name == 'description') {
         changed.description = value;
         setOwnership(ownership =>({...ownership, [name]:value}));
      }

      if (ownershipTimer !== null){
         clearTimeout(ownershipTimer);
       }
       ownershipTimer = setTimeout(() => {
         updateOwnership(changed);;
       }, keyPressTimer);  
   }

   const handleOwnershipChange = function(e) {
      const { name, value } = e.target;

      var changed = ownership;
      if (name == 'calculationTypeId') {
         changed.calculationTypeId = parseInt(value);
      }

      setOwnership(ownership =>({...ownership, [name]:parseInt(value)}));
      updateOwnership(changed);
      
   }

   const updateOwnership = function(data) {
      OwnershipApi.updateOwnership(data)
      .then(
         (response) => {
            // response data contains the value
            setOwnership(ownership =>({...ownership, value:response.data}));   
         },
         (error) => {
            console.log(error);
         }
      )
   }

   const handlePrint = function() {
      OwnershipApi.getPdf(id).then (
         (response) => {
         },
         (error) => {
            console.log(error);
         }
      );
   }

   const getGender = function(gender) {
      if (gender === 0){
         return "Mann";
      }
      else if (gender == 1) {
         return "Kvinne";
      }
   }

   const getSumText = function() {
      if (ownership.calculationTypeId == 1) {
         return "Verdi av borett utgjør kr.:"
      }
      else {
         return "Verdi av bruksrett utgjør kr.:"
      }
   }

   if (typeOptions.length === 0) {
      fillTypeOptions();
   }
   if (genderOptions.length === 0) {
      fillGenderOptions();
   }

   if (yearOptions.length === 0) {
      fillYearOptions();
   }

   if (monthOptions.length === 0) {
      fillMonthOptions();
   }

   if (dayOptions.length === 0) {
      fillDayOptions();
   }

   var residentsHtml = null;
   if (residents) {
      residentsHtml = residents.map((resident, index) =>{
         return (
            <>
               <div key={'otr' + index}  className="ownership-table-row">
                  <div className="flex-2 me-3">
                     <input className="form-control ownership-control-large" name="name" type="text" value={resident.name} onChange={(e)=>handleResidentTextChange(e,resident)}/>
                  </div>
                  <div className="flex-1 me-3">
                     <select className="form-control ownership-control-small" name="year" value={resident.year} onChange={(e)=>handleResidenSelectChange(e,resident)}>
                        {yearOptions}
                     </select>                    
                  </div>
                  <div className="flex-1 me-3">
                     <select className="form-control ownership-control-small" name="month" value={resident.month} onChange={(e)=>handleResidenSelectChange(e,resident)}>
                        {monthOptions}
                     </select>                    
                  </div>
                  <div className="flex-1 me-3">
                     <select className="form-control ownership-control-small" name="day" value={resident.day} onChange={(e)=>handleResidenSelectChange(e,resident)}>
                        {dayOptions}
                     </select>                    
                  </div>
                  <div className="flex-1">
                     <select className="form-control ownership-control-small" name="gender" value={resident.gender} onChange={(e)=>handleResidenSelectChange(e,resident)}>
                        {genderOptions}
                     </select>      
                  </div>
                  <div className="ownership-table-delete-img mt-1" >
                     <img className={(resident.residentId == 0 )?'visibility-hidden':''} title="Slett rettighetshaver" src="/Content/images/font-awsome/black/times.png" onClick={()=>handleDeleteResident(resident.residentId)}/>
                  </div>
               </div>


            </>
         )
      })
   }


   return (
      <>
          <div className="container">
            <div className="bk-row-header bk-row-header-title">
               <div className="row mb-3 ">
                  <div className="col-6">
                  Bo- og bruksrett  
                  </div>
                    
                 
               <div className='col-5 d-flex justify-content-end'>              
                  <ProjectLink id={ownership.projectId} address={project.address.address} zipCode={project.address.zipCode} city={project.address.city}/>
                     </div>
               
               <div className='col-auto content-end'>
                  <img className="bk-header-icon-printer" src="/Content/images/design/icon-print.svg" title="Skriv ut" onClick={handlePrint}/>
               </div>
               </div> 
               </div>       
               <div className='report-content'>
               <div className="row mt-2">
               <div className="col-md-1 ms-4">
                     <div className="bk-edit-comment">Merknad:</div>
                     </div>
                <div className="col-md-5">
                     <input className="form-control bk-edit-comment-input" name="description" type="text" value={ownership.description} onChange={handleOwnershipTextChange}/>
                  </div>
               </div>


               <div className="bk-page-section">
                  <SectionHeader title={'Rettighetshaver(e)'}></SectionHeader><div className='content-frame'> 
                  <div className="">
                     <div className="ownership-table">
                        <div  className="ownership-table-row">
                           <div className="flex-2"><div>Navn</div></div>
                           <div className="flex-1"><div>År</div></div>
                           <div className="flex-1"><div>Måned</div></div>
                           <div className="flex-1"><div>Dag</div></div>
                           <div className="flex-1"><div>Kjønn</div></div>
                           <div className="ownership-table-action-menu"></div>
                        </div>
                        {residentsHtml}
                     </div>
                    
                  </div> </div>
               </div>

               <div className="bk-page-section">
                  <SectionHeader title={'Eiendomstype'}></SectionHeader>
                  <div className='content-frame'>
                  <div className="">
                     <div className="ownership-table">
                        <div className="ownership-table-row">
                           <div className="flex-1">Eiendomstype:</div>
                           <div className="flex-6">
                              <select className="form-control ownership-control-large" name="calculationTypeId"  value={ownership.calculationTypeId} onChange={(e)=>handleOwnershipChange(e)}>
                                 {typeOptions}
                              </select> 
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>

               <div className="bk-page-section">
                  <SectionHeader title={'Markedsvurdering'}></SectionHeader> 
                  <div className='content-frame'>   
                  <div className="">
                     <div className="ownership-table">
                        <div className="ownership-table-row">
                           <div className="flex-1">Markedsverdi:</div>
                           <div className="flex-2">
                              <input className="form-control ownership-control-large" name="marketValue" type="number" value={displayNumericValue(ownership.marketValue)} onChange={(e)=>handleOwnershipTextChange(e)}/>     
                           </div>
                           <div className="flex-2 ms-5">
                              <span  className={(ownership.calculationTypeId == 1 )?'':'visibility-hidden'} >Antatt markedsleie pr. mnd:</span></div>
                           <div className="flex-2">
                              <input className={(ownership.calculationTypeId == 1 )?'form-control ownership-control-large':'visibility-hidden'}  name="marketValueMonth" type="number" value={ownership.marketValueMonth} onChange={(e)=>handleOwnershipTextChange(e)}/>     
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>

               <div className="bk-page-section">
                  <SectionHeader title={'Vurdering av bo- og bruksrett'}></SectionHeader>
                  <div className='content-frame'>
                  <div className="bk-section">
                     <div className="bk-paragraph">
                        <div className="bk-paragraph-header">Vurdering / forklaring</div>
                        <div className="bk-paragraph-content">
                           Bo- og bruksrett er en rettighet som følger eller hefte ved eiendommen.<br></br>
                           Ved tinglyst bo- og bruksrett betyr det at bestemte personer har rett til å bo og / eller bruke eiendommen.<br></br>
                           Borett/ bruksrett er vanligvis inngått i forbindelse med overdragelse av eiendom til neste generasjon.<br></br>
                           En tinglyst bo- eller bruksrett er en verdireduserende heftelse på eiendommen, da det er uvisst når kjøper kan få full råderett over eiendommen.<br></br>
                           Verdien av bo- og bruksrett beregnes i henhold til arveavgiftslovens § 13. Denne paragrafen / beregningsmetoden er utgått fra arveloven, men metoden benyttes fremdeles for beregning av verdien av rettigheten.
                        </div>
                     </div>
                     <div className={(ownership.calculationTypeId == 1 )?'bk-paragraph':'display-none'}>
                        <div className="bk-paragraph-header">Helårsbolig</div>
                        <div className="bk-paragraph-content">
                           Rettigheten på en helårsbolig vil ofte betegnes som en borett.<br></br>
                           Det tas utgangspunkt i kapitalverdien basert på årlig verdi og antatt varighet.<br></br>
                           Boretten beregnes ut fra antall år iht. arveloven og årlig forventet leieinntekt på boligen.
                        </div>
                     </div>
                     <div className={(ownership.calculationTypeId == 4 )?'bk-paragraph':'display-none'}>
                        <div className="bk-paragraph-header">Fritidsbolig</div>
                        <div className="bk-paragraph-content">
                           Rettigheten på en helårsbolig vil ofte betegnes som en bruksrett.<br></br>
                           Merk at skattemyndighetene legger til grunn at årlig bruksperioder for fritidseiendommer utgjør 8 uker. <br></br>
                           Av disse godkjennes kun 4 uker som grunnlag for beregning av bruksretten. <br></br>
                           Dette har sammenheng med at dersom bruksrett forbeholdes i mer enn fire uker, betyr dette at giveren ikke har gitt fra seg rådigheten..<br></br>
                           Bruksretten beregnes ut fra avkastning av eiendommen og varighet.<br></br>
                           4 % avkastning og 4 ukers bruk.
                        </div>
                     </div>
                     </div>
                  </div>
               </div>

               <div className="bk-page-section">
                  <SectionHeader title={'Sammendrag'}></SectionHeader>
                  <div className='content-frame'>
                  <div className="">
                     <div className="ownership-table">
                        <div  className="ownership-table-row">
                           <div className="flex-1"><b>Rettighershaver som legges til grunn</b></div>
                        </div>
                        <div className="ownership-result-row">
                           <div className="flex-1">Navn:</div>
                           <div className="flex-1">{relevantResident.name}</div>
                        </div>
                        <div className="ownership-result-row">
                           <div className="flex-1">Alder:</div>
                           <div className="flex-1">{relevantResident.age} år</div>
                        </div>
                        <div className="ownership-result-row">
                           <div className="flex-1">Antall år iht. arveloven:</div>
                           <div className="flex-1">{relevantResident.remainingYears} år</div>
                        </div>
                        <div className="ownership-result-row">
                           <div className="flex-1">Kjønn:</div>
                           <div className="flex-1">{getGender(relevantResident.gender)}</div>
                        </div>
                        <div className="ownership-result-row">
                           <div className="flex-1">Kapitaliseringsfaktor:</div>
                           <div className="flex-1">{relevantResident.factor}</div>
                        </div>
                        <div className="ownership-result-row ownership-result-borders">
                           <div className="flex-3">
                           <b>{getSumText()}</b>
                           </div>
                           <div className="flex-1 align-right ownership-right-margin">
                              {formatValue(ownership.value, 0, ',-')}
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>

               <div className="bk-page-section">
                  <SectionHeader title={'Beregning av markedsverdi'}></SectionHeader>
                  <div className='content-frame'>
                  <div className="">
                     <div className="ownership-table">
                        <div  className="ownership-table-row">
                           <div className="flex-3">
                              Markedsverdi for eiendom uten fradrag for rettighet:
                           </div>
                           <div className="flex-1 align-right ownership-right-margin">
                              {formatValue(ownership.marketValue, 0, ',-')}
                           </div>
                        </div>
                        <div  className="ownership-table-row">
                           <div className="flex-3">
                           Fradraget for verdi av rettighet:
                           </div>
                           <div className="flex-1 align-right ownership-right-margin">
                              {formatValue(ownership.value, 0, ',-')}
                           </div>
                        </div>
                        <div className="ownership-table-row ownership-result-borders">
                           <div className="flex-3">
                           <b>Sum markedsverdi med fradrag for rettighet</b>
                           </div>
                           <div className="flex-1 align-right ownership-right-margin">
                              {formatValue(ownership.marketValue - ownership.value, 0, ',-')}
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>

               <div className="bk-page-section">
               <CategoryHeaderModule title={'Kommentarer til bo- og bruksrett'}></CategoryHeaderModule>
                  <div className='content-frame'>
                  <div className="">
                     <div className="ownership-table">
                        <div  className="ownership-table-row">
                           <div className="flex-1">
                              <textarea className="form-control ownership-control-max" name="comment" rows={6} value={ownership.comment} onChange={(e)=>handleOwnershipTextChange(e)}/>     
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
               </div> 
                
               </div>   
         </div>     
         <ConfirmationDialog
               title="Slette?"
               text="Er du sikker på at du vil slette rettighetshaveren"
               id={selectedResidentId}
               open={displayConfirmDialog}
               onClose={handleConfirmDialogClose}
            />
      </>
   )
}

export default Ownership;