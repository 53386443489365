import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class AgricultureSummaryModuleService extends BaseService {
   private static _AgricultureSummaryModuleService: AgricultureSummaryModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): AgricultureSummaryModuleService {
     return this._AgricultureSummaryModuleService || (this._AgricultureSummaryModuleService = new this('AgricultureSummaryModule'));
   }
 

   public async getAgricultureSummaryModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addAgricultureSummaryModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addAgricultureSummaryModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateAgricultureSummaryModule(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateForest.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

}

export const AgricultureSummaryModuleApi = AgricultureSummaryModuleService.Instance;