import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {BkButton,ContactButton} from '../../../components'
import {formatDateTime} from '../../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faShareFromSquare, faFile } from '@fortawesome/free-solid-svg-icons'

const PrintReportDialog = ({open, latestReleaseDate, handleSelectedCb}) => {

   const handleClose= () => {   
      handleSelectedCb(0);
   }

   const handlePrintDraft = () => {
      handleSelectedCb(1);
   }

   const handlePublish= () => {
      handleSelectedCb(2);
   }

   const handlePrintGenerated = () => {
      handleSelectedCb(3);
   }

   ////<BkButton caption={'Skriv ut generert rapport (' + formatDateTime(latestReleaseDate) + ')'} onClickHandler={handlePrintGenerated}/>

   function PrintButtonArrangementCheck(){
      if (latestReleaseDate != null)
      {
         return (
            <>
            <div className='col-4 col-centered'>
            <div className="report-dialog-button report-dialog-text "  onClick={handlePrintDraft}>
            <div><FontAwesomeIcon icon={faFile} className="fa-5x mb-4 mt-3"/></div>
               <span className=''>Skriv ut utkast  <br/> (uten QR kode)</span> </div>
            </div>

            <div className='col-4 col-centered'>
            <div className="report-dialog-button report-dialog-text"  onClick={handlePublish}> 
            <div><img src="\Content\images\byggekost-qr.png" alt="QR-kode" width="110px" className='report-dialog-QR-img'/></div>
            <span className=''>Skriv ut rapport <br/> (med QR kode)</span> </div>
            </div>

            <div className='col-4 col-centered'>
                 <div className="report-dialog-button report-dialog-text"  onClick={handlePrintGenerated}> 
               <div><FontAwesomeIcon icon={faShareFromSquare} className="fa-5x mb-4 mt-3"/></div>
               <span className=''>Skriv ut generert rapport {'(' + formatDateTime(latestReleaseDate) + ')'}</span> </div>
                 </div>
            </>
            
         )
      }
      else {
         return(
            <>
             <div className='col-6 col-centered'>
               <div className="report-dialog-button report-dialog-text "  onClick={handlePrintDraft}>
               <div><FontAwesomeIcon icon={faFile} className="fa-5x mb-4 mt-3"/></div>
                  <span className=''>Skriv ut utkast  <br/> (uten QR kode)</span> </div>
               </div>

               <div className='col-6 col-centered'>
               <div className="report-dialog-button report-dialog-text"  onClick={handlePublish}> 
               <div><img src="\Content\images\byggekost-qr.png" alt="QR-kode" width="110px" className='report-dialog-QR-img'/></div>
               <span className=''>Skriv ut rapport <br/> (med QR kode)</span> </div>
               </div>
            </>
         )
      }
   }

 
  return (
    <>
         <Dialog open={open} fullWidth={true} maxWidth = {'md'} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className="dialog-header">Velg utskrift</DialogTitle>
            <DialogContent>

            
           
            <div className="report-dialog"> 
            <div className='row'>
               {PrintButtonArrangementCheck()}   
            </div>
            </div>
            </DialogContent>
            <DialogActions>
               <ContactButton caption='Avbryt' onClickHandler={handleClose}/> 
            </DialogActions>
         </Dialog>

    </>
  )
}

export default React.memo(PrintReportDialog);