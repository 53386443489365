import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class HeaderMessageService extends BaseService {
   private static _headerMessageService: HeaderMessageService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): HeaderMessageService {
     return this._headerMessageService || (this._headerMessageService = new this('HeaderMessage'));
   }
 

   public async get(): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      await this.$http.get('get/', {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          //this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

}

export const HeaderMessageApi = HeaderMessageService.Instance;