import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';


class HomeService extends BaseService {
  private static _homeService: HomeService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): HomeService {
    return this._homeService || (this._homeService = new this('home'));
  }

  public async getAboutContent(): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('About', {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, (error) => {
      console.log(error);
      axiosResponse = error;
    });
   
    return axiosResponse;
  }

  public async sendMail(name, email, type, message): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {
      name : name,
      email: email,
      type: type,
      message:message
    }
    await this.$http.post('SendMail', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, (error) => {
      console.log(error);
      axiosResponse = error;
    });
   
    return axiosResponse;
  }

  public async sendMailLoggedIn(type, message, path): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {
      type: type,
      message:message,
      path:path
    }
    await this.$http.post('SendMailLoggedIn', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, (error) => {
      console.log(error);
      axiosResponse = error;
    });
   
    return axiosResponse;
  }


  public async getAcceptMessage(): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('accept', {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async sendAcceptResponse(msgId, accepted): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {
      msgId: msgId,
      accepted:accepted
    }
    await this.$http.post('messageResponse', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, (error) => {
      console.log(error);
      axiosResponse = error;
    });
   
    return axiosResponse;
  }


}

export const HomeApi = HomeService.Instance;