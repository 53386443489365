import React, {useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { SimpleCalculationApi } from '../../../api';
import { UploadDialog } from '../../../components';

const keyPressTimer = 1000;
let inputTimer = null;

const SimpleCalcDescriptionRow = ({descriptionRow, handleChangeCb, handleMoveUpCb, handleMoveDownCb, handleDeleteCb }) => {
  const [description, setDescription] = React.useState({
    simpleCalculationDescriptionRowId:0,
    title:'',
    text:'',
    imageUrl:''
  });
  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);

  // Add a ref so that its possible to access the data in a callback
  const stateRef = useRef(description);
  stateRef.current = description;

  const id=descriptionRow.simpleCalculationDescriptionRowId;

  useEffect(() => {
    setDescription(descriptionRow);
  }, [descriptionRow])


  const handleChange = function(e) {
    const { name, value } = e.target;
    let changedDescription = description;

    if (name === "text"){
      changedDescription.text = value;
    }
    else {
      changedDescription.title = value;
    }
    setDescription(description =>({...description, [name]:value}));

    if (inputTimer !== null){
      clearTimeout(inputTimer);
    }

    inputTimer = setTimeout(() => {
      updateDesriptionRow(changedDescription);
    }, keyPressTimer); 
  }

  const updateDesriptionRow = function(description) {
    
    SimpleCalculationApi.updateDescriptionRow(description)
    .then (
      (response) => {
        handleChangeCb(description);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleMoveUp = function() {
    handleMoveUpCb(description.simpleCalculationDescriptionRowId);
  }

  const handleMoveDown = function() {
    handleMoveDownCb(description.simpleCalculationDescriptionRowId);
    
  }

  const handleDelete = function() {
    handleDeleteCb(description.simpleCalculationDescriptionRowId);
  }

  const handleImageUrlUpdated = function(url: string) {
    setDescription({...description, imageUrl:url});
    setOpenUploadDialog(false);
    var data = description;
    data.imageUrl = url;
    SimpleCalculationApi.updateDescriptionRow(data);
  }

  const handleImageCancel = function() {
    setOpenUploadDialog(false);
  }

  const handleChangePicture = function() {
    setOpenUploadDialog(true);
  }


  // const onDrop = useCallback(acceptedFiles => {
  //     var data = new FormData();
  //     data.append("simpleCalculationDescriptionRowid", id);

  //     if (acceptedFiles.length > 0) {
  //       data.append("file", acceptedFiles[0]);
  //     } else {
  //       return;
  //     }
  //     SimpleCalculationApi.upload(data)
  //    .then(
  //      (response) => {
  //        setDescription({...stateRef.current, imageUrl:response.data});
  //      },
  //      (error) => {
  //        console.log(error);
  //      }
  //    )
  // }, []);

  // const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})



  // const getPicture = function(imageUrl) {
  //   if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
  //     return (
  //       <div className="image-frame" title="Klikk for å endre, eller dra inn nytt bilde" {...getRootProps()}>
  //         <input {...getInputProps()} />
  //         <img src={process.env.REACT_APP_API_URL+"/upload/" + imageUrl} />
  //       </div>
  //     )
  //   }
  //   else {
  //     return ( 
  //       <div className="simple-calc-description-upload-frame" {...getRootProps()}>
  //         <input {...getInputProps()} />
  //         <div className="frame-header">Dra og slipp</div>
  //         <div className="frame-text">eller klikk her for å laste opp bilde</div>
  //       </div>);
  //   }
  // }

  const getPicture = function(imageUrl) {
    if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
      return (
        <img src={"/upload/" + imageUrl} />
      )
    }
    else {
      return ( 
        <div className="no-image" ></div>);
    }
  }





  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick} className="ms-3 mt-1"/>
          <Menu

            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu "
          >
            <MenuItem onClick={handleMoveUp}>Flytt opp</MenuItem>
            <MenuItem onClick={handleMoveDown}>Flytt ned</MenuItem>
            <Divider />
            <MenuItem onClick={handleDelete}>Slett underpunkt</MenuItem>
          </Menu>
        </div>;

  }
  

  return (
    <>
    <div key={'SCS1_' + description.simpleCalculationDescriptionRowId} className="simple-calc-section-row">
      <div className="simple-calc-description-title">Underpunkt:</div>
      <div className="simple-calc-description-type">
        <input name="title" className="form-control" type="text" value={description.title} onChange={handleChange}/>
      </div>
      <div className="simple-calc-action-menu" title="Alternativer">
        <ActionMenu />
      </div>
    </div>
    <div key={'SCS2_' + description.simpleCalculationDescriptionRowId} className="simple-calc-section-row">
      <div className="simple-calc-description-title">Beskrivelse:</div>
      <div className="simple-calc-description-type">
        <div className="simple-calc-description-type-text"><textarea name="text" className="form-control" rows={7} value={description.text} onChange={handleChange}/></div>
        <div className="image-frame" onClick={handleChangePicture} title="Klikk for å endre bilde">
          {getPicture(description.imageUrl)}
        </div>
        
      </div>
      <div className="simple-calc-action-menu">
      </div>
    </div>

    <UploadDialog
        open={openUploadDialog}
        handleImageUrlUpdatedCb={handleImageUrlUpdated}
        handleCancelCb={handleImageCancel}
        url={description.imageUrl}
      />
  </>
  );

}

export default React.memo(SimpleCalcDescriptionRow);