import React, {useEffect} from 'react';
import {SimpleCalcDescriptionMain, SelectTemplateDialog} from '.';
import { SimpleCalculationApi, DescriptionTemplateApi } from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { useHistory } from 'react-router-dom'

const SimpleCalcDescription = ({calculationId, descriptionElements}) => {
  const [descriptions, setDescriptions] = React.useState([]);
  const [openSelectTemplate, setOpenSelectTemplate] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);


  useEffect(() => {
    loadTemplates();
  }, [])

  useEffect(() => {
    setDescriptions(descriptionElements);
  }, [descriptionElements])

  const history = useHistory();

  const loadTemplates = function() {
    DescriptionTemplateApi.get()
      .then (
      (response) => {
        setTemplates(response.data);
      },
      (error) => {
         console.log(error);
      }
      );
  }



  const handleChange = function(data) {
    var newDescriptions = descriptions.map((description, index) => {
      if (description.simpleCalculationDescriptionId === data.simpleCalculationDescriptionId) {
        return data;
      }
      return description;
    })
    setDescriptions(newDescriptions);
  }


  
  const handleMoveUp = function(id) {
    var selectedIndex = 0;
    descriptions.map((description, index) => {
      if (description.simpleCalculationDescriptionId === id) {
        selectedIndex=index;
      }
      return description;
    })

    if (selectedIndex === 0) return; // already on top

    let data = [...descriptions];
    var firstOrder = data[selectedIndex-1].order;
    var lastOrder = data[selectedIndex].order;
    let temp = data[selectedIndex-1];
    temp.order = lastOrder;
    data[selectedIndex].order = firstOrder;
    data[selectedIndex-1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setDescriptions(data);
    updateDescription(data[selectedIndex]);
    updateDescription(data[selectedIndex-1]);
  }


  const handleMoveDown = function(id) {
    var selectedIndex = descriptions.length-1;
    descriptions.map((description, index) => {
      if (description.simpleCalculationDescriptionId === id) {
        selectedIndex=index;
      }
      return description;
    })

    if (selectedIndex === descriptions.length-1) return; // already at the end

    let data = [...descriptions];
    var firstOrder = data[selectedIndex].order;
    var lastOrder = data[selectedIndex+1].order;
    let temp = data[selectedIndex+1];
    temp.order = firstOrder;
    data[selectedIndex].order = lastOrder;
    data[selectedIndex+1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setDescriptions(data);
    updateDescription(data[selectedIndex]);
    updateDescription(data[selectedIndex+1]);
  }

  const handleDelete = function(id) {
    SimpleCalculationApi.deleteDescription(id)
    .then (
      (response) => {
        setDescriptions(descriptions => (descriptions.filter(description =>description.simpleCalculationDescriptionId !== id)));
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  const updateDescription = function(description) {
    SimpleCalculationApi.updateDescription(description)
    .then (
      (response) => {
        //handleInfoUpdatedCb(info);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleAddDescription = function() {
    SimpleCalculationApi.getNewDescription(calculationId)
    .then (
      (response) => {
        setDescriptions(descriptions => [...descriptions, response.data]);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleAddImage = function() {
    SimpleCalculationApi.getNewPicture(calculationId)
    .then (
      (response) => {
        setDescriptions(descriptions => [...descriptions, response.data]);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleChooseFromTemplateStore = function() {
    setOpenSelectTemplate(true);
  }

  const handleOpenTemplateStore = function() {
    //history.push("/descriptionTemplate");
    const win = window.open("/descriptionTemplate", "_blank");
    win.focus();
  }

  const handleTemplateSelected = function(res) {
    if (res !== null) {
      SimpleCalculationApi.AddDescriptionTemplates(calculationId, res) .then (
        (response) => {
          setDescriptions([...descriptions, ...response.data]);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    setOpenSelectTemplate(false);
  }



  
  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
        <FontAwesomeIcon icon={faBars}  onClick={handleClick} className="ms-2"/>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          className="navbar-dropdown-menu"
        >
          <MenuItem onClick={handleAddDescription}>Legg til hovedpunkt</MenuItem>
          <MenuItem onClick={handleAddImage}>Legg til bilde</MenuItem>
          <MenuItem onClick={handleChooseFromTemplateStore}>Velg fra malbank</MenuItem>
          <Divider />
          <MenuItem onClick={handleOpenTemplateStore}>Åpne malbank i eget vindu</MenuItem>
        </Menu>
      </div>;

  }


  let descriptionHtml = null;
  if (descriptions) {
    descriptionHtml = descriptions.map((description, index) =>
    {
      return (<SimpleCalcDescriptionMain 
        descriptionRow={description} 
        handleChangeCb={handleChange}
        handleMoveUpCb={handleMoveUp}
        handleMoveDownCb={handleMoveDown}
        handleDeleteCb={handleDelete}
        />)
      })
  }

  return (
    <>
        <div className="simple-calc-section-header">
          <div className="description">Beskrivelse</div>
          <div className="action-menu"> <ActionMenu /></div>
        </div>
        <div className='content-frame'>
      {descriptionHtml}
      </div>

      <SelectTemplateDialog 
        templateRows={templates}
        open={openSelectTemplate}
        handleSelectedCb={handleTemplateSelected}
      />
    </>
  );

}
SimpleCalcDescription.displayName = 'Beskrivelse';

export default React.memo(SimpleCalcDescription);