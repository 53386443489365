import React from 'react'
import {SectionHeader} from '.'

function InputTextBox(props) {

   return <>
      <div className='bk-module-section'>
         <SectionHeader title={props.title}></SectionHeader>
         <textarea name={props.name || 'text'} className="form-control text" rows={props.rows || 7} value={props.text || ''} onChange={(e) => props.changeCb(e)} />
      </div>
   </>
}
  
export default InputTextBox;