import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators, reducer } from '../../store/auth';
import { BkButton } from '../../components';
import { RoutesConfig } from '../../config/routes.config';
import { useHistory } from 'react-router-dom'


type SelectLayoutProps = ReturnType<typeof reducer>
  & typeof actionCreators
  & { history: History, readonly isAuthenticated: boolean; readonly allowOld:boolean};

const SelectLayout: React.FC <SelectLayoutProps> = ({
   status,
   isAuthenticated,
   allowOld
 }) => {
   const history = useHistory();


   useEffect(() => {
      if (!allowOld) {
         history.push(RoutesConfig.Projects.path)
      }
   }, []);

   const handleNew = () => {
      history.push(RoutesConfig.Projects.path)
   }

   const handleOld = () => {
      window.location.href="https://old.byggekost.no";
   }


   return (
      <div className="bk-container">
         <div className="simple-calc-wrapper">
            <div className="simple-calc-header">
               Velg layout
            </div>
            <br className="clear-both" />
            
            <div className="bk-page-section">
               <div className="bk-section select-layout-text">
                  Vi har laget en ny versjon av byggekost.no<br></br>
                  Denne har et oppdatert brukergrensesnitt og har også noen nye funksjoner som bl.a. arealskjema og festetomt.<br></br>
                  Du vil i en periode fortsatt kunne bruke den gamle versjonen av byggekost.no.
               </div>
               <br></br>
               <br></br>
            
               <div className="bk-section display-flex select-layout-frame">
                  <div className="select-layout-button" onClick={handleNew}>
                     <div>Ny</div>
                  </div>
                  <div className="select-layout-button"  onClick={handleOld}>
                     <div>Gammel</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = (state: IApplicationState) => state.auth;

export default connect(mapStateToProps, actionCreators)(SelectLayout);

