import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BkButton, ContactButton } from '../../../components'
import { formatDateTime } from '../../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faShareFromSquare, faFile } from '@fortawesome/free-solid-svg-icons'
import { AdminApi } from '../../../api/admin.service';
import CustomerResultRow from './CustomerResultRow'

const ShareDialog = ({ open, handleSelectedCb, userID }) => {

   const [isLoading, _setIsLoading] = useState(true);
   const [customers, setCustomers] = useState([]);
   const [searchModel, setSearchModel] = useState({
      searchString: null,
      customerId: userID
   })
   const [selectedCustomer, setSelectedCustomer] = useState();

   let inputTimer = null;
   const keyPressTimer = 500;

   const handleClose = () => {
      handleSelectedCb();
   }

   const handleSaveClose = () => {
      if (selectedCustomer != null) {
         handleSelectedCb(selectedCustomer);
      }
   }


   useEffect(() => {
      setSearchModel(searchModel => ({ ...searchModel, customerId: userID }));
   }, [userID]);

   useEffect(() => {
      fetchData();
   }, [searchModel])

   const isLoadingRef = React.useRef(isLoading);
   const setIsLoading = data => {
      isLoadingRef.current = data;
      _setIsLoading(data);
   };

   const fetchData = async () => {
      setIsLoading(true);
      AdminApi.getCustomersSimple(searchModel)
         .then(
            (response) => {
               setCustomers(response.data);
            },
            (error) => {
               console.log(error);
               setIsLoading(false);
            });
   }


   const onSearchTextChanged = function (e) {
      const { value } = e.target;

      if (inputTimer !== null) {
         clearTimeout(inputTimer);
      }

      inputTimer = setTimeout(() => {
         doTextSearch(value);
      }, keyPressTimer);
   }


   const doTextSearch = function (searchText) {
      setSearchModel(searchModel => ({ ...searchModel, searchString: searchText }));
   }

   const handleCustomerSelected = (value) => {
      if (value > 0) {
         setSelectedCustomer(value)
      }
   };

   let customerRows = null;
   if (customers && customers.length > 0) {
      customerRows = customers.map((customer, index) => {
         return (
            <CustomerResultRow selectedCustomer={selectedCustomer} handleSelectedCb={handleCustomerSelected} customer={customer} index={index}  ></CustomerResultRow>
         )
      });
   }

   return (
      <>
         <Dialog open={open} fullWidth={true} maxWidth={'md'} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className="dialog-header">Legg til tilgang:</DialogTitle>
            <DialogContent>
               <input type="text" className='form-control' placeholder="Søk" onChange={(e) => onSearchTextChanged(e)}></input>

               {customerRows != null ?
                  <div className='customer-share-container'>
                     {customerRows}
                  </div> :
                  <div className='customer-no-search-result'>
                     Ingen søkeresultat
                  </div>
               }
            </DialogContent>
            <DialogActions>
               <BkButton caption='Legg til' onClickHandler={handleSaveClose} />
               <ContactButton caption='Avbryt' onClickHandler={handleClose} />
            </DialogActions>
         </Dialog>

      </>
   )
}

export default React.memo(ShareDialog);