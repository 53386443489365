import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class ProjectClientService extends BaseService {
   private static _projectClientService: ProjectClientService;
 
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
 
   public static get Instance(): ProjectClientService {
     return this._projectClientService || (this._projectClientService = new this('projectClient'));
   }
 
 
   public async addContact(data): Promise<AxiosResponse> {
     var axiosResponse;
     await this.$http.post('post', data, {withCredentials: true})
     .then((response) => {
       axiosResponse = response;
     }, 
     (error) => {this.handleError(error); });
    
     return axiosResponse;
   }
 
 
   public async deleteContact(contactId, projectId): Promise<AxiosResponse> {
     var axiosResponse;
     await this.$http.delete('delete/'+ contactId + '/' + projectId, {withCredentials: true})
     .then((response) => {
       axiosResponse = response;
     }, 
     (error) => {this.handleError(error); });
 
     return axiosResponse;
   }
 
 
 }
 
 export const ProjectClientApi = ProjectClientService.Instance;