import React, {useEffect} from 'react';
import {isNullOrEmpty, formatValue } from '../../../utils';

const SimpleCalcResult = ({categoriesArray}) => {
  const [totalHours, setTotalHours] = React.useState(0); 
  const [totalUnits, setTotalUnits] = React.useState(0); 
  const [totalWork, setTotalWork] = React.useState(0); 



  useEffect(() => {
    var hours = 0;
    var units = 0;
    var work = 0;
    categoriesArray.map((category) => {      
      if (!isNullOrEmpty(category.hoursTotal)) {
        hours = hours +  category.hoursTotal;
      }
      if (!isNullOrEmpty(category.unitTotal)) {
        units = units +  category.unitTotal;
      }
      if (!isNullOrEmpty(category.workTotal)) {
        work = work +  category.workTotal;
      }
      return category;
    })

    setTotalHours(hours);
    setTotalUnits(units);
    setTotalWork(work);
  }, [categoriesArray])


  
  return (
    <>
      <div className="simple-calc-result-row">
        <div className="simple-calc-result-heading">Timer</div>
        <div className="simple-calc-result-value-odd">{formatValue(totalHours,0,' t','-')}</div>
      </div>  
      <div className="simple-calc-result-row">
        <div className="simple-calc-result-heading">Sum materiell</div>
        <div className="simple-calc-result-value-even">{formatValue(totalUnits,0,' ,-','-')}</div>
      </div>    
      <div className="simple-calc-result-row">
        <div className="simple-calc-result-heading">Sum arbeid</div>
        <div className="simple-calc-result-value-odd">{formatValue(totalWork,0,' ,-','-')}</div>
      </div>    
      <div className="simple-calc-result-row">
        <div className="simple-calc-result-heading">Sum eks. mva.</div>
        <div className="simple-calc-result-value-even">{formatValue(totalUnits + totalWork,0,' ,-','-')}</div>
      </div>    
      <div className="simple-calc-result-row">
        <div className="simple-calc-result-heading">Mva</div>
        <div className="simple-calc-result-value-odd">{formatValue((totalUnits + totalWork)*0.25,0,' ,-','-')}</div>
      </div>    
      <div className="simple-calc-result-row">
        <div className="simple-calc-result-heading">Sum inkl. mva</div>
        <div className="simple-calc-result-value-even">{formatValue((totalUnits + totalWork)*1.25,0,' ,-','-')}</div>
      </div>      
    </>
  );

}
export default React.memo(SimpleCalcResult);