import axios, { AxiosInstance } from 'axios';
import { actionCreators} from '../store/auth';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../utils';
import { getAllServerValidationErrors } from '../utils/serverValidationHandler';

/**
 * Service API base class - configures default settings/error handling for inheriting class
 */

export abstract class BaseService {
  protected readonly $http: AxiosInstance;

  protected constructor(controllerName: string, requestTimeout: number = 50000) {
    this.$http = axios.create({
      timeout: requestTimeout,    
      baseURL: '/api/' + controllerName + '/'
      //baseURL: window.location.origin +'/api/' + controllerName + '/'
    });
  }
  
  public handleError(error) {

    // Add logging to server
    if (error.response.status === 401) {    // 401 - Unauthorized
     var url = `/Logout`;
     window.location.href = url;
    }

    else if (error.response.status === 402) {    // 402 - PaymentRequired
      var url = `/Accept`;
      window.location.href = url;
    }


    console.log(error.response);
    throw new Error(error);
  }

  // Maybe need to be merged with handleError? Made separate function - because i do not want to break something.
  public handleAllErrors(error){

   const commonText = `Vi beklager, det oppsto en feil.`;

    const commonErrorHandling = (errorMessage: string) => {
      toast.dismiss();
      const technicalInfo = error && error.message?  ' Teknisk info: ' + error.message: '';
      toast.error(errorMessage + technicalInfo, {autoClose: 10000});
    }

    if (typeof error === 'string'){
      toast.dismiss();
      toast.error(`${commonText} Teknisk info: ${error}`);
      return;
    }
    
    if (!error || !error.response){
       console.log("No data in error response");
       return;
       
    }

    const errorResponse = error.response;
    const errorStatus = errorResponse.status || null;

    if (errorStatus === 401) {
      actionCreators.logoutUser();
      var url = `/Logout`;
      window.location.href = url;
    }
    // validation errors
    else if (errorStatus === 400){

      const serverValidationErrorData =  (errorResponse.data && errorResponse.data.errors) || "";
      const serverValidationErrors = serverValidationErrorData? getAllServerValidationErrors(serverValidationErrorData): null;
      const errMessage = serverValidationErrors != null && serverValidationErrors !== `` ? serverValidationErrors: `Valideringsfeil! `;
      commonErrorHandling(errMessage);
    }
    else if (error.response.status === 402) {    // 402 - PaymentRequired
      var url = `/Accept`;
      window.location.href = url;
    }
    else if (errorStatus === 404){
      return;
    }
    // server errors
    else if (errorStatus === 500){
      commonErrorHandling(`Beklager, det har skjedd en server feil.`);
    }
    //other errors
    else {

      commonErrorHandling(`Vi beklager, det oppsto en feil.`);
    }

    return;
  }

}

