import React, {useEffect} from 'react';
import { SimpleCalculationApi } from '../../../api';
import {isNullOrEmpty } from '../../../utils';
import {SimpleCalcInfoRow} from '.';


const SimpleCalcInfo = ({calculationId, infoRows}) => {
  const [infos, setInfos] = React.useState([]);

  useEffect(() => {
    setInfos(infoRows);
  }, [infoRows])

  const updateInfo = function(info) {
    SimpleCalculationApi.updateInfo(info)
    .then (
      (response) => {
        checkIfNewRowShouldBeAdded();
      },
      (error) => {
        console.log(error);
      }
    );

  }

  const addBlankInfoRow = function() {
    SimpleCalculationApi.getNewinfoRow(calculationId)
    .then (
      (response) => {
        setInfos(infos => [...infos, response.data]);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const checkIfNewRowShouldBeAdded = function() {
    var lastRow = infos[infos.length-1];
    if ( !isNullOrEmpty(lastRow.name) || !isNullOrEmpty(lastRow.text)) {
      addBlankInfoRow();
    }
  }

  const handleInfoUpdated = function(info) {
    var newInfos = infos.map((oldInfo, index) => {
      if (oldInfo.simpleCalculationInfoId === info.simpleCalculationInfoId) {
        return info;
      }
      return oldInfo;
    })
    setInfos(newInfos);
    checkIfNewRowShouldBeAdded();
  }

  const handleInfoMoveUp = function(infoId) {
    var selectedIndex = 0;
    infos.map((info, index) => {
      if (info.simpleCalculationInfoId === infoId) {
        selectedIndex=index;
      }
      return info;
    })

    if (selectedIndex === 0) return; // already on top

    let data = [...infos];
    var firstOrder = data[selectedIndex-1].order;
    var lastOrder = data[selectedIndex].order;
    let temp = data[selectedIndex-1];
    temp.order = lastOrder;
    data[selectedIndex].order = firstOrder;
    data[selectedIndex-1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setInfos(data);
    updateInfo(data[selectedIndex]);
    updateInfo(data[selectedIndex-1]);
  }

  const handleInfoMoveDown = function(infoId) {
    var selectedIndex = infos.length-1;
    infos.map((info, index) => {
      if (info.simpleCalculationInfoId === infoId) {
        selectedIndex=index;
      }
      return info;
    })

    if (selectedIndex === infos.length-1) return; // already at the end

    let data = [...infos];
    var firstOrder = data[selectedIndex].order;
    var lastOrder = data[selectedIndex+1].order;
    let temp = data[selectedIndex+1];
    temp.order = firstOrder;
    data[selectedIndex].order = lastOrder;
    data[selectedIndex+1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setInfos(data);
    updateInfo(data[selectedIndex]);
    updateInfo(data[selectedIndex+1]);
  }

  const handleInfoDelete = function(infoId) {
    SimpleCalculationApi.deleteInfo(infoId)
    .then (
      (response) => {
        setInfos(infos.filter(info =>info.simpleCalculationInfoId !== infoId));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  let infosHtml = null;
  if (infos) {
    infosHtml = infos.map((info, index) =>
    {
      return (
        <>
        <SimpleCalcInfoRow 
            infoRow={info} 
            handleInfoMoveUpCb={handleInfoMoveUp} 
            handleInfoMoveDownCb={handleInfoMoveDown}
            handleInfoDeleteCb={handleInfoDelete}
            handleInfoUpdatedCb={handleInfoUpdated} />
        </>
      )

    })
  }


  return (
    <>
      {infosHtml}
    </>
  );

}
export default React.memo(SimpleCalcInfo);