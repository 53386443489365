import React from "react";
import './NavbarButtonLogin.scss';

function NavbarButtonLogin(props) {

    function handleClick(e) {
      e.preventDefault();
      if (props.onClickHandler !== undefined) {
        if (props.arg !== undefined) {
          props.onClickHandler(props.arg);
        }
        else {
          props.onClickHandler();
        }
      }
    }
  
    return <div className="navbarbuttonlogin" onClick={(e) =>handleClick(e)}>{props.caption}</div>
  }
  
  export default NavbarButtonLogin;