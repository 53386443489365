import React from 'react';
import { RoutesConfig } from '../../config/routes.config';
import { BkButton } from '../../components';
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'

const RegisterConfirmation: React.FC = () => {

  const history = useHistory();

  const handleSubmit = () => {
    history.push(RoutesConfig.Login.path)
  }

  
  return (
    <div className="container">
      <div className='row'>
        <div className='col-lg-3'></div>
        <div className='col-lg-6 register-container mt-4'>
        <div className='register-text bold'>
        <p className='my-2 mt-4'> Takk for at du har registrert deg hos oss </p> 
        </div>
     
      <div className="register-subtext col-8 col-centered">
        <p> Du er nå klar til å benytte deg av byggekost.no sine tjenester.   
        Har du spørsmål rundt tjenesten, så ikke vent med å kontakte oss.</p>
      </div>

      <div className='row'>
      <div className='col-auto mx-auto mb-5 mt-3'>
        <BkButton caption='Trykk her for å logge inn' onClickHandler={handleSubmit}/>
      </div>
      </div>
      </div>
        
      </div>
    
    
      
    
    </div>  
)}

export default RegisterConfirmation;