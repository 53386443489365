import React, {useState, useEffect,useRef }  from 'react';
import { actionCreators} from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory, useParams } from 'react-router-dom'
import {AdminApi, AuthApi, ReportApi} from '../../api';
import AdminMenu from './child-components/AdminMenu'
import IndexRow from './child-components/IndexRow'
import SubscriberMessageRow from './child-components/SubscriberMessageRow'
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';
import { faCheck } from '@fortawesome/free-solid-svg-icons'


type SsbIndexProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly isAdmin: boolean;
};

const SsbIndex: React.FC<SsbIndexProps> = ({ 
   isAuthenticated, 
   isAdmin,
   logoutUser
 }) => {

   const history = useHistory();
   
   const [SsbIndex, setSsbIndex] = useState([]);
   const [lastCheck, setlastCheck] = useState([]);


   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = async () => {
    AdminApi.getSsbIndexes()
    .then(
    (response) => {    
       setSsbIndex(response.data.indexes.slice(0).reverse());
       setlastCheck(response.data.lastChecked);
    },
    (error) => {
       console.log(error);
    });
 }


   if(!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if(!isAdmin) {
      history.push("/projects");
   }

   var indexIdarr = SsbIndex.map(a => a.id)

   var lastindex = indexIdarr[0] 
   const toastIdRef = useRef<string | number>('');
   
   const activateIndex = () => {

      AdminApi.activateSsbIndex(lastindex)
      .then(
      (response) => {   
      },
      (error) => {
         console.log(error);
      });

      var currentDate = new Date();
      var dd = String(currentDate.getDate()).padStart(2, '0');
      var mm = String(currentDate.getMonth() + 1).padStart(2, '0');
      var yyyy = currentDate.getFullYear();
   
      const btn = document.getElementById("activatebtn")
      var date = dd + '-' + mm + '-' + yyyy;
      
      btn.replaceWith(date);

      if (!toast.isActive(toastIdRef.current)) 
         toastIdRef.current = toast.success(
            renderToastifyMsg('Index er oppdatert', faCheck)  
         );

   }

   let indexRows = null;
      indexRows = SsbIndex.map((ind) =>  
      {  
            return <IndexRow ssbindex={ind} activateIndex={activateIndex} latestindex = {lastindex}/> 
      });

   

   return (
      <>
           <div className="admin-container">
               <AdminMenu activeMenuItem={'SSB-Indeks'}></AdminMenu>

            <div className="admin-frame">
              <div className='col-10 mx-auto'>
              <div className='ssb-container container'> 
                <div className='row'> 
                    <div className='bk-row-header bk-row-header-title mx-4'>SSB - Index</div>
                    <div className='bk-text-blue mb-4 mx-4'> {lastCheck} </div>
                </div>
                
                <div className='row'>
                    <div className='col-11 col-centered'> 
                     <div className='row index-row'>
                        <div className='col-3'><span>Periode</span></div>
                        <div className='col-3'><span>Oppdatert</span></div>
                        <div className='col-2'><span>Index (%)</span></div>
                        <div className='col-2'><span>Endring (%)</span></div>
                        <div className='col-2'><span>Aktivert</span></div>
                     </div>

                    {indexRows}

                    </div>
                
                </div>
               
               </div> 
              </div>
               </div>
           </div>

      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
 });
 
 export default connect(mapStateToProps, actionCreators)(SsbIndex);