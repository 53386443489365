  import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class DescriptionTemplateRowService extends BaseService {
  private static _descriptionTemplateRowService: DescriptionTemplateRowService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): DescriptionTemplateRowService {
    return this._descriptionTemplateRowService || (this._descriptionTemplateRowService = new this('descriptionTemplateRow'));
  }
  
  // /* DescriptionTemplates*/
  // public async get(): Promise<AxiosResponse> {
  //   var axiosResponse;
    
  //   await this.$http.get('get', {withCredentials: true})
  //   .then((response) => {
  //     axiosResponse = response;
  //   }, 
  //   (error) => {
  //     if (error.response.status === 401) {
  //       actionCreators.logoutUser();
  //     }
  //     throw new Error(error);
  //   });

  //   return axiosResponse;
  // }


  public async add(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('post', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async update(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.put('put',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async delete(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('delete/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }
}
  
export const DescriptionTemplateRowApi = DescriptionTemplateRowService.Instance;