import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class OwnershipService extends BaseService {
  private static _ownershipService: OwnershipService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): OwnershipService {
    return this._ownershipService || (this._ownershipService = new this('ownership'));
  }

  public async get(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('get/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async updateOwnership(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.put('put', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async getRelevantId(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('getRelevant/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async getValue(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('getValue/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async getPdf(id): Promise<boolean> {
//    var url = `${window.location.origin}/api/ownership/GetPdf?id=${id}`;
    var url = `/api/ownership/GetPdf?id=${id}`;
    window.location.href = url;
    return Promise.resolve(true);
 }




}



export const OwnershipApi = OwnershipService.Instance;