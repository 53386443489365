import React, {useState,useEffect} from 'react';
import './CategoryHeaderModule.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'


interface CategoryHeaderProps {
   title: string,
}


export const CategoryHeaderModule: React.FC<CategoryHeaderProps> = ({ title }) => {


  
      return <> 
         <div className='category-header-module'>
            <div className="description">{title || ''} </div>
         </div>
      </>
   }
