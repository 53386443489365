import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import {ContactDialog} from '.';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import {ConfirmationDialog} from '../../../components/Dialogs'

const GreenCheckbox = withStyles({
  root: {
    color: 'rgba(106,157,158,1)',
    '&$checked': {
      color: 'rgba(106,157,158,1)',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);



const ContactRow = ({contactData, index, mode, handleSelectedCb, handleChangeCb, handleDeleteCb, displaySelect=false}) => {
  const [openEdit, setOpenEdit] = React.useState(false);
  const [contact,setContact]= useState({
    contactId:0,
    firstName:'',
    lastName:'',
    email:'',
    mobile:'',
    address:'',
    zipCode:'',
    city:'',
    checked:false
  });
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

  useEffect(() => {
    if (contactData.contactId != contact.contactId || 
      contactData.firstName != contact.firstName || 
      contactData.lastName != contact.lastName || 
      contactData.email != contact.email || 
      contactData.mobile != contact.mobile || 
      contactData.address != contact.address || 
      contactData.zipCode != contact.zipCode || 
      contactData.city != contact.city || 
      contactData.checked != contact.checked) {

    setContact(contactData);
      }
  }, [contactData]);

  const getRowClass = function(index) {
    if (index % 2 === 0) {
      return "bk-project-row bk-row-white";
    }
    else {
      return "bk-project-row bk-row-grey";
    }
  }

  const handleClick = function() {
    if (mode === 0) {
      setOpenEdit(true);
    }
    else {
      contact.checked = !contact.checked;
      handleSelectedCb(contact);
    }
  }

  const handleEdit = function() {
    // Fire dialog
    setOpenEdit(true);
  }
 
  const handleDelete = function() {
    // Fire dialog
    setDisplayConfirmDialog(true);
  }


  const handleConfirmDialogClose = function(id) {
    if (id > 0) {
      handleDeleteCb(id);
    }
    setDisplayConfirmDialog(false);
  }


  const handleContactUpdated = function(contact) {
    handleChangeCb(contact);
    setOpenEdit(false);
  }


  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleEdit}>Rediger</MenuItem>
            <MenuItem onClick={handleDelete}>Slett</MenuItem>
          </Menu>
        </div>;
  }

  let checkboxHtml = null;
  if (displaySelect) {
    checkboxHtml = 
      <div className="flex-1 contacts-row-checkbox">
      <GreenCheckbox className="no-padding" checked={contact.checked} onClick={handleClick} />
    </div>
  }

  return (
    <>
      <div className={getRowClass(index)}>
        {checkboxHtml}
        <div className="bk-project-row-flex-4 bk-project-row-text bk-project-row-format" onClick={handleClick}><span>{contact.firstName} {contact.lastName}</span></div>
        <div className="bk-project-row-flex-3 bk-project-row-text bk-project-row-format" onClick={handleClick}><span>{contact.email}</span></div>
        <div className="bk-project-row-flex-2 bk-project-row-text bk-project-row-format" onClick={handleClick}><span>{contact.mobile}</span></div>
        <div className="bk-project-row-flex-5 bk-project-row-text bk-project-row-format" onClick={handleClick}><span>{contact.address} {contact.zipCode} {contact.city}</span></div>
        <div className="bk-project-row-flex-min bk-project-row-buttons ms-2">
              <ActionMenu />
          </div>
      </div>
    
      <ContactDialog 
        contactData={contact}
        open={openEdit}
        handleChangeCb={handleContactUpdated}
      />

      <ConfirmationDialog
          title="Slette?"
          text="Er du sikker på at du vil slette kontakten"
          open={displayConfirmDialog}
          id={contact.contactId}
          onClose={handleConfirmDialogClose}
      />
    </>
  )
}

export default React.memo(ContactRow);