import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Route = {
  readonly path: string;
  readonly icon?: IconProp;
  readonly exact?: boolean;
  readonly displayName: string;
  readonly showInNav?: boolean;
  readonly pathAbsolute?: string;
  readonly loggedIn?: boolean;
  readonly isAdmin?: boolean;
  readonly userOptions?: boolean;
};

export const RoutesConfig = Object.freeze<Record<string, Route>>({
  Home: {
    path: '/',
    showInNav: false,
    displayName: '',
    loggedIn: false,
    userOptions: false
  }, 
  
  About: {
    exact: true,
    path: '/About',
    showInNav: false,
    displayName: 'Om oss',
    loggedIn: true,
    userOptions: false
  },
  AboutWeb: {
    exact: true,
    path: '/AboutWeb',
    showInNav: true,
    displayName: 'Om oss',
    loggedIn: false,
    userOptions: false
  },
  Examples: {
    exact: true,
     path: '/examples',
     showInNav: false,
     displayName: 'Rapporter',
     loggedIn: true,
     userOptions: true
  },
  Price: {
    exact: true,
    showInNav: false,
    path: '/',
    displayName: 'Priser',
    loggedIn: false,
    userOptions: false
  },
  Contact: {
    exact: true,
    showInNav: true,
    path: '/contact',
    displayName: 'Kontakt oss',
    loggedIn: false,
    userOptions: true
  },
  ContactConfirmation: {
    exact: true,
    showInNav: false,
    path: '/contactConfirmation',
    displayName: 'Bekreftelse',
    loggedIn: false,
    userOptions: false
  },
  Register: {
    showInNav: true,
    path: '/register',
    displayName: 'Opprett konto',
    loggedIn: false,
    userOptions: true
  },
  RegisterConfirmation: {
    path: '/registerConfirmation',
    showInNav: false,
    displayName: 'Bekreftelse',
    loggedIn: false,
    userOptions: false
  },
  Login: {
    path: '/login',
    showInNav: true,
    displayName: 'Logg inn',
    loggedIn: false,
    userOptions: true
  },
  Logout: {
    path: '/logout',
    showInNav: false,
    displayName: 'Logout',
    loggedIn: true,
    userOptions: true
  },
  Admin: {
    path: '/admin',
    showInNav: true,
    displayName: 'Admin',
    loggedIn: true,
    isAdmin:true,
    userOptions: true
  },
  Forgot: {
    path: '/forgot',
    showInNav: false,
    displayName: 'Glemt passord',
    loggedIn: false,
    userOptions: false
  },
  ForgotConfirmation: {
    path: '/forgotConfirmation',
    showInNav: false,
    displayName: 'Bekreftelse',
    loggedIn: false,
    userOptions: false
  },
  ResetPassword: {
    path: '/resetPassword',
    showInNav: false,
    displayName: 'Reset passord',
    loggedIn: false,
    userOptions: false
  },
  Contacts: {
    path: '/contacts',
    showInNav: true,
    displayName: 'Kontaktoversikt',
    loggedIn: true,
    userOptions: false
  },  
  Projects: {
    path: '/projects',
    showInNav: true,
    displayName: 'Prosjektoversikt',
    loggedIn: true,
    userOptions: false
  },  
  Documents: {
    path: '/documents',
    showInNav: true,
    displayName: 'Dokumenter',
    loggedIn: true,
    userOptions: false
  },  
  Profile: {
    path: '/profile',
    showInNav: false,
    displayName: 'Min side',
    loggedIn: true,
    userOptions: true
  },
  Project: {
    path: '/project/:id',
    showInNav: false,
    displayName: 'Project',
    loggedIn: true,
    userOptions: false
  },
  Calculation: {
    path: '/calculation/:id',
    showInNav: false,
    displayName: 'Calculation',
    loggedIn: true,
    userOptions: false
  },  
  Property: {
    path: '/property/:id',
    showInNav: false,
    displayName: 'Property',
    loggedIn: true,
    userOptions: false
  },  
  SimpleCalculation: {
    path: '/simpleCalculation/:id',
    showInNav: false,
    displayName: 'SimpleCalculation',
    loggedIn: true,
    userOptions: false
  }, 
  DescriptionTemplate: {
    path: '/descriptionTemplate',
    showInNav: false,
    displayName: 'DescriptionTemplate',
    loggedIn: true,
    userOptions: false
  }, 
  CalculationTemplate: {
    path: '/calculationTemplate',
    showInNav: false,
    displayName: 'CalculationTemplate',
    loggedIn: true,
    userOptions: false
  }, 
  Ownership: {
    path: '/ownership/:id',
    showInNav: false,
    displayName: 'Ownership',
    loggedIn: true,
    userOptions: false
  }, 
  PriceGuide: {
    path: '/priceguide',
    showInNav: false,
    displayName: 'PriceGuide',
    loggedIn: true,
    userOptions: false
  }, 
  Document: {
    path: '/document/:id',
    showInNav: false,
    displayName: 'Document',
    loggedIn: true,
    userOptions: false
  }, 
  Plot: {
    path: '/plot/:id',
    showInNav: false,
    displayName: 'Plot',
    loggedIn: true,
    userOptions: false
  },
  Accept: {
    exact: true,
    path: '/accept',
    showInNav: false,
    displayName: 'Aksepter',
    loggedIn: true,
    userOptions: false
  },
  Invoice: {
    exact: true,
    path: '/invoice',
    showInNav: false,
    displayName: 'faktura adresse',
    loggedIn: true,
    userOptions: false
  },
  Report: {
    exact: true,
    path: '/report/:id',
    showInNav: false,
    displayName: 'Report',
    loggedIn: true,
    userOptions: false
  },
  SelectLayout: {
    exact: true,
    path: '/selectLayout',
    showInNav: false,
    displayName: 'Selectlayout',
    loggedIn: true,
    userOptions: false
  },
  DocumentLinks: {
   exact: true,
   path: '/links',
   showInNav: false,
   displayName: 'Links',
   loggedIn: true,
   userOptions: false
 },
 Support: {
  exact: true,
  path: '/support',
  showInNav: false,
  displayName: 'Veileder',
  loggedIn: true,
  userOptions: false
},
 ViewReport: {
   exact: true,
   path: '/ViewReport/:id',
   showInNav: false,
   displayName: 'Rapport',
   loggedIn: false,
   userOptions: false
 },
 Customers: {
   exact: true,
   path: '/Customers/:filter',
   showInNav: false,
   displayName: 'Kunder',
   loggedIn: true,
   isAdmin:true,
   userOptions: false
 },
 Customer: {
   exact: true,
   path: '/Customer/:id',
   showInNav: false,
   displayName: 'Kundekort',
   loggedIn: true,
   isAdmin:true,
   userOptions: false
 },
 SsbIndex: {
   exact: true,
   path: '/SsbIndex',
   showInNav: false,
   displayName: 'SsbIndex',
   loggedIn: true,
   isAdmin:true,
   userOptions: false
 },
 UpdateMessages: {
   exact: true,
   path: '/UpdateMessages',
   showInNav: false,
   displayName: 'UpdateMessages',
   loggedIn: true,
   isAdmin:true,
   userOptions: false
 },
 AdminLinks: {
   exact: true,
   path: '/AdminLinks',
   showInNav: false,
   displayName: 'AdminLinks',
   loggedIn: true,
   isAdmin:true,
   userOptions: false
 },
 Statistics: {
   exact: true,
   path: '/Statistics',
   showInNav: false,
   displayName: 'Statistics',
   loggedIn: true,
   isAdmin:true,
   userOptions: false
 },
 Tables: {
   exact: true,
   path: '/Tables',
   showInNav: false,
   displayName: 'Tables',
   loggedIn: true,
   isAdmin:true,
   userOptions: false
 },
 Text: {
   exact: true,
   path: '/Text',
   showInNav: false,
   displayName: 'Text',
   loggedIn: true,
   isAdmin:true,
   userOptions: false
 },
 Messages: {
   exact: true,
   path: '/Messages',
   showInNav: false,
   displayName: 'Meldinger',
   loggedIn: true,
   isAdmin:true,
   userOptions: false
 },
  Invoices: {
   exact: true,
   path: '/invoices',
   showInNav: false,
   displayName: 'Faktura',
   loggedIn: true,
   isAdmin:true,
   userOptions: false
 },
 Tools: {
  exact: true,
  path: '/Tools',
  showInNav: false,
  displayName: 'Verktøy',
  loggedIn: true,
  isAdmin:true,
  userOptions: false
},
});
