import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BkButton } from '../'
import { ContactButton } from '../'
import Dialog from '@material-ui/core/Dialog';
import parse from 'html-react-parser';
export interface ConfirmationDialogProps {
  title:string;
  open: boolean;
  grades:any,
  instructions:any,
  onClose: (tgId: number) => void;
}

export default function TgDialog(props: ConfirmationDialogProps) {
   const { onClose, open, title, grades, instructions } = props;
   
   const handleCancel = () => {
      onClose(-1);
   };

   const handleReset = () => {
      onClose(0);
   };

   const handleSelected = (tgId, showInDialog) => {
      if (!showInDialog) {
         return;
      }
      onClose(tgId);
   };

   // const isDisabled = (tgId) => {
   //    var details = getDetails(tgId);
   //    if (details.toLowerCase().indexOf("anvendes ikke") >= 0){
   //       return true;
   //    }
   //    return false;
   // }

   const getDetails= (gradeId) => {
      if (instructions != null) {
         var instruction = instructions.find(i=>i.gradeId==gradeId);
         if (instruction != null) {
            return instruction.instructions;
         }
      }
      var grade = grades.find(i=>i.gradeId==gradeId);
      if (grade != null) {
         return grade.details;
      }
      return "";
   }

   var gradesHtml = null;
   if (grades) {
      gradesHtml = grades.map((grade, index) => {
         var showInDialog = true
         if (instructions != null) {
            var instruction = instructions.find(i=>i.gradeId==grade.gradeId);
            if (instruction != null) {
               showInDialog = instruction.displayInDialog;
            }
         }

         return <>
               <div className="tg-dialog-row" >
                  <div className={"tg-dialog-button " + (showInDialog?"":"tg-dialog-button-disabled")} 
                     style={{backgroundColor:grade.colorCode, borderColor:grade.colorCode, boxShadow:"6px 5px white, 6px 5px 0 1.5px" + grade.colorCode }} 
                     onClick= {() =>handleSelected(grade.gradeId, showInDialog)}>{grade.name}</div>
                  { instructions == null || instructions.length == 0 &&
                     <div className="tg-dialog-description">{grade.description}</div>
                  }
                 
                  <div className={"tg-dialog-details " + (showInDialog?"":"tg-dialog-details-disabled")}>{parse(getDetails(grade.gradeId))}</div>
               </div>
            </>
      });

   }




  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="tg-dialog-title"
      open={open}
      onClose={handleCancel}
    >
      <DialogTitle id="tg-dialog-title"  className="dialog-header">{title}</DialogTitle>
      <DialogContent>
         <div className="">
            {gradesHtml}
         </div>
      </DialogContent>
      <DialogActions  className="">
         <ContactButton caption='Avbryt' onClickHandler={handleCancel}/>
         <BkButton caption='Nullstill' onClickHandler={handleReset}/>
      </DialogActions>
    </Dialog>
  );
}
