import thunk from 'redux-thunk';
import { History } from 'history';
import { IApplicationState } from './index';
import createRootReducer from './rootReducer';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 
const persistConfig = {
  key: 'root',
  storage,
}
 
export const configureStore = (
  history: History,
  initialState?: IApplicationState
) => {
  const composeEnhancer: typeof compose =
    (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
    compose;

  const store = createStore(
    persistReducer(persistConfig, createRootReducer(history)),
    initialState,
    composeEnhancer(applyMiddleware(thunk, routerMiddleware(history)))
  );

  const persistor = persistStore(store);
  // Enable webpack hot module replacement for reducers
  if (module.hot) {
    module.hot.accept("./rootReducer", () => {
      store.replaceReducer(persistReducer(persistConfig, createRootReducer(history)));
    });
  }

  return { store, persistor };
};
