import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { CalculationApi } from '../../../api';
import { ConfirmationDialog } from '../../../components/Dialogs'
import { ReportEntity } from '../../Report/types';
import { ReportApi } from '../../../api/report.service';
import { LeaseholdApi } from '../../../api/leasehold.service';
import { formatValue, formatDateTime } from '../../../utils'
import Leasehold from '../../Modules/Leasehold/Leasehold';
import PrintReportDialog from '../../Report/child-components/printReportDialog'



interface ReportRowProps {
    report: ReportEntity,
    index: number,
    showValue:boolean,
    useTemplateTitle:boolean,
    onDeleteCb: (number) => void,
    onCopyCb: (number) => void
}

const ReportRow = ({ report, index, showValue, useTemplateTitle, onDeleteCb, onCopyCb }: ReportRowProps) => {
   const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
   const [openPrintDialog, setOpenPrintDialog] = useState(false);
   const [valueString, setValueString] = useState('');

    const history = useHistory();
    const id = report.reportId;

    useEffect(() => {
        if (report.reportTemplateName == "Festetomt") {
            const getDataFromResponse = (response) => response && response.data && response.data ? response.data : null;

            LeaseholdApi.getLeasehold(report.reportId)
                .then((response) => {
                    return response;
                },
                    (error) => {
                        console.log(error);
                        throw (error)
                    }).then(response => {
                        const leaseholdData = getDataFromResponse(response);

                        if (leaseholdData == null)
                            return;

                        setValueString(formatValue(leaseholdData.value - (leaseholdData.fee * 25), 0, ' kr', '-'));

                    });
        }
        if (showValue) {
            setValueString(formatValue(report.value,0, ' kr', '-'));
        }
    }, [report]);


    const getRowClass = function (index) {
        if (index % 2 === 0) {
            return "bk-project-row bk-row-white"
        }
        else {
            return "bk-project-row bk-row-grey"
        }
    }

    const getTitle = () => {
        if (report.title != null && report.title != '') {
            if (useTemplateTitle) {
                return report.reportTemplateName + ' - ' + report.title;
            }
            else {
                return report.title;
            }
        }
        return report.reportTemplateName;

    }

    const getValue = () => {
        if (report.reportTemplateName == "Festetomt") {

        }
    }

    const handleEdit = event => {
        event.preventDefault();
        const url = "/report/" + id;
        history.push(url);
    }

    const handleCopy = event => {
      if (id > 0) {
        onCopyCb(id);
      }
    }
  

    const handleDeleteReport = event => {
        setDisplayConfirmDialog(true);
    }

    const handleConfirmDialogClose = function (id) {
        if (id && id > 0) {
            onDeleteCb(id);
        }

        setDisplayConfirmDialog(false);
    }

    const handlePrint = event => {
        event.preventDefault();

        setOpenPrintDialog(true);;
    }

    const handlePrintSelected = (selection) => {
      setOpenPrintDialog(false);
      if (selection == 1) {
         // Draft
         ReportApi.getPdf(id, 0);
      }
      if (selection == 2) {
         // Publish
         ReportApi.getPdf(id, 1);
      }
      if (selection == 3) {
         // Publish
         ReportApi.getPdf(id, 2);
      }
    }

    const getReportName = () => {
       if (report.reportTemplateId == 1) {
          return 'Arealskjemaet';
       }
       if (report.reportTemplateId == 2) {
         return 'Festetomten';
      }
      if (report.reportTemplateId == 3) {
         return 'Verditaksten';
      }
      if (report.reportTemplateId == 4) {
         return 'Tilstandsrapporten';
      }
      else 
         return "Raporten"
    }


    function ActionMenu(props) {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
            <FontAwesomeIcon icon={faBars} onClick={handleClick} />
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                className="navbar-dropdown-menu"
            >
                <MenuItem onClick={handleEdit}>Rediger</MenuItem>
                <MenuItem onClick={handlePrint}>Skriv ut</MenuItem>
                <MenuItem onClick={handleCopy}>Kopier</MenuItem>
                <MenuItem onClick={handleDeleteReport}>Slett</MenuItem>
            </Menu>
        </div>;

    }


    return (
        <>
            <div className={getRowClass(index)} >
                <div className="bk-project-row-flex-1 bk-project-row-text bk-project-row-align-left" onClick={handleEdit}>{formatDateTime(report.created)}</div>
                <div className="bk-project-row-flex-3 bk-project-row-text bk-project-row-align-left" title={report.reportTemplateName} onClick={handleEdit}>{getTitle()}</div>
                {/* <div className="bk-project-row-flex-1 bk-project-row-text bk-project-row-align-center" onClick={handleEdit}>{formatDateTime(report.updated)}</div> */}
                <div className="bk-project-row-flex-3 bk-project-row-text bk-project-row-align-right"onClick={handleEdit}></div>
                <div className="bk-project-row-flex-1 bk-project-row-text bk-project-row-align-right" title={''} onClick={handleEdit}>{valueString}</div>
                <div className="bk-project-row-flex-1 bk-project-row-text bk-project-row-align-right"></div>

                <div className="bk-project-row-flex-min bk-project-row-buttons">
                    <ActionMenu />
                </div>
            </div>


            <ConfirmationDialog
                title="Slette?"
                text={"Er du sikker på at du vil slette " + getReportName()}
                open={displayConfirmDialog}
                id={id}
                onClose={handleConfirmDialogClose}
            />

            <PrintReportDialog
               open = {openPrintDialog}
               latestReleaseDate = {report? report.latestReleaseDate:null}
               handleSelectedCb={handlePrintSelected}
            />
        </>
    )
}
ReportRow.displayName = 'Rapport';

export default React.memo(ReportRow);