import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class CapitalizationModuleService extends BaseService {
   private static _capitalizationModuleService: CapitalizationModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): CapitalizationModuleService {
     return this._capitalizationModuleService || (this._capitalizationModuleService = new this('capitalizationModule'));
   }
 

   public async getCapitalizationModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addCapitalizationModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addCapitalizationModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateCapitalization(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateCapitalization.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async updateExpense(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateExpense`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateExpense',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async updateRevenue(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateRevenue`); 
        Promise.reject();
      }
      
      await this.$http.post('updateRevenue',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async deleteRevenue(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.delete('deleteRevenue/'+ id, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
  
      return axiosResponse;
    }

    public async deleteExpense(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.delete('deleteExpense/'+ id, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
  
      return axiosResponse;
    }
}

export const CapitalizationModuleApi = CapitalizationModuleService.Instance;