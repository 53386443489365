import React from 'react';
import {PlotItem} from './'


const PlotGroup = ({calculationId, group, onChanged}) => {

   const handlePlotItemChange = function() {
      onChanged();
   }

   let itemsHtml = null;
   if (group.items) {
      itemsHtml = group.items.map((item) => {
         return (
            <PlotItem calculationId={calculationId} itemRow={item} onChanged={handlePlotItemChange}/>
         )
      });  
   }

   return (
      <div className="property-frame">
         <div className="property-group-header">{group.header}</div>
         {itemsHtml}
      </div>
   )
}

export default React.memo(PlotGroup);