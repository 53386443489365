import React, { useMemo, useState, useEffect } from 'react';
import {formatValue } from '../../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faXmark } from '@fortawesome/free-solid-svg-icons'


interface AreaTableRowProps {
  id: any,
  name: any,
  area: any,
  buildYears:any,
  displaySecondaryArea:boolean,
  displayDelete:boolean,
  readonly onAreaChangeCb?: (area:any) => void;
  readonly onDeleteCb?: (id:any) => void;
}

const keyPressTimer = 1000;
let inputTimer = null;
function AreaTable(props:AreaTableRowProps) {

  const [area, setArea] = useState({
    primaryArea:0,
    secondaryArea:0,
    buildYear:0
  });
  
  useEffect(() => {
    setArea(props.area);  
  }, [props.id]);

  const onYearChange = (e) => {
    const { name, value } = e.target;
    var intVal = parseInt(value);
    setArea(area => ({...area, buildYear:intVal}));

    var toBeUpdated = area;
    area.buildYear=intVal;
    
    props.onAreaChangeCb(toBeUpdated);
  }

  const onNumericChange = (e) => {
    const { name, value } = e.target;
    var intVal = parseInt(value);
    if (isNaN(intVal)) {
      intVal = 0;
    }
    
    setArea(area => ({...area, [name]:intVal}));

    var toBeUpdated = area;
    toBeUpdated[name]=intVal;

    if (inputTimer !== null){
      clearTimeout(inputTimer);
    }
    inputTimer = setTimeout(() => {
      props.onAreaChangeCb(toBeUpdated);
    }, keyPressTimer);  
  }
  
  const onNumericBlur = (e) => {
    const { name, value } = e.target;
    clearTimeout(inputTimer);
    var intVal = parseInt(value);
    if (isNaN(intVal)) {
      intVal = 0;
    }
    setArea(area => ({...area, [name]:intVal}));
    var toBeUpdated = area;
    toBeUpdated[name]=intVal;
    props.onAreaChangeCb(toBeUpdated);
  }

  const deleteArea = () => {
    props.onDeleteCb(props.id);
  }

  

  const displayNumericValue = function(value) {
    if (value=="" || value==null || value==undefined) {
      return "";
    }

    if (value > 0) {
      return value;
    }
    return "";
  }

  const getOptionsBuildYears = function(buildYears) {
    if (!buildYears)
      return null;

    const optionsBuildYears = buildYears.map((buildYear) => <option key={buildYear.year} value={buildYear.year}>{buildYear.name}</option>);
    return optionsBuildYears;

  }

  const getYearSelectorWithCurrentValue = function(currentBuildYear, calculationId, optionsBuildYears){
    return (
      <select 
        key={calculationId} 
        className="form-control area-table-select" 
        value={currentBuildYear} 
        onChange={(e)=>onYearChange(e)}>
        {optionsBuildYears}
      </select>
    );
  }

  const optionsBuildYears = useMemo(()=> getOptionsBuildYears(props.buildYears), [props.buildYears]);
  
  return (
    <>
      <div className="display-flex area-table-row">
        <div className="flex-3 area-table-col area-table-border-left"><div>{props.name}</div></div>
        <div className="flex-2 area-table-col-input " >
          {getYearSelectorWithCurrentValue(area.buildYear, 0, optionsBuildYears)}
        </div>
        
        <div className="flex-2 ">
          <div className="display-flex">
            <div className="flex-1 area-table-col-input">
              <div className="input-icon area-table-header-input-icon-right inline-block">
                <i>m&#178;</i>
                <input className="form-control area-table-header-input" id='area-table-header-input-no-border' name="primaryArea" onChange={onNumericChange} onBlur={onNumericBlur} placeholder="0" type="text" value={displayNumericValue(area.primaryArea)} />
              </div>
            </div>
          </div>
        </div>
        
        {props.displaySecondaryArea &&
        <>
          <div className="flex-2">
            <div className="display-flex">
              <div className="flex-1 area-table-col-input">
                <div className="input-icon area-table-header-input-icon-right inline-block">
                  <i>m&#178;</i>
                  <input className="form-control area-table-header-input" id='area-table-header-input-no-border' name="secondaryArea" onChange={onNumericChange} onBlur={onNumericBlur} placeholder="0" type="text" value={displayNumericValue(area.secondaryArea)} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-2 ">
            <div className="display-flex">
              <div className="flex-1 area-table-right-column">
                <div>
                  {formatValue(area.primaryArea + area.secondaryArea,0,' m²')}
                </div>
              </div>
            </div>
          </div>  
        </>
        }
             
          <div className="flex-1 area-table-delete-btn">
          {props.displayDelete &&
            <FontAwesomeIcon icon={faXmark} className=" ms-3 delete-addon-button fa-1x" name="deleteAddon" type="submit" onClick={()=>deleteArea()}/>
          }
         
        </div>
        {!props.displaySecondaryArea &&
            <div className="flex-3"></div>
          }
      </div>
            
    </>
  )
}

export default React.memo(AreaTable);