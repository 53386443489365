import React, {useState, useEffect} from 'react'
import {AutoTextBox, UploadImage, TextLineInput} from '..'


export interface ImageWithSubTitleProps {
   readonly picture: any,
   readonly showTitle: boolean,
   readonly class: string,
   readonly allowEdit: boolean,
   readonly readOnly: boolean,
   readonly updatePictureCb?: (id:number, url:string, subTitle: string, mainTitle: string) =>  Promise<any>;
 }

const keyPressTimer = 1000;
let inputTimer = null;
function ImageWithSubTitle(props:ImageWithSubTitleProps) {

   const [id, setId] = useState(0);
   const [url, setUrl] = useState(null);
   const [subtitle, setSubtitle] = useState('');
   const [maintitle, setMainTitle] = useState('');

   const [initialized, setInitialized] = useState(false);
   useEffect(() => {
      if (props.picture != null) {
         setId(props.picture.id);
         if (!initialized) {
            setUrl(props.picture.url);
            setSubtitle(props.picture.subTitle);
            setMainTitle(props.picture.mainTitle);
            setInitialized(true);
         }
      }
   }, [props])

   const onValueChange = (value) => {
      setMainTitle(value);
      props.updatePictureCb(id, url, subtitle, value); 
   }
   
   const onTextChange = (value) => {
      setSubtitle(value);
      props.updatePictureCb(id, url, value, maintitle); 
   }
   
   const onUrlChanged = (value) => {
      setUrl(value);
      props.updatePictureCb(id, value, subtitle, maintitle);  
   }

   return ( 
   <> 
      {props.showTitle &&
      <div>
         <div className='category-header-module margin-bottom-10'> <TextLineInput name="titel" class="description-input" placeholder="Valgfri overskrift" readOnly={props.readOnly} value={maintitle} onValueChangeCb={onValueChange}/></div>
      </div>
      }
      <div className={props.class}>
         <UploadImage pictureUrl={url} readOnly={props.readOnly} onUrlChangedCb={onUrlChanged}></UploadImage>
      </div>
      <div className='mb-3'>
         <AutoTextBox text={subtitle} readOnly={props.readOnly} onValueChangeCb={onTextChange}></AutoTextBox>
      </div>
   </>
  )
           
                      
}
  
export default ImageWithSubTitle;