import React, { useState, useEffect} from 'react';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faClipboardList } from '@fortawesome/free-solid-svg-icons'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { AutoTextBox, BkButton } from '../'

const GreenCheckbox = withStyles({
   root: {
     color: 'rgba(106,157,158,1)',
     '&$checked': {
       color: 'rgba(106,157,158,1)',
     },
   },
   checked: {},
 })((props: CheckboxProps) => <Checkbox color="default" {...props} />);


export interface RoomRowProps {
  roomId: number;
  room:string;
  editMode:boolean;
  hasFocus:boolean;
  handleSelectedCb: (roomId:number, selected:boolean) => void;
  handleUpdatedCb: (roomId:number, text:string) => void;
  handleDeleteCb: (roomId:number) => void;
}

const  RoomRows = (props: RoomRowProps, ) => {
  const [room,setRoom]= useState({
    text:'',
    checked:false,
    editMode:false
  });

  useEffect(() => {
    setRoom(room => ({...room, text:props.room}));
  }, [props.roomId]);

  const handleClick = () => {
    var newVal = !room.checked
    setRoom(room => ({...room, checked:newVal}));
    props.handleSelectedCb(props.roomId, newVal);
  }

  const handleTextChange = (text) => {
    if (text != room.text) {
       setRoom(room => ({...room, text:text}));
       props.handleUpdatedCb(props.roomId,text);
    }
 }

  const handleDelete = (roomId) => {
    props.handleDeleteCb(roomId);
 };

 const handleEdit = (roomId) => {
    var newVal = !room.editMode
    setRoom(room => ({...room, editMode:newVal}));
 };

  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
       setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
              elevation={0}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={() =>handleEdit(props.phraseId)}>{room.editMode ?'Lukk redigering':'Rediger rom'}</MenuItem>
            <MenuItem onClick={() =>handleDelete(props.phraseId)}>Slett rom</MenuItem>
          </Menu>
        </div>;

 }

  return (
    <>
      <div className='row my-2 ms-2 bk-text-blue'>
      <div className='col-1'> <GreenCheckbox className="no-padding" checked={room.checked} onClick={handleClick}/></div>

             {room.editMode ?
              <>
                 <div className='col-9'>
                 <AutoTextBox text={room.text} onValueChangeCb={handleTextChange} focus={props.hasFocus} phraseRegisterKey=''></AutoTextBox>
                 </div>
              </>
              :
              <>
                  <div className='col-9' onClick={handleClick}> {props.room} </div>
              </>
              }

     

      <div className='col-1'><div className='phrase-text-icon'><ActionMenu phraseId={props.roomId}></ActionMenu></div></div>
      </div>         
    </>
   )
}

export default React.memo(RoomRows);