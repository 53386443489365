import React, {useState} from 'react';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faTrashCan } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { MonetizationOn, Cancel } from '@material-ui/icons';

const CustomerResultRow = ({customer, index, handleDeletedCb }) => {

   const handleDeleteClick = function(id) {
      handleDeletedCb(id);
   }
   
   return (
      <>
            <div className="customer-share-row customer-row-white">
               <div className="customer-text-trim">{customer.firstName}</div>
               <div className="customer-text-trim">{customer.lastName}</div>
               <div className="customer-text-trim">{customer.company}</div>
               <FontAwesomeIcon icon={faTrashCan} className="fa-1x align-right" onClick={()=>handleDeleteClick(customer.userId)}/> 
            </div>
      </>
   )
}

export default React.memo(CustomerResultRow);
