import React, { useState, useEffect,  useRef, } from 'react';
import { useHistory } from 'react-router-dom'
import {AdminApi, AuthApi, ReportApi} from '../../../api';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../../utils';
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const IndexRow = ({ssbindex, activateIndex, latestindex}) => {

   const history = useHistory();
   const toastIdRef = useRef<string | number>('');

   const [SsbIndex, setSsbIndex] = useState([ssbindex.value]); 
   
   

   const activatebtn = () => {
      if ( ssbindex.id == latestindex){
         if (ssbindex.activated == null){
             return <div className='col-2'><div className='index-btn' id="activatebtn" onClick={activateIndex}> Aktiver</div></div>
         }
         else return <div className='col-2'><span>{ssbindex.activated}</span></div>
        
      }
      else return <div className='col-2'><span>{ssbindex.activated}</span></div>
   }
   
   const formatValue=(value) => {
      if (value==0) {
         return '-'
      }
      else 
      return value + ' %'
   }



   return (
      <>
                <div className='row my-2 index-data-row'>
                
                <div className='col-3'>{ssbindex.name}</div>
                <div className='col-3'>{ssbindex.indexDate}</div>
                <div className='col-2'>{ssbindex.value} %</div>
                <div className='col-2'>{formatValue(ssbindex.change)}</div>
                {activatebtn()}
                </div>         
      </>
   )
}

export default React.memo(IndexRow);