import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class PhraseService extends BaseService {
  private static _phraseService: PhraseService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): PhraseService {
    return this._phraseService || (this._phraseService = new this('phrase'));
  }

  public async getPhrases(key, alphabetical): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('get/' + key + '/' + alphabetical, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async addPhrase(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('post', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async updatePhrase(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.put('put', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async deletePhrase(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('delete/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


}



export const PhraseApi = PhraseService.Instance;