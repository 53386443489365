import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class ProjectService extends BaseService {
  private static _projectService: ProjectService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): ProjectService {
    return this._projectService || (this._projectService = new this('Project'));
  }

  // public async getProjects(): Promise<AxiosResponse> {
  //   var axiosResponse;
  //   await this.$http.get('Projects', {withCredentials: true})
  //   .then((response) => {           
  //     axiosResponse = response;
  //   }, 
  //   (error) => {this.handleError(error); });
  //   return axiosResponse;
  // }

  public async getProject(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('Project?projectId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    },  
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async addProject(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('AddProject',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async copyProject(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('CopyProject',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async editProjectData(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('EditProjectData',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async editAddressData(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('EditAddress',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async deleteProject(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('delete/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }



  public async uploadImage(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    const config = {   
      withCredentials: true,  
      headers: { 'content-type': 'multipart/form-data' }
  }
  
    await this.$http.post('UploadImage',data, config)
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async getPdf(id): Promise<boolean> {

 //   var url = `${window.location.origin}/api/project/GetPdf?projectId=${id}`;
    var url = `/api/project/GetPdf?projectId=${id}`;
    window.location.href = url;

    return Promise.resolve(true);
  }


  public async addCalculation(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('AddCalculation',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  // public async addSimpleCalculation(data): Promise<AxiosResponse> {
  //   var axiosResponse;
    
  //   await this.$http.post('AddOtherCalculation',data, {withCredentials: true})
  //   .then((response) => {
  //     axiosResponse = response;
  //   }, 
  //   (error) => {this.handleError(error); });

  //   return axiosResponse;
  // }

  public async addRightOfUse(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('AddRightOfUseCalculation',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async addReport(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('AddReport',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
        (error) => {
            this.handleAllErrors(error);
            throw (error);
        });

    return axiosResponse;
  }


  public async getLocationFromZipCode(zipCode): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('getLocationFromZipCode?zipCode=' + zipCode, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async deleteCalculation(projectId, calculationId): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('deleteCalculation/'+ projectId + '/' + calculationId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async deleteSimpleCalculation(projectId, calculationId): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('deleteSimpleCalculation/'+ projectId + '/' + calculationId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async deleteOwnershipCalculation(projectId, calculationId): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('deleteRightOfUseCalculation/'+ projectId + '/' + calculationId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async deleteReport(projectId, reportId): Promise<AxiosResponse> {
    var axiosResponse;

    await this.$http.delete('deleteReport/'+ projectId + '/' + reportId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error); throw(error)});

    return axiosResponse;
  }

  public async copyCalculation(projectId, calculationId): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {projectId: parseInt(projectId) , calculationId: parseInt(calculationId) };
    await this.$http.post('copyCalculation', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error); throw(error)});

    return axiosResponse;
  }

  public async copySimpleCalculation(projectId, calculationId): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {projectId: parseInt(projectId) , calculationId: parseInt(calculationId) };
    await this.$http.post('copyOtherCalculation', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error); throw(error)});

    return axiosResponse;
  }

  public async copyReport(projectId, reportId): Promise<AxiosResponse> {
   var axiosResponse;
   var data = {projectId: parseInt(projectId) , calculationId: parseInt(reportId) };
   await this.$http.post('copyReport', data, {withCredentials: true})
   .then((response) => {
     axiosResponse = response;
   }, 
   (error) => {this.handleAllErrors(error); throw(error)});

   return axiosResponse;
 }

 public async copyOwnerShipCalculation(projectId, ownershipId): Promise<AxiosResponse> {
   var axiosResponse;
   var data = {projectId: parseInt(projectId) , calculationId: parseInt(ownershipId) };
   await this.$http.post('copyRightOfUseCalculation', data, {withCredentials: true})
   .then((response) => {
     axiosResponse = response;
   }, 
   (error) => {this.handleAllErrors(error); throw(error)});

   return axiosResponse;
 }

}

export const ProjectApi = ProjectService.Instance;