import React, {useState, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import { PlotApi } from '../../../api';
import { truncate } from 'lodash';

const keyPressTimer = 1000;
let inputTimer = null;

const PlotItem = ({calculationId, itemRow, onChanged}) => {
   const [item, setItem] = useState({
      propertyType:0,
      helpText:'',
      name:''
   });
   const [properties, setProperties] = useState([]);
   
   useEffect(() => {
      setItem(itemRow);
      setProperties(itemRow.properties);
      CheckIfNewPropertyShouldBeAdded(itemRow.propertyType, itemRow.properties);
   }, []);

   const updateProperty = function(property) {
       // There is a mismatch between the model to and from server
      var num = (property.numericValue !== null && property.numericValue !== undefined)?property.numericValue.toString():'0';
      var selected = (property.selectedValue !== null && property.selectedValue !== undefined)?property.selectedValue.toString():'0';
 
       var data =  {
         calculationId:  parseInt(calculationId),
         properties:[]
       };
         
       var element = {        
         id: property.costFactorId,
         selectionId: property.selectionId,
         numericValue: num,
         textValue: property.textValue,
         selectedValue: selected
       }
       data.properties.push(element);

       PlotApi.updateProperty(data).then (
         (response) => {
            //update selectionId
            if (property.selectionId == 0) {
               var newProps = properties.map((prop, index) => {
                  if (prop.selectionId === 0) {
                     prop.selectionId = response.data.selectionId;
                    return prop;
                  }
                  return prop;
               })              
               setProperties(newProps);
            }
            CheckIfNewPropertyShouldBeAdded(item.propertyType, properties);
            onChanged();
         },
         (error) => {
           console.log(error);
         }
       );
   }


   const displayNumericValue = function(value) {
      if (value !== 0) {
         return value;
      }
      return "";
   }

   const onNumericChange = function(e, id) { 
      const { value } = e.target;
      var selectedProperty = null;

      // Only accept numeric
      var intVal = parseInt(value);
      if (isNaN(intVal)) {
        intVal = 0;
      }
      var newProps = properties.map((prop, index) => {
         if (prop.selectionId === id) {
            prop.numericValue = intVal
            selectedProperty = prop;
           return prop;
         }
         return prop;
      })
      setProperties(newProps);
 
      if (selectedProperty != null) {
         if (inputTimer !== null){
            clearTimeout(inputTimer);
          }
          inputTimer = setTimeout(() => {
            delayedPropertyUpdate(selectedProperty)
          }, keyPressTimer);
      }
   }
  
    const onNumericBlur = (e, id) => {
      const { name, value } = e.target;
      var selectedProperty = null;

      clearTimeout(inputTimer);

      var intVal = parseInt(value);
      if (isNaN(intVal)) {
        intVal = 0;
      }

      var newProps = properties.map((prop, index) => {
         if (prop.selectionId === id) {
            prop.numericValue = intVal
            selectedProperty = prop;
           return prop;
         }
         return prop;
      })

      setProperties(newProps);
      var toBeUpdated = selectedProperty;
      updateProperty(toBeUpdated);
    }





   const handleTextChange = function(e, id) {
      const { value } = e.target;
      var selectedProperty = null;
      var newProps = properties.map((prop, index) => {
         if (prop.selectionId === id) {
            prop.textValue = value
            selectedProperty = prop;
           return prop;
         }
         return prop;
      })
      setProperties(newProps);

      if (selectedProperty != null) {
         if (inputTimer !== null){
            clearTimeout(inputTimer);
          }
          inputTimer = setTimeout(() => {
            delayedPropertyUpdate(selectedProperty)
          }, keyPressTimer);

      }
   }

   const delayedPropertyUpdate = function(item) {
      
      updateProperty(item);
      
   }

   const handleSelectedChange = function(e, id) {
      const { value } = e.target;
      var selectedProperty = null;
      var newProps = properties.map((prop, index) => {
         if (prop.selectionId === id) {
            prop.selectedValue = value;
            prop.selectedId = parseInt(value);
            selectedProperty = prop;
           return prop;
         }
         return prop;
      })
      setProperties(newProps);

      if (selectedProperty != null) {
         updateProperty(selectedProperty);
         //CheckIfNewPropertyShouldBeAdded();
      }
   }

   const handleDeleteRow = function(id) {
      setProperties(properties.filter(prop => prop.selectionId !== id));

      var data = {
         calculationId:parseInt(calculationId),
         selectionId:id
      }

      PlotApi.deleteProperty(data).then (
         (response) => {
            onChanged(); 
            CheckIfNewPropertyShouldBeAdded(item.propertyType, properties.filter(prop => prop.selectionId !== id));
         },
         (error) => {
           console.log(error);
         }
      );
     
   }

   const CheckIfNewPropertyShouldBeAdded = function(propertyType, props) {
      var addNewRow = false;
      var numericValue = 0;

      if (propertyType == 8) {
         // Select boxes with value
         addNewRow = true;
         props.map((prop) => {
            if (prop.numericValue == '0' || prop.numericValue == '' || prop.numericValue == null || prop.numericValue == undefined || 
                  prop.selectedId == 0  || prop.selectedId == null  || prop.selectedId == undefined) {
               addNewRow = false;
            }
         })
      }
      else if (propertyType == 9) {
         //Select boxes with value until 100%
         var totalPercent = 0;
         addNewRow = true;
         props.map((prop) => {            
            totalPercent = totalPercent + parseInt(prop.numericValue,10);
            if (prop.numericValue == '0' || prop.numericValue == '' || prop.numericValue == null || prop.numericValue == undefined || 
                  prop.selectedId == 0  || prop.selectedId == null  || prop.selectedId == undefined) {
               addNewRow = false;
            }
         })
         if (totalPercent >= 100) {
            addNewRow = false;
         }
         else {
            numericValue = 100 - totalPercent;
         }

      }

      else if (propertyType == 10) {
         // Extra addon
         addNewRow = true;
         props.map((prop) => {
            if (prop.numericValue == '0' || prop.numericValue == '' || prop.numericValue == null || prop.numericValue == undefined || 
                  prop.textValue == ''  || prop.textValue == null  || prop.textValue == undefined) {
               addNewRow = false;
            }
         })
      }

      if (addNewRow) {
         var newProp = JSON.parse(JSON.stringify(props[props.length-1])); // deep clone of an object 
         newProp.numericValue=numericValue.toString();
         newProp.textValue='';
         newProp.selectedValue='0';
         newProp.selectionId=0;
         newProp.selectedId=0;
         setProperties([...props, newProp])
      }
   }



   const initialOptions = function(caption) {
      return (
         <option key={0} value={0}>{caption}</option>
      )
   }


  const getSelectItems = function(property, index) {
    var options = [];
    if (property.selectItems.length > 0)
    {
      options.push(initialOptions(property.caption));
      var newOptions =  property.selectItems.map((option) => {
        return <option key={option.value} value={option.value}>{option.text}</option>
      });
      options.push(newOptions);

      return (
         <div className="property-item-select display-flex">
            <select className={['form-control',  (property.required && property.selectedId <=0)?'calculation-required-error':''].join(' ')} value={property.selectedId.toString()} onChange={(e)=>handleSelectedChange(e,property.selectionId)}>
               {options}
            </select>
            <img className={(index) > 0?'':'visibility-hidden'} title="Slett rad" src="/Content/images/font-awsome/black/times.png" onClick={()=>handleDeleteRow(property.selectionId)}/>
         </div>
      );
   }
   else {
      return (<></>);
   }
  }

  let propertiesHtml = null;
  if (properties) {
      propertiesHtml = properties.map((property, index) => {
         if (item.propertyType == 1 || item.propertyType == 2 || item.propertyType == 3 || item.propertyType == 7 || item.propertyType == 8  || item.propertyType == 9) 
         {
            return (
               <div key={'a_' + index} className="property-item-container">
                  <div className="property-item-value">
                     <i>{property.suffix}</i>
                     <input className="form-control" placeholder="0" name={"TC" +index} type="text" value={displayNumericValue(property.numericValue)} onChange={(e)=>onNumericChange(e,property.selectionId)} onBlur={(e)=>onNumericBlur(e,property.selectionId)}/>
                  </div>
                  {getSelectItems(property, index)}
               </div>
            );
         }
         else if (item.propertyType == 10) {
            return (
               <div key={'b_' + index} className="property-group-items-container">
                  <div className="property-item-text-area">
                     <textarea className="form-control" rows={4}  placeholder="Notat" value={property.textValue} onChange={(e)=>handleTextChange(e,property.selectionId)}></textarea>
                  
                  </div>
                  <div className="property-item-value-area">
                     <input className="form-control" placeholder="0" name={"TC" +index} type="text" value={displayNumericValue(property.numericValue)} onChange={(e)=>onNumericChange(e,property.selectionId)} onBlur={(e)=>onNumericBlur(e,property.selectionId)}/>
                  </div>
                  <div className="property-itemgroup-helptext">
                     <div className={(index) == 0?'':'visibility-hidden'}>                     
                        <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"Help"} data-for={"tooltip"} data-tip={item.helpText}/>
                        <ReactTooltip id={"tooltip"} place="right" type="light" html={true} effect="solid"  className="helptext-custom"/>
                     </div>

                  </div>
               </div>
            )
         }
      });
   }


// Return

   if (item.propertyType == 1 || item.propertyType == 2 || item.propertyType == 3 || item.propertyType == 7 || item.propertyType == 8  || item.propertyType == 9) {
      return (
         <div key={item.name} className="property-group-items-container">
            <div className="property-itemgroup-name">{item.name}</div>
            <div className="property-itemgroup-item">
                  {propertiesHtml}
            </div>
            <div className="property-itemgroup-helptext">
               <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"Help"} data-for={"tooltip"} data-tip={item.helpText}/>
               <ReactTooltip id={"tooltip"} place="right" type="light" html={true} effect="solid"  className="helptext-custom"/>

            </div>
         </div>
      );
   }
   else if (item.propertyType == 6) {
      return (
         <div key={item.name} className="display-flex calculation-row">
            <div className="flex-12 calculation-note-row">
               <div className="calculation-cell">{item.name}</div>
               <div className="calculation-cell">
                  <div className="input-icon input-icon-right">
                     <textarea className="form-control form-control-note" rows={6}  placeholder="Notat" value={properties[0].textValue} onChange={(e)=>handleTextChange(e,properties[0].selectionId)}></textarea>
                  </div>
               </div>
            </div>
         </div>
      );
   }
   else if (item.propertyType == 10) {
      return (
         <>
            {propertiesHtml}
         </>
      )
   }

}


export default React.memo(PlotItem);