import React from 'react';
import ReactTooltip from 'react-tooltip';
import {formatValue } from '../../../utils'
import AreaTableRow from './AreaTableRow';

const primaryAreaProperty = 27;
const secondaryAreaProperty = 28;

interface AreaTableProps {
  calculation: any,
  additions: any,
  buildYears: any,
  properties:any,
  displaySecondaryArea:boolean,
  readonly handleAreaChangeCb?: (area:any) => void;
  readonly deleteAdditionCb?: (id:any) => void;
  readonly newAdditioncb?: () => void;
}

function AreaTable(props:AreaTableProps) {
  
  const handleAreaChange = function(area) {
    props.handleAreaChangeCb(area);
  }

  const handleAreaDelete = function(id) {
    props.deleteAdditionCb(id);
  }

  const newAdditionHandler = function() {
    props.newAdditioncb();
  }

  const getTotalPrimaryArea = function() {
    var area = props.calculation.primaryArea;
    props.additions.map((addition) => {
      area = area + addition.calculation.primaryArea;
      return area;
    });
    return area;
  }

  const getTotalSecondaryArea = function() {
    var area = props.calculation.secondaryArea;
    props.additions.map((addition) => {
      area = area + addition.calculation.secondaryArea;
      return area;
    });
    return area;
  }

  const getTotalArea = function() {
    var area = getTotalPrimaryArea() + getTotalSecondaryArea();
    return area;
  }


  const getHelptext= function(id) {
    var text = "Not found";

    const getText = (value) => {
      const res = props.properties.find(element=> element.propertyTypeId === value);
      const currentText = res? res.helpText: "";
      return currentText;
    }

    if (id === 0) {
      text = getText(primaryAreaProperty);
    } 
    else if (id === 1) {
      text = getText(secondaryAreaProperty);     
    }

    return text; 
  }

  const getPrimaryRoomText = function(id) {
    var text = "Not found";

    const setText = (value) => {
      let current = props.properties.find(element => element.propertyTypeId === value);
      const currentText = current ? current.name: "";
      return currentText;
    }

    if (id === 0) {
      text = setText(primaryAreaProperty);

    } 
    if (id === 1) {
      text = setText(secondaryAreaProperty);
    }

    return text; 
  }

  const getArea = (calc) => {
    return  {
      calculationId:calc.calculationId,
      primaryArea: calc.primaryArea,
      secondaryArea: calc.secondaryArea,
      buildYear: calc.buildYear
    }
  }


  let addonsAreaHtml = null;
  if (props.additions)
  {
    addonsAreaHtml = props.additions.map((addition, index) =>
    {
        return (
        <>
          <AreaTableRow id={addition.calculation.calculationId} name={'Tilbygg ' + (index + 1)} buildYears={props.buildYears} area={getArea(addition.calculation)} displaySecondaryArea={props.displaySecondaryArea} displayDelete={true} onAreaChangeCb={handleAreaChange} onDeleteCb={handleAreaDelete}></AreaTableRow>
        </>
        )
    });
  }
  
  return (
    <>
          <div className="area-table">
            <div className="display-flex">
             
              <div className="flex-3 area-table-header area-table-header-center area-table-border-top area-table-border-left">Bygning</div>
              <div className="flex-2 area-table-header area-table-header-center area-table-border-top">Byggeår</div>
              {props.displaySecondaryArea &&
              <>
                <div className="flex-6">
                  <div className="display-flex">
                    <div className="flex-1 area-table-header area-table-header-center area-table-border-top">Areal</div>
                  </div>
                  <div className="display-flex">
                    <div className="flex-4 area-table-header area-table-header-center">
                                <div className="input-icon input-icon-right inline-block area-table-header-content">{getPrimaryRoomText(0)}
                        <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-for={"prom"} data-tip={getHelptext(0)}/>
                        <ReactTooltip place="right" id={"prom"}  type="light" html={true} effect="solid"  className="helptext-custom"/>
                      </div>

                    </div>
                    <div className="flex-4 area-table-header area-table-header-center">
                                <div className="input-icon input-icon-right inline-block area-table-header-content">{getPrimaryRoomText(1)}
                        <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-for={"srom"} data-tip={getHelptext(1)}/>
                        <ReactTooltip place="right" id={"srom"}  type="light" html={true} effect="solid"  className="helptext-custom"/>
                      </div>
                    </div>
                    <div className="flex-4 area-table-header area-table-header-center">
                      <div className="input-icon input-icon-right inline-block area-table-header-content">Sum</div>
                    </div>                 
                  </div>

                </div>
                <div className="flex-1"></div>
              </>
              }   
               {!props.displaySecondaryArea &&
              <>
                <div className="flex-2">
                  <div className="display-flex">
                    <div className="flex-1 area-table-header area-table-header-center area-table-border-top">Areal</div>
                  </div>
                  <div className="display-flex">
                    <div className="flex-1 area-table-header area-table-header-center">
                                <div className="input-icon input-icon-right inline-block area-table-header-content">BRA
                        <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-for={"prom"} data-tip={getHelptext(0)}/>
                        <ReactTooltip place="right" id={"prom"}  type="light" html={true} effect="solid"  className="helptext-custom"/>
                      </div>

                    </div>                         
                  </div>

                </div>
                
                <div className="flex-1"></div>
                <div className="flex-3"></div>
              </>
              }

            </div>

            <AreaTableRow id={props.calculation.calculationId} name={'Opprinnelig bygning'} buildYears={props.buildYears} area={getArea(props.calculation)} displaySecondaryArea={props.displaySecondaryArea} displayDelete={false} onAreaChangeCb={handleAreaChange} onDeleteCb={handleAreaDelete}></AreaTableRow>

           
            {addonsAreaHtml}

            <div className="display-flex">
              <div className="flex-3 area-table-header area-table-border-right"></div>
              <div className="flex-2 area-table-header area-table-header-center-area">Sum BRA</div>

              {props.displaySecondaryArea &&
              <>
                <div className="flex-6">
                  <div className="display-flex">
                    <div className="flex-4  area-table-right-column">
                      <div>{formatValue(getTotalPrimaryArea(), 0 , ' m²')}</div>
                    </div>
                    <div className="flex-4  area-table-right-column">
                      <div>{formatValue(getTotalSecondaryArea(), 0 , ' m²')}</div>
                    </div>
                    <div className="flex-4  area-table-right-column">
                      <div>{formatValue(getTotalArea(), 0 , ' m²')}</div>
                    </div>                 
                  </div>
                </div>
                <div className="flex-1"></div>
              </>
              }   
               {!props.displaySecondaryArea &&
              <>
                  <div className="flex-2">
                    <div className="display-flex">
                        <div className="flex-4  area-table-right-column">
                          <div>{formatValue(getTotalPrimaryArea(), 0 , ' m²')}</div>
                        </div>                               
                      </div>
                    </div>
                  <div className="flex-1"></div>
                  <div className="flex-3"></div>
              </>
              }

            </div>

            <div className="row area-addbtn-row mt-3">
              <div className="col-md-1 area-table-button">
                <input name="UseBuildYear" type="submit" className="area-table-button-green" value="Legg til tilbygg" title="Trykk her for å legge til et tilbygg" onClick={newAdditionHandler}/>
                </div>
            </div>
          </div>

    </>
  )
}

export default React.memo(AreaTable);