import React, { useEffect } from 'react';
import { CallbackFunction } from '../types';
import { AuthStatusEnum } from '../store/auth/types';

type AuthenticatorProps = {
  readonly delay?: number;
  readonly authStatus?: AuthStatusEnum;
  readonly handleOnFail: CallbackFunction;
  readonly handleOnSuccess: CallbackFunction;
};

const Authenticator = React.memo<AuthenticatorProps>(
  ({ authStatus, handleOnFail, handleOnSuccess, delay = 0 }) => {
    useEffect(() => {
      const authHandler = setTimeout(() => {
        switch (authStatus) {
          case AuthStatusEnum.FAIL:
            handleOnFail();
            return;
          case AuthStatusEnum.SUCCESS:
            handleOnSuccess();
            return;
          default:
            return;
        }
      }, delay);

      return () => {
        clearTimeout(authHandler);
      };
    }, [authStatus, delay, handleOnFail, handleOnSuccess]);

    if (!authStatus || authStatus === AuthStatusEnum.NONE) {
      return null;
    }

    return (<></>

    );
  }
);

Authenticator.displayName = 'Authenticator';

export default Authenticator;