import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class ProjectsService extends BaseService {
  private static _projectsService: ProjectsService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): ProjectsService {
    return this._projectsService || (this._projectsService = new this('Projects'));
  }

  public async getProjects(model): Promise<AxiosResponse> {
    var axiosResponse;

    await this.$http.post('Projects',model, {withCredentials: true})
    .then((response) => {           
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
    return axiosResponse;
  }

  public async getCounties(): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('GetCounties', {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async getReportTypes(userId): Promise<AxiosResponse> {
    var axiosResponse;

    await this.$http.get('GetReportTypes/' + userId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async getCustomerSharing(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('GetCustomerSharing/' + id, {withCredentials: true})
    .then((response) => {
    axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
 
    return axiosResponse;
 }

}

export const ProjectsApi = ProjectsService.Instance;