import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class FinanceTableService extends BaseService {
   private static _financeTableService: FinanceTableService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): FinanceTableService {
     return this._financeTableService || (this._financeTableService = new this('financeTable'));
   }
 
   public async getAll(moduleId:number, subId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('getAll/' + moduleId + '/' + subId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }



   public async get(moduleId:number, subId:number, bridgeId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId + '/' + subId + '/' + bridgeId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }


   public async update(data): Promise<AxiosResponse> {
      var axiosResponse;
      if (!data){
         this.handleAllErrors(` Data was not provided for update.`); 
         Promise.reject();
      }
      
      await this.$http.post('update',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async delete(id:number): Promise<AxiosResponse> {
      var axiosResponse;
      var data = {
         id:id
      }
      if (!data){
         this.handleAllErrors(` Data was not provided for delete.`); 
         Promise.reject();
      }
      
      await this.$http.post('delete',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateRow(data): Promise<AxiosResponse> {
      var axiosResponse;
      if (!data){
         this.handleAllErrors(` Data was not provided for update.`); 
         Promise.reject();
      }
      
      await this.$http.post('updateRow',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async deleteRow(id:number): Promise<AxiosResponse> {
      var axiosResponse;
      var data = {
         id:id
      }
      if (!data){
         this.handleAllErrors(` Data was not provided for delete.`); 
         Promise.reject();
      }
      
      await this.$http.post('deleteRow',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

}

export const FinanceTableApi = FinanceTableService.Instance;