import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { SimpleCalculationApi, CalculationTemplateApi } from '../../../api';
import { SimpleCalcCategory, SelectTemplateDialog } from '.';
import { useHistory } from 'react-router-dom'
import {ProjectLink, SectionHeader, Spinner} from '../../../components'


const SimpleCalcCalculation = ({simpleCalculationId, categoriesArray, categoriesUpdateCb}) => {
  const [categories, setCategories] = React.useState([]);
  const [openSelectTemplate, setOpenSelectTemplate] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);


  useEffect(() => {
    loadTemplates();
  }, [])


  useEffect(() => {
    setCategories(categoriesArray);
    loadTemplates();
  }, [categoriesArray])

  const history = useHistory();
  
  const loadTemplates = function() {
    CalculationTemplateApi.get()
      .then (
      (response) => {
        setTemplates(response.data);
      },
      (error) => {
         console.log(error);
      }
      );
  }

  const handleCategoryAdd = function() {
    SimpleCalculationApi.getNewCategory(simpleCalculationId)
      .then (
        (response) => {
          setCategories([...categories, response.data]);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const handleSelectFromTemplateStore = function() {
    setOpenSelectTemplate(true);
  }

  const handleOpenTemplateStore = function() {
    //history.push("/calculationTemplate");
    const win = window.open("/calculationTemplate", "_blank");
    win.focus();
  }

  const handleTemplateSelected = function(res) {
    if (res !== null) {
      SimpleCalculationApi.AddCalculationTemplates(simpleCalculationId, res) .then (
        (response) => {
          setCategories([...categories, ...response.data]);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    setOpenSelectTemplate(false);
  }

  const handleOpenMaterialList = function() {
    const win = window.open("/priceguide", "_blank");
    win.focus();
  }


  const handleCategoryUpdated = function(category) {
    updateCategory(category);
    var newCategories = categories.map((orgCategory) => {
      if (orgCategory.simpleCalculationCategoryId === category.simpleCalculationCategoryId) {
        //calculate
        category.unitTotal = 0;
        category.hoursTotal = 0;
        category.workTotal = 0;
        category.total = 0;
        category.rows.map((row) => {
          category.unitTotal = category.unitTotal + row.unitTotal;
          category.hoursTotal = category.hoursTotal + row.hours;
          category.workTotal = category.workTotal + row.workTotal;
          category.total = category.total + row.total;
          return row;
        });

        return category;
      }
      return orgCategory;
    })
    setCategories(newCategories);
    categoriesUpdateCb(newCategories);
  }

  const handleCategoryMoveUp = function(categoryId) {
    var selectedIndex = 0;
    categories.map((category, index) => {
      if (category.simpleCalculationCategoryId === categoryId) {
        selectedIndex=index;
      }
      return category;
    })

    if (selectedIndex === 0) return; // already on top

    let data = [...categories];
    var firstOrder = data[selectedIndex-1].order;
    var lastOrder = data[selectedIndex].order;
    let temp = data[selectedIndex-1];
    temp.order = lastOrder;
    data[selectedIndex].order = firstOrder;
    data[selectedIndex-1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setCategories(data);
    updateCategory(data[selectedIndex]);
    updateCategory(data[selectedIndex-1]);
  }

  const handleCategoryMoveDown = function(categoryId) { 
    var selectedIndex = categories.length-1;
    categories.map((category, index) => {
      if (category.simpleCalculationCategoryId === categoryId) {
        selectedIndex=index;
      }
      return category;
    })

    if (selectedIndex === categories.length-1) return; // already at the end

    let data = [...categories];
    var firstOrder = data[selectedIndex].order;
    var lastOrder = data[selectedIndex+1].order;
    let temp = data[selectedIndex+1];
    temp.order = firstOrder;
    data[selectedIndex].order = lastOrder;
    data[selectedIndex+1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setCategories(data);
    updateCategory(data[selectedIndex]);
    updateCategory(data[selectedIndex+1]);
  }


  const handleCategoryDelete = function(categoryId) { 
    SimpleCalculationApi.deleteCategory(categoryId)
    .then (
      (response) => {
        //Recalculate? 
        setCategories(categories.filter(category =>category.simpleCalculationCategoryId !== categoryId));
        //setRows(rows.filter(row =>row.simpleCalculationRowId !== rowId));       
        //recalculate(category.rows.filter(row =>row.simpleCalculationRowId !== rowId));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleCategoryCopy = function(categoryId) { 
    SimpleCalculationApi.copyCategory(categoryId)
    .then (
      (response) => {
        //Recalculate? 
        setCategories([...categories, response.data]);
        //setRows(rows.filter(row =>row.simpleCalculationRowId !== rowId));       
        //recalculate(category.rows.filter(row =>row.simpleCalculationRowId !== rowId));
      },
      (error) => {
        console.log(error);
      }
    );
  }


  const updateCategory = function(category) {
    SimpleCalculationApi.updateCategory(category)
    .then (
      (response) => {
       //
      },
      (error) => {
        console.log(error);
      }
    );
  }

  let categoriesHtml=null;
  if (categories) {
    categoriesHtml = categories.map((category, index) =>
    {
      return (
        <SimpleCalcCategory 
          key={'SCC_' + index}
          categoryRow={category} 
          index={index} 
          updateCb={handleCategoryUpdated}
          moveUpCb={handleCategoryMoveUp} 
          moveDownCb={handleCategoryMoveDown} 
          deleteCb={handleCategoryDelete} 
          copyCb={handleCategoryCopy} 
          />
      );
    })
  }

  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick} className="ms-1"/>
          <Menu
              elevation={0}
              //getContentAnchorEl={null}
              // anchorOrigin={{
              //   vertical: 'bottom',
              //   horizontal: 'center',
              // }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleCategoryAdd}>Legg til hovedpunkt</MenuItem>
            <MenuItem onClick={handleSelectFromTemplateStore}>Velg fra malbank</MenuItem>
            <Divider />
            <MenuItem onClick={handleOpenTemplateStore}>Åpne malbank i eget vindu</MenuItem>
            <MenuItem onClick={handleOpenMaterialList}>Åpne materialliste</MenuItem>
          </Menu>
        </div>;

  }


  return (
    <>
        <div className="simple-calc-section-header">
          <div className="description">Kalkulasjon</div>
          <div className="action-menu"> <ActionMenu /></div>
        </div>
        <div className='content-frame'>
      <div className="simple-calc-calc-heading">
        <div className="simple-calc-calc-heading-wrapper flex-1">
          <div className="simple-calc-calc-heading-item simple-calc-vertical-align">Post</div>
        </div>
        <div className="simple-calc-calc-heading-wrapper flex-3">
        <div className="simple-calc-calc-heading-item simple-calc-vertical-align">Beskrivelse</div>
        </div>
        <div className="simple-calc-calc-heading-wrapper flex-4">
          <div className="simple-calc-calc-heading-item">Materiell</div>
          <div className="simple-calc-calc-heading-row">
            <div className="simple-calc-calc-heading-item flex-1 simple-calc-border-right">Antall Enheter</div>
            <div className="simple-calc-calc-heading-item flex-1 simple-calc-border-right simple-calc-vertical-align-small">Enhet</div>
            <div className="simple-calc-calc-heading-item flex-1 simple-calc-border-right simple-calc-vertical-align-small">Enhetspris</div>
            <div className="simple-calc-calc-heading-item flex-1">Sum Materiell</div>
          </div>
        </div>
        <div className="simple-calc-calc-heading-wrapper flex-3">
          <div className="simple-calc-calc-heading-item">Arbeid</div>
          <div className="simple-calc-calc-heading-row">
            <div className="simple-calc-calc-heading-item flex-1 simple-calc-border-right">Antall Timer</div>
            <div className="simple-calc-calc-heading-item flex-1 simple-calc-border-right simple-calc-vertical-align-small">Timesats</div>
            <div className="simple-calc-calc-heading-item flex-1">Sum Arbeid</div>
          </div>
        </div>
        <div className="simple-calc-calc-heading-wrapper flex-1">
        <div className="simple-calc-calc-heading-item simple-calc-vertical-align">Sum Post</div>
        </div>      
        <div className="simple-calc-heading-menu">
          
        </div>
      </div>
      
      {categoriesHtml}
</div>
      <SelectTemplateDialog 
        templateRows={templates}
        open={openSelectTemplate}
        handleSelectedCb={handleTemplateSelected}
      />

    </>
  );

}


export default React.memo(SimpleCalcCalculation);