import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class ServiceService extends BaseService {
  private static _serviceService: ServiceService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): ServiceService {
    return this._serviceService || (this._serviceService = new this('service'));
  }

  public async GetServices(): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('getServices', {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }
 
}



export const ServiceApi = ServiceService.Instance;