import React from 'react';
import ReactTooltip from 'react-tooltip';
import {renderToString } from 'react-dom/server'
import {formatValue } from '../../../utils'
import { SectionHeader} from '../../../components'

const SimpleSum = ({index, buildyear, area, totalCost, reductionPercent}) => {


  const getValueReduction = function(){
    var res = 0;
    res = reductionPercent * totalCost / 100;
    return res;
  }



  const getHelpTextTotal = function(section) {

    switch(section) {
      case 0:
        return (<> <h5><b>Sum antall BRA</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer det totale antall BRA for bygningen.</p></div></>);
      case 1:
        return (<> <h5><b>Gjennomsnittspris pr m&#178; BRA</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer gjennomsnittsprisen pr. m&#178; ut fra valgene gjort i de foregående postene.</p></div></>);
      case 2:
        return (<> <h5><b>Sum byggekostnad</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer den totale byggekostnaden ut fra de valgene som har blitt gjort i de forgående postene.</p></div><div className="helptext-list-item"><p>Byggekostnad er over ferdig planert tomt.</p></div><div className="helptext-list-item"><p>Kostnad for kjøp av tomt er ikke medregnet.</p></div></>);
      case 3:
        return (<> <h5><b>Verdireduksjon</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer samlet verdireduksjon ut fra de valgene som er gjort under verdireduksjonsberegningene.</p></div></>);
      case 4:
        return (<> <h5><b>Nåværende teknisk verdi etter fradrag</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer den totale byggekostnaden ut fra de valgene som har blitt gjort i de forgående postene.</p></div><div className="helptext-list-item"><p>Byggekostnad er over ferdig planert tomt.</p></div><div className="helptext-list-item"><p>Kostnad for kjøp av tomt er ikke medregnet.</p></div><div className="helptext-list-item"><p>Summen har fratrekk for verdireduksjon.</p></div></>);
      case 5:
        return (<> <h5><b>Byggeår</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer byggeåret for bygningen.</p></div></>);
    }
  }

  return (
    <>
        
      <div className="calculation-frame-sum">
        <div className="display-flex calculation-row">
          <div className="flex-6 calculation-total-cell">
            Byggeår
          </div>
          <div className="flex-3 calculation-total-cell calculation-sum-right">{buildyear > 0? buildyear:'-'}
          </div>
          <div className="flex-1">
            <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-tip={renderToString(getHelpTextTotal(5))} data-for='id5'/>
            <ReactTooltip id='id5' place="right" type="light" html={true} effect="solid" className="helptext-custom"/>
          </div>
        </div>
        <div className="display-flex calculation-row">
          <div className="flex-6 calculation-total-cell">
            Sum antall BRA
          </div>
          <div className="flex-3 calculation-total-cell calculation-sum-right">{formatValue(area, 0 , ' m²')}
          </div>
          <div className="flex-1">
            <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-tip={renderToString(getHelpTextTotal(0))} data-for='id0'/>
            <ReactTooltip id='id0' place="right" type="light" html={true} effect="solid" className="helptext-custom"/>
          </div>
        </div>
        <div className="display-flex calculation-row">
          <div className="flex-6 calculation-total-cell">
            Verdireduksjon ({formatValue(reductionPercent,2,'%')})
          </div>
          <div className="flex-3 calculation-total-cell calculation-sum-right">{formatValue(getValueReduction(), 0, ' kr')}
          </div>
          <div className="flex-1">
            <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-tip={renderToString(getHelpTextTotal(3))} data-for='id3'/>
            <ReactTooltip id='id3' place="right" type="light" html={true} effect="solid" className="helptext-custom"/>
          </div>
        </div>
      </div>
    </>
  )
}


export default React.memo(SimpleSum);