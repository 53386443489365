import React from 'react';



const ForgotConfirmation: React.FC = () => (
  <div className="container">
            <div className=" row mt-5">
            <div className="col-auto mx-auto register-container shadow mt-2">
        <div className="contact-container my-3">

          <div className='register-title mb-2'>
            E-post sendt
          </div>
          <div className="register-text mx-5">
            <div>Dersom du er registrert hos oss <div>vil du motta en email om kort tid</div>

            <div className="register-subtext mt-3 mb-4">Om du ikke har mottatt e-posten i løpet av  noen <span className='bold'>få minutter</span>
            <div><span className='bold'>vennligst sjekk "Søppelpost" mappe </span> </div> eller ta kontakt med
               oss for hjelp..</div></div>
          </div>
        </div>        
      </div>        
    </div>        
  </div>
)

export default ForgotConfirmation;