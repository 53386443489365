export const getAllServerValidationErrors = (fieldErrors: ValidationErrors): string | null => {

    if (!fieldErrors)
        return null;

    let result = '';

    console.log("fieldErrors");
    console.log(fieldErrors);
    console.log(JSON.stringify(fieldErrors));

    for (var key in fieldErrors) {
        result += fieldErrors[key].join(" ");
    }

    return result;
};

interface ValidationErrors {
    [key: string]: Array<string>[]
}