import  * as faIcons  from '@fortawesome/free-solid-svg-icons'


export const AdminMenuData = [
   {
      title:'Dashboard',
      linkTo:'/Admin',
      icon:faIcons.faHome,
      isSubMenu:false,
      isDeveloperPage:false,
      subMenus:[]
   },
   {
      title:'Kunder',
      linkTo:'/Customers/0',
      icon:faIcons.faUsers,
      isSubMenu:false,
      isDeveloperPage:false,
      /*subMenus:[
         {
            title:'Alle kunder',
            linkTo:'/Customers/0',
            icon:'',
            isSubMenu:false,
            subMenus:[]
         },      
         {
            title:'Nye abonnenter',
            linkTo:'/Customers/1',
            icon:'',
            isSubMenu:false,
            subMenus:[]
         },       
      ]*/
   },

   {
      title:'Meldinger',
      linkTo:'/messages',
      icon:faIcons.faEnvelope,
      isSubMenu:false,
      displayNumber:true,
      isDeveloperPage:false,
      subMenus:[]
   },
   {
      title:'Faktura',
      linkTo:'/invoices',
      icon:faIcons.faFileInvoiceDollar,
      isSubMenu:false,
      displayNumber:true,
      isDeveloperPage:false,
      subMenus:[]
   },
   {
      title:'SSB-Indeks',
      linkTo:'/SsbIndex',
      icon:faIcons.faArrowUpWideShort,
      isSubMenu:false,
      isDeveloperPage:false,
      subMenus:[]
   },
   {
      title:'Oppdateringer',
      linkTo:'/UpdateMessages',
      icon:faIcons.faScrewdriverWrench,
      isSubMenu:false,
      isDeveloperPage:false,
      displayNumber:true,
      subMenus:[]
   },
   // {
   //    title:'Statistikk',
   //    linkTo:'/Statistics',
   //    icon:faIcons.faChartPie,
   //    isSubMenu:false,
   //    isDeveloperPage:false,
   //    subMenus:[]
   // },

   {
      title:'Lenker',
      linkTo:'/AdminLinks',
      icon:faIcons.faLink,
      isSubMenu:false,
      isDeveloperPage:false,
      subMenus:[]
   },
   
  
   // {
   //    title:'Tekster',
   //    linkTo:'/Texts',
   //    icon:faIcons.faFont,
   //    isSubMenu:true,
   //    isDeveloperPage:false,
   //    subMenus:[
   //       {
   //          title:'Dokumenter',
   //          linkTo:'/Texts/0',
   //          icon:'',
   //          isSubMenu:false,
   //          subMenus:[]
   //       },      
   //       {
   //          title:'Hjelpetekster',
   //          linkTo:'/Texts/1',
   //          icon:'',
   //          isSubMenu:false,
   //          subMenus:[]
   //       }, 
   //    ]
   // },
   {
      title:'Verktøy',
      linkTo:'/tools',
      icon:faIcons.faCog,
      isSubMenu:false,
      isDeveloperPage:true,
      displayNumber:false,
      subMenus:[]
   },
];
 