import React from 'react';
import { BrowserRouter, Switch, Route, Redirect, Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'

const AboutWeb: React.FC = ()=>{

    return (

        <div className="container about-container mt-2">
            <div id="header-img"><Image fluid alt="header" src="\Content\images\design\header-thin.svg"/></div>
            <div className="row mt-2 mx-2"> 
            
            
           <div className='col-lg-6'>
           
         <div className='col-10 col-centered mb-4'>
             <div className='about-text-titel mb-3 mx-1'>Oppstart</div>
        
            <p className='about-text mx-2' id='text'>
             Arbeidene med byggekost.no ble påbegynt i 2013.
             
             Programmet var klart for et utvalg testpersoner i NITO-Takst i 2017 og
             videre for alle takstmenn i Norge 2018.
             
             Byggekost.no eies idag av Pro Verk AS som ble
             stiftet i 2018 av Erik Sørensen og Runar Gautestad.
             
             Erik Sørensen og Runar Gautestad har praksis og erfaring fra byggebransjen siden
             1980 og i takstbransjen siden 1997</p>

             <div className='row'>
                         <div className='col-sm-12 col-md-6 mt-2'>
                         <Image fluid alt="Pro verk AS" src="\Content\images\design\Proverk_logo.svg"/></div></div></div>  
             
         </div>

         <div className='col-lg-6 mt-5'>


                        <div className='row'>
                            <div className='col-sm-10 col-md-5 col-centered'>
                                <Image fluid alt="Runar Gautestad" src="\Content\images\bilde-runar.jpg" className='web-about-img'/>
                                    <p className='about-imagetext' >Runar Gautestad</p>
                            </div>
                            <div className='col-sm-10 col-md-5 col-centered'>
                                <Image fluid alt="Erik Sørensen" src="\Content\images\bilde-erik.jpg" className='web-about-img'/>
                                    <p className='about-imagetext'>Erik Sørensen</p>
                            </div>
                        </div>

                        <div className='mx-2 mt-2'><p className='about-text-subtitel'>Våre kvalifikasjoner er fagbrev og mesterbrev i tømrerfaget, 
                    teknisk fagskole og takstutdanning med tilhørende godkjenninger.</p></div>
           </div>
            </div>
        </div>
    );

}
export default AboutWeb;