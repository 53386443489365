import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { Footer, Header, BkButton } from '../../components';
import { ReportApi } from '../../api/report.service';
import Image from 'react-bootstrap/Image'

const ViewReport: React.FC = ()=>{
   const params = useParams();
   const reportGuid = (params as any).id || 0;

   const [data, setData] = useState({
      reportId:0,
      reportGuid:'',
      title:'',
      project:{
         imageUrl:'',
         address: {
            address:'',
            zipCode:'',
            city:'',
            gnr:'',
            bnr:'',
            fnr:'',
            snr:'',
            lnr:'',
            andelsnr:''
         }
      },
      name:'',

      firstName:'',
      lastName:'',
      address:'',
      email:'',
      phoneNumber:'',
      zipCode:'',
      city:'',
      company:'',
      companyLogo:'',
      organisationNumber:'',
      published:'',
      validTo:'',
      created:'',
      updated:''
   }); 
   const history = useHistory();

   useEffect(() => {
      ReportApi.getPublicData(reportGuid)
         .then((response) => { 
            setData(response.data);
         },
         (error) => {
            history.push("/home");
         });   
   }, []);

   if (reportGuid == null || reportGuid == '') {
      history.push("/home");
   }

   const handleGetPdf = () => {
      ReportApi.getPublicPdf(reportGuid);
   }

   const getPicture = function(imageUrl) {
      if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
        return (
          <img src={"/upload/" + imageUrl} />
        )
      }
      else {
        return (<img src="/images/prosjekt-default.jpg" />);
      }
   }

   const getCompanyPicture = function(imageUrl) {
      if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
        return (
          <img src={"/upload/" + imageUrl} />
        )
      }
      else {
        return (<img src="/images/Hus_linje_ill.jpg" />);
      }
   }

   const formatDateTime = function(date) {
      if (date === undefined || date === null) {
        // Could be for a new record
        date = new Date();
      }
      let thedate = new Date(date);
      var options = {year: 'numeric', month: '2-digit', day: '2-digit' } as const;
      return thedate.toLocaleDateString('nb-NO', options);
    }

   const getNumbers = () => {
      var numbers = '';
      if (data.project.address.gnr != null && data.project.address.gnr != '') {
         numbers = 'Gnr.: ' + data.project.address.gnr + '  ';
      }
      if (data.project.address.bnr != null && data.project.address.bnr != '') {
         numbers = numbers +  'Bnr.: ' + data.project.address.bnr + '  ';
      }
      if (data.project.address.fnr != null && data.project.address.fnr != '') {
         numbers = numbers +  'Fnr.: ' + data.project.address.fnr + '  ';
      }
      if (data.project.address.snr != null && data.project.address.snr != '') {
         numbers = numbers +  'Snr.: ' + data.project.address.snr + '  ';
      }
      if (data.project.address.lnr != null && data.project.address.lnr != '') {
         numbers = numbers +  'Lnr.: ' + data.project.address.lnr + '  ';
      }
      if (data.project.address.andelsnr != null && data.project.address.andelsnr != '') {
         numbers = numbers +  'Aksje/Andelsnr.: ' + data.project.address.andelsnr;
      }
      return numbers;
   }

   return (
   <>
      <Header/>
      <div className="container">
         <div className='report-content'>
         <div className="row">
            <div className="col-md-6 mb-2">
               <div className="viewreport-infobox">
               <div className='container'>
               <div className="report-info-header mt-2">{data.title}</div>
                  <hr></hr>
                  <div className='col-7 mx-auto mt-3'><div className="report-info-address bold mx-2">{data.project.address.address + ", " + data.project.address.zipCode + " " + data.project.address.city}</div></div>
                  <div className='col-7 mx-auto mt-3 mb-2'><div className="report-info-data"><div className='bold'>Utarbeidet av:</div>{data.firstName + " " + data.lastName+ ",  " + data.company}</div></div>
                  <div className='row my-3'>
                  <div className='col-auto ms-auto'><div className="report-info-data"> <div className='bold'>Publisert:</div>{formatDateTime(data.published)}</div></div>
                  <div className='col-auto me-auto'><div className="report-info-data"><div className='bold'>Gyldig til:</div>{formatDateTime(data.validTo)}</div></div>
                  </div>
                  <div className='col-md-2 col-4 mx-auto my-3'><div className="report-info-button"><BkButton caption='Last ned' onClickHandler={handleGetPdf}/></div></div>
                  
               </div>
               </div>
            </div>
            <div className="col-md-6">
               <div className="viewreport-frontpage">
                  <div className='container'>
                  <div className="row"><Image fluid alt="header" src="\Content\images\design\header-thin.svg"/></div>
                  <div className="report-second-header mt-2">{data.title}</div>
                  <div className='col-10 mx-auto'> 
                  <hr className='blue' />
                  <hr className='green' />
                  </div>
                  <div className="col-12 mb-2">
                        <div className="col-10 mx-auto report-address"><div className='bold'>{data.project.address.address}</div></div>
                        <div className="col-6 mx-auto report-zip"><div className='bold'>{data.project.address.zipCode + " " + data.project.address.city}</div></div>
                        <div className="col-9 mx-auto report-nr"><div className='mx-auto'>{getNumbers()}</div></div>
                     </div>
                  <div className="report-main-picture mx-4">{getPicture(data.project.imageUrl)}</div>
                  <div className="row report-banner mt-4 mb-4 mx-2">
                     <div className="col-6">
                        <div className="user-box bold">Bygningssakkyndig:</div>
                        <div className="user-box">{data.firstName + " " + data.lastName}</div>
                     </div>

                     <div className="col-6">
                           <div className="date-box-title">Opprettet:
                           <span className="date-box mb-2"> {formatDateTime(data.created)}</span> </div>
                           <div className="date-box-title">Oppdatert:
                           <span className="date-box"> {formatDateTime(data.updated)}</span></div>
                     </div>  
                  </div>

                  <div className="row report-company mx-4 mb-4">
                     <div className="col-4 company-logo">{getCompanyPicture(data.companyLogo)}</div>
                  </div>
                  <div className="col-7 ms-4">
                        <div className="company-box-big">{data.company}</div>
                        
      
                           <div className="company-box">Foretaksnr: {data.organisationNumber}</div>
                           <div className="company-box">Addresse: {data.address}</div>
                           <div className="company-box">{data.zipCode + " " + data.city}</div>
                           <div className="company-box">E-post: {data.email}</div>
                           <div className="company-box">Telefon: {data.phoneNumber}</div>
                     
                     </div>
                  <div className="row report-footer w-100 mt-2 mb-3">
                     <div className='col-4'><Image fluid alt="Pro verk AS" src="\Content\images\design\Proverk_logo.svg"/></div>
                  </div>
                  </div>
                  
               </div>
               
            </div>
            </div>
         </div>
      </div>
      <Footer/>
      </>
   )
}


export default ViewReport;