import React, { useCallback, useState, useRef, useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useTextInput } from '../../hooks';
import { renderToastifyMsg } from '../../utils';
import { IApplicationState } from '../../store';
import { Authenticator } from '../../components';
import { RoutesConfig } from '../../config/routes.config';
import { actionCreators, AuthStatusEnum, reducer } from '../../store/auth';
import { UserNameInput, PasswordInput, LoginControls } from './child-components';
import { Spinner } from '../../components';

type LoginProps = ReturnType<typeof reducer>
  & typeof actionCreators
  & { history: History, readonly isAuthenticated: boolean};

const Login: React.FC<LoginProps> = ({
  status,
  history,
  isAuthenticated,
  resetState,
  setAuthStatus,
  loginUserRequest,
}) => {
  const toastIdRef = useRef<string | number>('');


  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isInputInvalid, setIsInputInvalid] = useState<boolean>(false);
  const userNameInput = useTextInput('');
  const passwordInput = useTextInput('', showPassword ? 'text' : 'password');

  useEffect(() => {
    setAuthStatus(AuthStatusEnum.NONE);  
 }, []);

  const handleRegistrationClick = event => {
    event.preventDefault();
    history.push(RoutesConfig.Register.path)
  }

  const onFailedAuth = useCallback((): void => {
    resetState();
    setAuthStatus(AuthStatusEnum.NONE);
    // Todo add notification
    if (!toast.isActive(toastIdRef.current)) {
      toastIdRef.current = toast.error(
        renderToastifyMsg('Feil brukernavn/ passord', 'exclamation-triangle')
      );
    }


  }, [resetState, setAuthStatus]);

  const onRememberMeCheck = useCallback((checked: boolean): void => setRememberMe(checked), []);
  
  const onSuccessfulAuth = useCallback((): void => {
   //history.push(RoutesConfig.SelectLayout.path)
   history.push(RoutesConfig.Projects.path)
}, [history]);
  
  const onToggleShowPassword = useCallback((): void => setShowPassword((prevShow: boolean) => !prevShow), []);

  const handleLogin = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (status === AuthStatusEnum.PROCESS) return;

    if (!userNameInput.hasValue || !passwordInput.hasValue) {
      // Run invalidInputs error and display toast notification (if one is not already active)
      setIsInputInvalid(true);

      if (!toast.isActive(toastIdRef.current)) {
        toastIdRef.current = toast.error(
          renderToastifyMsg('Skriv inn brukernavn/ passord', 'exclamation-triangle')
        );
      }
    } else {
      // Clear any toast notifications and prepare state for Login request stub / run login request stub
      toast.dismiss();
      setIsInputInvalid(false);
      setAuthStatus(AuthStatusEnum.PROCESS);

      setTimeout(() => {
        loginUserRequest({
          rememberMe,
          email : userNameInput.value,
          password: passwordInput.value,
        });
      }, 500);

      
    }
  };

  return (<>
        <div className="container mt-3">
          <div className="row ">
            <div className='col-lg-3'>
        
            </div>
          <div className='col-lg-6 login-container my-3 shadow'>
            <div className='mt-2 mx-4'>
              <p className='login-subtitle'>Ny bruker? <span className="login-link" onClick={handleRegistrationClick}>Registrer her</span></p>

              <div className='login-text'>
                <form onSubmit={handleLogin}>
                  <UserNameInput
                    textInput={userNameInput}
                    isInputInvalid={isInputInvalid}
                  />
                  <PasswordInput
                    textInput={passwordInput}
                    showPassword={showPassword}
                    isInputInvalid={isInputInvalid}
                    toggleShowPassword={onToggleShowPassword}
                  />
                  <LoginControls
                    rememberMe={rememberMe}
                    handleRememberMeCheck={onRememberMeCheck}
                  />
                </form>
                <Authenticator
                  authStatus={status}
                  handleOnFail={onFailedAuth}
                  handleOnSuccess={onSuccessfulAuth}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Spinner isLoading={status == AuthStatusEnum.PROCESS} />
      </>
  );
};

const mapStateToProps = (state: IApplicationState) => state.auth;

export default connect(mapStateToProps, actionCreators)(Login);
