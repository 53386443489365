import React from 'react';
import ReactTooltip from 'react-tooltip';
import {renderToString } from 'react-dom/server'
import {formatValue } from '../../../utils'
import { SectionHeader} from '../../../components'

const SumProperty = ({cost, costPrSquareMeter}) => {

  const getHelpTextTotal = function(section) {
    switch(section) {
      case 0:
        return (<> <h5><b>Sum totalt for hele tomta</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer den totale kostnaden for tomta.</p></div></>);
      case 1:
        return (<> <h5><b>Sum pr m²</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer gjennomsnittsprisen pr. m² ut fra de valgene som blir gjort i de forgående postene.</p></div></>);
     }
  }

  return (
   <>
      <div className="calculation-frame-sum">
         <div className="display-flex calculation-row">
            <div className="flex-6 calculation-total-cell">
               Sum totalt for hele tomta
            </div>
            <div className="flex-3 calculation-total-cell calculation-sum-right">{formatValue(cost, 0 , ' kr')}
            </div><div className="flex-1">
               <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-tip={renderToString(getHelpTextTotal(0))} data-for='id5'/>
               <ReactTooltip id='id5' place="right" type="light" html={true} effect="solid" className="helptext-custom"/>
            </div>
         </div>
         <div className="display-flex calculation-row">
            <div className="flex-6 calculation-total-cell">
               Sum pr m²
            </div>
            <div className="flex-3 calculation-total-cell calculation-sum-right">
               {formatValue(costPrSquareMeter, 0 , ' kr')}
            </div><div className="flex-1">
               <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-tip={renderToString(getHelpTextTotal(1))} data-for='id5'/>
               <ReactTooltip id='id5' place="right" type="light" html={true} effect="solid" className="helptext-custom"/>
            </div>
         </div>
      </div>

   </>
  )
}


export default React.memo(SumProperty);