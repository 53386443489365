
const displayNumericValue = (val: number, decimals:number = 0, defaultValue:string=''): string => {
  
  if (val === 0 || isNaN(val)) {
    return defaultValue;
  }

  if (val > 0) {
    return round(val,decimals).toString();
  }

  return defaultValue;
};

const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}


export {
  displayNumericValue
};


