import React, { useState, useEffect } from 'react';
import { actionCreators } from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory, useParams } from 'react-router-dom'
import { AdminApi, AuthApi, ReportApi } from '../../api';
import AdminMenu from './child-components/AdminMenu'
import eventBus from "../../containers/Modules/EventBus";


type UpdateMessagesProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly isAdmin: boolean;
   readonly userName: string;
};

const UpdateMessages: React.FC<UpdateMessagesProps> = ({
   isAuthenticated,
   isAdmin,
   userName,
   logoutUser
}) => {

   const history = useHistory();

   const [updateMessages, setUpdateMessages] = useState([{
      messageText: '',
      createdDate: 0
   }]);



   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = async () => {
      AdminApi.getUpdateMessages()
         .then(
            (response) => {
               setUpdateMessages(response.data);
            },
            (error) => {
               console.log(error);
            });
   }


   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if (!isAdmin) {
      history.push("/projects");
   }

   if (isAdmin) {
      var data = {
         userName : userName
      }
      AdminApi.setUpdatesSeen(data)
      .then(
         (response) => {
            eventBus.dispatch("updateMessagesSeen", { message: "updateMessagesSeen" });
         },
         (error) => {
            console.log(error);
         });
   }

   const convertDateTime = (date) => {
      var pattern = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{1,3})/;
      var matches = date.toString().match(pattern)

      if (matches) {
         var year = matches[1]
         var month = matches[2]
         var day = matches[3]

         return day + '.' + month + '.' + year
      }
   }
   let updateMessagesRows = null;
   if (updateMessages != null) {

      if (updateMessages.length > 0) {
         updateMessagesRows = updateMessages.map((msg) => {
            return <div className='update-message-row'>
               <span className='bold'>{convertDateTime(msg.createdDate)}</span>
               <span>{msg.messageText}</span>

            </div>
         });
      }
   }

   return (
      <>
         <div className="admin-container">
            <AdminMenu activeMenuItem={'Oppdateringer'}></AdminMenu>
            <div className='update-message-container'>
               {updateMessagesRows}
            </div>
         </div>

      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin,
   userName: state.auth.userName
});

export default connect(mapStateToProps, actionCreators)(UpdateMessages);