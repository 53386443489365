import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { BuildingDescription} from '../containers/Modules/BuildingDescriptionModule/types'

class BuildingDescriptionModuleService extends BaseService {
   private static _buildingdescriptionModuleService: BuildingDescriptionModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): BuildingDescriptionModuleService {
     return this._buildingdescriptionModuleService || (this._buildingdescriptionModuleService = new this('buildingDescriptionModule'));
   }
 

   public async getBuildingDescriptionModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addBuildingDescriptionModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addBuildingDescriptionModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateBuildingDescription(data: BuildingDescription): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateBuildingDescription.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async updateBuildingDescriptionBuilding(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateBuildingDescriptionBuilding.`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateBuilding',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async deleteBuildingDescriptionBuilding(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Data was not provided for deleteBuildingDescriptionBuilding.`); 
        Promise.reject();
      }
      
      var model= {
        id:id
      }
      await this.$http.post('DeleteBuilding',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async addBuildingDescriptionBuilding(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Data was not provided for addBuildingDescriptionBuilding.`); 
        Promise.reject();
      }
      
      var model= {
        id:id
      }
      await this.$http.post('AddBuilding',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 


}

export const BuildingDescriptionModuleApi = BuildingDescriptionModuleService.Instance;