import React from 'react';



const ContactConfirmation: React.FC = () => (
  <div className="container">
    <div className="row ">
      <div className='col-lg-4'></div>
      <div className='col-lg-4 my-4 contact-container'>
        <p className='contact-title m-3'>Takk for din henvendelse.</p>
    
        <div className="contact-text m-3">
          <p>Vi setter stor pris på alle slags tilbakemeldinger.
              Din henvendelse vil bli behandlet så snart det lar seg gjøre.</p>
        </div>
    </div>
    </div>
  </div>
)

export default ContactConfirmation;