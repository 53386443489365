import React, {useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faBars } from '@fortawesome/free-solid-svg-icons'
import { PhraseDialog } from './Dialogs'
import { toXmlSafeText } from '../utils';


type AutoTextBoxProps = {
   readonly text: string;
   readonly defaultRows?: number;
   readonly lineHeight?: number;
   readonly phraseRegisterKey?:string;
   readonly class?:string;
   readonly id?:string;
   readonly focus?:boolean;
   readonly readOnly?:boolean;
   readonly addPadding?:boolean;
   readonly smallIcon?:boolean;
   readonly disabled?:boolean;
   readonly onValueChangeCb?: (value: string) => void;
   readonly onSelectedChangeCb?: (value: string) => void;
 };

 const keyPressTimout = 500;
 let inputTimer = null;
 const blurDelayTimout = 100;
 let blurDelayTimer = null;

function AutoTextBox(props:AutoTextBoxProps) {

   const [rows, setRows] = useState(1);
   const [text, setText] = useState('');
   const [selectedText, setSelectedText] = useState('');
   const [lineHeight, setLineHeight] = useState(18);
   const [openPhraseDialog, setOpenPhraseDialog] = useState(false);
   const textBoxRef = useRef(null);
   const [key, setKey] = useState('');
   const [id, setId] = useState('auto');
   const [readOnly, setReadOnly] = useState(false);

   let init=0

   useEffect(() => {
      if (text == '') {
         setKey(props.phraseRegisterKey);
         if (props.defaultRows != null) {
            setRows(props.defaultRows);
         }
         if (props.lineHeight != null) {
            setLineHeight(props.lineHeight);
         }
         setText(props.text)
         if (props.id) {
            setId(props.id);
         }

         if (props.readOnly != null){
            setReadOnly(props.readOnly);
         }
      }
   }, [props]);


   useEffect(() => {
      if (!init) {
         const previousRows = textBoxRef.current.rows;
         textBoxRef.current.rows = props.defaultRows;

         const currentRows = ~~(textBoxRef.current.scrollHeight / lineHeight);
         if (currentRows === previousRows) {
            textBoxRef.current.rows = currentRows;
         }
         setRows(currentRows);
         init=1;
      }
      if (props.focus) {
         textBoxRef.current.focus();
      }
   });

   const onBlur = () => {
      if (inputTimer !== null){
         clearTimeout(inputTimer);
         blurDelayTimer = setTimeout(() => {
            updateValue(text);
          }, blurDelayTimout);
      }
   }

   const onValueChange = (e) => {
      const minRows = props.defaultRows;
      const { name, value } = e.target;
      setText(toXmlSafeText(value));

      const previousRows = e.target.rows;
  	   e.target.rows = minRows; // reset number of rows in textarea 
		
		const currentRows = ~~(e.target.scrollHeight / lineHeight);

      if (currentRows === previousRows) {
         e.target.rows = currentRows;
      }
		
		setRows(currentRows);
      


      if (inputTimer !== null){
         clearTimeout(inputTimer);
       }
       inputTimer = setTimeout(() => {
         updateValue(toXmlSafeText(value));
       }, keyPressTimout);
   }

   const updateValue = (value) => {
      props.onValueChangeCb(value);
   }

   const openPhraseregister = () => {
     if (!readOnly){
      let cursorStart = textBoxRef.current.selectionStart;
      let cursorEnd = textBoxRef.current.selectionEnd;
      if (cursorEnd > cursorStart) {
         setSelectedText(text.substring(cursorStart,cursorEnd));
      }
      else {
         setSelectedText(text);
      }
      setOpenPhraseDialog(true);
     }
   }


   const handlePhraseSave = (value) => {
      setOpenPhraseDialog(false);
      if (value == '') {
         return;
      }
      if (text== null || text==undefined || text=='') {
         setText(value);
         props.onValueChangeCb(value);
      }
      else {
         setText(text + '\r\n' + value);
         props.onValueChangeCb(text + '\r\n' + value);
      }
   }


   const handlePhraseCancel = () => {
      setOpenPhraseDialog(false);
   }

   const handleMouseUp = () => {
      if (props.onSelectedChangeCb != null) {
         let cursorStart = textBoxRef.current.selectionStart;
         let cursorEnd = textBoxRef.current.selectionEnd;
         var selected = '';
         if (cursorEnd > cursorStart) {
            selected = text.substring(cursorStart,cursorEnd)
         }
         
         if (selected != selectedText) {
            setSelectedText(selected);
            props.onSelectedChangeCb(selected);
         }
      }
     
   }
  
   return <>
      <div className="auto-text-box-frame" key={id}>
         <div className="text-box">
            <textarea ref={textBoxRef} readOnly={props.readOnly} disabled={props.disabled} className={"form-control " + props.class} rows={rows} value={text} onChange={(e) => onValueChange(e)} onBlur={onBlur} onMouseUp={handleMouseUp}/>
         </div>
         {(props.smallIcon) &&
            <> 
               <div className="add-icon" >
                  <FontAwesomeIcon icon={faClipboardList}  onClick={openPhraseregister}/>
               </div>
            </>               
         }
         {(!props.smallIcon && props.phraseRegisterKey != null && props.phraseRegisterKey!= '' && !readOnly) &&
            <> 
               <div className="phrase-icon" >

                  <FontAwesomeIcon icon={faClipboardList}  onClick={openPhraseregister}/>
               </div>
            </>               
         }
         {(props.addPadding) && 
            <>
            <div className="phrase-icon" ></div>
            </>
         }
      </div>

      <PhraseDialog
         open={openPhraseDialog}
         phraseKey={key}
         text={selectedText}
         handleSaveCb = {handlePhraseSave}
         handleCancelCb = {handlePhraseCancel}
      />

     
   </>
}
  
export default AutoTextBox;