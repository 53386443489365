import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';


class MyProfileService extends BaseService {
   private static _myProfileService: MyProfileService;

   private constructor(controllerName: string) {
      super(controllerName);
   }

   public static get Instance(): MyProfileService {
      return this._myProfileService || (this._myProfileService = new this('myProfile'));
   }


   public async get(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('get', {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
      
      return axiosResponse;
   }

   public async Update(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('Update', data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
     
      return axiosResponse;
    }
  


    public async SaveApprovalMark(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('SaveApprovalMark', data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
     
      return axiosResponse;
    }
  






   public async getUserData(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('invoiceData', {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
      
      return axiosResponse;
   }

   
   public async setInvoiceInfo(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('updateCompany', data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
     
      return axiosResponse;
    }
  


}

export const MyProfileApi = MyProfileService.Instance;