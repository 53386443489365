import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class DocumentTitleModuleService extends BaseService {
  private static _documentTitleModuleService: DocumentTitleModuleService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): DocumentTitleModuleService {
    return this._documentTitleModuleService || (this._documentTitleModuleService = new this('documentTitleModule'));
  }

  public async getDocumentTitleModule(moduleId:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    if (!moduleId){
      this.handleAllErrors(` moduleId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.get('get/' + moduleId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
 }

 public async addDocumentTitleModule(data: {moduleId:number}): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
       this.handleAllErrors(` Data was not provided for addDocumentTitleModule.`); 
       Promise.reject();
    }
    
    await this.$http.post('Add',data, {withCredentials: true})
    .then((response) => {
       axiosResponse = response;
    }, 
    (error) => {
          this.handleAllErrors(error); 
          throw (error);
    });

    return axiosResponse;
 }

 public async updateDocumentTitle(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for updateDocumentTitle.`); 
      Promise.reject();
    }
    
    await this.$http.post('Update',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  } 

}



export const DocumentTitleModuleApi = DocumentTitleModuleService.Instance;