import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom'
import { ContactApi } from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import {ContactRow, ContactDialog, AddContactDialog} from './child-components';
import {isNullOrEmpty } from '../../utils'
import { BkButton } from '../../components';

const Contacts: React.FC = ()=>{
  const [contacts,setContacts]= useState([]);

  // Used for edit project data
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [searchText,setSearchText] = useState('');
  const [contactData, setContactData] = useState({
    contactId:0,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    address: '',
    zipCode: '',
    city: '',
    company: '',
    vatNumber: '',
  });

  const [displayArray, setDispayArray] =useState([]);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      ContactApi.getContacts()
      .then(
        (response) => {
          var data = response.data.sort((a, b) => (a.firstName < b.firstName ? -1 : 1));
          setContacts(data);
          setDispayArray(data);
        },
        (error) => {
          console.log(error);
        });
    }
    fetchData();
    setSearchText('');
  }, []);


  const handleAddContact= function() {
    setOpenAdd(true);
  }

  const handleEditContact= function() {
    setOpenEdit(true);
  }

  const handleContactAdded= function(contact) {
    if (contact !== null) {
        // Add new contact
        ContactApi.addContact(contact)
        .then(
          (response) => {
            setContacts(contacts => [response.data, ...contacts]);
            setDispayArray(displayArray=> [response.data, ...displayArray]);
          },
          (error) => {
            console.log(error);
          });
    }
    setOpenAdd(false);
  }
  const handleContactUpdated= function(contact) {
    if (contact !== null) {
     
        // Update existing contact
        ContactApi.updateContact(contact)
        .then(
          (response) => {
            // Update the contact in the list
            var newContacts = contacts.map((oldContact) => {
              if (oldContact.contactId === contact.contactId) {
                return response.data;
              }
              return oldContact;
            })
            setContacts(newContacts);
            setDispayArray(newContacts);
          },
          (error) => {
            console.log(error);
          });
    }
    setOpenEdit(false);
  }

  const handleContactSelected = function(id) {
    // Not used
  }

  const handleContactDeleted= function(id) {
    // Delete contact
    ContactApi.deleteContact(id)
    .then(
      (response) => {
        setContacts(contacts.filter(contact =>contact.contactId !== id));
        setDispayArray(displayArray.filter(contact =>contact.contactId !== id));
      },
      (error) => {
        console.log(error);
      });
  }


  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
        <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          className="navbar-dropdown-menu"
        >
          <MenuItem onClick={handleAddContact}>Legg til kontakt</MenuItem>
        </Menu>
      </div>;
  }

  const handleContactSearch = function(e) {
    var searchstring = e.target.value.toLowerCase();
    setSearchText(searchstring);

    if (isNullOrEmpty(searchstring)) {
      setDispayArray(contacts);
      return;
    }

    setDispayArray(contacts.filter(contact =>(
      contact.firstName.toLowerCase().includes(searchstring)  || 
      contact.lastName.toLowerCase().includes(searchstring) ||
      (contact.firstName.toLowerCase() + ' ' + contact.lastName.toLowerCase()).includes(searchstring) ||
      contact.email.toLowerCase().includes(searchstring) ||
      contact.address.toLowerCase().includes(searchstring) ||
      contact.city.toLowerCase().includes(searchstring) 
      )));


  }


  let contactsHtml = null;
  if (displayArray) {
    contactsHtml = displayArray.map((contact, index) =>
    {
      return (
      <ContactRow  key={'CR_' + index}
        contactData={contact} 
        index={index}        
        mode={0}
        handleSelectedCb={handleContactSelected}
        handleChangeCb={handleContactUpdated}
        handleDeleteCb={handleContactDeleted}
        />)
      })
  }

  return (
    <div className="container">

          <div className="row mt-2 mb-3">
            <div className='col-auto'>
            <div className="bk-row-header bk-row-header-title ">Kontaktoversikt</div>
            </div>

            <div className='col-md-2 mt-3'> 
                <BkButton caption='Legg til kontakt' onClickHandler={handleAddContact} />
               </div> 
        
            <div className='col-md-4 ms-auto mt-3'>
              <input className={['form-control', ''].join(' ')} placeholder="Søk" name="ContactSearch" type="text" value={searchText} onChange={handleContactSearch} />
                </div>
          </div>
          


          <div className=" bk-row-contact bk-row-header-text">
            <div className=""></div>
            <div className="bk-project-row-flex-1 ms-3">Navn</div>
            <div className="bk-project-row-flex-075 ms-4">Epost</div>
            <div className="bk-project-row-flex-min ms-4">Mobil</div>
            <div className="bk-project-row-flex-min ms-1">Adresse</div>
            <div className="bk-project-row-flex-1"></div>
            {/* <div className="action-menu"> <ActionMenu /></div> */}
          </div>
          
          {contactsHtml}

      <ContactDialog 
        contactData={contactData}
        open={openEdit}
        handleChangeCb={handleContactUpdated}
      />
      <AddContactDialog 
        //contactData={contactData}
        open={openAdd}
        handleChangeCb={handleContactAdded}
      />

    </div>
  )
}

export default Contacts;