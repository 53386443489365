import React, {useState} from 'react'
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators} from '../../store/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { BkButton } from '../../components';
import { HomeApi } from '../../api';
import { useLocation } from 'react-router';

type ContactDialogProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly role: number;
  readonly userName: string;
  readonly isAdmin: boolean;
};


const ContactDialog: React.FC<ContactDialogProps> = ({ 
      isAuthenticated, 
      isAdmin,
      userName, 
      role
    }) => {

   const [icon,setIcon]= useState(faEnvelope);
   const [open,setOpen]= useState(false);
   const [messageType,setMessageType]= useState('Spørsmål ifm. bruk av tjenesten');
   const [comment,setComment]= useState(' ');
   var currentUrl = useLocation()
   var path = currentUrl.pathname.toString().replace(/^\/+/,'')

   const handleClick= () => {
      if (open) {
         setIcon(faEnvelope);
         setOpen(false);
      }
      else {
         setIcon(faTimes);
         setOpen(true);
      }
   }


   const handleSend = () => {
      HomeApi.sendMailLoggedIn(messageType, comment,path )
      .then(() => {
         setOpen(false);
         setIcon(faEnvelope);
      });

   }

   const handleClose = () => {
      setOpen(false);
      setIcon(faEnvelope);
   }

   const handleSelectChange = (e) => {
      const {value } = e.target;
      setMessageType(value);
    };

   const handleSetComment = (e) => {
      const {value } = e.target;
      setComment(value);
   }

   let hideOnAdmin = ""

   // if (isAdmin) {
   //       hideOnAdmin = "dialog-hidden"
   //    }

   if (!isAuthenticated) {
      return (<></>);
   }

   return (
      <>
         <div className="contact-dialog-frame" id={hideOnAdmin}>
            <div className="contact-dialog-collapsed" title="Kontakt oss">
               <i>
                  <FontAwesomeIcon icon={icon}  onClick={handleClick}/>
               </i> 
            </div>
         </div>
         
         <div className="contact-dialog-box" hidden={!open}>
            <div className="contact-dialog-box-header">
               <FontAwesomeIcon icon={faEnvelope}/>
               Send mail til Byggekost
               <FontAwesomeIcon icon={faTimes} onClick={handleClose}/>
            </div>
            <div className="contact-dialog-box-content">
               <div>
                  Hei, kontakt oss gjerne for spørsmål og tilbakemeldinger om Byggekost.
               </div>
               <div>
                  For bruk av siden, les gjerne Instruks og Veiledning.
               </div>

               <div>
                  <FormControl className="contact-dialog-box-form" >
                     <InputLabel htmlFor="uncontrolled-native">Henvendelsen gjelder</InputLabel>
                     <Select
                        native
                        labelId="demo-simple-select-outlined-label"
                        label="Henvendelsen gjelder"
                        value={messageType}
                        onChange={(e) =>handleSelectChange(e)}
                        
                     >
                        <option  value={'Spørsmål ifm. bruk av tjenesten'}>Spørsmål ifm. bruk av tjenesten</option >
                        <option  value={'Kvadratmeterpriser'}>Kvadratmeterpriser</option >
                        <option  value={'Forslag til forbedring'}>Forslag til forbedring</option >
                        <option  value={'Feil i websiden'}>Feil i websiden</option >
                        <option  value={'Annet'}>Annet</option >
                     </Select>

                     <TextField
                        multiline
                        rows={6}
                        variant="outlined"
                        value={comment}
                        onChange={(e)=> handleSetComment(e)}
                        className='mt-2'
                     />

                     <BkButton caption='Send'  onClickHandler={handleSend} />
                  </FormControl>


               </div>


            </div>
         </div>
      </>

   )
}
 
const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   role: state.auth.role,
   userName: state.auth.userName,
   isAdmin: state.auth.isAdmin
 });

export default connect(mapStateToProps, actionCreators)(ContactDialog);