import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class ModuleService extends BaseService {
   private static _moduleService: ModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): ModuleService {
     return this._moduleService || (this._moduleService = new this('module'));
   }
 

   public async getComments(moduleId:number, subId:number, sectionId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('getComments/' + moduleId + "/" + subId+ "/" + sectionId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async saveComments(data): Promise<AxiosResponse> {
      var axiosResponse;
      if (!data){
         this.handleAllErrors(` Data was not provided for saveComments.`); 
         Promise.reject();
      }
      
      await this.$http.post('SaveComment',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   
}

export const ModuleApi = ModuleService.Instance;