import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { LegalMatters} from '../containers/Modules/LegalMattersModule/types'

class LegalMattersModuleService extends BaseService {
   private static _legalMattersModuleService: LegalMattersModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): LegalMattersModuleService {
     return this._legalMattersModuleService || (this._legalMattersModuleService = new this('legalMattersModule'));
   }
 

   public async getLegalMattersModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addLegalMattersModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addLegalMattersModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   
   public async addBuilding(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for addBuilding.`); 
      Promise.reject();
    }
    
    await this.$http.post('AddBuilding',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  } 

   public async updateBuilding(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateLegalMatters.`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateBuilding',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async deleteBuilding(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Id was not provided for deleteLegalMattersBuilding.`); 
        Promise.reject();
      }
      var model= {
        id:id
      }
      await this.$http.post('DeleteBuilding',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 
 
}

export const LegalMattersModuleApi = LegalMattersModuleService.Instance;