import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { MarketAnalysis} from '../containers/Modules/MarketAnalysisModule/types'

class MarketAnalysisModuleService extends BaseService {
   private static _marketanalysisModuleService: MarketAnalysisModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): MarketAnalysisModuleService {
     return this._marketanalysisModuleService || (this._marketanalysisModuleService = new this('marketAnalysisModule'));
   }
 

   public async getMarketAnalysisModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addMarketAnalysisModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addMarketAnalysisModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateMarketAnalysis(data: MarketAnalysis): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateMarketAnalysis.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async updatePlaceOfInterest(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updatePlaceOfInterest`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdatePlaceOfInterest',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async deletePlaceOfInterest(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.delete('deletePlaceOfInterest/'+ id, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
  
      return axiosResponse;
    }

    public async updateComparableObject(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateComparableObject`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateComparableObject',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 


    public async deleteComparableObject(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.delete('deleteComparableObject/'+ id, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
  
      return axiosResponse;
    }
}

export const MarketAnalysisModuleApi = MarketAnalysisModuleService.Instance;