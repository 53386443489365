import React, {useState, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';

const PropertyItemGroup = ({itemGroupRow, onChanged}) => {
   const [itemGroup, setItemGroup] = useState({
      items:[{
         textValue:'',
         suffix:'',
         numericValue:0,
         selectedId:0,
         selectionId:0
      }],
      itemType:0,
      helpText:'',
      name:''
   });

   useEffect(() => {
      setItemGroup(itemGroupRow);
   }, [itemGroupRow]);

   const displayNumericValue = function(value) {
      if (value !== 0) {
         return value;
      }
      return "";
   }

   const handleNumericChange = function(e, id) { 
      const { value } = e.target;
      var newItems = itemGroup.items.map((item, index) => {
         if (item.selectionId === id) {
            item.numericValue = value
           return item;
         }
         return item;
       })
       setItemGroup(itemGroup => ({...itemGroup, items:newItems}));

      onChanged(e,id);
   }

   const handleTextChange = function(e, id) {
      const { value } = e.target;
      var newItems = itemGroup.items.map((item, index) => {
         if (item.selectionId === id) {
            item.textValue = value
           return item;
         }
         return item;
       })
       setItemGroup(itemGroup => ({...itemGroup, items:newItems}));

      onChanged(e,id);
   }


   const handleSelectedChange = function(e, id) {
      const { value } = e.target;
      var newItems = itemGroup.items.map((item, index) => {
         if (item.selectionId === id) {
            item.selectedId = value
           return item;
         }
         return item;
       })
       setItemGroup(itemGroup => ({...itemGroup, items:newItems}));

      onChanged(e,id);
   }


   const initialOptions = function(caption) {
      return (
         <option key={0} value={0}>{caption}</option>
      )
   }


  const getSelectItems = function(item, index) {
    var options = [];
    if (item.selectItems.length > 0)
    {
      options.push(initialOptions(item.caption));
      var newOptions =  item.selectItems.map((option) => {
        return <option key={option.id} value={option.id}>{option.name}</option>
      });
      options.push(newOptions);
      return (
         <div className="property-item-select">
            <select className={['form-control',  (item.required && item.selectedId <=0)?'calculation-required-error':''].join(' ')} value={item.selectedId} onChange={(e)=>handleSelectedChange(e,item.selectionId)}>
               {options}
            </select>
            <img className={(index) > 0?'':'visibility-hidden'} title="Slett rad" src="/Content/images/font-awsome/black/times.png" />
         </div>
      );
   }
   else {
      return (<></>);
   }
  }

  let itemsHtml = null;
  if (itemGroup.items) {
      itemsHtml = itemGroup.items.map((item, index) => {
         if (itemGroup.itemType == 1 || itemGroup.itemType == 2 || itemGroup.itemType == 3 || itemGroup.itemType == 7 || itemGroup.itemType == 8  || itemGroup.itemType == 9) 
         {
            return (
               <div className="property-item-container">
                  <div className="property-item-value">
                     <i>{item.suffix}</i>
                     <input className="form-control" placeholder="0" name={"TC" +index} type="text" value={displayNumericValue(item.numericValue)} onChange={(e)=>handleNumericChange(e,item.selectionId)} />
                  </div>
                  {getSelectItems(item, index)}
               </div>
            );
         }
         else if (itemGroup.itemType == 10) {
            return (
               <>
               <div className="property-group-items-container">
                  <div className="property-item-text-area">
                     <textarea className="form-control" rows={4}  placeholder="Notat" value={item.textValue} onChange={(e)=>handleTextChange(e,item.selectionId)}></textarea>

                  </div>
                  <div className="property-item-value-area">
                     <input className="form-control" placeholder="0" name={"TC" +index} type="text" value={displayNumericValue(item.numericValue)} onChange={(e)=>handleNumericChange(e,item.selectionId)} />
                  </div>
                  <div className="property-itemgroup-helptext">
                     <div className={(index) == 0?'':'visibility-hidden'}>                     
                        <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"Help"} data-for={"tooltip"} data-tip={itemGroup.helpText}/>
                        <ReactTooltip id={"tooltip"} place="right" type="light" html={true} effect="solid"  className="helptext-custom"/>
                     </div>

                  </div>
               </div>
               </>
            )
         }
      });
   }


// Return

   if (itemGroup.itemType == 1 || itemGroup.itemType == 2 || itemGroup.itemType == 3 || itemGroup.itemType == 7 || itemGroup.itemType == 8  || itemGroup.itemType == 9) {
      return (
         <div className="property-group-items-container">
            <div className="property-itemgroup-name">{itemGroup.name}</div>
            <div className="property-itemgroup-item">
                  {itemsHtml}
            </div>
            <div className="property-itemgroup-helptext">
               <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"Help"} data-for={"tooltip"} data-tip={itemGroup.helpText}/>
               <ReactTooltip id={"tooltip"} place="right" type="light" html={true} effect="solid"  className="helptext-custom"/>

            </div>
         </div>
      );
   }
   else if (itemGroup.itemType == 6) {
      return (
         <div className="display-flex calculation-row">
            <div className="flex-12 calculation-note-row">
               <div className="calculation-cell">{itemGroup.name}</div>
               <div className="calculation-cell">
                  <div className="input-icon input-icon-right">
                     <textarea className="form-control form-control-note" rows={6}  placeholder="Notat" value={itemGroup.items[0].textValue} onChange={(e)=>handleTextChange(e,itemGroup.items[0].selectionId)}></textarea>
                  </div>
               </div>
            </div>
         </div>
      );
   }
   else if (itemGroup.itemType == 10) {
      return (
         <>
            {itemsHtml}
         </>
      )
   }

}


export default React.memo(PropertyItemGroup);