import React, {useEffect } from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators, reducer } from '../../store/auth';

type LogoutProps = ReturnType<typeof reducer>
  & typeof actionCreators
  & { history: History, readonly isAuthenticated: boolean};

const Logout: React.FC<LogoutProps> = ({
  history,
  logoutUser,
}) => {

   useEffect(() => {
      logoutUser();
      history.push("/login");  
   }, []);

  return (
   <div></div>
  );
};

const mapStateToProps = (state: IApplicationState) => state.auth;

export default connect(mapStateToProps, actionCreators)(Logout);
