import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { Buildings} from '../containers/Modules/BuildingsModule/types'

class FarmBuildingsModuleService extends BaseService {
   private static _farmbuildingsModuleService: FarmBuildingsModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): FarmBuildingsModuleService {
     return this._farmbuildingsModuleService || (this._farmbuildingsModuleService = new this('farmbuildingsModule'));
   }
 

   public async getFarmBuildingsModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addFarmBuildingsModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addFarmBuildingsModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateFarmBuildings(data: Buildings): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateFarmBuildings.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async addFarmBuilding(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for addBuilding.`); 
        Promise.reject();
      }
      
      await this.$http.post('AddFarmBuilding',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async updateFarmBuilding(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateFarmBuildings.`); 
        Promise.reject();
      }
      
      await this.$http.post('updateFarmBuilding',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async deleteFarmBuilding(data): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for deleteFarmBuilding.`); 
        Promise.reject();
      }
      
      await this.$http.post('deleteFarmBuilding',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

   
}

export const FarmBuildingsModuleApi = FarmBuildingsModuleService.Instance;