import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button} from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const DatePickerDialog = ({open, title, date, handleSelectedCb}) => {
   const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

   useEffect(() => {
      setSelectedDate(date);
   }, [date]);


   const handleDateChange = (date: Date | null) => {
      setSelectedDate(date);
   };



   const handleClose = function() {
      handleSelectedCb(null);
   }

   const handleSaveAndClose = function() {
      handleSelectedCb(selectedDate);
   }
  
   return (
      <>
         <Dialog open={open} fullWidth={true} maxWidth = {'md'} onClose={handleClose} aria-labelledby="form-dialog-title">
         <DialogTitle id="form-dialog-title">{title}</DialogTitle>
         <DialogContent>
               <div className="simple-calc-section">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                     <KeyboardDatePicker
                           disableToolbar
                           variant="inline"
                           format="MM/dd/yyyy"
                           margin="normal"
                           id="date-picker-inline"
                           label="Sett dato"
                           value={selectedDate}
                           onChange={handleDateChange}
                           KeyboardButtonProps={{
                           'aria-label': 'change date',
                           }}
                     />
                  </MuiPickersUtilsProvider>
               </div>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleClose} color="primary">
               Avbryt
            </Button>
            <Button onClick={handleSaveAndClose} color="primary">
               OK
            </Button>
         </DialogActions>
         </Dialog>
      </>
   )
}

export default React.memo(DatePickerDialog);