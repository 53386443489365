import React, {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators} from '../../store/auth';
import { SimpleCalculationApi } from '../../api';
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { SimpleCalcInfo, SimpleCalcDescription, SimpleCalcCalculation, SimpleCalcResult, SimpleCalcPrintDialog } from './child-components'
import {ProjectLink, SectionHeader, Spinner, SectionHeaderModule} from '../../components'

type SimpleCalculationProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly role: number;
  readonly userName: string;
};


const keyPressTimer = 1000;
let caclulationTimer = null;

const SimpleCalculation: React.FC<SimpleCalculationProps> = ({ 
  isAuthenticated, 
  userName, 
  role
}) => {
  const [isLoading, setIsLoading] = useState(true);
  
  const [calculation,setCalculation]= useState({
    projectId:0,
    simpleCalculationId:0,
    name: '',
    subTitle: '',
    descriptions: [],
    infos:[],
    categories:[]
  });
  const [project,setProject]= useState({
    address: {
      address: '',
      zipCode: '',
      city: ''
    },
    userName: ''
  });
  const [openPrintDialog, setOpenPrintDialog] = useState(false);

  var openPrintDlg = false;

  const params = useParams();
  const calculationId = (params as any).id;
  const history = useHistory();
  const calculationRef = useRef(calculation);
  calculationRef.current = calculation;


  useEffect(() => {
    const fetchData = async () => {
      SimpleCalculationApi.getSimpleCalculation(calculationId)
      .then(
        (response) => {
          setCalculation(response.data.calculation);
          setProject(response.data.project);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
          actionCreators.logoutUser();
          history.push("/login");      
        });
    }
    fetchData();
  }, []);


  const handleChange = function(e) {
    const { name, value } = e.target;
    setCalculation(calculation => ({ ...calculation, [name]: value }));

    if (caclulationTimer !== null){
      clearTimeout(caclulationTimer);
    }
    caclulationTimer = setTimeout(() => {
      delayedCalculationUpdate();
    }, keyPressTimer);

  }

  const delayedCalculationUpdate = function() {
    SimpleCalculationApi.updateSimpleCalculation(calculationRef.current)
    .then (
      (response) => {
        
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleCategoriesUpdated = function(categories) {
    setCalculation(calculation => ({ ...calculation, categories: categories }));
  }

  const handlePrint = function() {
    setOpenPrintDialog(true);
  }

  const handlePrintCb = function(type) {
    setOpenPrintDialog(false);
    if (type > 0) {
      SimpleCalculationApi.downloadPdf(calculationId, type);
    }
  }
 
  const handleProjectClick = function() {
    history.push('/project/' + calculation.projectId);
 }

 const showOwner = (ownerName) =>{
  if (ownerName != userName && ownerName != null && ownerName != ''){
     return <span className='bk-project-owner-title'>Bygningssakkyndig: {ownerName}</span>
  }
}
  return(
    <>
    <div className="container">
      <div className="bk-report-header">
                  <div className="bk-report-title">
                     Detaljert kalkulasjon 
                     {project ? showOwner(project.userName) : ''}
                  </div>
                    <ProjectLink id={calculation.projectId} address={project.address.address} zipCode={project.address.zipCode} city={project.address.city}/>
                    
                    <img className="bk-header-icon-printer" src="/Content/images/design/icon-print.svg" title="Skriv ut" onClick={handlePrint}/>
      </div>   
          
          {/* <span className="bk-calculation-project-info" title="Gå til prosjekt" onClick={handleProjectClick}>{project.address}, {project.zipCode} {project.city} </span> */}
        
          <div className='report-content'>
          <SectionHeader title={' Forside'}></SectionHeader>
           <div className='content-frame'> 
          <div className="simple-calc-section-row">
            <div className="simple-calc-info-type">
                Dokumentets tittel:
            </div>
            <div className="simple-calc-info-text">
              <input className="form-control" type="text" name="name" value={calculation.name} onChange={handleChange}/>
            </div> 
            <div className="simple-calc-action-menu" title=""></div>
          </div>
          <div className="simple-calc-section-row">
            <div className="simple-calc-info-type">
                Dokumentets undertittel:
            </div>
            <div className="simple-calc-info-text">
            <input className="form-control" type="text" name="subTitle" value={calculation.subTitle} onChange={handleChange}/>
            </div> 
            <div className="simple-calc-action-menu" title=""></div>
          </div>
          </div>
        

        <br className="clear-both" />

        <div className="simple-calc-section">
        <SectionHeader title={' Opplysninger'}></SectionHeader>
        <div className='content-frame'>
            <SimpleCalcInfo calculationId={calculationId} infoRows={calculation.infos}/>  
        </div>
        </div>

        <br className="clear-both" />


        <div className="simple-calc-section">
          <SimpleCalcDescription calculationId={calculationId} descriptionElements={calculation.descriptions}></SimpleCalcDescription>
        </div>

        <br className="clear-both" />

        <div className="simple-calc-section">
          <SimpleCalcCalculation 
            simpleCalculationId={calculation.simpleCalculationId}
            categoriesArray={calculation.categories} 
            categoriesUpdateCb={handleCategoriesUpdated} />
        </div>

        <br className="clear-both" />

        <div className="simple-calc-section">
          <div className="simple-calc-section-header">Resultat</div>
          <div className='content-frame'>
          <SimpleCalcResult categoriesArray={calculation.categories} />
        </div>
        </div>
    </div>
    </div>
    
    <SimpleCalcPrintDialog open={openPrintDialog} handlePrintCb={handlePrintCb}/>
    <Spinner isLoading={isLoading} />
    </>
  );

}

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(SimpleCalculation);
//export default React.memo(SimpleCalculation);