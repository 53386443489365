
const formatValue = (val: number, decimals:number, mark:string, nullValue:string='-'): string => {

  decimals = decimals || 0;
  mark = mark || '';

  if (val === 0 || isNaN(val)) {
    return nullValue;
  }

  var value = parseFloat(val.toString().replace(',', '.')).toFixed(decimals);
  if (value === '0') {
      return nullValue;
  }

  var nStr = value.toString();
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? ',' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1 $2');
  }
  return x1 + x2 + mark;

};

export {
  formatValue
};


