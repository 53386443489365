import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';


class AreaTableService extends BaseService {
  private static _areaTableService: AreaTableService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): AreaTableService {
    return this._areaTableService || (this._areaTableService = new this('areaTableModule'));
  }

  public async getAreaTable(moduleId:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    if (!moduleId){
      this.handleAllErrors(` moduleId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.get('get/' + moduleId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }

  public async addAreaTable(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for addAreaTable.`); 
      Promise.reject();
    }
    
    await this.$http.post('Add',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw (error);
    });

    return axiosResponse;
  }

  public async updateAreaTable(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for updateAreaTable.`); 
      Promise.reject();
    }
    
    await this.$http.post('Update',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  }

  public async addBuilding(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for addBuilding.`); 
      Promise.reject();
    }
    
    await this.$http.post('AddBuilding',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw (error);
    });

    return axiosResponse;
  }


  public async updateBuilding(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateBuilding',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error); throw(error)});

    return axiosResponse;
  }

  public async updateFloor(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateFloor',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error);});

    return axiosResponse;
  }

  public async AddFloor(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('AddFloor', data, 
    {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  }
  
  
  public async deleteBuilding(id:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    if (!id){
      this.handleAllErrors(`id was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.post('DeleteBuilding', {id}, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }

    
  public async deleteFloor(data): Promise<AxiosResponse | null> {
    var axiosResponse;
       
    if (!data){
      this.handleAllErrors(`id was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.post('DeleteFloor', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }
      

}

export const AreaTableApi = AreaTableService.Instance;