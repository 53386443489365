import React, {useState} from 'react';
import './SectionHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { count } from 'console';

interface SectionHeaderProps {
    title: string,
    input?:JSX.Element
    menu?: JSX.Element,
    displayShow?:boolean,
    onClickCb?: (show: boolean) => void;
}


export const SectionHeader: React.FC<SectionHeaderProps> = ({ title, input, menu , displayShow, onClickCb }) => {

   const [show, setShow] = useState(true);
   const [helpText, setHelpText] = useState('Skjul');


   const onHeaderClicked = ()  => {
      setHelpText(show?'Vis':'Skjul');
      setShow(!show);
      onClickCb(!show);
   }

   if (displayShow != null && displayShow== true) {
      if (input !== null && input !== undefined) {
         return <> 
            <div className="section-header" >
               { show ?
                     <FontAwesomeIcon icon={faCaretDown} className='search-icon'  onClick={onHeaderClicked}/>
                     :
                     <FontAwesomeIcon icon={faCaretRight} className='search-icon'  onClick={onHeaderClicked}/>
               }
                  <div className="description-mini"  onClick={onHeaderClicked}>{title || ''} </div>
                  <div className="input-field">{input || ''} </div>
                  <div className="action-menu">{menu}</div>
            </div>
         </>
         
      }
   
      return <> 
         <div className="section-header">
         { show ?
                     <FontAwesomeIcon icon={faCaretDown} className='search-icon'  onClick={onHeaderClicked}/>
                     :
                     <FontAwesomeIcon icon={faCaretRight} className='search-icon'  onClick={onHeaderClicked}/>
               }
            <div className="description"  onClick={onHeaderClicked}>{title || ''} </div>
            <div className="action-menu">{menu}</div>
         </div>
      </>

   }
   else {
      if (input !== null && input !== undefined && (title == null || title== undefined || title.length==0)) {
         return <> 
            <div className="section-header">
                  <div className="input-field">{input || ''} </div>
                  <div className="action-menu">{menu}</div>
            </div>
         </>         
      }      
      
      if (input !== null && input !== undefined && title.length < 10) {
         return <> 
            <div className="section-header">
                  <div className="description-mini">{title || ''} </div>
                  <div className="input-field">{input || ''} </div>
                  <div className="action-menu">{menu}</div>
            </div>
         </>
         
      }
   
      if (input !== null && input !== undefined) {
         return <> 
            <div className="section-header">
                  <div className="description-medium">{title || ''} </div>
                  <div className="input-field">{input || ''} </div>
                  <div className="action-menu">{menu}</div>
            </div>
         </>
         
      }

      return <> 
         <div className="section-header">
            <div className="description">{title || ''} </div>
            <div className="action-menu">{menu}</div>
         </div>
      </>
   }


}