import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { Leasehold, LeaseholdOwner } from '../containers/Modules/Leasehold/types';


class LeaseholdService extends BaseService {
   private static _leaseholdService: LeaseholdService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): LeaseholdService {
     return this._leaseholdService || (this._leaseholdService = new this('leasehold'));
   }
 

   public async getLeasehold(leaseholdId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!leaseholdId){
        this.handleAllErrors(` leaseholdId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + leaseholdId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addLeasehold(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addLeasehold.`); 
         Promise.reject();
      }
      
      await this.$http.post('AddLeasehold',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateLeasehold(data: Leasehold): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateLeasehold.`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateLeasehold',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    }

    public async updateLeaseholdOwner(data: LeaseholdOwner): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateLeaseholdOwner.`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateLeaseholdOwner',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    }

    public async deleteLeaseholdOwner(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.delete('deleteLeaseholdOwner/'+ id, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
  
      return axiosResponse;
    }

}

export const LeaseholdApi = LeaseholdService.Instance;