import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { IAuthUser, ICredentials, AuthStatusEnum } from '../store/auth/types';

/**
 * Auth API abstraction layer communication via Axios (typescript singleton pattern)
 */
class AuthService extends BaseService {
  private static _authService: AuthService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): AuthService {
    return this._authService || (this._authService = new this('Auth'));
  }

  public async logoutAsync(): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('Logout', {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, (error) => {
      console.log(error);
      axiosResponse = error;
    });
   
    return axiosResponse;
  }

  public async loginAsync(credentials: ICredentials): Promise<IAuthUser> {
    const data:IAuthUser = {};
    await this.$http.post<IAuthUser>('Login', credentials)
    .then((response) => {
      data.userName = response.data.userName;
      data.role = response.data.role;
      data.isAdmin = response.data.isAdmin;
      data.status = AuthStatusEnum.SUCCESS;
      data.allowOld = response.data.allowOld;
    }, (error) => {
      console.log(error);
      data.status = AuthStatusEnum.FAIL;
    });

    return data;
  }

  public async Register(registerData): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('Register', registerData)
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
      throw new Error(error.response.data.Error[0]);
    });   
    return axiosResponse;
  }

  public async ForgotPassword(forgotPasswordData): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('ForgotPassword', forgotPasswordData)
    .then((response) => {
      axiosResponse = response;
    }, (error) => {
      console.log(error.response);
      throw new Error(error.data);
    });
   
    return axiosResponse;
  }

  public async ResetPassword(resetPasswordData): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('ResetPassword', resetPasswordData)
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
      throw new Error(error);
    });   
    return axiosResponse;
  }

}

export const AuthApi = AuthService.Instance;
