import React from 'react';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCircleInfo, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import Button from "@material-ui/core/Button";
import parse from 'html-react-parser';

const MessageRow = ({message, onMessageAnswered, onMessageArchived, archive}) => {

   const history = useHistory();

   const formatDateTime = function(date) {
      if (date === undefined || date === null) {
         // Could be for a new record
         return "-";
      }
      let thedate = new Date(date);
      var options = {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' } as const;
      return thedate.toLocaleString('nb-NO', options);
   }


   const handleAnswer = () => {
      onMessageAnswered(message);
   }

   const handleArchive = () => {
      onMessageArchived(message.userMessageId);
   }

   const getIcon = () => {
      if (message.messageTypeId == 3) {
         return <FontAwesomeIcon icon={faCircleInfo} className="message-icon color-red" />;
      }
      else if (message.messageTypeId == 4) {
         return <FontAwesomeIcon icon={faEnvelope} className="message-icon color-green" />;
      }
      else return <FontAwesomeIcon icon={faCircleQuestion} className="message-icon color-blue" />
   }


   return (
      <>
         <div className ="message-row message-row-border">
            {/*<div className="col-md-1 message-mobile">{getIcon()}</div>*/}
            <div className="message-mobile">
            <span className='bold'>{formatDateTime(message.created)}</span>
            <br></br>
            <a href={"/customer/" + message.userId}>{message.from}</a>
            <br></br>
            {message.email}
            
            </div>
            <div className="message-mobile message-over">{message.title}</div>
            <div className="message-mobile-padding">{parse(message.message)} <br></br> <a href={message.url}>{message.url}</a></div>
            <div className='message-mobile-padding'>
            {archive ?
               <>
                  <div className="col-12 message-mobile">{message.checkedByUser}</div>
                  <div className="col-12 message-mobile">{formatDateTime(message.checked)}</div>
               </>
            :
            <div className="message-button-container">
               {message.messageTypeId == 4 &&
                  <>
                     <div onClick={handleAnswer} className="message-button">Svar</div>
                  </>
               }                  
               <div onClick={handleArchive} className="message-button" id='message-button-archive'>Arkiver</div>
            </div> 
            }
            </div>


         </div>

      </>
   )
}

export default React.memo(MessageRow);
