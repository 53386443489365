import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLink, faArrowLeft  } from '@fortawesome/free-solid-svg-icons'
import { BkButton} from '../../components';

const DocumentLinks: React.FC = ()=>{

   return (
      <div className='container'>
            <div className='row mb-2 bk-row-header'>
               <div className='col-lg-10'> <div className=" bk-row-header-title "> Linker </div></div>
               <div className='col-lg-2'> 
               <a href="/documents" className='dashboard-back-btn mx-auto'> 
               <FontAwesomeIcon icon={faArrowLeft} className=" mt-3 me-3"/>
               Hovedmeny </a></div>
            </div>
           
            <div className="report-content">

            <div className='row mt-3'>
            <div className='col-6'>

                  <div className="dashboard-btn " id="dashboard-link-btn">
                  <FontAwesomeIcon icon={faLink} className="fa-xs mx-3 mt-1 dashboard-link-icon"/>  
                  <a href="https://lovdata.no/dokument/SF/forskrift/2021-06-08-1850" target="_blank" className='dashboard-link'>
                     Forskrift til avhendingslova 
                     (tryggere bolighandel)
                     1. januar 2022</a>
                  </div>

               </div>

                     <div className='col-6'>

                        <div className="dashboard-btn" id="dashboard-link-btn">
                              <FontAwesomeIcon icon={faLink} className="fa-xs mx-3 mt-1 dashboard-link-icon"/>
                              <a href="https://dibk.no/regelverk/forskrift-til-avhendingslova-tryggere-bolighandel/" target="_blank" className='dashboard-link mt-3 mb-2'>
                              Ny forskrift til avhendingslova 
                           
                              (tryggere bolighandel) 
                         
                              1. januar 2022</a>
                        </div>

                     </div>
                  </div>
            <div className='row mt-3'>
            <div className='col-6'>

                  <div className="dashboard-btn " id="dashboard-link-btn">
                  <FontAwesomeIcon icon={faLink} className="fa-xs mx-3 mt-1 dashboard-link-icon"/>  
                  <a href="https://www.digitalarkivet.no/" target="_blank" className='dashboard-link'>
                     Digitalarkivet</a>
                  </div>

               </div>

                              <div className='col-6'>

               <div className="dashboard-btn " id="dashboard-link-btn">
               <FontAwesomeIcon icon={faLink} className="fa-xs mx-3 mt-1 dashboard-link-icon"/>  
               <a href="https://www.kartverket.no/" target="_blank" className='dashboard-link'>
                  Kartverket</a>
               </div>

               </div>
                  </div>
               </div>
      </div>
   )
}

export default DocumentLinks;