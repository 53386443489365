import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { Prerequisites} from '../containers/Modules/PrerequisitesModule/types';


class PrerequisitesModuleService extends BaseService {
   private static _prerequisitesModuleService: PrerequisitesModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): PrerequisitesModuleService {
     return this._prerequisitesModuleService || (this._prerequisitesModuleService = new this('prerequisitesModule'));
   }
 

   public async getPrerequisitesModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addPrerequisitesModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addPrerequisitesModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updatePrerequisites(data: Prerequisites): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updatePrerequisites.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

}

export const PrerequisitesModuleApi = PrerequisitesModuleService.Instance;