import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {TextField } from '@material-ui/core';
import { BkButton } from '../../../components';
import { ContactButton } from '../../../components';
import { isNullOrEmpty } from '../../../utils';
import { makeStyles } from '@material-ui/core/styles';
import { ProjectApi } from '../../../api';

const ContactDialog = ({open, contactData, handleChangeCb}) => {

  const [zipCodeError, setZipCodeError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [contact,setContact]= useState({
    contactId:0,
    firstName:'',
    lastName:'',
    email:'',
    mobile:'',
    address:'',
    zipCode:'',
    city:'',
    company:'',
    vatNumber:''
  });

  useEffect(() => {
    setContact(contactData);
  }, [contactData]);


  useEffect(() => {
    setOpenEdit(open);
  }, [open]);


  const handleCloseEdit = () => {
    handleChangeCb(null);
  };

  const handleSaveAndCloseEdit= () => {   
    var error = false;
    if (isNullOrEmpty(contact.firstName)) {
      setFirstNameError(true);
      error=true;
    }
    if (!error) {
      handleChangeCb(contact);
    }
  
  }


  const handleChange = function(e) {
    const { name, value } = e.target;
    setContact(contact => ({ ...contact, [name]: value }));
  }

  const handleLocationDataChange = function(e) {
    const { name, value } = e.target;
    setContact(contact => ({ ...contact, [name]: value }));
    if (name == "zipCode" && !isNullOrEmpty(value)){
      setZipCodeError(false);

      if (value.length == 4) {
        ProjectApi.getLocationFromZipCode(value).then(
          (response) => {
            var loc = response.data;
            if (!isNullOrEmpty(loc.city)) {
              setContact(contact => ({ ...contact, city: loc.city }));
            }
            else{
              setZipCodeError(true);
            }
          }
        )
      }
    }
    if (name == "city" && !isNullOrEmpty(value)){
      setCityError(false);
    }
  }

  let titleHtml = contact.contactId>0?'Rediger Kontakt':'Legg til ny kontakt';

  const useTextFieldStyles = makeStyles(() => ({
    root: {
    '& label.Mui-focused': {
      color: '#7DA868',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#7DA868',
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: '#ff1744',
    },
    }
  }));

  const textFieldStyles = useTextFieldStyles(false);

  
  return (
    <Dialog open={openEdit} onClose={handleCloseEdit} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title" className="dialog-header">{titleHtml}</DialogTitle>
    <DialogContent>
      <div className="project-dialog-edit-wrapper">
        <div className="project-dialog-edit-frame">
          <TextField required autoFocus margin="dense" error={firstNameError} className={textFieldStyles.root} id="firstName" name="firstName" label="Fornavn" type="text" fullWidth value={contact.firstName} onChange={(e)=>handleChange(e)}/>
          <TextField margin="dense" className={textFieldStyles.root} id="lastName" name="lastName" label="Etternavn" type="text" fullWidth value={contact.lastName} onChange={(e)=>handleChange(e) }/>          <TextField margin="dense" className={textFieldStyles.root} id="email" name="email" label="Email" type="text" fullWidth value={contact.email} onChange={(e)=>handleChange(e)}/>
          <TextField margin="dense" className={textFieldStyles.root} id="mobile" name="mobile" label="Mobil" type="text" fullWidth value={contact.mobile} onChange={(e)=>handleChange(e)}/>
          <TextField margin="dense" className={textFieldStyles.root} id="address" name="address" label="Adresse" type="text" fullWidth value={contact.address} onChange={handleLocationDataChange}/>
          <TextField margin="dense" error={zipCodeError} inputProps={{ maxLength: 4 }} className={textFieldStyles.root} id="zipCode" name="zipCode" label="Postnummer" type="text" value={contact.zipCode} onChange={handleLocationDataChange}/>
          <TextField margin="dense" error={cityError} className={textFieldStyles.root} id="city" name="city" label="Sted" type="text" fullWidth value={contact.city} onChange={handleLocationDataChange}/>
          <TextField margin="dense" className={textFieldStyles.root} id="company" name="company" label="Firma" type="text" fullWidth value={contact.company} onChange={(e)=>handleChange(e)}/>
          <TextField margin="dense" className={textFieldStyles.root} id="vatNumber" name="vatNumber" label="Org.nr." type="text" fullWidth value={contact.vatNumber} onChange={(e)=>handleChange(e)}/>
      </div>
      </div>
    </DialogContent>
    <DialogActions>
      <ContactButton caption='Avbryt' onClickHandler={handleCloseEdit}/>
      <BkButton caption='Lagre kontakt'  onClickHandler={handleSaveAndCloseEdit} />
    </DialogActions>
  </Dialog>
  )
}

export default React.memo(ContactDialog);