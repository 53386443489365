import React, {useEffect} from 'react';
import {DescriptionTemplateMain} from './child-components';
import { DescriptionTemplateApi } from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

const DescriptionTemplate: React.FC = ()=>{

   const [descriptions, setDescriptions] = React.useState([]);

   useEffect(() => {
      //Load templates
      DescriptionTemplateApi.get()
      .then (
        (response) => {
          setDescriptions(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    }, [])

  
  
    const handleChange = function(data) {
      var newDescriptions = descriptions.map((description, index) => {
        if (description.id === data.id) {
          return data;
        }
        return description;
      })
      setDescriptions(newDescriptions);
    }
  
  
    
    const handleMoveUp = function(id) {
      var selectedIndex = 0;
  
      descriptions.map((description, index) => {
        if (description.id === id) {
          selectedIndex=index;
        }
        return description;
      })
  
      if (selectedIndex === 0) return; // already on top
  
      let data = [...descriptions];
      var firstOrder = data[selectedIndex-1].order;
      var lastOrder = data[selectedIndex].order;
      let temp = data[selectedIndex-1];
      temp.order = lastOrder;
      data[selectedIndex].order = firstOrder;
      data[selectedIndex-1] = data[selectedIndex];
      data[selectedIndex] = temp;
      setDescriptions(data);
      updateDescription(data[selectedIndex]);
      updateDescription(data[selectedIndex-1]);
    }
  
  
    const handleMoveDown = function(id) {
      var selectedIndex = descriptions.length-1;
      descriptions.map((description, index) => {
        if (description.id === id) {
          selectedIndex=index;
        }
        return description;
      })
  
      if (selectedIndex === descriptions.length-1) return; // already at the end
  
      let data = [...descriptions];
      var firstOrder = data[selectedIndex].order;
      var lastOrder = data[selectedIndex+1].order;
      let temp = data[selectedIndex+1];
      temp.order = firstOrder;
      data[selectedIndex].order = lastOrder;
      data[selectedIndex+1] = data[selectedIndex];
      data[selectedIndex] = temp;
      setDescriptions(data);
      updateDescription(data[selectedIndex]);
      updateDescription(data[selectedIndex+1]);
    }
  
    const handleDelete = function(id) {
      DescriptionTemplateApi.delete(id)
      .then (
        (response) => {
          setDescriptions(descriptions => (descriptions.filter(description =>description.id !== id)));
        },
        (error) => {
          console.log(error);
        }
      );
    }
    
    const updateDescription = function(description) {
      DescriptionTemplateApi.update(description)
      .then (
        (response) => {
          //handleInfoUpdatedCb(info);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  
    const handleAddDescription = function() {
      var desc = {
        format:1,
        title:''
      }
      DescriptionTemplateApi.add(desc)
      .then (
        (response) => {
          setDescriptions(descriptions => [...descriptions, response.data]);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  
    const handleAddImage = function() {
      var desc = {
        format:2,
        title:''
      }
      DescriptionTemplateApi.add(desc)
      .then (
        (response) => {
          setDescriptions(descriptions => [...descriptions, response.data]);
        },
        (error) => {
          console.log(error);
        }
      );
    }


   function ActionMenu(props) {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
    
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
      
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleAddDescription}>Legg til hovedpunkt</MenuItem>
            <MenuItem onClick={handleAddImage}>Legg til bilde</MenuItem>

          </Menu>
        </div>;
  
    }


    let descriptionHtml = null;
    if (descriptions) {
      descriptionHtml = descriptions.map((description, index) =>
      {
        return (<DescriptionTemplateMain 
          descriptionRow={description} 
          handleChangeCb={handleChange}
          handleMoveUpCb={handleMoveUp}
          handleMoveDownCb={handleMoveDown}
          handleDeleteCb={handleDelete}
          />)
        })
    }
  


  return (
   <div className="bk-container">
      <div className="simple-calc-wrapper">
         <div className="simple-calc-header">
            Malbank - Beskrivelse
         </div>
     
         <br className="clear-both" />

         <div className="simple-calc-section">

          <div className="simple-calc-section-header">
            <div className="description">Maler</div>
            <div className="action-menu"> <ActionMenu /></div>
          </div>
          {descriptionHtml}

          {/* <SimpleCalcDescription calculationId={calculationId} descriptionElements={calculation.descriptions}></SimpleCalcDescription> */}
        </div>
     </div>    
   </div> 
  )
}

export default DescriptionTemplate;