import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class ModuleSectionService extends BaseService {
  private static _moduleSectionService: ModuleSectionService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): ModuleSectionService {
    return this._moduleSectionService || (this._moduleSectionService = new this('moduleSection'));
  }


  public async getModuleSection(moduleId): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('get/'+ moduleId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async addModuleSection(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('post', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async updateModuleSection(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.put('put', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async deleteModuleSection(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('delete/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


}



export const ModuleSectionApi = ModuleSectionService.Instance;