import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { CalculationTemplateCategoryRow } from '.';
import { CalculationTemplateApi, CalculationTemplateRowApi } from '../../../api';
import {formatValue, isNullOrEmpty } from '../../../utils'


let inputTimer = null;

const CalculationTemplateCategory = ({categoryRow, index, updateCb, moveUpCb, moveDownCb, deleteCb, copyCb}) => {
  const [category, setCategory] = React.useState({
    id:0,
    description:'',
    order:0,
    unitTotal:0.0,
    hoursTotal:0.0,
    workTotal:0.0,
    total:0.0,
    rows:[]
  });

  useEffect(() => {
    setCategory(categoryRow);
  }, [categoryRow])



  const handleCategoryChange = function(e) {
    const {value } = e.target;

    let changedCategory = category;
    if (inputTimer !== null) {
      clearTimeout(inputTimer);
    }
    inputTimer = setTimeout(() => {
      changedCategory.description = value;
      updateCb(changedCategory);
    }, 1000);

    setCategory(category =>({...category, description:value}));
  }


  const handleCategoryAdd = function() {
    addRow();
  }

  const handleCategoryMoveUp = function() {
    moveUpCb(category.id);
  }

  const handleCategoryMoveDown = function() {
    moveDownCb(category.id);
  }

  const handleCategoryCopy = function() {
    copyCb(category.id);
  }

  const handleCategoryDelete = function() {
    deleteCb(category.id);
  }


  // Row handle callbacks
  const handleRowUpdate = function(row) {
    updateRow(row);

    var newRows = category.rows.map((orgRow, index) => {
      if (orgRow.id === row.id) {
        //calculate
        row.unitTotal = row.unitQuantity * row.unitPrice;
        if (isNaN(row.unitTotal)){
          row.unitTotal=0;
        }
        row.workTotal = row.hours * row.hourPrice;
        if (isNaN(row.workTotal)){
          row.workTotal=0;
        }
        row.total = row.unitTotal + row.workTotal;
        if (isNaN(row.total)){
          row.total=0;
        }
        return row;
      }
      return orgRow;
    })

    //setRows(newRows);
    setCategory(category => ({...category, rows:newRows}))
    recalculate(newRows);
    // Check if new row must be added
    checkIfNewRowShouldBeAdded();
  }

  const checkIfNewRowShouldBeAdded = function() {
    var lastRow = category.rows[category.rows.length -1];
    if ( !isNullOrEmpty(lastRow.description) || !isNullOrEmpty(lastRow.unitQuantity) ||
         !isNullOrEmpty(lastRow.unit) || !isNullOrEmpty(lastRow.unit)  || 
         !isNullOrEmpty(lastRow.hours) || !isNullOrEmpty(lastRow.hourPrice)) {
          addRow();
    }
  }



  const handleRowMoveUp = function(rowId) {
    var selectedIndex = 0;
    category.rows.map((row, index) => {
      if (row.id === rowId) {
        selectedIndex=index;
      }
      return row;
    })

    if (selectedIndex === 0) return; // already on top

    let data = [...category.rows];
    var firstOrder = data[selectedIndex-1].order;
    var lastOrder = data[selectedIndex].order;
    let temp = data[selectedIndex-1];
    temp.order = lastOrder;
    data[selectedIndex].order = firstOrder;
    data[selectedIndex-1] = data[selectedIndex];
    data[selectedIndex] = temp;
    //setRows(data);
    setCategory(category => ({...category, rows:data}))
    updateRow(data[selectedIndex]);
    updateRow(data[selectedIndex-1]);
  }

  const handleRowMoveDown = function(rowId) {
    var selectedIndex = category.rows.length-1;
    category.rows.map((row, index) => {
      if (row.id === rowId) {
        selectedIndex=index;
      }
      return row;
    })

    if (selectedIndex === category.rows.length-1) return; // already at the end

    let data = [...category.rows];
    var firstOrder = data[selectedIndex].order;
    var lastOrder = data[selectedIndex+1].order;
    let temp = data[selectedIndex+1];
    temp.order = firstOrder;
    data[selectedIndex].order = lastOrder;
    data[selectedIndex+1] = data[selectedIndex];
    data[selectedIndex] = temp;
    //setRows(data);
    setCategory(category => ({...category, rows:data}))
    updateRow(data[selectedIndex]);
    updateRow(data[selectedIndex+1]);
  }


  const handleRowDelete = function(rowId) {
    CalculationTemplateRowApi.delete(rowId)
    .then (
      (response) => {
        //Recalculate? 
        setCategory(category => ({...category, rows:category.rows.filter(row =>row.id !== rowId)}))
        //setRows(rows.filter(row =>row.simpleCalculationRowId !== rowId));       
        recalculate(category.rows.filter(row =>row.id !== rowId));
      },
      (error) => {
        console.log(error);
      }
    );
  }


  const recalculate = function(rows) {
    let changedCategory = category;
    changedCategory.rows = rows;
    updateCb(changedCategory);
    var unitTotal = 0;
    var hoursTotal = 0;
    var workTotal = 0;
    var total = 0;
    rows.map((row) => {
      unitTotal = unitTotal + row.unitTotal;
      hoursTotal = hoursTotal + row.hours;
      workTotal = workTotal + row.workTotal;
      total = total + row.total;
      return row;
    });

    setCategory(category =>({...category, unitTotal:unitTotal, hoursTotal:hoursTotal, workTotal:workTotal, total:total}))
  }


  const getLastRowOrder = function() {
    var order = 0;
    category.rows.map((row) => {
      if (row.order > order) {
        order = row.order;
      }
      return row;
    });
    return order;
  }


  // Api functions
  const addRow = function() {

    var row = {
      calculationTemplateId:category.id,
      description:'',
      order: getLastRowOrder() + 1
    }

    CalculationTemplateRowApi.add(row)
    .then (
      (response) => {
       //
       var rows = category.rows;
       rows.push(response.data);
       setCategory(category => ({...category, rows:rows}))
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const updateRow = function(row) {
    CalculationTemplateRowApi.update(row)
    .then (
      (response) => {
        //Recalculate?        
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
              elevation={0}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleCategoryAdd}>Legg til underpunkt</MenuItem>
            <Divider />
            <MenuItem onClick={handleCategoryMoveUp}>Flytt opp</MenuItem>
            <MenuItem onClick={handleCategoryMoveDown}>Flytt ned</MenuItem>
            <Divider />            
            <MenuItem onClick={handleCategoryCopy}>Kopier hovedpunkt</MenuItem>
            <Divider />
            <MenuItem onClick={handleCategoryDelete}>Slett hovedpunkt</MenuItem>
          </Menu>
        </div>;

  }

  const getColorClass = function(index) {
    if (index % 2 > 0) {
      return "sc-color-main-odd";
    }
    return "sc-color-main-even"
  }


  let rowsHtml=null;
  if (category.rows) {
    rowsHtml = category.rows.map((row, rowIndex) =>
    {
      return (
        <CalculationTemplateCategoryRow 
          categoryRow={row} 
          index={rowIndex} 
          catIndex={index}
          updateCb={handleRowUpdate} 
          moveUpCb={handleRowMoveUp} 
          moveDownCb={handleRowMoveDown} 
          deleteCb={handleRowDelete} />
      );
    })
  }


  return (
    <>
    <div className="simple-calc-category-wrapper">
      <div className="display-flex">
        <div className={"flex-1 simple-calc-category " + getColorClass(index)}></div>
        <div className={"flex-6 simple-calc-category simple-calc-category-description " + getColorClass(index)}>
          <input className="simple-calc-input" placeholder="Skriv inn navn på kategori" name={"SC" +index} type="text" value={category.description} onChange={(e)=>handleCategoryChange(e)} />
        </div>
        <div className={"flex-1 simple-calc-category align-right " + getColorClass(index)}><div className="simple-calc-category-value">{formatValue(category.unitTotal, 0, ',-', '0,-')}</div></div>
        <div className={"flex-1 simple-calc-category align-right " + getColorClass(index)}><div className="simple-calc-category-value">{formatValue(category.hoursTotal, 0, '', '0')}</div></div>
        <div className={"flex-1 simple-calc-category " + getColorClass(index)}></div>
        <div className={"flex-1 simple-calc-category align-right " + getColorClass(index)}><div className="simple-calc-category-value">{formatValue(category.workTotal, 0, ',-', '0,-')}</div></div>
        <div className={"flex-1 simple-calc-category align-right " + getColorClass(index)}><div className="simple-calc-category-value">{formatValue(category.total, 0, ',-', '0,-')}</div></div>
        <div className="simple-calc-heading-menu"><ActionMenu /></div>
      </div>
      {rowsHtml}
    </div>
    </>
  );

}

export default React.memo(CalculationTemplateCategory);