import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { BuildingDescriptionDetailed, BuildingDescriptionAssessment, BuildingDescriptionCategory, 
  BuildingDescriptionCategoryPicture, BuildingDescriptionBuilding, BuildingDescriptionCheckpoint} 
      from '../containers/Modules/BuildingDescriptionDetailedModule/types'

class BuildingDescriptionDetailedModuleService extends BaseService {
   private static _buildingdescriptiondetailedModuleService: BuildingDescriptionDetailedModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): BuildingDescriptionDetailedModuleService {
     return this._buildingdescriptiondetailedModuleService || (this._buildingdescriptiondetailedModuleService = new this('buildingDescriptionDetailedModule'));
   }
 

   public async getBuildingDescriptionDetailedModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addBuildingDescriptionDetailedModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;
      if (!data){
         this.handleAllErrors(` Data was not provided for addBuildingDescriptionDetailedModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateBuildingDescriptionDetailed(data: BuildingDescriptionDetailed): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateBuildingDescriptionDetailed.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async updateBuildingDescriptionBuilding(data: BuildingDescriptionBuilding): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateBuildingDescriptionBuilding.`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateBuilding',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async deleteBuildingDescriptionBuilding(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Data was not provided for deleteBuildingDescriptionBuilding.`); 
        Promise.reject();
      }
      
      var model= {
        id:id
      }
      await this.$http.post('DeleteBuilding',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async addBuildingDescriptionBuilding(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Data was not provided for addBuildingDescriptionBuilding.`); 
        Promise.reject();
      }
      
      var model= {
        id:id
      }
      await this.$http.post('AddBuilding',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 



    public async updateBuildingDescriptionCategory(data: BuildingDescriptionCategory): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateBuildingDescriptionCategory.`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateCategory',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 


    public async deleteBuildingDescriptionCategory(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Id was not provided for deleteBuildingDescriptionCategory.`); 
        Promise.reject();
      }
      var model= {
        id:id
      }
      await this.$http.post('DeleteCategory',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async addBuildingDescriptionCategory(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Id was not provided for addBuildingDescriptionCategory.`); 
        Promise.reject();
      }
      
      var model= {
        id:id
      }
      await this.$http.post('AddCategory',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async addBuildingDescriptionCategoryType(categoryId, buildingId): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (categoryId <= 0 || buildingId < 0){
        this.handleAllErrors(` Id was not provided for addBuildingDescriptionCategory.`); 
        Promise.reject();
      }
      
      var model= {
        categoryTypeId:categoryId,
        buildingDescriptionDetailedBuildingId: buildingId
      }
      await this.$http.post('AddCategoryType',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 




    public async updateBuildingDescriptionAssessment(data: BuildingDescriptionAssessment): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateBuildingDescriptionAssessment.`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateAssessment',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async addBuildingDescriptionAssessment(id: number): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (id <= 0){
        this.handleAllErrors(` Id was not provided for addBuildingDescriptionAssessment.`); 
        Promise.reject();
      }
      
      var model= {
        id:id
      }
      await this.$http.post('AddAssessment',model, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 



    public async saveBuildingDescriptionCategoryPicture(data: BuildingDescriptionCategoryPicture): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for saveBuildingDescriptionCategoryPicture.`); 
        Promise.reject();
      }
      
      await this.$http.post('SavePicture',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 
    
    public async updateBuildingDescriptionCheckpoint(data: BuildingDescriptionCheckpoint): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateBuildingDescriptionCheckpoint.`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateCheckpoint',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

}

export const BuildingDescriptionDetailedModuleApi = BuildingDescriptionDetailedModuleService.Instance;