import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { PlotInformation} from '../containers/Modules/PlotInformationModule/types'

class PlotInformationModuleService extends BaseService {
   private static _plotinformationModuleService: PlotInformationModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): PlotInformationModuleService {
     return this._plotinformationModuleService || (this._plotinformationModuleService = new this('plotInformationModule'));
   }
 

   public async getPlotInformationModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addPlotInformationModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addPlotInformationModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updatePlotInformation(data: PlotInformation): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updatePlotInformation.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 


    public async addPlotInformationText(data): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addPlotInformationModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('AddText',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }


   public async updatePlotInformationText(data): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
       this.handleAllErrors(` Data was not provided for addPlotInformationModule.`); 
       Promise.reject();
    }
    
    await this.$http.post('UpdateText',data, {withCredentials: true})
    .then((response) => {
       axiosResponse = response;
    }, 
    (error) => {
          this.handleAllErrors(error); 
          throw (error);
    });

    return axiosResponse;
 }


 public async deletePlotInformationText(data): Promise<AxiosResponse> {
  var axiosResponse;

  if (!data){
     this.handleAllErrors(` Data was not provided for addPlotInformationModule.`); 
     Promise.reject();
  }
  
  await this.$http.post('DeleteText',data, {withCredentials: true})
  .then((response) => {
     axiosResponse = response;
  }, 
  (error) => {
        this.handleAllErrors(error); 
        throw (error);
  });

  return axiosResponse;
}





}

export const PlotInformationModuleApi = PlotInformationModuleService.Instance;