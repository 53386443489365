import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import {DescriptionTemplateRow, DescriptionTemplatePicture} from '.';
import { DescriptionTemplateApi } from '../../../api';
import { DescriptionTemplateRowApi } from '../../../api';
import { Description } from '@material-ui/icons';

const keyPressTimer = 1000;
let inputTimer = null;

const DescriptionTemplateMain = ({descriptionRow, handleChangeCb, handleMoveUpCb, handleMoveDownCb, handleDeleteCb}) => {
  const [description, setDescription] = React.useState({
    format:1,
    id: 0,
    title:'',
    rows:[]
  });

  useEffect(() => {
    setDescription(descriptionRow);
  }, [descriptionRow])



  const handleDescriptionChange = function(e) {
    const { name, value } = e.target;
    let changedDescription = description;

    setDescription(description =>({...description, [name]:value}));
    if (name === "title"){
      changedDescription.title = value;
    }

    if (inputTimer !== null){
      clearTimeout(inputTimer);
    }

    inputTimer = setTimeout(() => {
      updateDescription(changedDescription);
    }, keyPressTimer); 
  }

  const updateDescription = function(description) {
    
    DescriptionTemplateApi.update(description)
    .then (
      (response) => {
        handleChangeCb(description);
      },
      (error) => {
        console.log(error);
      }
    );
  }



  const handleDescriptionMoveUp = function() {
    handleMoveUpCb(description.id);
  }

  const handleDescriptionMoveDown = function() {
    handleMoveDownCb(description.id);
  }

  const handleDescriptionDelete = function() {
    handleDeleteCb(description.id);
  }



  const handleDescriptionAddRow = function() {
    var desc = {
      templateDescriptionId:description.id,
      format:1,
      title:''
    }
    
    DescriptionTemplateRowApi.add(desc)
    .then (
      (response) => {
       //
       var rows = description.rows;
       rows.push(response.data);
       setDescription(description => ({...description, rows:rows}))
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleDescriptionAddTemplate = function() {
    
  }


  const handleDescriptionRowChange = function(row) {
    var newDescriptions = description.rows.map((description, index) => {
      if (description.id === row.id) {
        return row;
      }
      return description;
    })
    setDescription(description => ({...description, rows:newDescriptions}));
  }

  const handleDescriptionRowMoveUp = function(rowId) {
    var selectedIndex = 0;
    description.rows.map((row, index) => {
      if (row.id === rowId) {
        selectedIndex=index;
      }
      return row;
    })

    if (selectedIndex === 0) return; // already on top

    let data = [...description.rows];
    var firstOrder = data[selectedIndex-1].order;
    var lastOrder = data[selectedIndex].order;
    let temp = data[selectedIndex-1];
    temp.order = lastOrder;
    data[selectedIndex].order = firstOrder;
    data[selectedIndex-1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setDescription(description => ({...description, rows:data}));
    updateDescriptionRow(data[selectedIndex]);
    updateDescriptionRow(data[selectedIndex-1]);
  }


  const handleDescriptionRowMoveDown = function(rowId) {
    var selectedIndex = description.rows.length-1;
    description.rows.map((row, index) => {
      if (row.id === rowId) {
        selectedIndex=index;
      }
      return row;
    })

    if (selectedIndex === description.rows.length-1) return; // already at the end

    let data = [...description.rows];
    var firstOrder = data[selectedIndex].order;
    var lastOrder = data[selectedIndex+1].order;
    let temp = data[selectedIndex+1];
    temp.order = firstOrder;
    data[selectedIndex].order = lastOrder;
    data[selectedIndex+1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setDescription(description => ({...description, rows:data}));
    updateDescriptionRow(data[selectedIndex]);
    updateDescriptionRow(data[selectedIndex+1]);
  }


  const handleDescriptionRowDelete = function(rowId) {
    DescriptionTemplateRowApi.delete(rowId)
    .then (
      (response) => {
        setDescription(description => ({...description, rows:description.rows.filter(row =>row.id !== rowId)}));
      },
      (error) => {
        console.log(error);
      }
    );
  }


  const updateDescriptionRow = function(row) {
    DescriptionTemplateRowApi.update(row)
    .then (
      (response) => {
        //handleInfoUpdatedCb(info);
      },
      (error) => {
        console.log(error);
      }
    );
  }


  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    if (description.format === 1) {
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleDescriptionMoveUp}>Flytt opp</MenuItem>
            <MenuItem onClick={handleDescriptionMoveDown}>Flytt ned</MenuItem>
            <Divider />
            <MenuItem onClick={handleDescriptionAddRow}>Legg til underpunkt</MenuItem>
            <Divider />
            <MenuItem onClick={handleDescriptionDelete}>Slett hovedpunkt</MenuItem>
          </Menu>
        </div>;
    }
    else {
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleDescriptionMoveUp}>Flytt opp</MenuItem>
            <MenuItem onClick={handleDescriptionMoveDown}>Flytt ned</MenuItem>
            <Divider />
            <MenuItem onClick={handleDescriptionDelete}>Slett hovedpunkt</MenuItem>
          </Menu>
        </div>;
    }
  }


  let descriptionsHtml = null;
  if (description.rows) {
    descriptionsHtml = description.rows.map((row, index) => {
      if (row.format === 1) {
        return <DescriptionTemplateRow 
          key={'SCDR_' + row.id} 
          descriptionRow={row}
          handleChangeCb={handleDescriptionRowChange}
          handleMoveUpCb={handleDescriptionRowMoveUp}
          handleMoveDownCb={handleDescriptionRowMoveDown}
          handleDeleteCb={handleDescriptionRowDelete} />
      }
      if (row.format === 2) {
        return <DescriptionTemplatePicture 
          key={'SCDP_' + row.id} 
          descriptionRow={row}
          handleChangeCb={handleDescriptionRowChange}
          handleDeleteCb={handleDescriptionRowDelete} />
      }
    });
  }


  return (
    <>
      <div key={'SCDM_' + description.id} className="simple-calc-description-wrapper">
        <div className="simple-calc-section-row">
          <div className="simple-calc-description-title bold">Tittel:</div>
          <div className="simple-calc-description-type">
            <input className="form-control bold" name="title" type="text" value={description.title} onChange={handleDescriptionChange}/>
          </div>
          <div className="simple-calc-action-menu" title="Alternativer">
            <ActionMenu />
          </div>   
        </div>
        {descriptionsHtml}
      </div>
    </>
  );

}

export default React.memo(DescriptionTemplateMain);