import React, { Fragment } from 'react';
import { Footer, Navbar, ContactDialog, HeaderMessage } from './components';

const Layout: React.FC = ({ children }) => (
  <Fragment>
    {/* <div className="wrapper"> */}
      <HeaderMessage/>
      <Navbar />
      {children}
      {/* <div className="push"></div> */}
    {/* </div> */}
    <ContactDialog/>
    <Footer />
  </Fragment>
);

export default Layout;