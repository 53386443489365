import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { DocumentApi } from '../../api/document.service';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLink, faArrowLeft  } from '@fortawesome/free-solid-svg-icons'

const Document: React.FC = ()=>{
   const [document,setDocument]= useState({
      content:'',
      heading:'',
      url:''
   });

   const params = useParams();
   const id = (params as any).id;

   useEffect(() => {
      const fetchData = async () => {
         DocumentApi.getDocument(id)
         .then(
         (response) => {    
            setDocument(response.data);
         },
         (error) => {
            console.log(error);
           // history.goBack();
         });
      }

      fetchData();
   }, []);

   var html = parse(document.content);

   return (
      <div className="container ">
         <div className='row mb-2 bk-row-header'>
               <div className='col-lg-10'> <div className=" bk-row-header-title "> {document.heading} </div></div>
               <div className='col-lg-2'> 
               <a href="/documents" className='dashboard-back-btn mx-auto'> 
               <FontAwesomeIcon icon={faArrowLeft} className=" mt-3 me-3"/>
               Hovedmeny </a></div>
            </div>
         <div className='about-container'>
            <div className='row'>
               <div className=" about-content mt-2">
                  {html}
               </div>
            </div>
         </div>    
      </div>
   )
}

export default Document;