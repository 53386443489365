import React from 'react';
import { useState, useEffect, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'
import { HeaderMessageApi } from '../api';
import { actionCreators} from '../store/auth';
import { IApplicationState } from '../store';
import { connect } from 'react-redux';
import { is } from 'date-fns/locale';

type HeaderMessageProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
};

const HeaderMessage: React.FC<HeaderMessageProps> = ({
    isAuthenticated, 
}) => {

    const [headerMessages, setHeaderMessages] = useState([{
        headerMessageId:0,
        messageText:'',
        messageType: 0,
        allowClose:false,
        isClosed: false
    }]);



    useEffect(() => {
        const getDataFromResponse = (response) => response && response.data && response.data ? response.data : null;

        if (isAuthenticated) {
        HeaderMessageApi.get()
            .then((response) => {
                const headerMessageData = getDataFromResponse(response);
                setHeaderMessages(getMessageClosedData(headerMessageData));
            },
            (error) => {
                console.log(error);
            })
        }
    }, [isAuthenticated]);

    const getMessageClosedData = (messages) => {       
        if(messages != null){
            var array = messages.map((message, index) => {
                var isClosed = sessionStorage.getItem('Header_Message_' + message.headerMessageId);
                if (isClosed != undefined && isClosed != null) {
                    message.isClosed = true;
                }
                return message;
            });
            return array;
        }
    }

    const messageBackground = (message) => {
        switch (message.messageType) {
            case 0:
                return 'bg-update'
            case 1:
                return 'bg-message'
        }
    }

    const messageIcon = (message) => {
        switch (message.messageType) {
            case 0:
                return faScrewdriverWrench
            case 1:
                return faInfoCircle
        }
    }

    const closeIcon = (message) => {
        switch (message.messageType) {
            case 0:
                return null
            case 1:
                return faTimes
        }
    }

    const closeMessage = (message) => {
        var newMessages = headerMessages.map((messageItem,index) => {
            if(messageItem.headerMessageId == message.headerMessageId){
                messageItem.isClosed = true;
            }
            return messageItem;
        })
        setHeaderMessages(newMessages);
        sessionStorage.setItem('Header_Message_' + message.headerMessageId, 'true')        
    }

    var messagesHtml = null;
    if (headerMessages) {
        messagesHtml = headerMessages.map((message, index) => {
            if (message.messageText != '' && message.messageText != null && !message.isClosed) 
            {
                return ( 
                    <div className={"header-message-container" + ' ' + messageBackground(message)}>
                        <div className='header-message-inner-container'>
                            <FontAwesomeIcon className='header-message-info-icon' icon={messageIcon(message)} />
                            {message.messageText}
                        </div>
                        <div onClick={() =>closeMessage(message)}><FontAwesomeIcon className='header-message-close-icon' icon={closeIcon(message)} /></div>
                    </div>
                
                )
            }
       });
    }


    return <>
        {messagesHtml}
    </>
};

const mapStateToProps = (state: IApplicationState) => ({
    isAuthenticated: state.auth.isAuthenticated,
  });
  
export default connect(mapStateToProps, actionCreators)(HeaderMessage);

