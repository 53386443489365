import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class ResidentService extends BaseService {
  private static _residentService: ResidentService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): ResidentService {
    return this._residentService || (this._residentService = new this('resident'));
  }

  public async getResidents(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('get/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async addResident(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('post', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async updateResident(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.put('put', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async deleteResident(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('delete/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }




}



export const ResidentApi = ResidentService.Instance;