import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom'
import { BkButton } from '../../../components';
import { ContactButton } from '../../../components';
import { ServiceApi } from '../../../api/service.service';


const NewServiceDialog = ({open, handleNewServiceCb, showReports}) => {
   const [reports,setReports]= useState([]);
   const [serviceGroups,setServiceGroups]= useState([]);
   const [calculationTypes,setCalculationTypes]= useState([]);
   const [otherCalculations,setOtherCalculations]= useState([]);
   const [privateCalculations,setPrivateCalculations]= useState([]);
   const [farmingCalculations,setFarmingCalculations]= useState([]);
   const [selectedServiceGroup, setSelectedServiceGroup] = useState(0);
   const [selectedCalculationType, setSelectedCalculationType] = useState(0);
   const [selectedService, setSelectedService] = useState(0);
   const [selectedType, setSelectedType] = useState(0);
   const [selectedDetail, setSelectedDetail] = useState(0);

   useEffect(() => {
      fetchData();
   }, [])


   const handleClose = function() {
      handleNewServiceCb(null);
      setSelectedService(0)
      setSelectedServiceGroup(0)
      setSelectedCalculationType(0)
   }

   const handleSaveAndClose = function() {
      handleNewServiceCb(selectedService, selectedType);
      setSelectedService(0)
      setSelectedServiceGroup(0)
      setSelectedCalculationType(0);
   }

   const handleServiceGroupClick = function(id) {
      setSelectedCalculationType(0);
      setSelectedService(0)
      setSelectedServiceGroup(id);
   }
   const handleCalculationTypeClick = function(id) {
      setSelectedService(0)
      setSelectedCalculationType(id);
   }

   const showServices = function(id) {
      switch (id){
         case 1:
            return reportsHtml
         case 2:
            return calculationTypesHtml
         case 3:
            return otherCalculationsHtml
      }
   }
   const showCalculations = function(id) {
      switch (id){
         case 1:
            return privateCalculationsHtml
         case 3:
            return farmingCalculationsHtml
      }
   }

   const handleServiceClick = function(id, type) {
      setSelectedService(id);
      setSelectedType(type);
   }
  
   const fetchData = async () => {
      ServiceApi.GetServices()
         .then(
            (response) => {
               setServiceGroups(response.data.serviceGroups);
               setCalculationTypes(response.data.calculationTypes);
               setReports(response.data.reports);
               setOtherCalculations(response.data.otherCalculations);
               setPrivateCalculations(response.data.privateCalculations);
               setFarmingCalculations(response.data.farmingCalculations);
            },
            (error) => {
               console.log(error);
            });
   }
   
   let serviceGroupsHtml = null;

   if (serviceGroups.length > 0){
      serviceGroupsHtml = serviceGroups.map((serviceGroup, index) =>   
         <div className={['new-service-button', (selectedServiceGroup === serviceGroup.id)?'new-service-button-selected':''].join(' ')} onClick={()=>handleServiceGroupClick(serviceGroup.id)}>{serviceGroup.name}</div>
      );
   }
   let calculationTypesHtml = null;

   if (calculationTypes.length > 0){
      calculationTypesHtml = calculationTypes.map((calculationType, index) =>   
         <div className={['new-service-button', (selectedCalculationType === calculationType.id)?'new-service-button-selected':''].join(' ')} onClick={()=>handleCalculationTypeClick(calculationType.id)}>{calculationType.name}</div>
      );
   }
   let reportsHtml = null;

   if (reports.length > 0){
      reportsHtml = reports.map((report, index) =>   
         <div className={['new-service-button', (selectedService === report.id)?'new-service-button-selected':''].join(' ')} onClick={()=>handleServiceClick(report.id,1)}>{report.name}</div>
      );
   }

   let privateCalculationsHtml = null;

   if (privateCalculations.length > 0){
      privateCalculationsHtml = privateCalculations.map((privateCalculation, index) =>   
         <div className={['new-service-button', (selectedService === privateCalculation.id)?'new-service-button-selected':''].join(' ')} onClick={()=>handleServiceClick(privateCalculation.id,2)}>{privateCalculation.name}</div>
      );
   }

   let farmingCalculationsHtml = null;

   if (farmingCalculations.length > 0){
      farmingCalculationsHtml = farmingCalculations.map((farmingCalculation, index) =>   
         <div className={['new-service-button', (selectedService === farmingCalculation.id)?'new-service-button-selected':''].join(' ')} onClick={()=>handleServiceClick(farmingCalculation.id,2)}>{farmingCalculation.name}</div>
      );
   }

   let otherCalculationsHtml = null;

   if (otherCalculations.length > 0){
      otherCalculationsHtml = otherCalculations.map((otherCalculation, index) =>   
         <div className={['new-service-button',(selectedService === otherCalculation.id)?'new-service-button-selected':''].join(' ')} onClick={()=>handleServiceClick(otherCalculation.id,3)}>{otherCalculation.name}</div>
      );
   }



  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth = {'md'} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialog-header">Legg til tjeneste</DialogTitle>
        <DialogContent>
           <div className="new-service-container">
              <div className="new-service-column">
                  <div>Velg tjeneste:</div>
                     {serviceGroupsHtml}
              </div>
              <div className="new-service-column">
                  <div>Velg type:</div>
                     {showServices(selectedServiceGroup)}
              </div>
              <div className="new-service-column">
                  <div>Detaljer:</div>
                     {showCalculations(selectedCalculationType)}
              </div>
           </div>
        </DialogContent>
        <DialogActions>
          <ContactButton caption='Avbryt' onClickHandler={handleClose}/>
          <BkButton caption='OK'  onClickHandler={handleSaveAndClose} />
        </DialogActions>
      </Dialog>

    </>
  )
}

export default React.memo(NewServiceDialog);