import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { BkButton} from '.';
function ProjectLink(props) {

   const history = useHistory();

   const handleProjectClick = function(id) {
      history.push('/project/' + props.id);
   }

   return (<div className="bk-calculation-project-link">
      <BkButton caption={props.address + ', ' + props.zipCode + ' ' + props.city} onClickHandler={()=>handleProjectClick(props.id)}/>
      </div>
      // <span className="bk-calculation-project-info" title="Gå til prosjekt" onClick={handleProjectClick}>
      //    {props.address}, {props.zipCode} {props.city} 
      //    <FontAwesomeIcon icon={faArrowAltCircleLeft} className="stroke-current text-1xs margin-left-1em" />
      // </span>
   )
}
  
export default ProjectLink;