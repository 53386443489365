import React from 'react';
import { useHistory } from 'react-router-dom'
import {TextField,Button} from '@material-ui/core'
import { HomeApi } from '../../api';
import { RoutesConfig } from '../../config/routes.config';
import { ContactButton } from '../../components';
import { BkButton } from '../../components';
import Form from 'react-bootstrap/Form';

const Contact: React.FC = ()=>{
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    title: '',
    message: '',
  });
  const history = useHistory();

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = () => {
    //event.preventDefault();
    HomeApi.sendMail(values.name, values.email, values.title, values.message)
      .then(() => {
        history.push(RoutesConfig.ContactConfirmation.path)
      });
  }

   return (
      <div className="container">
         <div className="row "> 
            <div className="col-md-8 contact-container col-centered mt-4 shadow">  
            <div className='mx-4 my-3'>
            <Form>
               <Form.Group className="mb-2"  controlId="exampleForm.ControlInput1">
               <Form.Label className='contact-title'>Navn</Form.Label>
               <Form.Control onChange={handleChange('name')} type="text" placeholder="Navn Etternavn" className='contact-content'/>
               </Form.Group>

               <Form.Group className="mb-2"  controlId="exampleForm.ControlInput1">
               <Form.Label className='contact-title'>E-post</Form.Label>
               <Form.Control onChange={handleChange('email')} type="email" placeholder="navn@eksempel.no" className='contact-content'/>
               </Form.Group>

               <Form.Group className="mb-2"  controlId="exampleForm.ControlInput1">
               <Form.Label className='contact-title'>Emne</Form.Label>
               <Form.Control onChange={handleChange('title')} type="text" placeholder='Type oppdrag' className='contact-content'/>
               </Form.Group>

               <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
               <Form.Label className='contact-title'>Melding</Form.Label>
               <Form.Control onChange={handleChange('message')} as="textarea" rows={3} placeholder="Fritekst" className='contact-content'/>
               </Form.Group>

               <div className="row">
               <div className="col-auto col-centered my-2">
               <button className="register-button" onClick={handleSubmit}>Send e-post</button>
               </div>
               </div>
            </Form>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Contact;