import React, {useState, useEffect} from 'react';
import { version } from '../../../../package.json';
import { actionCreators} from '../../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../store';
import { useHistory } from 'react-router-dom'
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMultiply  } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { AdminMenuData } from './AdminMenuData'
import {AdminApi} from '../../../api';
import eventBus from "../../../containers/Modules/EventBus";


type AdminMenuProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly isAdmin: boolean;
  readonly userName: string;
  activeMenuItem?:string;
  activeSubIndex?:number;
};

const AdminMenu: React.FC<AdminMenuProps> = ({ 
   isAuthenticated, 
   isAdmin,
   logoutUser,
   userName,
   activeMenuItem,
   activeSubIndex
 }) => {

   const [collapsed, setCollapsed] = useState(true);
   const [subOpen, setSubOpen] = useState(false);
   const [messageNumber, setMessageNumber] = useState(0);
   const [updatesNumber, setUpdatesNumber] = useState(0);
   const [invoiceNumber, setInvoiceNumber] = useState(0);
   const [toggle, setToggle] = useState(false)

   const history = useHistory();


   useEffect(() => {
      eventBus.on("updateMessagesSeen", (data) =>
      {
         AdminApi.getUpdatesCount(userName)
         .then(
            (response) => {   
               setUpdatesNumber(response.data);
            },
            (error) => {
               console.log(error);
            });
      });
     //Get number to messages
      AdminApi.getMessageCount()
      .then(
         (response) => {    
            setMessageNumber(response.data);
         },
         (error) => {
            console.log(error);
         });

      AdminApi.getInvoiceCount()
      .then(
         (response) => {    
            setInvoiceNumber(response.data);
         },
         (error) => {
            console.log(error);
         });

      AdminApi.getUpdatesCount(userName)
      .then(
         (response) => {   
            setUpdatesNumber(response.data);
         },
         (error) => {
            console.log(error);
         });

   }, [activeMenuItem])

   if(!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if(!isAdmin) {
      history.push("/projects");
   }

  
   const getIcon = (title, icon, displayNumber) => {
      if (displayNumber) {
         if (title == "Oppdateringer"){ 
            return <div className="admin-menu-icon-with-number">
               <FontAwesomeIcon icon={icon} className="admin-menu-icon"></FontAwesomeIcon>
            { updatesNumber > 0
            ? <div className="admin-menu-icon-number">{updatesNumber}</div> : ''}
         </div>
         }
         if (title == "Meldinger"){
            return <div className="admin-menu-icon-with-number">
               <FontAwesomeIcon icon={icon} className="admin-menu-icon"></FontAwesomeIcon>
            { messageNumber> 0 ? <div className="admin-menu-icon-number">{messageNumber}</div> : ''}
         </div>
         }
         if (title == "Faktura"){
            return <div className="admin-menu-icon-with-number">
               <FontAwesomeIcon icon={icon} className="admin-menu-icon"></FontAwesomeIcon>
           { invoiceNumber> 0 ? <div className="admin-menu-icon-number">{invoiceNumber}</div> : ''}
         </div>
         }
         
         
      }
      else {
         return <FontAwesomeIcon icon={icon} className="admin-menu-icon"></FontAwesomeIcon>
      }
   }
   

   const onOpenChange=() => {
      setSubOpen(!subOpen);
   }

   const handleMouseEnter = () => {
      setCollapsed(false);
   }

   const handleMouseLeave = () => {
     
      setCollapsed(true);
   }

   const handleClickOpen = () => {
      setToggle(true)
      setCollapsed(false)
    };
   const handleClickClose = () => {
      setToggle(false)
      setCollapsed(true)
    };

   return (
      <>
      {/*<div className=' admin-sidebar-btn mt-2'> <FontAwesomeIcon icon={faBars} onClick={handleClickOpen} className="fa-2x btn-burger mx-1"/></div>*/}
      <div>
        <ProSidebar collapsed={collapsed} breakPoint="sm" toggled={toggle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <SidebarContent>
            <div className='me-4 admin-sidebar-close-btn'><FontAwesomeIcon icon={faMultiply} onClick={handleClickClose} className="fa-2x mt-3 ms-2"/></div>
               <Menu iconShape="square">
               {AdminMenuData.map((item, index) => {
                  if (item.isDeveloperPage == true && !userName.startsWith('Bjørn')) {
                     return;
                  } //Skal dette være her? -K
                  if (window.innerWidth < 576){
                     if (item.title == "Dashboard" || item.title == "Kunder" || item.title == "Meldinger"){
                        if (item.isSubMenu == false) {
                           return <MenuItem className="admin-menu-text" icon={getIcon(item.title, item.icon, item.displayNumber)} active={activeMenuItem == item.title}>{item.title} <Link to={item.linkTo} /></MenuItem>
                        }
                        else {
                           return <> 
                              <SubMenu className="admin-menu-text" title={item.title} icon={getIcon(item.title,item.icon, item.displayNumber)} defaultOpen={activeMenuItem == item.title}>
                              {item.subMenus.map((sub,subIndex) => {
                                 return <MenuItem className="admin-menu-text" active={activeSubIndex == subIndex}>{sub.title} <Link to={sub.linkTo} /></MenuItem>
                              })}
                              </SubMenu>
                           </>
                        }
                     }
                    
                  }
                  else{
                     if (item.isSubMenu == false) {
                        return <MenuItem className="admin-menu-text" icon={getIcon(item.title,item.icon, item.displayNumber)} active={activeMenuItem == item.title}>{item.title} <Link to={item.linkTo} /></MenuItem>
                     }
                     else {
                        return <> 
                           <SubMenu className="admin-menu-text" title={item.title} icon={getIcon(item.title,item.icon, item.displayNumber)} defaultOpen={activeMenuItem == item.title}>
                           {item.subMenus.map((sub,subIndex) => {
                              return <MenuItem className="admin-menu-text" active={activeSubIndex == subIndex}>{sub.title} <Link to={sub.linkTo} /></MenuItem>
                           })}
                           </SubMenu>
                        </>
                     }
                  }
                 
               })}
               </Menu>
            </SidebarContent>
         </ProSidebar>
         </div>
      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin,
   userName: state.auth.userName
 });
 
 export default connect(mapStateToProps, actionCreators)(AdminMenu);