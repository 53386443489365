import React, {useEffect, useCallback, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import {useDropzone} from 'react-dropzone'
import { DescriptionTemplateRowApi, SimpleCalculationApi } from '../../../api';

const keyPressTimer = 1000;
let inputTimer = null;

const SimpleCalcDescriptionPicture = ({descriptionRow, handleChangeCb, handleDeleteCb }) => {
   const [description, setDescription] = React.useState({
      id:0,
      title:'',
      text:'',
      imageUrl:''
   });



   // Add a ref so that its possible to access the data in a callback
   const stateRef = useRef(description);
   stateRef.current = description;

   const id=descriptionRow.id;

   useEffect(() => {
      setDescription(descriptionRow);
   }, [descriptionRow])

   const handleChange = function(e) {
      const { name, value } = e.target;
      let changedDescription = description;
  
      changedDescription.text = value;
      setDescription(description =>({...description, [name]:value}));
  
      if (inputTimer !== null){
        clearTimeout(inputTimer);
      }
  
      inputTimer = setTimeout(() => {
        updateDesriptionRow(changedDescription);
      }, keyPressTimer); 
   }

   const updateDesriptionRow = function(description) {
    
    DescriptionTemplateRowApi.update(description)
      .then (
        (response) => {
          handleChangeCb(description);
        },
        (error) => {
          console.log(error);
        }
      );
    }


   const handleChangePicture = function() {
      
   }

   const handleDeletePicture = function() {
      handleDeleteCb(description.id);
   }  


   const onDrop = useCallback(acceptedFiles => {
      var data = new FormData();
      data.append("simpleCalculationDescriptionRowid", id);

      if (acceptedFiles.length > 0) {
        data.append("file", acceptedFiles[0]);
      } else {
        return;
      }
      SimpleCalculationApi.upload(data)
     .then(
       (response) => {
         setDescription({...stateRef.current, imageUrl:response.data});
       },
       (error) => {
         console.log(error);
       }
     )
   }, []);

   const {getRootProps, getInputProps} = useDropzone({onDrop})


   const getPicture = function(imageUrl) {
    if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
      return (
        <div className="large-image-frame" title="Klikk for å endre, eller dra inn nytt bilde" {...getRootProps()}>
          <input {...getInputProps()} />
          <img src={+"/upload/" + imageUrl} />
        </div>
      )
    }
    else {
      return ( 
        <div className="simple-calc-description-upload-frame large" {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="frame-header">Dra og slipp</div>
          <div className="frame-text">eller klikk her for å laste opp bilde</div>
        </div>);
    }
   }

   function ActionMenu(props) {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
   
      const handleClick = (event) => {
         setAnchorEl(event.currentTarget);
      };
   
      const handleClose = () => {
         setAnchorEl(null);
      };
      
         return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
            <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
            <Menu
               elevation={0}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
               }}
               id="fade-menu"
               anchorEl={anchorEl}
               keepMounted
               open={open}
               onClose={handleClose}
               TransitionComponent={Fade}
               className="navbar-dropdown-menu"
            >
               <MenuItem onClick={handleDeletePicture}>Slett bilde</MenuItem>
               <MenuItem onClick={handleChangePicture}>Endre bilde</MenuItem>

            </Menu>
         </div>;
   }

  return (
    <>
      <div className="simple-calc-section-row">
        <div className="simple-calc-description-title"></div>
        <div className="simple-calc-description-type">
         {getPicture(description.imageUrl)}
        </div>
        <div className="simple-calc-action-menu">
        </div>
      </div>
      <div className="simple-calc-section-row">
        <div className="simple-calc-description-title"></div>
        <div className="simple-calc-description-type">
          <textarea className="form-control big-text-area" name="text" rows={3} value={description.text} onChange={handleChange}/>
        </div>
        <div className="simple-calc-action-menu">
        </div>
      </div>
    </>
  );

}
SimpleCalcDescriptionPicture.displayName = 'Bilde';

export default React.memo(SimpleCalcDescriptionPicture);