import React from 'react';



const Header: React.FC = () => (
   <div className="">
      <div>
         <a href="/">
            <img className='navbar-logo_placeholder my-3 ms-2' src="\Content\images\navbar\ByggekostLogo_blue.png" alt='byggekost-logo'/>
         </a>
      </div>
  </div>
);

export default Header;