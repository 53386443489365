import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators} from '../../store/auth';
import { PlotApi, ProjectApi } from '../../api';
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router-dom";
import {PlotGroup, PlotSum} from './child-components'
import {ProjectLink, SectionHeader} from '../../components'

type PlotProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly role: number;
   readonly userName: string;
};


const keyPressTimer = 1000;
let descriptionTimer = null;

const Plot: React.FC<PlotProps> = ({ 
   isAuthenticated, 
   userName, 
   role
}) => { 
   const [project, setProject] = useState({
      address: {
         address:'',
         zipCode:'',
         city:''
      }
   });
   const [groups, setGroups] = useState([]);
   const [plot,setPlot]= useState({
      projectId:0,
      calculationId:0,
      description: '',
      area:0,
      rawCost:0,
      totalCost:0,
   });

   const params = useParams();
   const calculationId = (params as any).id;
   const history = useHistory();

   let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));

   if(user_data === null || user_data.length === 0)
   {
      actionCreators.logoutUser();
      history.push("/login");  
   }

   useEffect(() => {
      const fetchData = async () => {
         PlotApi.getPlot(calculationId)
         .then(
         (response) => {
            setPlot(response.data);
            setGroups(response.data.groups);
            getProject(response.data.projectId);
         },
         (error) => {
            console.log(error);
            actionCreators.logoutUser();
            history.push("/login");        
         });
      }
      fetchData();
      window.scrollTo(0, 0)
   }, []);


   const getProject = function(projectId) {
      ProjectApi.getProject(projectId)
      .then(
      (response) => {            
         setProject(response.data.data);
      },
      (error) => {
         console.log(error);
         actionCreators.logoutUser();
         history.push("/login");     
      }); 
   }


   const handleDescriptionChange = function(e) {
      const { value } = e.target;

      setPlot(plot => ({ ...plot, description: value }));

      if (descriptionTimer !== null){
      clearTimeout(descriptionTimer);
      }
      descriptionTimer = setTimeout(() => {
      delayedDescriptionUpdate(plot.calculationId, value);
      }, keyPressTimer);

   }

   const delayedDescriptionUpdate = function(calculationId, description) {
      var data = {
      calculationId : parseInt(calculationId),
      description: description
      };
      PlotApi.updateDescription(data)
      .then (
      (response) => {
      },
      (error) => {
         console.log(error);
      }
      );
   }

   const checkInputValue = function(value) {
      if (value === "")
         return 0;
      let intVal = parseInt(value);
      if (isNaN(intVal) || intVal < 0) {
         //Invalid input
         return NaN;
      }
      return intVal;
   }

   
   const handlePrintCalculationClick = function() {

      PlotApi.getPdf(calculationId).then (
         (response) => {
         },
         (error) => {
         console.log(error);
         }
      );

   }


   const handlePlotGroupChange = function() {
      // property has changed. Get the updatet price
      PlotApi.getCost(calculationId).then (
         (response) => {
            setPlot(plot => ({ ...plot, rawCost: response.data.rawCost }));
            setPlot(plot => ({ ...plot, totalCost: response.data.totalCost }));
         },
         (error) => {
            console.log(error);
         }
      );
   }

   const handleProjectClick = function() {
      history.push('/project/' + plot.projectId);
   }

   let groupsHtml = null;
   if (groups) {
      groupsHtml = groups.map((group, index) =>
      {
         return (
         <PlotGroup calculationId={calculationId} group={group} onChanged={handlePlotGroupChange} />
         )
      })
   }

   return (
      <div className="container">
          <div className="row mt-2 mb-3">
              <div className='col-md-8'>
                <div className="bk-row-header bk-row-header-title">
                 Byggekostnader Tomt
                </div>
              </div>

              <div className='col-md-3 mt-3'> 
                <ProjectLink id={plot.projectId} address={project.address.address} zipCode={project.address.zipCode} city={project.address.city}/>
                </div> 

              <div className='col-md-1 mt-3'>
              <img className="bk-header-icon-printer" src="/Content/images/design/icon-print.svg" onClick={handlePrintCalculationClick}/>
                  </div> 
            </div>

            <div className='report-content'>

            <div className="row mt-2">
               <div className="col-md-1 ms-4">
                  <div className="bk-edit-comment">Merknad:</div>
                  </div>
                <div className="col-md-5">
                  <input className="form-control bk-edit-comment-input" type="text" value={plot.description} onChange={handleDescriptionChange}/>
               </div>
            </div>

            <div className="bk-page-section">
               <SectionHeader title={'Sammendrag'}></SectionHeader>
               <div className="bk-section">
                  <PlotSum rawCost={plot.rawCost} totalCost={plot.totalCost} ></PlotSum>
               </div>
            </div>

            <div className="bk-page-section">
               <SectionHeader title={'Byggekostnader Tomt'}></SectionHeader>    
               <div className="bk-section">
                  {groupsHtml}
               </div>
            </div>

         </div>
      </div>
   )
}

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(Plot);