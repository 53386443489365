import React, {useEffect} from 'react';
import {SimpleCalcDescriptionRow, SimpleCalcDescriptionPicture} from '.';
import { SimpleCalculationApi } from '../../../api';
import { SectionHeader, ActionMenu } from '../../../components';

const keyPressTimer = 1000;
let inputTimer = null;

const SimpleCalcDescriptionMain = ({descriptionRow, handleChangeCb, handleMoveUpCb, handleMoveDownCb, handleDeleteCb}) => {
  const [description, setDescription] = React.useState({
    format:0,
    simpleCalculationDescriptionId: 0,
    title:'',
    rows:[]
  });

  const [isLoading, setIsLoading] = React.useState(true);

  
  useEffect(() => {
    setDescription(descriptionRow);
    setIsLoading(false);
  }, [descriptionRow])

  useEffect(() => {
    if (!isLoading) {
      handleChangeCb(description);
    }
    
  }, [description])



  const handleDescriptionChange = function(e) {
    const { name, value } = e.target;
    let changedDescription = description;

    setDescription(description =>({...description, [name]:value}));
    if (name === "title"){
      changedDescription.title = value;
    }

    if (inputTimer !== null){
      clearTimeout(inputTimer);
    }

    inputTimer = setTimeout(() => {
      updateDescription(changedDescription);
    }, keyPressTimer); 
  }

  const updateDescription = function(description) {
    
    SimpleCalculationApi.updateDescription(description)
    .then (
      (response) => {
        handleChangeCb(description);
      },
      (error) => {
        console.log(error);
      }
    );
  }



  const handleDescriptionMoveUp = function() {
    handleMoveUpCb(description.simpleCalculationDescriptionId);
  }

  const handleDescriptionMoveDown = function() {
    handleMoveDownCb(description.simpleCalculationDescriptionId);
  }

  const handleDescriptionDelete = function() {
    handleDeleteCb(description.simpleCalculationDescriptionId);
  }



  const handleDescriptionAddRow = function() {
    SimpleCalculationApi.getNewDescriptionRow(description.simpleCalculationDescriptionId)
    .then (
      (response) => {
       //
       var rows = description.rows;
       rows.push(response.data);
       setDescription(description => ({...description, rows:rows}))
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleDescriptionAddTemplate = function() {
    
  }




  const handleDescriptionRowChange = function(row) {
    var newDescriptions = description.rows.map((description, index) => {
      if (description.simpleCalculationDescriptionRowId === row.simpleCalculationDescriptionRowId) {
        return row;
      }
      return description;
    })
    setDescription(description => ({...description, rows:newDescriptions}));
  }

  const handleDescriptionRowMoveUp = function(rowId) {
    var selectedIndex = 0;
    description.rows.map((row, index) => {
      if (row.simpleCalculationDescriptionRowId === rowId) {
        selectedIndex=index;
      }
      return row;
    })

    if (selectedIndex === 0) return; // already on top

    let data = [...description.rows];
    var firstOrder = data[selectedIndex-1].order;
    var lastOrder = data[selectedIndex].order;
    let temp = data[selectedIndex-1];
    temp.order = lastOrder;
    data[selectedIndex].order = firstOrder;
    data[selectedIndex-1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setDescription(description => ({...description, rows:data}));
    updateDescriptionRow(data[selectedIndex]);
    updateDescriptionRow(data[selectedIndex-1]);
  }


  const handleDescriptionRowMoveDown = function(rowId) {
    var selectedIndex = description.rows.length-1;
    description.rows.map((row, index) => {
      if (row.simpleCalculationDescriptionRowId === rowId) {
        selectedIndex=index;
      }
      return row;
    })

    if (selectedIndex === description.rows.length-1) return; // already at the end

    let data = [...description.rows];
    var firstOrder = data[selectedIndex].order;
    var lastOrder = data[selectedIndex+1].order;
    let temp = data[selectedIndex+1];
    temp.order = firstOrder;
    data[selectedIndex].order = lastOrder;
    data[selectedIndex+1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setDescription(description => ({...description, rows:data}));
    updateDescriptionRow(data[selectedIndex]);
    updateDescriptionRow(data[selectedIndex+1]);
  }


  const handleDescriptionRowDelete = function(rowId) {
    SimpleCalculationApi.deleteDescriptionRow(rowId)
    .then (
      (response) => {
        setDescription(description => ({...description, rows:description.rows.filter(row =>row.simpleCalculationDescriptionRowId !== rowId)}));
      },
      (error) => {
        console.log(error);
      }
    );
  }


  const updateDescriptionRow = function(row) {
    SimpleCalculationApi.updateDescriptionRow(row)
    .then (
      (response) => {
        //handleInfoUpdatedCb(info);
        //handleChangeCb(description);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  let actionMenuHtml = null;
  if (description.format === 1) {
    actionMenuHtml = (
      <ActionMenu
            horizontalPos={'center'}
            menuItems={[
               { func: handleDescriptionMoveUp, name: 'Flytt opp', funcArgs: null},
               { func: handleDescriptionMoveDown, name: 'Flytt ned', funcArgs: null},
               { type: 'divider', funcArgs: null},
               { func: handleDescriptionAddRow, name: 'Legg til underpunkt', funcArgs: null},
               { func: handleDescriptionAddTemplate, name: 'Opprett i malbank', funcArgs: null},
               { type: 'divider', funcArgs: null},
               { func: handleDescriptionDelete, name: 'Slett hovedpunkt', funcArgs: null},
              ]} />
   );
  }
  else {
    actionMenuHtml = (
      <ActionMenu
            horizontalPos={'center'}
            menuItems={[
              { func: handleDescriptionMoveUp, name: 'Flytt opp', funcArgs: null},
              { func: handleDescriptionMoveDown, name: 'Flytt ned', funcArgs: null},
              { type: 'divider', funcArgs: null},
              { func: handleDescriptionDelete, name: 'Slett hovedpunkt', funcArgs: null},
             ]} />
   );
  }


  let descriptionsHtml = null;
  if (description.rows) {
    descriptionsHtml = description.rows.map((row, index) => {
      if (row.format === 1) {
        return <SimpleCalcDescriptionRow 
          key={'SCDR_' + row.simpleCalculationDescriptionRowId} 
          descriptionRow={row}
          handleChangeCb={handleDescriptionRowChange}
          handleMoveUpCb={handleDescriptionRowMoveUp}
          handleMoveDownCb={handleDescriptionRowMoveDown}
          handleDeleteCb={handleDescriptionRowDelete} />
      }
      if (row.format === 2) {
        return <SimpleCalcDescriptionPicture 
          key={'SCDP_' + row.simpleCalculationDescriptionRowId} 
          descriptionRow={row}
          handleChangeCb={handleDescriptionRowChange}
          handleDeleteCb={handleDescriptionRowDelete} />
      }
    });
  }

  let inputHtml = ( <input className="form-control " name="title" placeholder="Skriv inn tittel" type="text" value={description.title} onChange={handleDescriptionChange}/>)

  return (
    <>
      <div key={'SCDM_' + description.simpleCalculationDescriptionId} className="simple-calc-description-wrapper">
        <SectionHeader title={'Hovedpunkt'} input={inputHtml} menu={actionMenuHtml}></SectionHeader>
        <div className='content-frame'>
        {descriptionsHtml}
      </div>
      </div>
    </>
  );

}

export default React.memo(SimpleCalcDescriptionMain);