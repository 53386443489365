import React from 'react';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import Button from "@material-ui/core/Button";
import {formatDateTime} from '../../../utils';

const SubscriberMessageRow = ({message}) => {

   const history = useHistory();


   const handleOpenCustomerCard = () => {
      history.push("/customer/" + message.userId)
   }


   return (
      <>
         <div className ="subscription-row">
            <div className=""><FontAwesomeIcon icon={faCircleExclamation} className="message-icon color-red mx-2" /> {message.name}</div>
            <div className="">{message.email}</div>
            <div className="">{formatDateTime(message.acceptedDate)}</div>
            <div className="">
               <div onClick={handleOpenCustomerCard} className="message-button">Kundekort</div>
            </div> 
         </div>

      </>
   )
}

export default React.memo(SubscriberMessageRow);
