import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RoutesConfig } from '../../config/routes.config';
import { actionCreators } from '../../store/auth';
import { TextField, Select, MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { AuthApi } from '../../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';
import Form from 'react-bootstrap/Form';
import { ContactButton } from '../../components';

const SignupSchema = Yup.object().shape({
   firstName: Yup.string().required('Må fylles ut'),
   lastName: Yup.string().required('Må fylles ut'),
   email: Yup.string().email('Ugyldig epost adresse').required('Må fylles ut'),
   company: Yup.string().required('Må fylles ut'),
   roleId: Yup.number().required('Må fylles ut'),
   department: Yup.string(),
   address: Yup.string().required('Må fylles ut'),
   zipCode: Yup.string().required('Må fylles ut'),
   city: Yup.string().required('Må fylles ut'),
   phoneNumber: Yup.string().required('Må fylles ut'),
   password: Yup.string()
      .min(4, 'Minimum 4 karakterer')
      .required('Må fylles ut'),
   confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passordene må matche')
      .required('Må fylles ut'),
});



const Register: React.FC = () => {

   const history = useHistory();
   const toastIdRef = useRef<string | number>('');

   const formik = useFormik({
      initialValues: {
         roleId: 1,
         company: '',
         department: '',
         address: '',
         zipCode: '',
         city: '',
         firstName: '',
         lastName: '',
         phoneNumber: '',
         email: '',
         password: '',
         confirmPassword: '',
      },
      validationSchema: SignupSchema,
      onSubmit: (values) => {
         var dataToSend = {
            roleId: values.roleId.toString(),
            company: values.company,
            department: values.department,
            address: values.address,
            zipCode: values.zipCode,
            city: values.city,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            email: values.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
         }

         AuthApi.Register(dataToSend)
            .then(
               (response) => {
                  history.push(RoutesConfig.RegisterConfirmation.path)
               },
               (error) => {
                  var msg = "Registrering feilet";
                  if (error.message != undefined) {
                     msg = error.message;
                  }

                  if (!toast.isActive(toastIdRef.current)) {
                     toastIdRef.current = toast.error(
                        renderToastifyMsg(msg, 'exclamation-triangle')
                     );
                  }
               }
            );
      },
   });


   return (
      <div className="container shadow mt-4 col-10">
         <div className="row">
            <div className="register-container">

               <Form onSubmit={formik.handleSubmit}>

                  <div className="container">
                     <div className='register-title mt-3'>Velkommen til Byggekost</div>
                     <div className='register-text mt-1 mb-4 bold'>Opprett en bruker</div>

                     <div className='row mx-3'>
                         <div className="col-6">
                         <div className='register-text mb-4'>Brukerinformasjon</div>

                          <div className='col-10 col-centered my-3'>
                          <Form.Control type="text" placeholder="Fornavn" required className="register-control" id="firstName"
                              value={formik.values.firstName} onChange={formik.handleChange} />
                          </div>
                          <div className='col-10 col-centered my-3'>
                          <div id='register-form'>
                              <Form.Control type="text" placeholder="Etternavn" required className="register-control" id="lastName"
                                 value={formik.values.lastName} onChange={formik.handleChange} />
                           </div>
                          </div>
                          <div className='col-10 col-centered my-3'>
                          <Form.Control type="text" placeholder="Telefon" required className="register-control" id="phoneNumber"
                              value={formik.values.phoneNumber} onChange={formik.handleChange} />

                          </div>
                          <div className='col-10 col-centered my-3'>
                          <Form.Control type="text" placeholder="E-post" required className="register-control" id="email"
                                                         value={formik.values.email} onChange={formik.handleChange} />
                          </div>
                          <div className='col-10 col-centered my-3'>
                           <Form.Control type="password" placeholder="Passord" required className="register-control" id="password"
                              value={formik.values.password} onChange={formik.handleChange} />

                           </div>

                           <div className='col-10 col-centered my-3' id='register-form'>
                              <Form.Control type="password" placeholder="Bekreft passord" required className="register-control" id="confirmPassword"
                                 value={formik.values.confirmPassword} onChange={formik.handleChange} />
                           </div>
                     </div>

                  <div className="col-6">

                     <div className='register-text mb-4'>Firmainformasjon</div>

                     <div className='col-12 col-centered my-3'>
                     <Form.Control type="text" placeholder="Firmanavn" required className="register-control" id="company"
                              value={formik.values.company} onChange={formik.handleChange} />
                     </div>
                     <div className='col-12 col-centered my-3'>
                     <div  id='register-form'>
                              <Form.Control type="text" placeholder="Adresse" required className="register-control" id="address"
                                 value={formik.values.address} onChange={formik.handleChange} />
                           </div>
                     </div>
                     <div className='row my-3'>
                     <div className='col-3 col-centered'> <Form.Control type="text" placeholder="Postnummer" required className="register-control" id="zipCode"
                              value={formik.values.zipCode} onChange={formik.handleChange} />
                       </div>
                     <div className='col-9 col-centered'>
                        <div  id='register-form'>
                              <Form.Control type="text" placeholder="Sted" required className="register-control" id="city"
                                 value={formik.values.city} onChange={formik.handleChange} />
                           </div>
                           </div>
                     </div>
                     </div>

                       
                       {/*<div className='col-lg-6' id='register-form'>
                  
                     <Form.Select className="register-control"
                     id="roleId"
                     value={formik.values.roleId}
                     onChange={formik.handleChange('roleId')}>

                        <option value={1}>Takstmann</option>
                        <option value={2}>Bankfunksjonær</option>
                        <option value={3}>Byggmester</option>
                     </Form.Select>
                     </div>*/}              
                      </div>

                     <div className="row my-3 mx-3">
                        
                     </div>

                     <div className="row justify-content-end">
                        <div className='col-lg-3 mt-2 mb-4'>
                           <button className="register-button" type="submit">Opprett konto</button>
                        </div>
                     </div>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = (state: IApplicationState) => state.auth;

export default connect(mapStateToProps, actionCreators)(Register);

