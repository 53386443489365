import React, { useState } from 'react';


const CustomerResultRow = ({ handleSelectedCb, customer, index, selectedCustomer }) => {


   const getRowClass = function (index) {
      if (index % 2 === 0) {
         return "customer-result-row customer-row-grey"
      }
      else {
         return "customer-result-row customer-row-white"
      }
   }

   const handleCustomerClick = function(id) {
         handleSelectedCb(id);
   }

   return (
      <>
         <div className={[getRowClass(index), (selectedCustomer === customer.userId) ? 'customer-share-row-selected' : ''].join(' ')} onClick={() => handleCustomerClick(customer.userId)}>
            <div className="customer-text-trim">{customer.firstName}</div>
            <div className="customer-text-trim">{customer.lastName}</div>
            <div className="customer-text-trim">{customer.company}</div>
         </div>
      </>
   )
}

export default React.memo(CustomerResultRow);
