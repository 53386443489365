import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Divider, Fade, Menu, MenuItem } from '@material-ui/core';


export interface ActionMenuItemProps {
  type?: 'divider' | null,
  name?: string,
  func?: any,
  funcArgs: object
}

interface ActionMenuForCategoryProps {
 // children: JSX.Element,
  iconPropName?: string,
  horizontalPos?: number | 'center' | 'left' | 'right',
  menuItems: Array<ActionMenuItemProps>
}
  

export interface ActionMenuItemProps {
  type?: 'divider' | null,
  name?: string,
  func?: any,
  funcArgs: object
}

interface ActionMenuForCategoryProps {
 // children: JSX.Element,
  iconPropName?: string,
  horizontalPos?: number | 'center' | 'left' | 'right',
  menuItems: Array<ActionMenuItemProps>
}
  
export const ActionMenu = ({iconPropName, horizontalPos, menuItems}: ActionMenuForCategoryProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    let result = null;
    if (menuItems){
       result = menuItems.map((menuItem, index)=> {
        if (menuItem.type  && menuItem.type === 'divider'){
          return <Divider key={index} />
        }
        else {
          return <MenuItem key={index} onClick={() => {
             menuItem.func(menuItem.funcArgs);
             handleClose();
          }}>{menuItem.name}</MenuItem>
        }
      })
    }

    let iconPropValue = faBars;

    if (iconPropName === 'faCaretDown'){
      iconPropValue = faCaretDown;
    }

      
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
        
          <FontAwesomeIcon style={{cursor: 'pointer'}} icon={iconPropValue}  onClick={handleClick}/>
          <Menu
              elevation={0}
              transformOrigin={{
                vertical: 'top',
                horizontal: horizontalPos || 'left',
              }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            {
              result? result: ''
            }
          </Menu>
        </div>;
  
  }
  
  