import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { formatValue, formatDateTime } from '../../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { SimpleCalculationApi } from '../../../api';
import {ConfirmationDialog} from '../../../components/Dialogs'

const SimpleCalculationRow = ({calculation, index, onDeleteCb, onCopyCb}) => {
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
  const history = useHistory();

  const getRowClass = function(index) {
    if (index % 2 === 0) {
      return "bk-project-row bk-row-white"
    }
    else {
      return "bk-project-row bk-row-grey"
    }
  }

  const formatDescription = function() {
    if (calculation.subTitle === null || calculation.subTitle === ''){
      return calculation.name;
    }
    return calculation.name + " - " + calculation.subTitle;
  }

  const id = calculation.simpleCalculationId;
  const handleEditCalculation = event => {
    event.preventDefault();
    const url = "/simpleCalculation/" + id;
    history.push(url);
  }

  const handlePrintCalculation = event => {
    SimpleCalculationApi.downloadPdf(id, 1);
  }

  const handleCopyCalculation = event => {
    if (id > 0) {
      onCopyCb(id);
    }
  }

  const handleDeleteCalculation = event => {
    setDisplayConfirmDialog(true);
  }

  const handleConfirmDialogClose = function(id) {
    if (id > 0) {
      //onDeleteCb(id);
      onDeleteCb(id);
    }
    setDisplayConfirmDialog(false);
  }



  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
              elevation={0}
              //getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleEditCalculation}>Rediger</MenuItem>
            <MenuItem onClick={handlePrintCalculation}>Skriv ut</MenuItem>
            <MenuItem onClick={handleCopyCalculation}>Kopier</MenuItem>
            <MenuItem onClick={handleDeleteCalculation}>Slett</MenuItem>
          </Menu>
        </div>;

  }



  return (
    <>
      <div className ={getRowClass(index)} >
        <div className="bk-project-row-flex-1 bk-project-row-text bk-project-row-align-left" onClick={handleEditCalculation}>{formatDateTime(calculation.created)}</div>
        <div className="bk-project-row-flex-3 bk-project-row-text bk-project-row-align-left" title={calculation.name} onClick={handleEditCalculation}>{formatDescription()}</div>
        {/* <div className="bk-project-row-flex-1 bk-project-row-text bk-project-row-align-center" onClick={handleEditCalculation}>{formatDateTime(calculation.updated)}</div> */}
        <div className="bk-project-row-flex-3 bk-project-row-text bk-project-row-align-right"></div>
        <div className="bk-project-row-flex-1 bk-project-row-text bk-project-row-align-right" onClick={handleEditCalculation}>{formatValue(calculation.sumIncVat, 0 , ' kr')}</div>
        <div className="bk-project-row-flex-1 bk-project-row-text bk-project-row-align-right"></div>
        <div className="bk-project-row-flex-min bk-project-row-buttons">
              <ActionMenu />
          </div>
      </div>
    
      <ConfirmationDialog
          title="Slette?"
          text="Er du sikker på at du vil slette kalkulasjonen"
          open={displayConfirmDialog}
          id={id}
          onClose={handleConfirmDialogClose}
      />
    </>
  )
}
SimpleCalculationRow.displayName = 'Skadekalkyle';

export default React.memo(SimpleCalculationRow);