import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class InsuranceInformationModuleService extends BaseService {
   private static _insuranceInformationModuleService: InsuranceInformationModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): InsuranceInformationModuleService {
     return this._insuranceInformationModuleService || (this._insuranceInformationModuleService = new this('insuranceInformationModule'));
   }
 

   public async getInsuranceInformationModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addInsuranceInformationModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addInsuranceInformationModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateInsuranceInformation(data): Promise<AxiosResponse> {
      var axiosResponse;
      if (!data){
        this.handleAllErrors(` Data was not provided for updateInsuranceInformation.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

}

export const InsuranceInformationModuleApi = InsuranceInformationModuleService.Instance;