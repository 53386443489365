import React from 'react'

function StatisticsBox(props) {

   var totalTotal = 0;
   var totalYear = 0;
   var totalMonth = 0;
   var totalWeek = 0;
   var totalDay = 0;

   const getRowClass = function(index) {
      if (index % 2 === 0) {
        return "statistics-column statistics-white"
      }
      else {
        return "statistics-column statistics-grey"
      }
    }

   var reportdataHtml = null;
   if (props.data) {
      reportdataHtml = props.data.map((data, index) => {
         return <>
         <div className={getRowClass(index)}>
            <div className="flex-3 statistics-column-name">{data.name}</div>
            <div className="flex-1 statistics-column-data">{data.total}</div>
            <div className="flex-1 statistics-column-data">{data.year}</div>            
            <div className="flex-1 statistics-column-data">{data.month}</div>
            <div className="flex-1 statistics-column-data">{data.week}</div>
            <div className="flex-1 statistics-column-data">{data.day}</div>
         </div>
         </>
      });
   }

   if (props.data) {
      props.data.map((data, index) => {
         totalTotal += data.total;
         totalYear += data.year;
         totalMonth += data.month;
         totalWeek += data.week;
         totalDay += data.day;
      });
   }

   const statisticsHtml = () => {
      if (reportdataHtml.length != 0) {
            return <>
               <div className="statistics-box">
                  <div className="statistics-column">
                     <div className="flex-3 statistics-column-header-big">{props.title}</div>
                     <div className="flex-1 statistics-column-header">Totalt</div>
                     <div className="flex-1 statistics-column-header">År</div>
                     <div className="flex-1 statistics-column-header">Måned</div>
                     <div className="flex-1 statistics-column-header">Uke</div>
                     <div className="flex-1 statistics-column-header">Idag</div>
                  </div>
                  {reportdataHtml}
                  <div className='statistics-sum-row statistics-column '>
                     <span className="flex-3 statistics-column-name">SUM</span>
                     <span className="flex-1 statistics-column-data">{totalTotal}</span>
                     <span className="flex-1 statistics-column-data">{totalYear}</span>
                     <span className="flex-1 statistics-column-data">{totalMonth}</span>
                     <span className="flex-1 statistics-column-data">{totalWeek}</span>
                     <span className="flex-1 statistics-column-data">{totalDay}</span>
                  </div>
               </div>
            </>
      }
   };
   

   return <>
      {statisticsHtml()}
   </>
}
  
export default StatisticsBox;