import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { Route as ReactRoute} from 'react-router';
import NavbarButton from './Buttons/NavbarButton/NavbarButton';
import NavbarButtonSp from './Buttons/NavbarButtonSp/NavbarButtonSp';
import NavbarButtonLogin from './Buttons/NavbarButtonLogin/NavbarButtonLogin';
import { IApplicationState } from '../store';
import { actionCreators} from '../store/auth';
import { RoutesConfig, Route } from '../config/routes.config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faUser } from '@fortawesome/free-solid-svg-icons'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { consoleLog } from '../utils/consoleHelper';

const ByggekostLogo = require('../assets/image/ByggekostLogo.png') as string;


type NavbarProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly role: number;
  readonly userName: string;
  readonly isAdmin:boolean;
};

const Navbar_action: React.FC<NavbarProps> = ({ 
    isAuthenticated, 
    userName, 
    role,
    isAdmin,
    logoutUserRequest
  }) => {

  const history = useHistory();


  const navRoutes: Route[] = Object.keys(RoutesConfig).reduce((acc, key) => {
    const route = RoutesConfig[key];
    route.showInNav && route.loggedIn===isAuthenticated && (!route.isAdmin || isAdmin) && acc.push(route);
    return acc;
  }, [] as Route[]);

  function RoleName(role) {
    let roleName = "";
    switch (role){
      case 1:
        roleName = "Takstmann";
        break;
      case 2:
        roleName = "Bankmann";
        break;
      case 3:
        roleName = "Byggmester";
        break;
    }
  
    return roleName;
  }

  const handleLogout = (history: History<any>) => (): void => {
    const onLogoutCallbackFn = () => history.push("/login");
    logoutUserRequest(onLogoutCallbackFn);
  };

  const openMyProfile = () => {
   history.push('/profile');
  }
  
  function UserMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMyProfile = () => {
      setAnchorEl(null);
      openMyProfile();
    }

    const handleSwitch = () => {
      setAnchorEl(null);
      window.location.href  = "https://old.byggekost.no";
    };
    
    
    if (isAuthenticated) {
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
        <FontAwesomeIcon icon={faBars}  onClick={handleClick} className="fa-fw btn-burger mt-3 ms-2"/>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          className="navbar-dropdown-menu"
        >
          <MenuItem disabled className="navbar-droptown-text-name"> 
          {userName}
          <FontAwesomeIcon icon={faUser}  onClick={handleClick} className="fa-sm ms-2"/>  
          
           </MenuItem>
          <MenuItem disabled className="navbar-droptown-text">{RoleName(role)}</MenuItem>
          <Divider />
          <MenuItem onClick={handleMyProfile} className="navbar-droptown-text-name" id="navbar-drop-btn">Min side</MenuItem>
          <ReactRoute
              render={({ history }) => (
                <MenuItem onClick={handleLogout(history)} className="navbar-droptown-text-name" id="navbar-drop-btn">Logg ut</MenuItem>
              )}
            />  
           {/* <Divider />     */}
           {/* <MenuItem onClick={handleSwitch}>Bytt til gammel layout</MenuItem> */}
        </Menu>
        </div>;
    }
    return <></>;

  }
  const [show, setShow] = useState(false);

  const handleNavBarClick = function(path) {
    history.push(path);
  }

  const navToggle = () => {
    setShow(show ? false : true);
  };

  const handleClose = () => {
    setShow(false);
  }
  
  return (

    <Navbar expand="md" expanded={show}>
    <Container>
            <Navbar.Brand href="/" className="ms-3 col-5 col-md-2"><Image fluid src="\Content\images\design\Logo_vector_blue.svg" className="w-75"/></Navbar.Brand>
            <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" onClick={navToggle}/>
            <Navbar.Offcanvas placement="end" onHide={handleClose} className="navbar-offcanvas offcanvas-md"  aria-labelledby="offcanvasNavbar-expand-md">
            <Offcanvas.Header closeButton className='justify-content-end mt-3 me-2' ></Offcanvas.Header>
            <Offcanvas.Body className='justify-content-end'>
            <Nav className='' onClick={handleClose}>
              { 
                navRoutes.map((route, index) => {
                  switch (route.displayName) {
                    case "Opprett konto":
                      return ( 
                        <Nav.Link className='mx-1 justify-content-end' onClick={handleClose}>
                        <NavbarButtonSp caption={route.displayName} arg={route.path} onClickHandler={handleNavBarClick} key={index}/>
                        </Nav.Link>
                        );
                    case "Logg inn":
                      return ( 
                        <Nav.Link className='mx-1 justify-content-end'>
                        <NavbarButtonLogin caption={route.displayName} arg={route.path} onClickHandler={handleNavBarClick} key={index}/>
                        </Nav.Link>
                        );
                    default:
                      return (
                        <Nav.Link className=''>
                        <NavbarButton caption={route.displayName} arg={route.path} onClickHandler={handleNavBarClick} key={index}/>
                        </Nav.Link>
                      );} 
                })
              }
              <UserMenu isLoggedIn={isAuthenticated}/>
            </Nav>
            </Offcanvas.Body>
             </Navbar.Offcanvas>
        </Container>
      </Navbar>

  );
};

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  userName: state.auth.userName,
  isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, actionCreators)(Navbar_action);
