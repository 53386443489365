import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class CalculationService extends BaseService {
  private static _calculationService: CalculationService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): CalculationService {
    return this._calculationService || (this._calculationService = new this('calculation'));
  }


  public async getCalculation(id): Promise<AxiosResponse> {
    var axiosResponse;
//    console.log(this.$http.defaults);
    await this.$http.get('Calculation?calculationId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async addCalculationAddon(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('AddCalculationAddon', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async deleteCalculationAddon(id): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {id: parseInt(id)};
    await this.$http.post('DeleteCalculationAddon',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async updateCalculation(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateCalculation',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async updateDescription(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateDescription',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async updateValueReduction(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateValueReduction',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async updateBuildYear(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateBuildYear',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  
  public async setSameBuildYear(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('SetSameYear',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async getPdf(id): Promise<boolean> {

    var url = `/api/calculation/GetPdf?calculationId=${id}`;
//    var url = `${window.location.origin}/api/calculation/GetPdf?calculationId=${id}`;
    window.location.href = url;

    return Promise.resolve(true);
  }


  public async getSimpleCalculation(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('SimpleCalculation?calculationId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }


  public async getProperty(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('Property?calculationId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }
 
  public async getCalculationCost(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('CalculationCost?calculationId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

}

export const CalculationApi = CalculationService.Instance;