import React, { useState, useEffect } from 'react';
import { actionCreators } from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory, useParams } from 'react-router-dom'
import { AdminApi, AuthApi, ReportApi } from '../../api';
import AdminMenu from './child-components/AdminMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowAltCircleLeft, faArrowCircleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';


type AdminLinksProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly isAdmin: boolean;
};

const AdminLinks: React.FC<AdminLinksProps> = ({
   isAuthenticated,
   isAdmin,
   logoutUser
}) => {

   const history = useHistory();

   // useEffect(() => {
   //    fetchData();
   // }, []);

   // const fetchData = async () => {
   //    AdminApi.getMessages()
   //    .then(
   //    (response) => {    

   //    },
   //    (error) => {
   //       console.log(error);
   //    });
   // }


   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if (!isAdmin) {
      history.push("/projects");
   }

   return (
      <>
         <div className="admin-container">
            <AdminMenu activeMenuItem={'Lenker'}></AdminMenu>

            <div className='links-container'>
               <a className='link-row' href="https://byggekost.no/accept">
                  Akseptavtale 
                  <FontAwesomeIcon icon={faArrowRight} className='fa-sm'/>
                  </a>
            </div>

         </div>

      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, actionCreators)(AdminLinks);