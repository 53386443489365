import React, {useState, useEffect, useRef} from 'react'

type TextInputProps = {
    readonly value: string;
    readonly class?:string;
    readonly id?:number;
    readonly name?:string;
    readonly key?:string;
    readonly readOnly?:boolean;
    readonly disabled?:boolean;
    readonly placeholder?:string;
    readonly onTextChangeCb?: (value: string, id?:number,  name?:string) => void;
  };

const keyPressTimer = 2000;
let textTimer = null;

function TextInput(props:TextInputProps) {

    const [value, setValue] = useState("");
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState(0);

    useEffect(() => {
        setValue(props.value);
        if (props.id != null){
          setId(props.id);
        }
     }, [props]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setValue(value);

        if (textTimer !== null){
          clearTimeout(textTimer);
        }
        textTimer = setTimeout(() => {
          if (props.onTextChangeCb != null)
            props.onTextChangeCb(value, id, props.name);
       }, keyPressTimer);        
    }

    const handleBlur = (e) => {
        if (textTimer != null && props.onTextChangeCb != null){
            clearTimeout(textTimer);
            const {name, value} = e.target;
            if (value != null){
                setValue(value)
                props.onTextChangeCb(value, id, props.name);
            }
        }  
     }

    return (
          <input type="text" key={props.key} readOnly={readOnly} disabled={props.disabled} placeholder={props.placeholder} className={"form-control " + props.class} value={value} onChange={handleChange} onBlur={handleBlur} />
    );

}
export default TextInput;