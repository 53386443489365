import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button} from '@material-ui/core'

const NumberDialog = ({open, title, initialValue, onClose}) => {
   const [value, setValue] = useState<number>(0);

   useEffect(() => {
      setValue(initialValue);
   }, [initialValue]);


   const onValueChange = (e) => {
      const { name, value } = e.target;
      var intValue = parseFloat(value);
      setValue(intValue);   
   }

   const handleClose = function() {
      onClose(null);
   }

   const handleSaveAndClose = function() {
      onClose(value);
   }
  
   return (
      <>
         <Dialog open={open} fullWidth={true} maxWidth = {'xs'} onClose={handleClose} aria-labelledby="form-dialog-title">
         <DialogTitle id="form-dialog-title">{title}</DialogTitle>
         <DialogContent>
               <div className="simple-calc-section">
               <input type='number' placeholder="0" step="1" name='' className="form-control market-analysis-row-input align-right" value={value} onChange={onValueChange} onBlur={onValueChange}/>
               </div>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleClose} className="form-dialog-btn">
               Avbryt
            </Button>
            <Button onClick={handleSaveAndClose} className="form-dialog-btn me-3">
               OK
            </Button>
         </DialogActions>
         </Dialog>
      </>
   )
}

export default React.memo(NumberDialog);