import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';


class SimpleCalculationService extends BaseService {
  private static _simpleCalculationService: SimpleCalculationService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): SimpleCalculationService {
    return this._simpleCalculationService || (this._simpleCalculationService = new this('simpleCalculation'));
  }


  public async getSimpleCalculation(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.get('SimpleCalculation?calculationId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
    return axiosResponse;
  }


  public async updateSimpleCalculation(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateSimpleCalculation',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async updateInfo(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateInfo',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async getNewDescription(id): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.get('getNewDescription?calculationId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async getNewPicture(id): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.get('getNewPicture?calculationId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }
  

  public async updateDescription(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateDescription',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async deleteDescription(id): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {id: parseInt(id)};
    await this.$http.post('deleteDescription',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async updateDescriptionRow(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateDescriptionRow',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async deleteDescriptionRow(id): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {id: parseInt(id)};
    await this.$http.post('deleteDescriptionRow',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async getNewDescriptionRow(id): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.get('getNewDescriptionRow?descriptionId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async getNewinfoRow(id): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.get('getNewinfoRow?calculationId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async deleteInfo(id): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {id: parseInt(id)};
    await this.$http.post('deleteInfo',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  // Category
  public async getNewCategory(id): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.get('getNewCategory?calculationId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async updateCategory(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateCategory',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async deleteCategory(id): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {id: parseInt(id)};
    await this.$http.post('deleteCategory',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async copyCategory(id): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {id: parseInt(id)};
    await this.$http.post('copyCategory',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  //CategoryRow
  public async getNewCategoryRow(id): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.get('getNewCategoryRow?categoryId=' + id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async updateCategoryRow(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateRow',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });;

    return axiosResponse;
  }


  public async deleteCategoryRow(id): Promise<AxiosResponse> {
    var axiosResponse;
    var data = {id: parseInt(id)};
    await this.$http.post('deleteRow',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }
  

  public async upload(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    const config = {   
      withCredentials: true,  
      headers: { 'content-type': 'multipart/form-data' }
  }
  
    await this.$http.post('Upload',data, config)
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }



  /* DescriptionTemplates*/
  public async getDescriptionTemplates(): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.get('getDescriptionTemplates', {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async AddCalculationTemplates(id, array): Promise<AxiosResponse> {
    var axiosResponse;

    var data = {
      id:parseInt(id),
      SelectedIds:array
    }
 
    await this.$http.post('getFromTemplate',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }
  
  public async AddDescriptionTemplates(id, array): Promise<AxiosResponse> {
    var axiosResponse;

    var data = {
      id:parseInt(id),
      SelectedIds:array
    }
 
    await this.$http.post('getFromDescriptionTemplate',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }
  

  /* pdf */
  public async getPdf(id, type): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.get('getPdf?calculationId=' + id + '&printType=' + type, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public downloadPdf(id, type) {
//    var url = window.location.origin +'/api/simpleCalculation/getPdf?calculationId=' + id + '&printType=' + type;
    var url = '/api/simpleCalculation/getPdf?calculationId=' + id + '&printType=' + type;
    //window.open(url) //to open new page
    window.location.href = url;
  }


}



export const SimpleCalculationApi = SimpleCalculationService.Instance;