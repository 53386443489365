import React from 'react';
import {PropertyItemGroup} from './'


const PropertyGroup = ({group, onChanged}) => {

  const handlePropertyChange = function(e, id) {
    onChanged(e,id);
  }

  let groupItemsHtml = null;
  if (group.itemGroups) {
   groupItemsHtml = group.itemGroups.map((itemGroup, index) =>
      {
         return (
            <PropertyItemGroup itemGroupRow={itemGroup} onChanged={handlePropertyChange}/>
         )
      });  
  }

  return (
    <>
         <div className="property-frame">
            <div className="property-group-header">{group.header}</div>
            {groupItemsHtml}
         </div>
    </>
  )
}

export default React.memo(PropertyGroup);