import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';


class DetailedCalculationService extends BaseService {
  private static _detailedCalculationService: DetailedCalculationService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): DetailedCalculationService {
    return this._detailedCalculationService || (this._detailedCalculationService = new this('detailedCalculationModule'));
  }


  public async getDetailedCalculation(moduleId:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    if (!moduleId){
      this.handleAllErrors(` moduleId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.get('get/' + moduleId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
 }

 public async setSelectedPrintOption(data): Promise<AxiosResponse> {
  var axiosResponse;

  if (!data){
     this.handleAllErrors(` Data was not provided for setSelectedPrintOption.`); 
     Promise.reject();
  }
  
  await this.$http.post('SetSelectedPrintOption',data, {withCredentials: true})
  .then((response) => {
     axiosResponse = response;
  }, 
  (error) => {
        this.handleAllErrors(error); 
        throw (error);
  });

  return axiosResponse;
}


 public async addDetailedCalculation(data: {moduleId:number}): Promise<AxiosResponse> {
  var axiosResponse;

  if (!data){
     this.handleAllErrors(` Data was not provided for addDetailedCalculation.`); 
     Promise.reject();
  }
  
  await this.$http.post('Add',data, {withCredentials: true})
  .then((response) => {
     axiosResponse = response;
  }, 
  (error) => {
        this.handleAllErrors(error); 
        throw (error);
  });

  return axiosResponse;
}

public async updateDetailedCalculation(data): Promise<AxiosResponse> {
  var axiosResponse;

  if (!data){
     this.handleAllErrors(` Data was not provided for updateDetailedCalculation.`); 
     Promise.reject();
  }
  
  await this.$http.post('Update',data, {withCredentials: true})
  .then((response) => {
     axiosResponse = response;
  }, 
  (error) => {
        this.handleAllErrors(error); 
        throw (error);
  });

  return axiosResponse;
}

public async updateDetailedCalculationCalculation(data): Promise<AxiosResponse> {
  var axiosResponse;

  if (!data){
     this.handleAllErrors(` Data was not provided for updateDetailedCalculationCalculation.`); 
     Promise.reject();
  }
  
  await this.$http.post('UpdateCalculation',data, {withCredentials: true})
  .then((response) => {
     axiosResponse = response;
  }, 
  (error) => {
        this.handleAllErrors(error); 
        throw (error);
  });

  return axiosResponse;
}

public async updateDetailedCalculationCategory(data): Promise<AxiosResponse> {
  var axiosResponse;

  if (!data){
     this.handleAllErrors(` Data was not provided for updateDetailedCalculationCategory.`); 
     Promise.reject();
  }
  
  await this.$http.post('UpdateCategory',data, {withCredentials: true})
  .then((response) => {
     axiosResponse = response;
  }, 
  (error) => {
        this.handleAllErrors(error); 
        throw (error);
  });

  return axiosResponse;
}

public async copyDetailedCalculationCategory(data): Promise<AxiosResponse> {
  var axiosResponse;

  if (!data){
    this.handleAllErrors(` Data was not provided for copyCategory.`); 
    Promise.reject();
  }
  await this.$http.post('copyCategory',data, {withCredentials: true})
  .then((response) => {
    axiosResponse = response;
  }, 
  (error) => {this.handleAllErrors(error);  throw (error); });

  return axiosResponse;
} 


public async addCategoryFromTemplates(id, calculationId, array): Promise<AxiosResponse> {
  var axiosResponse;

  var data = {
    id:parseInt(id),
    calculationId:parseInt(calculationId),
    SelectedIds:array
  }

  await this.$http.post('addFromTemplate',data, {withCredentials: true})
  .then((response) => {
    axiosResponse = response;
  }, 
  (error) => {this.handleError(error); });

  return axiosResponse;
}


public async updateDetailedCalculationRow(data): Promise<AxiosResponse> {
  var axiosResponse;
  if (!data){
     this.handleAllErrors(` Data was not provided for updateDetailedCalculationRow.`); 
     Promise.reject();
  }
  await this.$http.post('UpdateRow',data, {withCredentials: true})
  .then((response) => {
     axiosResponse = response;
  }, 
  (error) => {
        this.handleAllErrors(error); 
        throw (error);
  });

  return axiosResponse;
}


public async deleteCategory(data): Promise<AxiosResponse> {
  var axiosResponse;

  if (!data){
    this.handleAllErrors(` Data was not provided for deleteCategory.`); 
    Promise.reject();
  }
  await this.$http.post('deleteCategory',data, {withCredentials: true})
  .then((response) => {
    axiosResponse = response;
  }, 
  (error) => {this.handleAllErrors(error);  throw (error); });

  return axiosResponse;
} 



public async deleteRow(data): Promise<AxiosResponse> {
  var axiosResponse;

  if (!data){
    this.handleAllErrors(` Data was not provided for deleteRow.`); 
    Promise.reject();
  }
  await this.$http.post('deleteRow',data, {withCredentials: true})
  .then((response) => {
    axiosResponse = response;
  }, 
  (error) => {this.handleAllErrors(error);  throw (error); });

  return axiosResponse;
} 



}
export const DetailedCalculationApi = DetailedCalculationService.Instance;