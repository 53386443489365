import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom'
import { ContactApi } from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Menu} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import {ContactRow, ContactDialog} from '../../Contacts/child-components';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatDateTime } from '../../../utils/'
import { BkButton,ContactButton } from '../../../components';
import {isNullOrEmpty } from '../../../utils'
import { faCalendarXmark, faTimes } from '@fortawesome/free-solid-svg-icons'

const keyPressTimer = 1000;
let inputTimer = null;

const SelectContactDialog = ({open, handleSelectedCb, title, displayDate, date=null, multiselect=false, contactList=null, hideDate=false}) => {
  const [contacts,setContacts]= useState([]);
  const [filteredContacts,setFilteredContacts]= useState([]);
  const [displayArray, setDisplayArray] =useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedContacts,setSelectedContacts]= useState([]);
  const [searchText,setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [nextCustomerIndex, setNextCustomerIndex] = useState(0);

  // Used for edit project data
  const [openEdit, setOpenEdit] = React.useState(false);
  const [contactData, setContactData] = useState({
    contactId:0,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    address: '',
    zipCode: '',
    city: '',
    company: '',
    vatNumber: '',
  });

  const history = useHistory();
  const defaultTake=30;

  useEffect(() => {

    const fetchData = async () => {  
      ContactApi.getContacts()
      .then(
        (response) => {
          var data = response.data.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
          var updatedData = data.map((contact) => {
            if (contactList) {

              var selected = contactList.some(s=>s.contactId == contact.contactId);
              if (selected) {
                contact.checked = true;
              }
              else {
                contact.checked = false;
              }
            }
            return contact;
         
          })

          contactList.map((dcontact) => {
           if(dcontact.deleted == true){
            dcontact.checked = true;
            updatedData.push(dcontact)
            // if (updatedData.some(v => (v.contactId != dcontact.contactId))){
             
            // }
           }
          })
          setContacts(updatedData);
          setNextCustomerIndex(defaultTake);
          setFilteredContacts(sortArray(updatedData));          
        },
        (error) => {
          console.log(error);
        });
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
      //var options = {year: 'numeric', month: '2-digit', day: '2-digit' } as const;
      setSelectedDate(date);
      setInputValue(formatDateTime(date));
   }, [date])

   useEffect(() => {
    if (contactList != null) {
      setSelectedContacts(contactList);
    }
   }, [contactList])

   useEffect(() => {
    setDisplayArray(filteredContacts.slice(0, nextCustomerIndex));
   }, [filteredContacts])



   useEffect(() => {
    setLoading(false);
   }, [displayArray])

   const sortArray = (array) => {
      //const alphabetical = array.sort((a,b) =>(a.lastName < b.lastName)?1:-1)
      const newArray = array.sort(c =>c.checked?-1:1);
      return newArray;
   }

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    var options = {year: 'numeric', month: '2-digit', day: '2-digit' } as const;
    setInputValue(date.toLocaleDateString('nb-NO', options));
  };

  const clearDate = () => {
    setSelectedDate(null);
    setInputValue(null);
 }

  const handleAddContact= function() {
    setOpenEdit(true);
  }

  const handleContactUpdated= function(contact) {
    if (contact !== null) {
      if (contact.contactId === 0) {
        // Add new contact
        ContactApi.addContact(contact)
        .then(
          (response) => {
            setContacts(contacts => [response.data, ...contacts]);
            setFilteredContacts(filteredContacts => [response.data, ...filteredContacts]);
          },
          (error) => {
            console.log(error);
          });
      }
      else {
        // Update existing contact
        ContactApi.updateContact(contact)
        .then(
          (response) => {
            // Update the contact in the list
            var newContacts = contacts.map((oldContact) => {
              if (oldContact.contactId === contact.contactId) {
                var updatedContact =  response.data;
                updatedContact.checked = oldContact.checked;
                return response.data;
              }
              return oldContact;
            })
            setContacts(newContacts);

            var newFilteredArray = filteredContacts.map((oldContact) => {
              if (oldContact.contactId === contact.contactId) {
               var updatedContact =  response.data;
               updatedContact.checked = oldContact.checked;
               return response.data;
              }
              return oldContact;
            })
            setFilteredContacts(newFilteredArray);

            var newSelectedArray = selectedContacts.map((oldContact) => {
              if (oldContact.contactId === contact.contactId) {
               var updatedContact =  response.data;
               updatedContact.checked = oldContact.checked;
               return response.data;
              }
              return oldContact;
            })
            setSelectedContacts(newSelectedArray);

          },
          (error) => {
            console.log(error);
          });
      }
    }
    setOpenEdit(false);
  }

  const handleContactDeleted= function(id) {
    // Delete contact
    ContactApi.deleteContact(id)
    .then(
      (response) => {
        setContacts(contacts.filter(contact =>contact.contactId !== id));
        setFilteredContacts(filteredContacts.filter(contact =>contact.contactId !== id));
      },
      (error) => {
        console.log(error);
      });
  }

  const handleClose = function() {
    handleSelectedCb(null, null);
  }

  const handleSaveAndClose = function() {
    if (multiselect) {
      handleSelectedCb(selectedContacts, selectedDate);
    }
    else {
      if (selectedContacts.length > 0) {
        handleSelectedCb(selectedContacts[0], selectedDate);
      }
      else {
        handleSelectedCb(null, selectedDate);
      }
    }
  }

  const handleContactSelected = function(contact) {
      if (multiselect) {

        var newContacts = contacts.map((contactElement, index) => {
          if (contactElement.contactId == contact.contactId) {
            contactElement.checked = contact.checked;            
          }
          return contactElement;
        });
        setContacts(newContacts);

        var aselected = selectedContacts.some(s=>s.contactId == contact.contactId);
        if (aselected) {
          // remove
          setSelectedContacts(selectedContacts.filter(c => c.contactId !== contact.contactId));
        }
        else {
          // add
          setSelectedContacts([...selectedContacts, contact]);
        }
      }
      else if (displayDate == false) {
         handleSelectedCb(contact, null);
      }
      else {
        setSelectedContacts([contact]);
      }
   }

  const handleContactSearch = function(e) {
    var searchstring = e.target.value.toLowerCase();

    setSearchText(searchstring);

    if (inputTimer !== null){
      clearTimeout(inputTimer);
    }
    inputTimer = setTimeout(() => {
      contactSearch(searchstring);
    }, keyPressTimer); 
  }


  const contactSearch = (searchstring) => {
    setNextCustomerIndex(defaultTake);

    if (isNullOrEmpty(searchstring)) {
      setFilteredContacts(contacts);
      return;
    }

    setFilteredContacts(contacts.filter(contact =>(
      contact.firstName.toLowerCase().includes(searchstring)  || 
      contact.lastName.toLowerCase().includes(searchstring) ||
      (contact.firstName.toLowerCase() + ' ' + contact.lastName.toLowerCase()).includes(searchstring) ||
      contact.email.toLowerCase().includes(searchstring) ||
      contact.address.toLowerCase().includes(searchstring) ||
      contact.city.toLowerCase().includes(searchstring) 
      )));
  }


  const handleScroll = function(e) {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    if (bottom && !loading) { 
      setLoading(true);
      var newelements = filteredContacts.slice(nextCustomerIndex, nextCustomerIndex + defaultTake);
      setDisplayArray([...displayArray, ...newelements]);
      setNextCustomerIndex(nextCustomerIndex + defaultTake);
    }
  }


  let contactsHtml = null;
  if (displayArray) {
    contactsHtml = displayArray.map((contact, index) =>
    {
      return (
          <ContactRow key={'CR_' + index}
            contactData={contact} 
            index={index}
            mode={1}
            displaySelect={displayDate || multiselect}
            handleSelectedCb={handleContactSelected}
            handleChangeCb={handleContactUpdated}
            handleDeleteCb={handleContactDeleted}
            />
        )
      })
  }

  let selectDateHtml = null;
  if (displayDate) {
    selectDateHtml = 
    <div className='contact-dialog-date-picker'>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd.MM.yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Sett dato"
            value={selectedDate}
            inputValue={inputValue}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
      </MuiPickersUtilsProvider>
      <span id='clear-date-icon'><FontAwesomeIcon icon={faCalendarXmark} onClick={clearDate} className="tg-icon" title="Slett dato" /></span>
      </div>
  }
  
  let displayBlank = null;
  if (displayDate || multiselect) {
    displayBlank = 
      <div className="flex-1 contacts-row-checkbox">
    </div>
  }
  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth = {'md'} onClose={handleClose} aria-labelledby="form-dialog-title">
         <DialogTitle className="dialog-header" id="form-dialog-title">{title}</DialogTitle>
         <DialogContent className="dialog-content" onScroll={handleScroll}>
            <div className="contact-dialog-button-row" >
              <div className="contact-dialog-add-button me-4">
                <BkButton caption='Legg til kontakt' onClickHandler={handleAddContact} />
              </div>
              <div className="contact-dialog-search">
                <input className={['form-control', ''].join(' ')} placeholder="Søk" name="ContactSearch" type="text" value={searchText} onChange={handleContactSearch} />
              </div>
            </div>
            <div className="contact-dialog mt-3">
               <div className="bk-row-contact bk-row-header-text">
                  {displayBlank}
                  <div className="flex-4">Navn</div>
                  <div className="flex-3">Epost</div>
                  <div className="flex-2">Mobil</div>
                  <div className="flex-5">Adresse</div>
                  {/* <div className="action-menu"> <ActionMenu /></div> */}
               </div>
               <div className='contact-dialog-content-text' > {contactsHtml}</div>     
            </div>
        </DialogContent>
        <DialogActions>
          {hideDate == false && selectDateHtml}
          <ContactButton caption='Avbryt' onClickHandler={handleClose}/>
          <BkButton caption='OK'  onClickHandler={handleSaveAndClose} />
        </DialogActions>
      </Dialog>

      <ContactDialog 
        contactData={contactData}
        open={openEdit}
        handleChangeCb={handleContactUpdated}
      />
    </>
  )
}

export default React.memo(SelectContactDialog);