import React, {useState, useEffect} from 'react'


type ContactRowProps = {
   readonly refId: number;
   readonly name: string;
   readonly phone: string;
   readonly email: string;
   readonly comment?: string;
   readonly heading?: string;
   readonly displayHeading?: boolean;
   readonly displayComment?: boolean;
   readonly readOnly?: boolean;
   readonly displayHeader:boolean,
   readonly onContactClickedCb?: () => void;
   readonly onCommentChangeCb?: (id:number, value: string) => void;
 };

const keyPressTimer = 1000;
let inputTimer = null;

function ContactInformationRow(props:ContactRowProps) {

   const [comment, setComment] = useState('');

   useEffect(() => {
      setComment(props.comment);
   }, [props]);


   const onBlur = (e) => {
      const { name, value } = e.target;
      setComment(value);

      if (inputTimer !== null){
         clearTimeout(inputTimer);
         props.onCommentChangeCb(props.refId, value);
      }
   }

   const onValueChange = (e) => {
      const { name, value } = e.target;
      setComment(value);
    
      if (inputTimer !== null){
         clearTimeout(inputTimer);
       }
       inputTimer = setTimeout(() => {
         props.onCommentChangeCb(props.refId, value);
       }, keyPressTimer);  
   }

   const onInputClicked = () => {
      props.onContactClickedCb();
   }
  
   return <>
      <div className='module-row'>
         <div className='flex-2 module-row-header'>
            { props.displayHeader &&
               props.heading
            }
            </div>
         <div className='flex-3' onClick={onInputClicked}>
            <input readOnly={true} type='text' placeholder="" className="form-control module-row-input" 
            name='policyHolder' value={props.name} />
         </div>
         <div className='flex-2' onClick={onInputClicked}>
            <input readOnly={true} type='text' placeholder="" className="form-control module-row-input" 
            name='phone' value={props.phone}/>                 
         </div>
         <div className='flex-3' onClick={onInputClicked}>
            <input readOnly={true} type='text' placeholder="" className="form-control module-row-input" 
            name='email' value={props.email}/>                 
         </div>
         <div className='flex-3'>
            <input readOnly={props.readOnly} type='text' placeholder="" className="form-control module-row-input" 
            name='comment' value={comment} onChange={onValueChange} onBlur={onBlur}/>                 
         </div>
      </div>     
   </>
}
  
export default ContactInformationRow;