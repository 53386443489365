import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { CalculationTemplateApi } from '../../api';
import { CalculationTemplateCategory } from './child-components';


const CalculationTemplate = () => {
   const [categories, setCategories] = React.useState([]);

   useEffect(() => {
      CalculationTemplateApi.get()
      .then (
      (response) => {
         setCategories(response.data);
      },
      (error) => {
         console.log(error);
      }
      );
   }, [])

   const handleCategoryAdd = function() {
         
      let cat = {
         description:'',
         order: getLastRowOrder() + 1,
         rows:[{
            description:'',
            order:1
         }]
      }

      CalculationTemplateApi.add(cat)
      .then (
         (response) => {
            setCategories(categories => [...categories, response.data]);
         },
         (error) => {
           console.log(error);
         }
       );
   }

   const getLastRowOrder = function() {
      var order = 0;
      categories.map((category) => {
        if (category.order > order) {
          order = category.order;
        }
        return category;
      });
      return order;
    }


  const handleCategoryUpdated = function(category) {
    updateCategory(category);
    var newCategories = categories.map((orgCategory) => {
      if (orgCategory.id === category.id) {
        //calculate
        category.unitTotal = 0;
        category.hoursTotal = 0;
        category.workTotal = 0;
        category.total = 0;
        category.rows.map((row) => {
          category.unitTotal = category.unitTotal + row.unitTotal;
          category.hoursTotal = category.hoursTotal + row.hours;
          category.workTotal = category.workTotal + row.workTotal;
          category.total = category.total + row.total;
          return row;
        });

        return category;
      }
      return orgCategory;
    })
    setCategories(newCategories);
  }

  const handleCategoryMoveUp = function(categoryId) {
    var selectedIndex = 0;
    categories.map((category, index) => {
      if (category.id === categoryId) {
        selectedIndex=index;
      }
      return category;
    })

    if (selectedIndex === 0) return; // already on top

    let data = [...categories];
    var firstOrder = data[selectedIndex-1].order;
    var lastOrder = data[selectedIndex].order;
    let temp = data[selectedIndex-1];
    temp.order = lastOrder;
    data[selectedIndex].order = firstOrder;
    data[selectedIndex-1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setCategories(data);
    updateCategory(data[selectedIndex]);
    updateCategory(data[selectedIndex-1]);
  }

  const handleCategoryMoveDown = function(categoryId) { 
    var selectedIndex = categories.length-1;
    categories.map((category, index) => {
      if (category.id === categoryId) {
        selectedIndex=index;
      }
      return category;
    })

    if (selectedIndex === categories.length-1) return; // already at the end

    let data = [...categories];
    var firstOrder = data[selectedIndex].order;
    var lastOrder = data[selectedIndex+1].order;
    let temp = data[selectedIndex+1];
    temp.order = firstOrder;
    data[selectedIndex].order = lastOrder;
    data[selectedIndex+1] = data[selectedIndex];
    data[selectedIndex] = temp;
    setCategories(data);
    updateCategory(data[selectedIndex]);
    updateCategory(data[selectedIndex+1]);
  }


  const handleCategoryDelete = function(categoryId) { 
   CalculationTemplateApi.delete(categoryId)
    .then (
      (response) => {
        //Recalculate? 
        setCategories(categories.filter(category =>category.id !== categoryId));
        //setRows(rows.filter(row =>row.simpleCalculationRowId !== rowId));       
        //recalculate(category.rows.filter(row =>row.simpleCalculationRowId !== rowId));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleCategoryCopy = function(categoryId) { 
    var catToCopy = null;
    categories.map((category, index) => {
      if (category.id === categoryId) {
        catToCopy=category;
      }
      return category;
    })

    if (catToCopy === null) {
      return;
    }

    catToCopy.order = getLastRowOrder() + 1;

    CalculationTemplateApi.add(catToCopy)
    .then (
       (response) => {
          setCategories(categories => [...categories, response.data]);
       },
       (error) => {
         console.log(error);
       }
     );

  }


  const updateCategory = function(category) {
   CalculationTemplateApi.update(category)
    .then (
      (response) => {
       //
      },
      (error) => {
        console.log(error);
      }
    );
  }

  let categoriesHtml=null;
  if (categories) {
    categoriesHtml = categories.map((category, index) =>
    {
      return (
        <CalculationTemplateCategory 
          key={'SCC_' + index}
          categoryRow={category} 
          index={index} 
          updateCb={handleCategoryUpdated}
          moveUpCb={handleCategoryMoveUp} 
          moveDownCb={handleCategoryMoveDown} 
          deleteCb={handleCategoryDelete} 
          copyCb={handleCategoryCopy} 
          />
      );
    })
  }

  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
              elevation={0}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleCategoryAdd}>Legg til mal</MenuItem>
          </Menu>
        </div>;

  }


      return (
      <>
         <div className="bk-container">
            <div className="simple-calc-wrapper">
               <div className="simple-calc-header">
                  Malbank - Kalkyler
               </div>         
               <br className="clear-both" />
               <div className="simple-calc-section">
                  <div className="simple-calc-calc-heading">
                     <div className="simple-calc-calc-heading-wrapper flex-1">
                        <div className="simple-calc-calc-heading-item simple-calc-vertical-align">Post</div>
                     </div>
                     <div className="simple-calc-calc-heading-wrapper flex-3">
                     <div className="simple-calc-calc-heading-item simple-calc-vertical-align">Beskrivelse</div>
                     </div>
                     <div className="simple-calc-calc-heading-wrapper flex-4">
                        <div className="simple-calc-calc-heading-item">Materiell</div>
                        <div className="simple-calc-calc-heading-row">
                           <div className="simple-calc-calc-heading-item flex-1 simple-calc-border-right">Antall Enheter</div>
                           <div className="simple-calc-calc-heading-item flex-1 simple-calc-border-right">Enhet</div>
                           <div className="simple-calc-calc-heading-item flex-1 simple-calc-border-right">Enhetspris</div>
                           <div className="simple-calc-calc-heading-item flex-1">Sum Materiell</div>
                        </div>
                     </div>
                     <div className="simple-calc-calc-heading-wrapper flex-3">
                        <div className="simple-calc-calc-heading-item">Arbeid</div>
                        <div className="simple-calc-calc-heading-row">
                           <div className="simple-calc-calc-heading-item flex-1 simple-calc-border-right">Antall Timer</div>
                           <div className="simple-calc-calc-heading-item flex-1 simple-calc-border-right">Timesats</div>
                           <div className="simple-calc-calc-heading-item flex-1">Sum Arbeid</div>
                        </div>
                     </div>
                     <div className="simple-calc-calc-heading-wrapper flex-1">
                     <div className="simple-calc-calc-heading-item simple-calc-vertical-align">Sum Post</div>
                     </div>      
                     <div className="simple-calc-heading-menu">
                        <ActionMenu />
                     </div>
                  </div>
                  {categoriesHtml}
               </div>
            </div>
         </div>
      </>
   );

}


export default React.memo(CalculationTemplate);