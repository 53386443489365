import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class PriceguideService extends BaseService {
   private static _priceguideService: PriceguideService;


   private constructor(controllerName: string) {
      super(controllerName);
   }


   public static get Instance(): PriceguideService {
      return this._priceguideService || (this._priceguideService = new this('priceguide'));
   }

   public async getProductCategories(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('getProductCategories', {withCredentials: true})
         .then((response) => {
         axiosResponse = response;
         }, 
         (error) => {this.handleError(error); });
      
      return axiosResponse;
   }

   public async getProductsFromCategory(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetProductsFromCategory?productCategoryId=' + id, {withCredentials: true})
         .then((response) => {
         axiosResponse = response;
         }, 
         (error) => {this.handleError(error); });
      
      return axiosResponse;
   }

   public async getProductsFromFilter(filter): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('getProductsFromFilter?filter=' + filter, {withCredentials: true})
         .then((response) => {
         axiosResponse = response;
         }, 
         (error) => {this.handleError(error); });
      
      return axiosResponse;
   }

}

export const PriceguideApi = PriceguideService.Instance;