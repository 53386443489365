import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class BuildingMaintenanceModuleService extends BaseService {

   private static _buildingMaintenanceModuleService: BuildingMaintenanceModuleService;

   private constructor (controllerName: string){
         super(controllerName);
   }

   public static get Instance(): BuildingMaintenanceModuleService{
         return this._buildingMaintenanceModuleService || (this._buildingMaintenanceModuleService = new this ('buildingMaintenanceModule'))
   }

   public async getModule(moduleId:number): Promise<AxiosResponse | null>{
      var axiosResponse;

      if(!moduleId){
         this.handleAllErrors(` moduleId arg. was not provided.`); 
         Promise.reject();
      }

      await this.$http.get('get/' + moduleId, {withCredentials:true})
      .then((responce) => {
         axiosResponse = responce;
      },
      (error) => {
         this.handleAllErrors(error);
         throw(error);
      })

      return axiosResponse
   }

   public async addMaintenanceModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addMaintenanceModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }



   public async addBuilding(buildingMaintenanceModuleId:number): Promise<AxiosResponse> {
      var axiosResponse;

      if (!buildingMaintenanceModuleId || buildingMaintenanceModuleId == 0){
         this.handleAllErrors(` Data was not provided for addBuilding.`); 
         Promise.reject();
      }
      var data = {
         id:buildingMaintenanceModuleId
      }
      await this.$http.post('AddBuilding',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateBuilding(model): Promise<AxiosResponse> {
      var axiosResponse;

      if (!model){
         this.handleAllErrors(` Data was not provided for updateBuilding`); 
         Promise.reject();
      }
      
      await this.$http.post('UpdateBuilding',model, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });

      return axiosResponse;
   } 

   public async deleteBuilding(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.delete('DeleteBuilding/'+ id, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });

      return axiosResponse;
   }

   public async addCategory(data): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` data was not provided for addCategory.`); 
         Promise.reject();
      }
      
      await this.$http.post('AddCategory',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });

      return axiosResponse;
   } 

   public async updateCategory(model): Promise<AxiosResponse> {
      var axiosResponse;

      if (!model){
         this.handleAllErrors(` Data was not provided for updateCategory`); 
         Promise.reject();
      }
      
      await this.$http.post('UpdateCategory',model, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });

      return axiosResponse;
   } 

   public async deleteCategory(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.delete('DeleteCategory/'+ id, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });

      return axiosResponse;
   }

   public async updateDetail(data): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for updateDetail`); 
         Promise.reject();
      }
      
      await this.$http.post('UpdateDetail',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });

      return axiosResponse;
   } 

   public async deleteDetail(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.delete('DeleteDetail/'+ id, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });

      return axiosResponse;
   }




      
}

export const BuildingMaintenanceModuleApi = BuildingMaintenanceModuleService.Instance;