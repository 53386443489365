import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { DocumentControl, DocumentControlDocument} from '../containers/Modules/DocumentControlModule/types'

class DocumentControlModuleService extends BaseService {
   private static _documentcontrolModuleService: DocumentControlModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): DocumentControlModuleService {
     return this._documentcontrolModuleService || (this._documentcontrolModuleService = new this('documentControlModule'));
   }
 

   public async getDocumentControlModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addDocumentControlModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addDocumentControlModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async updateDocumentControl(data: DocumentControl): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateDocumentControl.`); 
        Promise.reject();
      }
      
      await this.$http.post('Update',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async updateDocumentControlDocument(data: DocumentControlDocument): Promise<AxiosResponse> {
      var axiosResponse;
  
      if (!data){
        this.handleAllErrors(` Data was not provided for updateDocupdateDocumentControlDocumentumentControl.`); 
        Promise.reject();
      }
      
      await this.$http.post('UpdateDocument',data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleAllErrors(error);  throw (error); });
  
      return axiosResponse;
    } 

    public async deleteDocument(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.delete('deleteDocument/'+ id, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
  
      return axiosResponse;
    }
}

export const DocumentControlModuleApi = DocumentControlModuleService.Instance;