import React,  {useRef,useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RoutesConfig } from '../../config/routes.config';
import { actionCreators } from '../../store/auth';
import {TextField} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { AuthApi } from '../../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const SignupSchema = Yup.object().shape({
   email: Yup.string().email('Ugyldig epost adresse').required('Må fylles ut'),
   password: Yup.string()
   .min(4, 'Minimum 4 karakterer')
   .required('Må fylles ut'),
   confirmPassword: Yup.string()
   .oneOf([Yup.ref('password'), null], 'Passordene må matche')
   .required('Må fylles ut'),
});

const ResetPassword: React.FC = () => {
   const history = useHistory();
   const toastIdRef = useRef<string | number>('');

   const search = useLocation().search;
   const code = new URLSearchParams(search).get('code');
   const emailInput = new URLSearchParams(search).get('email');

   const [email, setEmail] = useState(null);

   useEffect(() => {
      setEmail(emailInput);
      formik.values.email=emailInput;
   }, [emailInput]);

   const formik = useFormik({
      initialValues: {
         email: '',
         password: '',
         confirmPassword: ''
      },
      validationSchema: SignupSchema,
      onSubmit: (values) => {
         var dataToSend= {
            email:values.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
            code:code
         }
   
         AuthApi.ResetPassword(dataToSend)
         .then(
            (response) => {
               //history.push(RoutesConfig.Login.path)
               history.push(RoutesConfig.Login.path)
            },
            (error) => {
               var msg = "Resetting av passord feilet feilet";
               if (error.message != undefined) {
                  msg = error.message;
               }
      
               if (!toast.isActive(toastIdRef.current)) {
                  toastIdRef.current = toast.error(
                     renderToastifyMsg(msg, 'exclamation-triangle')
                  );
               }
            }
         );
      },
  });

  const useTextFieldStyles = makeStyles(() => ({
   root: {
   '& label.Mui-focused': {
     color: '#7DA868',
   },
   '& .MuiInput-underline:after': {
     borderBottomColor: '#7DA868',
   },
   }
 }));

 const textFieldStyles = useTextFieldStyles(false);


   return (
      <div className="container">
         
         <div className="bk-row-header bk-row-header-title mb-1">
              Endre passord
              { email != null &&
              <>
               &nbsp;for {email}
              </>
              }
            </div>
            <div className="report-content">
            <div className=" mt-4">

           <form onSubmit={formik.handleSubmit}>
               <div>
                  { email == null &&
                  <div className="row">
                     <div className='col-md-4'>
                        <TextField autoFocus margin="dense" className={textFieldStyles.root} required
                        id="email" label="E-post" type="text" fullWidth 
                        value={formik.values.email} onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)} 
                        helperText={formik.touched.email && formik.errors.email}/>
                        </div>
                  </div>
                  }
                  <div className="row">
                  <div className='col-md-4'>
                     <TextField margin="dense" className={textFieldStyles.root} required 
                        type="password"  label="Passord"  id="password" fullWidth
                        value={formik.values.password} onChange={formik.handleChange}  
                        error={formik.touched.password && Boolean(formik.errors.password)} 
                        helperText={formik.touched.password && formik.errors.password}/>
                        </div>
                  </div>

                  <div className="row">
                  <div className='col-md-4'>
                     <TextField margin="dense" className={textFieldStyles.root} required 
                        type="password"   label="Bekreft passord"  id="confirmPassword" fullWidth
                        value={formik.values.confirmPassword} onChange={formik.handleChange}  
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)} 
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}/>
                        </div>
                  </div>
                  <br></br>
                  <div className="row">
                  <div className='col-md-4'>
                     <button className="register-button" type="submit">Sett passord</button>
                     </div>
                  </div>
               </div>  
            </form>
            </div>
         </div>
      </div>

   );
   };


   const mapStateToProps = (state: IApplicationState) => state.auth;

export default connect(mapStateToProps, actionCreators)(ResetPassword);
