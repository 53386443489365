import React from 'react';
import { useTextInput } from '../../../hooks';
import { createClassName } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';

type UserNameInputProps = {
  readonly isInputInvalid: boolean;
  readonly textInput: ReturnType<typeof useTextInput>;
};

const UserNameInput = React.memo<UserNameInputProps>(
  ({ textInput, isInputInvalid }) => {
    const { hasValue, bindToInput } = textInput;

    const className = createClassName([
      'input',
      'is-medium',
      (isInputInvalid && !hasValue) && 'is-danger',
    ]);

    return (
        <div className='login-field mb-3'>
        <Form.Control autoFocus
            {...bindToInput}
            className="login-text"
            placeholder='Brukernavn' />
        </div>
    );
  }
);

UserNameInput.displayName = 'UserNameInput';

export default UserNameInput;
