import React, {useState, useEffect, Fragment} from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators} from '../../store/auth';
import { CalculationApi, ProjectApi } from '../../api';
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router-dom";
import {ValueReduction, TotalSum, SimpleSum, AreaTable, CalculationProperty, SumProperty} from './child-components'
import {ProjectLink, SectionHeader, Spinner} from '../../components'
import { CostFactorCategory } from '../../config/constants';
import { cloneDeep } from 'lodash';

type CalculationProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly role: number;
  readonly userName: string;
};

interface CalculationPropertyListItem {
  name:string,
  id:string
}

interface ResidenceWithFloorsData {
  floors:  Array<CalculationPropertyListItem>;
  residenceName:string
}


const keyPressTimer = 500;
let reductionTimer = null;
let descriptionTimer = null;



const Calculation: React.FC<CalculationProps> = ({ 
  isAuthenticated, 
  userName, 
  role
}) => {
  const [isLoading, setIsLoading] = useState(true);
  
  const [totalValue, setTotalValue] = useState(0.0);
  const [totalArea, setTotalArea] = useState(0.0);
  const [totalValueReduction, setTotalValueReduction] = useState(0.0);
  const [defaultValueReductionId, setDefaultValueReductionId] = useState(0);
  const [buildYears, setBuildYears] = useState([]);
  const [buildingCost, setBuildingCost] = useState(0);
  const [properties, setProperties] = useState([]);
  const [additions, setAdditions] = useState([]);
  const [valueReductions, setValueReductions] = useState([]);
  const [valueReductionTypes, setValueReductionTypes] = useState({
    costFactorEntities:[]
  });
  const [residenceTypes, setResidenceTypes] = useState<Array<ResidenceWithFloorsData>>([]);
  const [propertyPendingUpdate, setPropertyPendingUpdate] = useState({
    propertyId:0,
    numericValue:'',
    textValue:''
  });
  const [factorsChanged, setFactorsChanged] = useState(false);

  const [areaPendingUpdate, setAreaPendingUpdate] = useState({
    propertyId:0,
    id:0,
    value:''
  });

  const [calculation,setCalculation]= useState({
    projectId:0,
    calculationId:0,
    description: '',
    primaryArea:0,
    secondaryArea:0,
    buildYear:1980,
    totalCost:0,
    reductionPercent:0,
    defaultValueReductionId:0,
    calculationTypeId:0,
    calculationTypeGroupId:0
  });

  const [project, setProject] = useState({
    address: {
      address:'',
      zipCode:'',
      city:''
    },
    userName:''
  });

  

  const params = useParams();
  const calculationId = (params as any).id;
  const history = useHistory();

  let user_data = JSON.parse(localStorage.getItem('user'))==null ?  [] : JSON.parse(localStorage.getItem('user'));

  if(user_data === null || user_data.length === 0)
  {
    actionCreators.logoutUser();
    history.push("/login");
  }

  useEffect(() => {
    const fetchData = async () => {
      CalculationApi.getCalculation(calculationId)
      .then(
        (response) => {
          setDefaultValueReductionId(response.data.defaultValueReductionId);
          setBuildYears(response.data.buildYears);
          setTotalValue(response.data.totalCost);
          setCalculation(response.data.calculation);
          setResidenceTypes(response.data.residencesByIdWithData as Array<ResidenceWithFloorsData>);
          setTotalArea(response.data.grossArea);
          setTotalValueReduction(response.data.totalReductionPercent);
          // Handles special case: if it is only one residence type per calculation type. For example: anneks, set it as default.
          const propertiesWithSetSelectedResidenceIfOnlyOneResidence = setSelectedResidenceIfOnlyOneResidence(response.data.properties);
          const propertiesWithAddedFloorsPerResidence = setRelatedFloorsOnInit(propertiesWithSetSelectedResidenceIfOnlyOneResidence, response.data.residencesByIdWithData);
          setProperties(propertiesWithAddedFloorsPerResidence);
          setAdditions(response.data.additions);
          setValueReductionTypes(response.data.valueReductionTypes);
          setValueReductions(response.data.valueReductions);
          getProject(response.data.calculation.projectId);
          setFactorsChanged(response.data.factorsChanged);

          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
          actionCreators.logoutUser();
          history.push("/login");
          
        });
    }
    fetchData();
  }, []);


  const getProject = function(projectId) {
      ProjectApi.getProject(projectId)
      .then(
      (response) => {            
        setProject(response.data.data);
      },
      (error) => {
        console.log(error);
        actionCreators.logoutUser();
        history.push("/login");    
      }); 
  }
  

  const newAdditionHandler = function() {
    var data = {
      calculationId : parseInt(calculationId),
      description: ''
    };
    CalculationApi.addCalculationAddon(data)
    .then (
      (response) => {
        setAdditions(old=> [...old,response.data]);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const deleteCalculationAddon = function(id) {
    CalculationApi.deleteCalculationAddon(parseInt(id))
    .then (
      (response) => {
        setAdditions(additions.filter(addition =>addition.calculation.calculationId !== id));

        //Update changed values
        setTotalValue(response.data.totalCost);
        setTotalArea(response.data.totalArea);
        setValueReductionPercent(calculationId, response.data.reductionPercent ); 
        setTotalValueReduction(response.data.totalReductionPercent);
        setFactorsChanged(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const checkInputValue = function(value) {
    if (value === "")
      return 0;
    let intVal = parseInt(value);
    if (isNaN(intVal) || intVal < 0) {
      //Invalid input
      return NaN;
    }
    return intVal;
  }


  const packProperty = (propertyTypeId, value) => {
    var property = {        
      propertyTypeId:propertyTypeId, //secondary Area
      numericValue: value.toString(),
    }
    var data = [];
    data.push(property);

    return data;
  }

  const handleAreaChange = (area) => {

    if (area.calculationId == calculation.calculationId) {
      if (area.buildYear != calculation.buildYear) {
        setCalculation(calculation => ({ ...calculation, buildYear: area.buildYear}));
        UpdateBuildYear(calculation.calculationId, area.buildYear);
      }
      else if (area.primaryArea != calculation.primaryArea) {
        setCalculation(calculation => ({ ...calculation, primaryArea: area.primaryArea}));
        updateCalculationProperty(calculation.calculationId, packProperty(27, area.primaryArea));
      } 
      else if (area.secondaryArea != calculation.secondaryArea) {
        setCalculation(calculation => ({ ...calculation, secondaryArea:area.secondaryArea }));
        updateCalculationProperty(calculation.calculationId, packProperty(28, area.secondaryArea));
      }
    }
    else {
      let newArr = additions.map((addition) => {
        if (addition.calculation.calculationId === area.calculationId) {
          if (addition.calculation.buildYear != area.buildYear) {
            addition.calculation = {...addition.calculation, buildYear:area.buildYear};
            UpdateBuildYear(addition.calculation.calculationId, area.buildYear);
          }
          else if (addition.calculation.primaryArea != area.primaryArea) { 
            addition.calculation = {...addition.calculation, primaryArea:area.primaryArea};
            updateCalculationProperty(addition.calculation.calculationId, packProperty(27, area.primaryArea));
          }
          else if (addition.calculation.secondaryArea != area.secondaryArea) { 
            addition.calculation = {...addition.calculation, secondaryArea:area.secondaryArea};
            updateCalculationProperty(addition.calculation.calculationId, packProperty(28, area.secondaryArea));
          }
        }
          return addition;
      });
  
      setAdditions(newArr);
    }
  }


  const UpdateBuildYear = (id, value) => {
    var data = {
      calculationId: id,
      buildYear: value
    }
    CalculationApi.updateBuildYear(data).then (
      (response) => {
        // Set default reduction Id
        if (id === calculation.calculationId) {
          setDefaultValueReductionId(response.data)          
        }
        else {
          let newAdd = additions.map((addition) => {
            if (addition.calculation.calculationId === id) {
              addition = {...addition, defaultValueReductionId:response.data};
            }
            return addition;
          });
          setAdditions(newAdd);
        }
      },
      (error) => {
        console.log(error);
      }
    );


  }


  const setValueReductionPercent = function(id, percent) {
    if (id === calculation.calculationId) {
      setCalculation(calculation =>({...calculation, reductionPercent:percent}))
    }
    else {
      additions.map((addition)=> {
        if (addition.calculation.calculationId === id) {
          addition.calculation.reductionPercent=percent;
        }
        return addition;
      })
    }
  }

  
  const setAdditionValueReductionPercent = function(additions2, id, percent) {

    var newAdd = additions2.map((addition)=> {
      if (addition.calculation.calculationId === id) {
        const tempAddition = cloneDeep(addition);
        tempAddition.calculation.reductionPercent=percent;
        return tempAddition;
      }
      return addition;
    });

    return newAdd;
    
  }


  const getResidenceFloors = function (residenceTypes: Array <ResidenceWithFloorsData>, residenceId: number = 0)
  :Array<CalculationPropertyListItem>
  {

    if (!residenceTypes){
      return null;
    }
    residenceId = residenceId ? residenceId: 0;

    return residenceTypes[residenceId]? residenceTypes[residenceId].floors: null;
  }

  const resetSelectedFloorIfNotExist = function(currentProps:any, floors: Array<CalculationPropertyListItem>):any{
    if (!currentProps || currentProps.length <= 0){
      return currentProps;
    }

    const propertyFloorsIndex = currentProps.findIndex((x) => x.propertyTypeId === CostFactorCategory.ResidenceFloors);

    if(propertyFloorsIndex == -1){
      return currentProps;
    }

    const propertyFloors = currentProps[propertyFloorsIndex];
    const currentFloorIsExist = floors && floors.length > 0 && propertyFloors? floors.find(x=>x.id === propertyFloors.selectedId): null;

    if (!currentFloorIsExist)
    {
      propertyFloors.selectedId = null;
      }

      return currentProps;

  }


  // Handles special case: if it is only one residence type per calculation type. For example: anneks.
  const setSelectedResidenceIfOnlyOneResidence = function (currentProps:any): any{
    
      const propertyResidence = currentProps.find((x) => x.propertyTypeId === CostFactorCategory.Residence);

      if(!propertyResidence){
        return currentProps;
      }   

      let selectedResidenceId = propertyResidence.selectedId;

      // if it is only one residence type per calculation type. For example: anneks 
      if (!selectedResidenceId && propertyResidence.items && propertyResidence.items.length === 1){
        let residenceItem = propertyResidence.items[0] as CalculationPropertyListItem;
        selectedResidenceId = residenceItem.id;
        propertyResidence.selectedId = residenceItem.id;
      }

      return currentProps;

  }

  const setRelatedFloorsIfResidenceTypeChanged = function(newResidenceId:number, currentProps:any, residenceTypes: Array<ResidenceWithFloorsData>):any 
  {
    // find floors
    const propertyResidenceIndex = currentProps.findIndex((x) => x.propertyTypeId === CostFactorCategory.ResidenceFloors);  
    
    if(propertyResidenceIndex == -1){
      return currentProps;
    }

    const items = getResidenceFloors(residenceTypes, newResidenceId);
    currentProps[propertyResidenceIndex].items = items;

    let result = resetSelectedFloorIfNotExist(currentProps, items)
    return result;
    
  }

  const setRelatedFloorsOnInit = function (currentProps:any, dataWithResidenceTypes: Array<ResidenceWithFloorsData>):any {

    const propertyResidence = currentProps.find((x) => x.propertyTypeId === CostFactorCategory.Residence);

    if(!propertyResidence){
      return currentProps;
    }    

    let selectedResidenceId = propertyResidence.selectedId;

    const result = setRelatedFloorsIfResidenceTypeChanged(selectedResidenceId, currentProps, dataWithResidenceTypes);

    return result;
  } 
  


  const onTechnicalCalculationUpdated = function(changedProperty) {
    var changedProperties = [];
    changedProperties.push(changedProperty);

    var newArray = properties.map((prop) => {      
      if (prop.propertyTypeId == changedProperty.propertyTypeId)   {
        if (prop.controlTypeId == 12) {
          if (changedProperty.numericValue == 0) {
            changedProperty.required=false;
          }
          else {
            changedProperty.required=true;
          }
        }      
        return changedProperty;
      }
      if (changedProperty.controlTypeId ==12 ){
        if (prop.controlTypeId == 12 && prop.propertyTypeId != changedProperty.propertyTypeId) {
          prop.numericValue = 100 - changedProperty.numericValue;
          changedProperties.push(prop);
          if (prop.numericValue == 0) {
            prop.required=false;
          }
          else {
            prop.required=true;
          }
          return prop;
        }
      }
      return prop;
    });
    setProperties(newArray);
    updateCalculationProperty(calculation.calculationId, changedProperties);  
  }


  const updateCalculationProperty = function(id, properties) {
    var data =  {
      calculationId: id,
      properties:[]
    };
      
    properties.map((property)=> {
      var element = {        
        id: property.propertyTypeId,
        selectionId: 0,
        numericValue: property.numericValue.toString(),
        textValue: property.textValue,
        selectedValue: ''
      }
      data.properties.push(element);
    });
    CalculationApi.updateCalculation(data).then (
      (response) => {
        setTotalValue(response.data.totalCost);
        setTotalArea(response.data.totalArea);
        setValueReductionPercent(calculationId, response.data.reductionPercent ); 
        setTotalValueReduction(response.data.totalReductionPercent);
        setFactorsChanged(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }


  const handleAdditionValueReductionChange = function(idString, valueReductionId, value) {
    var intValue = 0;
    var selectedId = 0;
    var textValue = '';
    var id = parseInt(idString);

    if (id === null || id === undefined){
      return;
    }

    if ( id === 0) {
      return;
    }
  
    var newAdd = additions.map((add) =>
    {
      if (add.calculation.calculationId === id) {
        let newRed = add.valueReductions.map((red) => {
          if (red.valueReductionId === valueReductionId) {
            if (red.controlTypeId === 0) {
              selectedId = parseInt(value);
              red = {...red, selectedId:selectedId};
            }
            else if (red.controlTypeId === 3) {
              intValue = checkInputValue(value);
              if (isNaN(intValue)) {
                return red;
              }
              red = {...red, numericValue:intValue};
              // Special handling of explanation. Only show if value other than 0
              add.valueReductions.map((red) => {
                if (red.controlTypeId === 4) {
                  if (intValue > 0) {
                    red.visible = true;
                  }
                  else {
                    red.visible = false;
                  }
                }   
                return true;      
              });
            }
            else if (red.controlTypeId === 4) {
              textValue = value;
              red = {...red, textValue:value};
            }
          }
          return red
        });
        add= {...add, valueReductions:newRed};
     

      }
      return add;
    });
    setAdditions(newAdd);

    if (reductionTimer !== null){
      clearTimeout(reductionTimer);
    }
    reductionTimer = setTimeout(() => {
      delayedAdditionValueReductionUpdate(id, valueReductionId, intValue, selectedId, textValue);
    }, keyPressTimer);
  }


  const delayedAdditionValueReductionUpdate = function(id, valueReductionId, intValue, selectedId, textValue) {
    var data = {
      calculationId: id,
      valueReductionId: valueReductionId,
      numericValue: intValue,
      selectedId: selectedId,
      textValue: textValue
    }

    CalculationApi.updateValueReduction(data).then (
      (response) => {
        setAdditions((adds) => {
          const result = setAdditionValueReductionPercent(adds, id, response.data.reductionPercent );
          return result;
        });

        setTotalValueReduction(response.data.totalReductionPercent);
      },
      (error) => {
        console.log(error);
      }
    );
  }


  const handleValueReductionChange = function(idString, valueReductionId, value) {
    var intValue = 0;
    var selectedId = 0;
    var textValue = '';
    var id = parseInt(idString);
    if (id === null || id === undefined){
      id = 0;
    }
    var calcId = 0;
    if ( id === 0) {
      calcId = parseInt(calculationId);
    }

    let newRed = valueReductions.map((red) => {
      if (red.valueReductionId === valueReductionId) {
        if (red.controlTypeId === 0) {
          selectedId = parseInt(value);
          red = {...red, selectedId:selectedId};
        }
        else if (red.controlTypeId === 3) {
          intValue = checkInputValue(value);
          if (isNaN(intValue)) {
            return red;
          }
          red = {...red, numericValue:intValue};
          // Special handling of explanation. Only show if value other than 0
          valueReductions.map((red) => {
            if (red.controlTypeId === 4) {
              if (intValue > 0) {
                red.visible = true;
              }
              else {
                red.visible = false;
              }
            }
            return true;         
          });
        }
        else if (red.controlTypeId === 4) {
          textValue = value;
          red = {...red, textValue:value};
        }
      }
      return red
    });
    setValueReductions(newRed);

    if (reductionTimer !== null){
      clearTimeout(reductionTimer);
    }
    reductionTimer = setTimeout(() => {
      delayedValueReductionUpdate(id, calcId, valueReductionId, intValue, selectedId, textValue);
    }, keyPressTimer);
  }

  const delayedValueReductionUpdate = function(id, calcId, valueReductionId, intValue, selectedId, textValue) {
    var data = {
      calculationId: calcId,
      valueReductionId: valueReductionId,
      numericValue: intValue,
      selectedId: selectedId,
      textValue: textValue
    }

    CalculationApi.updateValueReduction(data).then (
      (response) => {
        setValueReductionPercent(calcId, response.data.reductionPercent );
        setTotalValueReduction(response.data.totalReductionPercent);
      },
      (error) => {
        console.log(error);
      }
    );
  }



  const handleBuildYearClicked = function(id) { 
    var calcId = parseInt(id);
    if (calcId === null || calcId === undefined){
      return;
    }
    if (calcId === 0) {
      calcId = calculation.calculationId;
    }

    var data = {
      calculationId: calcId,
      costFactorId: defaultValueReductionId
    }
    CalculationApi.setSameBuildYear(data).then (
      (response) => {
        setValueReductionPercent(calcId, response.data.reductionPercent );
        setTotalValueReduction(response.data.totalReductionPercent);

        let newRed = valueReductions.map((valueReduction) => {
          valueReduction.selectedId = defaultValueReductionId;
          return valueReduction
        });
        setValueReductions(newRed);

      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleAdditionBuildYearClicked = function(id) { 
    var calcId = parseInt(id);
    if (calcId === null || calcId === undefined){
     return;
    }

    var costFactorId = 0;
    additions.map((addition) => {
      if (addition.calculation.calculationId === id)
      {
        costFactorId = addition.defaultValueReductionId;
      }
      return addition;
    })

    if (costFactorId <= 0) {
      return;
    }

    var data = {
      calculationId: calcId,
      costFactorId:costFactorId
    }
    CalculationApi.setSameBuildYear(data).then (
      (response) => {
        setValueReductionPercent(calcId, response.data.reductionPercent );
        setTotalValueReduction(response.data.totalReductionPercent);

        setAdditions(currentAdditions=> {

          var newAdd = currentAdditions.map((addition) => {

            if (addition.calculation.calculationId === id)
            {
              const tempAddition = cloneDeep(addition);
              tempAddition.valueReductions.forEach((valueReduction) => {
                valueReduction.selectedId = addition.defaultValueReductionId;
              });

              return tempAddition;
            }
            return addition;
          });
          const result = setAdditionValueReductionPercent(newAdd, id, response.data.reductionPercent );
          return result;
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }


  const handleDescriptionChange = function(e) {
    const { value } = e.target;

    setCalculation(calculation => ({ ...calculation, description: value }));

    if (descriptionTimer !== null){
      clearTimeout(descriptionTimer);
    }
    descriptionTimer = setTimeout(() => {
      delayedDescriptionUpdate(calculation.calculationId, value);
    }, keyPressTimer);

  }

  const delayedDescriptionUpdate = function(calculationId, description) {
    var data = {
      calculationId : parseInt(calculationId),
      description: description
    };
    CalculationApi.updateDescription(data)
    .then (
      (response) => {
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  const handlePrintCalculationClick = function() {

    CalculationApi.getPdf(calculation.calculationId).then (
      (response) => {
      },
      (error) => {
        console.log(error);
      }
    );

  }

  const handleProjectClick = function() {
    history.push('/project/' + calculation.projectId);
 }

 const getCalculationType = function() {
  switch(calculation.calculationTypeId) {
    case 1:
      return "Bolig";
    case 2:
      return "Blokk";
    case 3:
      return "Garasje";
    case 4:
      return "Fritidsbolig";
    case 5:
      return "Tomt";
    case 6:
      return "Uthus / Bu";
    case 7:
      return "Anneks";
    }
  }

  const getName = function(index)
  {
    if (index === 0){
      return "Opprinnelig bygning";
    }
    else {
      return "Tilbygg " + index;
    }
  }

  const showOwner = (ownerName) =>{
    if (ownerName != userName && ownerName != null && ownerName != ''){
       return <span className='bk-project-owner-title'>Bygningssakkyndig: {ownerName}</span>
    }
  }



  const dataForValueReductionNotLoaded = !valueReductions || valueReductions.length <= 0 || !valueReductionTypes;
  
  let additionsHtml = null;
  if (calculation.calculationTypeId > 0 && calculation.calculationTypeId != 5 && additions) {
    additionsHtml = additions.map((addition, index) =>
    {
      return (
         <Fragment key={index}>
            <div key={index} className="bk-page-section">
              {
                      dataForValueReductionNotLoaded?
                      '': <ValueReduction index={index+1} 
                          onChanged={handleAdditionValueReductionChange} 
                          calculationId={addition.calculation.calculationId} 
                          valueReductions={addition.valueReductions} 
                          valueReductionTypeCostFactorEntities={valueReductionTypes? valueReductionTypes.costFactorEntities: []} 
                          onBuildYearClicked={handleAdditionBuildYearClicked} />
                }
            </div>
            <div className="bk-page-section">
              <SectionHeader title={'Sammendrag - ' + getName(index+1)}></SectionHeader>
              <div className='content-frame'>
              <div className="bk-section">
                <SimpleSum  index= {index+1} buildyear={addition.calculation.buildYear} area={addition.calculation.primaryArea + addition.calculation.secondaryArea} 
                  totalCost={totalArea == 0? 0:((addition.calculation.primaryArea + addition.calculation.secondaryArea) / totalArea) * totalValue} reductionPercent={addition.calculation.reductionPercent}></SimpleSum>
              </div>
               </div>
          </div>
        </Fragment>
      )

    })
  }

  let areaTableHtml = null;
  if (calculation.calculationTypeId > 0 && calculation.calculationTypeId != 5 && calculation.calculationTypeGroupId != 3) {
      areaTableHtml = (
         <div className="bk-page-section">
            <SectionHeader title={'Byggeår og arealberegning'}></SectionHeader> 
           
           <div className='content-frame'>
               <AreaTable calculation={calculation} additions={additions} buildYears={buildYears} properties={properties} displaySecondaryArea={true}
                  handleAreaChangeCb={handleAreaChange} deleteAdditionCb={deleteCalculationAddon} newAdditioncb={newAdditionHandler}></AreaTable>
          </div>
          
          </div>
    );
    
  }
  else if (calculation.calculationTypeId > 0 && calculation.calculationTypeGroupId == 3) {
    areaTableHtml = (
       <div className="bk-page-section">
          <SectionHeader title={'Byggeår og arealberegning'}></SectionHeader> 
         
         <div className='content-frame'>
             <AreaTable calculation={calculation} additions={additions} buildYears={buildYears} properties={properties}  displaySecondaryArea={false}
                handleAreaChangeCb={handleAreaChange} deleteAdditionCb={deleteCalculationAddon} newAdditioncb={newAdditionHandler}></AreaTable>
        </div>
        
        </div>
  );
  
}


  let valueReductionHtml = null;
  if (calculation.calculationTypeId > 0 && calculation.calculationTypeId != 5) {
    valueReductionHtml = (
      <div className="bk-page-section">
        <SectionHeader title={'Verdireduksjon'}></SectionHeader>
        <div className='content-frame'>
        {
              dataForValueReductionNotLoaded?
              '':  <ValueReduction 
                      index={0} 
                      onChanged={handleValueReductionChange} 
                      calculationId={0} 
                      valueReductions={valueReductions} 
                      valueReductionTypeCostFactorEntities={valueReductionTypes? valueReductionTypes.costFactorEntities: []}
                      onBuildYearClicked={handleBuildYearClicked}
                      />
                      
        }</div>
      </div>
    );
  }

  let valueReductionSumHtml = null;
  if (calculation.calculationTypeId > 0 && calculation.calculationTypeId != 5) {
   valueReductionSumHtml = (
      <div className="bk-page-section">
         <SectionHeader title={'Sammendrag - ' + getName(0)}></SectionHeader><div className='content-frame'>
         <div className="bk-section">
          
            <SimpleSum index={0} buildyear={calculation.buildYear} area={calculation.primaryArea + calculation.secondaryArea} 
               totalCost={totalArea == 0? 0:((calculation.primaryArea + calculation.secondaryArea) / totalArea) * totalValue} reductionPercent={calculation.reductionPercent}></SimpleSum>
         </div>
         </div>
     </div>
   );
  }


  let technicalCalculationHtml = null;
  if (properties) {
    technicalCalculationHtml = properties.map ((property, index) =>
    {
      return <>
        <CalculationProperty property={property} index={index} onUpdatedCb={onTechnicalCalculationUpdated} ></CalculationProperty>
      </>
    }
    );
  }
 

  let sumHtml = null;
  if (calculation.calculationTypeId > 0 && calculation.calculationTypeId != 5) {
    sumHtml = <TotalSum area={totalArea} totalCost={totalValue} reductionPercent={totalValueReduction}></TotalSum>
  }
  else if (calculation.calculationTypeId == 5) {
    sumHtml = <SumProperty cost={totalValue} costPrSquareMeter={totalValue / totalArea}></SumProperty>
  }

  let factorsChangedWarning = null;
  if (factorsChanged) {
    factorsChangedWarning = (
      <div className="bk-page-section">
        <div className="calculation-index-warning">Det har vært en indeksregulering etter at denne kalkulasjonen ble lagret sist. Ved endringer i kalkulasjonen så vil den nye indeksen bli brukt.</div>
      </div>
    )
  }

  return (
      <div className="container">
        <div className="bk-report-header">
          
              <div className="bk-report-title">
                <div className=''>
                  Byggekostnader {/*{getCalculationType()}  */}
                </div>
                {project ? showOwner(project.userName) : ''}
              </div>

              <ProjectLink id={calculation.projectId} address={project.address.address} zipCode={project.address.zipCode} city={project.address.city}/> 
              
              <img className="bk-header-icon-printer" src="/Content/images/design/icon-print.svg" onClick={handlePrintCalculationClick}/>
            </div>

            <div className='report-content'>
      
            {factorsChangedWarning}

            <div className="row mt-2">
               <div className="col-md-1 ms-4">
                  <div className="bk-edit-comment">Merknad:</div>
                  </div>
                <div className="col-md-5">
                  <input className="form-control bk-edit-comment-input" type="text" value={calculation.description} onChange={handleDescriptionChange}/>
               </div>
            </div>
            
            {areaTableHtml}

            <div className="bk-page-section">
               <SectionHeader title={'Sammendrag'}></SectionHeader> 
               <div className='content-frame'>
               <div className="bk-section">
                  {sumHtml}
               </div>
               </div>
            </div>

            <div className="bk-page-section">
               <SectionHeader title={'Teknisk beregning'}></SectionHeader>
               <div className='content-frame'> 
               <div className="bk-section">
                <div className="calculation-frame">
                  {technicalCalculationHtml}
                </div>
                  {/* <TechnicalCalculation properties={properties} onChanged={handlePropertyChange} onTextChange={handlePropertyTextChange}></TechnicalCalculation> */}
               </div>
               </div>
            </div>

            {valueReductionHtml}

            {valueReductionSumHtml}

            {additionsHtml}
            </div>
         <Spinner isLoading={isLoading} />
    </div>

  )

}

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(Calculation);