import { AnchorHTMLAttributes } from 'react';


/**
 * HTML attributes to spread on anchor elements in Settings.tsx component
 */
export type MenuLinkAttributes = AnchorHTMLAttributes<HTMLAnchorElement>;

export const LINK_ATTRIBUTES: MenuLinkAttributes = {
  role: 'button',
  target: '_blank',
  rel: 'noopener noreferrer',
};

/**
 * HealthChecks/Swagger response path config
 */
export const NUGET_URL_CONFIG: Record<string, string> = {
  HEALTH_UI: 'http://localhost:5000/healthchecks-ui',
  HEALTH_JSON: 'http://localhost:5000/healthchecks-json',
  SWAGGER_DOCS: 'http://localhost:5000/docs'
};

export enum CostFactorCategory {
    County = 1,
    Standard = 2,
    RoofShape = 3,
    Roofing = 4,
    WallType = 5,
    CeilingHeight = 6,
    Terrace = 7,
    HighCeilingSqm = 8,
    OverbuiltArea = 9,
    RoofAddons = 10,
    ExtraLivingUnit = 11,
    ValueReduction = 12,
    NoOfApartments = 13,
    CommonArea = 14,
    TerraceBalcony = 15,
    LiftUnits = 16,
    Floor = 17,
    ExtraPorts = 18,
    ExtraDoorsWindows = 19,
    PRoomGarage = 20,
    ExtraSpaceHolidayHouse = 21,
    Electricity = 22,
    Plumbing = 23,
    ValueReductionType = 24,
    FoundationType = 25,
    RecidenceType = 26,
    PrimaryArea = 27,
    SecondaryArea = 28,
    Lift = 29,
    ManualAdjustment = 30,
    ManualAdjustmentDescription = 31,
    UsableArea = 32,
    PlotSize = 33,
    PlotPrice = 34,
    PlotWater = 35,
    PlotPlumbing = 36,
    PlotPower = 37,
    PlotOther = 38,
    PlotWork = 39,
    PlotFilling = 40,
    PlotSurplus = 41,
    PlotMountain = 42,
    PlotPlants = 43,
    PlotParking = 44,
    PlotPaving = 45,
    PlotWall = 46,
    PlotFences = 47,
    PlotLighting = 48,
    Custom = 49,
    Custom1 = 50,
    Custom2 = 51,
    Custom3 = 52,
    HighCeilingSqm42 = 53,
    ValueReductionGeneral = 54,
    TekReduction = 55,
    LightPrimaryArea = 56,
    LightSecondaryArea = 57,
    ValueReductionCustomType = 58,
    Reduction = 59,
    ReductionDescription = 60,
    ValuereductionCustomDescription = 61,
    RemainingTimeMan = 62,
    RemainingTimeWoman = 63,
    RemainingTimeFactor = 64,
    AreaParking = 65,
    Note = 66,
    ElectricityArea = 67,
    PlumbingArea = 68,
    PlotContent = 69,
    PlotBlastedBuildings = 70,
    PlotRemovedMass = 71,
    PlotAddedMass = 72,
    PlotBuiltArea = 73,
    PlotPowerNew = 74,
    PlotWaterNew = 75,
    PlotPlumbingNew = 76,
    PlotRoad = 77,
    PlotYard = 78,
    PlotWalkPath = 79,
    PlotWallNew = 80,
    PlotCurb = 81,
    PlotLawn = 82,
    PlotBrushes = 83,
    PlotTrees = 84,
    PlotHedge = 85,
    PlotFlowers = 86,
    PlotNoise = 87,
    PlotOtherCosts = 88,
    PlotBlastedMass = 89,
    PlotFencesNew = 90,
    PlotLightingNew = 91,
    PlotMountainNew = 92,
    PlotPriceNew = 93,
    PlotSizeNew = 94,
    PlotStairs = 95, 
    PlotOtherDeductions = 96,
    Residence = 97,
    ResidenceFloors = 98
}


export enum UpOrDown {
  Up = 1,
  Down =2
}