import React from 'react';
import Layout from './Layout';
import { History } from 'history'
import { Route, Switch } from 'react-router';
import { RoutesConfig } from './config/routes.config';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect } from 'react-router-dom';
import { Login, Logout, Forgot, ForgotConfirmation, Home, About,AboutWeb, Examples, Contact, Contacts, ContactConfirmation, 
  Register, RegisterConfirmation, ResetPassword, SelectLayout, Projects, Project, Calculation, SimpleCalculation, 
  DescriptionTemplate, CalculationTemplate, Ownership, PriceGuide, 
  Documents, Document, Plot, Accept, Invoice, Report, Admin, Profile, DocumentLinks, Support, ViewReport, 
  Customers, Customer, Messages,Invoices, SsbIndex, UpdateMessages, AdminLinks, Statistics, Tables , Text, Tools } from './containers';
import ScrollToTop from "./utils//scrollToTop";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import Loading from './loading';

const App: React.FC<{ history: History }> = ({ history }) => {

   const isProduction = process.env.NODE_ENV === 'production';
   console.log('Byggekost:' + version);
   return (
   <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    >
  <ConnectedRouter history={history}>
    <ScrollToTop />
    <Switch>
      <Route exact path={RoutesConfig.ViewReport.path} component={ViewReport} />
      <Route>
         <Layout>
            <Switch>
               <Route exact path={RoutesConfig.Home.path} component={Home} />
               <Route exact path={RoutesConfig.About.path} component={About}/>
               <Route exact path={RoutesConfig.AboutWeb.path} component={AboutWeb}/>
               <Route exact path={RoutesConfig.Examples.path} component={Examples}/>
               <Route exact path={RoutesConfig.Login.path} component={Login} />
               <Route exact path={RoutesConfig.Logout.path} component={Logout} />
               <Route exact path={RoutesConfig.Forgot.path} component={Forgot} />
               <Route exact path={RoutesConfig.ForgotConfirmation.path} component={ForgotConfirmation} />
               <Route exact path={RoutesConfig.ResetPassword.path} component={ResetPassword} />
               <Route exact path={RoutesConfig.Contact.path} component={Contact} />        
               <Route exact path={RoutesConfig.Contacts.path} component={Contacts} />
               <Route exact path={RoutesConfig.ContactConfirmation.path} component={ContactConfirmation} />
               <Route exact path={RoutesConfig.Register.path} component={Register} />
               <Route exact path={RoutesConfig.RegisterConfirmation.path} component={RegisterConfirmation} />
               <Route exact path={RoutesConfig.SelectLayout.path} component={SelectLayout} />
               <Route exact path={RoutesConfig.Project.path} component={Project} />
               <Route exact path={RoutesConfig.Projects.path} component={Projects} />
               <Route exact path={RoutesConfig.Calculation.path} component={Calculation} />
               <Route exact path={RoutesConfig.SimpleCalculation.path} component={SimpleCalculation} />
               <Route exact path={RoutesConfig.DescriptionTemplate.path} component={DescriptionTemplate} />
               <Route exact path={RoutesConfig.CalculationTemplate.path} component={CalculationTemplate} />
               <Route exact path={RoutesConfig.Ownership.path} component={Ownership} />
               <Route exact path={RoutesConfig.PriceGuide.path} component={PriceGuide} />
               <Route exact path={RoutesConfig.Documents.path} component={Documents} />
               <Route exact path={RoutesConfig.Document.path} component={Document} />
               <Route exact path={RoutesConfig.Plot.path} component={Plot} />
               <Route exact path={RoutesConfig.Accept.path} component={Accept} />
               <Route exact path={RoutesConfig.Invoice.path} component={Invoice} />
               <Route exact path={RoutesConfig.Report.path} component={Report} />
               <Route exact path={RoutesConfig.Admin.path} component={Admin} />
               <Route exact path={RoutesConfig.Customers.path} component={Customers} />
               <Route exact path={RoutesConfig.Customer.path} component={Customer} />
               <Route exact path={RoutesConfig.Messages.path} component={Messages} />
               <Route exact path={RoutesConfig.Invoices.path} component={Invoices} />
               <Route exact path={RoutesConfig.SsbIndex.path} component={SsbIndex} />
               <Route exact path={RoutesConfig.UpdateMessages.path} component={UpdateMessages} />
               <Route exact path={RoutesConfig.AdminLinks.path} component={AdminLinks} />
               <Route exact path={RoutesConfig.Tools.path} component={Tools} />
               <Route exact path={RoutesConfig.Statistics.path} component={Statistics} />
               <Route exact path={RoutesConfig.Tables.path} component={Tables} />
               <Route exact path={RoutesConfig.Text.path} component={Text} />
               <Route exact path={RoutesConfig.Profile.path} component={Profile} />
               <Route exact path={RoutesConfig.DocumentLinks.path} component={DocumentLinks} />
               <Route exact path={RoutesConfig.Support.path} component={Support} />
               <Route exact path='/Account/Register' render={() => 
                  (
                     <Redirect to={RoutesConfig.Register.path} />
                  )
               }/>
            </Switch>
         </Layout>
      </Route>
    </Switch>
  </ConnectedRouter>
  </CacheBuster>)
  };

export default App;