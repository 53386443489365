import React from 'react';
import { BrowserRouter, Switch, Route, Redirect, Link } from "react-router-dom";


const About: React.FC = ()=>{

    return (<Redirect to="/document/10" />);
// const About: React.FC = () => (
//   <div className="about-outer-container">
//     <img className="about-logo-big" alt="" src="/Content/images/byggekost-logo.png" />

//     <div className="about-ingress">En enklere hverdag hmmmmm.</div>

//     <div className="about-container">
//         <div className="about-content">Arbeidene med byggekost.no ble påbegynt i 2013</div>
//         <div className="about-content">Programmet var klart for et utvalg testpersoner i NITO-Takst i 2017 og videre for alle takstmenn i Norge 2018.</div>
//         <div className="about-content">Byggekost.no eies idag av Pro Verk AS som ble stiftet i 2018 av Erik Sørensen og Runar Gautestad</div>
//     </div>

//     <div>
//         <img className="about-logo-small" alt="" src="/Content/images/proverk-logo.gif" />
//     </div>

//     <div>
//         <figure className="about-figure">
//             <img className="about-profile" alt="" src="/Content/images/bilde-erik.jpg" />
//             <figcaption>Erik Sørensen</figcaption>
//             <div className="about_mobile">mob: 906 351 60</div>
//         </figure>
//         <figure className="about-figure">
//             <img className="about-profile" alt="" src="/Content/images/bilde-runar.jpg" />
//             <figcaption>Runar Gautestad</figcaption>
//             <div className="about_mobile">mob: 911 618 17</div>
//         </figure>
//     </div>

//     <div className="about-container">
//         <div className="about-content">Erik Sørensen og Runar Gautestad har praksis og erfaring fra byggebransjen siden 1980 og i takstbransjen siden 1997.</div>
//         <div className="about-content">Våre kvalifikasjoner er fagbrev og mesterbrev i tømrerfaget, teknisk fagskole og takstutdanning med tilhørende godkjenninger.</div>
//     </div>
//     <br/>
//     <div className="about-logo-container">
//         <img className="about-logo-icon" alt="" src="/Content/images/nt.gif" />
//         <img className="about-logo-icon" alt="" src="/Content/images/mester.png" />
//         <img className="about-logo-icon" alt="" src="/Content/images/byggekost-logo-liten.gif" />
//     </div>
//   </div>
// )
}
export default About;