import React, {useState} from 'react';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCircleXmark, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { MonetizationOn, Cancel } from '@material-ui/icons';

const CustomerRow = ({customer, index}) => {

   const history = useHistory();

   const formatDateTime = function(date) {
      if (date === undefined || date === null) {
         // Could be for a new record
         return "-";
      }
      let thedate = new Date(date);
      var options = {year: 'numeric', month: '2-digit', day: '2-digit' } as const;
      return thedate.toLocaleDateString('nb-NO', options);
   }

   function ActionMenu(props) {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
   
      const handleClick = (event) => {
         setAnchorEl(event.currentTarget);
      };
   
      const handleClose = () => {
         setAnchorEl(null);
      };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
              elevation={0}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            {/* <MenuItem onClick={handleEditProject}>Rediger</MenuItem>
            <MenuItem onClick={handlePrintProject}>Skriv ut</MenuItem>
            <MenuItem onClick={handleCopyProject} disabled>Kopier</MenuItem>
            <MenuItem onClick={handleDeleteProject}>Slett</MenuItem> */}
          </Menu>
        </div>;
   }

   const getRowClass = function(index) {
      if (index % 2 === 0) {
      return "customer-row customer-row-white"
      }
      else {
      return "customer-row customer-row-grey"
      }
   }

   const getSubscriptionStatus = () => {
      if (customer.acceptedDate != null) {
         return <div className="admin-icon green"><MonetizationOn /></div>
      }
      else {
         return <div className="admin-icon transparent"><MonetizationOn /></div>
      }
   }

   const getActivStatus = () => {
      if (customer.active == false) {
         return <div className="admin-icon red"><Cancel /></div>
      }
      else {
         return <></>
      }
   }

   const handleCustomerClick = () => {
      const url = "/customer/" + customer.userId;
      history.push(url);
   }


   return (
      <>
        
            <div className ={getRowClass(index)} onClick={handleCustomerClick}>
               <div className="">{index + 1}</div>
               <div className='status-icons-container'> 
               {
                  getSubscriptionStatus()
               }
               {
                  getActivStatus()

               }              
               </div>
               <div className="">{customer.userId}</div>
               <div className="customer-text-trim">{customer.lastName}</div>
               <div className="customer-text-trim">{customer.firstName}</div>
               <div className="customer-text-trim">{customer.company}</div>
               <div className="">{formatDateTime(customer.registeredDate)}</div>
               <div className="">{formatDateTime(customer.lastLogin)}</div>
               <div className="align-right">{customer.projectCount}</div>
               <div className="align-right">{customer.calculationCount}</div>
               <div className="align-right">{customer.reportCount}</div>
            </div>
         
      </>
   )
}

CustomerRow.displayName = 'CustomerRow';

export default React.memo(CustomerRow);
