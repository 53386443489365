import React, { useState, useEffect, useRef } from 'react';
import { actionCreators } from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory } from 'react-router-dom'
import { AdminApi } from '../../api/admin.service';
import AdminMenu from './child-components/AdminMenu';
import StatisticsCard from './child-components/StatisticsCard';
import { StatisticsBox, Spinner } from '../../components';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend
);

type AdminProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly isAdmin: boolean;
};

const Admin: React.FC<AdminProps> = ({
   isAuthenticated,
   isAdmin,
   logoutUser
}) => {

   const [loading, setLoading] = useState(true);
   const [statistics, setStatistics] = useState({
      acceptedUsers: {},
      projects: {},
      services: {},
      technicalCalculations: [],
      otherCalculations: [],
      calculations: [],
      reports: []
   });
   const history = useHistory();

   const [messageNumber, setMessageNumber] = useState(0);
   useEffect(() => {
      //Get number to messages
      AdminApi.getMessageCount()
         .then(
            (response) => {
               setMessageNumber(response.data);
            },
            (error) => {
               console.log(error);
            });

   })

   useEffect(() => {
      fetchData();
   }, [])

   const fetchData = async () => {
      AdminApi.getStatistics()
         .then(
            (response) => {
               setStatistics(response.data);
               setLoading(false);
            },
            (error) => {
               console.log(error);
            });
   }


   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if (!isAdmin) {
      history.push("/projects");
   }

   const handleSubmit = () => {
      //event.preventDefault();
      AdminApi.resyncMailMojo()
         .then(() => {
            //console.log("MailMojo updated");
         });
   }

   let number = null
   if (messageNumber == 0) {
      number = <span className='messagebox-number-zero'>{messageNumber}</span>

   }
   else {
      number = <span className='messagebox-number-nonzero'>{messageNumber}</span>

   }


   return (
      <>
         <div className="admin-container">
            <AdminMenu activeMenuItem={'Dashboard'}></AdminMenu>

            <div className="admin-frame">
               <div className='col-10 mx-auto'>

               {/* <div className='row mt-2'>
                  <div className='col-5'>
                        <div className='messagebox'>
                           <div className='messagebox-text'>Meldinger: {number} </div>
                           <a href="/messages" className='messagebox-btn mx-auto d-block'>Åpne</a>
                        </div>
                     </div>
                  </div> */}

                  <div className='row'>
                     {/* <div className="col-12 col-lg-4 mt-2">
                         <div className='graph_frame'><Bar options={Otheroptions} data={dataOther} width="215px"/></div>
                     </div> */}

                     <div className="col-12">
                        <div className='row mb-3 mt-2'>
                           <div className='col-12 col-lg-4 mt-1'> <div className='admin-card-frame-n'><StatisticsCard stats={statistics.acceptedUsers} ></StatisticsCard></div></div>
                           <div className='col-12 col-lg-4 mt-1'> <div className='admin-card-frame-n'><StatisticsCard stats={statistics.projects} ></StatisticsCard></div></div>
                           <div className='col-12 col-lg-4 mt-1'> <div className='admin-card-frame-n'><StatisticsCard stats={statistics.services} ></StatisticsCard></div></div>
                        </div>
                     </div>
                  </div>

               

                  <div className='row mt-2 mx-auto'>
                           <div className='admin-statistics-header'> Tjenester forbruksoversikt</div>
                           
                           <div className='col-6 mt-2'>
                              <StatisticsBox title={'Rapporter'} data={statistics.reports}></StatisticsBox>
                           </div>
                           <div className='col-6 mt-2'>
                              <StatisticsBox title={'Teknisk-kalkulasjon privat'} data={statistics.calculations}></StatisticsBox>

                              <StatisticsBox title={'Andre kalkulasjoner'} data={statistics.otherCalculations}></StatisticsBox>
                           </div>
               
                     {/* <divclassName='col-6 col-centered customer-other-calc-card'>
                        <div className='mb-5'>
                           <div className='mx-5'>
                              <CustomerStatistics title={'Tjenester'} data={statistics.reports}></CustomerStatistics>
                           </div>
                        </div>
                     </div> */}
                  </div>
                 

               </div>

            </div>

            {/* <div className="simple-calc-header">
                  Resync MailMojo
               </div>
               <div className="contact-button-group">
                  <BkButton caption='Resynkroniser MailMojo' onClickHandler={handleSubmit}/>
               </div> */}
            <Spinner isLoading={loading} />
         </div>

      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, actionCreators)(Admin);