import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { actionCreators} from '../store/auth';

class CalculationTemplateRowService extends BaseService {
  private static _calculationTemplateRowService: CalculationTemplateRowService;


  private constructor(controllerName: string) {
    super(controllerName);
  }


  public static get Instance(): CalculationTemplateRowService {
    return this._calculationTemplateRowService || (this._calculationTemplateRowService = new this('calculationTemplateRow'));
  }
  
  /* DescriptionTemplates*/
  public async get(): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.get('get', {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }


  public async add(data): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.post('post', data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });
   
    return axiosResponse;
  }

  public async update(data): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.put('put',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

  public async delete(id): Promise<AxiosResponse> {
    var axiosResponse;
    await this.$http.delete('delete/'+ id, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleError(error); });

    return axiosResponse;
  }

}
  
export const CalculationTemplateRowApi = CalculationTemplateRowService.Instance;