import React, {useState, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import { CostFactorCategory } from '../../../config/constants';
import { AutoTextBox} from '../../../components'
import { displayNumericValue } from '../../../utils';

const primaryAreaProperty = 27;
const secondaryAreaProperty = 28;

interface CalculationPropertyProps {
  property: any,
  index:number,
  readonly onUpdatedCb?: (preoperty:any) => void;
}

const keyPressTimer = 1000;
let inputTimer = null;

function CalculationProperty(props:CalculationPropertyProps) {

  const [property, setProperty] = useState({
    propertyTypeId:0,
    controlTypeId:0,
    items:[],
    name:'',
    suffix:'',
    helpText:'',
    hidden:false,
    numericValue:0,
    textValue:'',
    required:true
  });

  useEffect(() => {
    setProperty(props.property);  
  }, [props]);


  const onNumericChange = (e) => {
    const { name, value } = e.target;
    var intVal = parseInt(value);
    if (isNaN(intVal)) {
      intVal = 0;
    }
    if (property.controlTypeId == 12) {
      if (intVal > 100)
        intVal = 100;
    }
    if (intVal == property.numericValue) {
      return;
    }
    
    setProperty(property => ({...property, numericValue:intVal}));
    var toBeUpdated = property;
    toBeUpdated.numericValue=intVal;

    if (inputTimer !== null){
       clearTimeout(inputTimer);
     }
     inputTimer = setTimeout(() => {
      updateProperty(toBeUpdated);
    }, keyPressTimer);  
  }

  const onNumericBlur = (e) => {
    const { name, value } = e.target;
    clearTimeout(inputTimer);
    var intVal = parseInt(value);
    if (isNaN(intVal)) {
      intVal = 0;
    }
    if (property.controlTypeId == 12) {
      if (intVal > 100)
        intVal = 100;
    }
    setProperty(property => ({...property, numericValue:intVal}));
    var toBeUpdated = property;
    toBeUpdated.numericValue=intVal;
    updateProperty(toBeUpdated);
  }

  const onTextChange = (e) => {
    const { name, value } = e.target;
    
    setProperty(property => ({...property, textValue:value}));
    var toBeUpdated = property;
    toBeUpdated.textValue=value;
    if (inputTimer !== null){
      clearTimeout(inputTimer);
    }
    inputTimer = setTimeout(() => {
      updateProperty(toBeUpdated);
    }, keyPressTimer);  
  }

  const onTextBlur = (e) => {
    const { name, value } = e.target;
    clearTimeout(inputTimer);
    
    setProperty(property => ({...property, textValue:value}));
    var toBeUpdated = property;
    toBeUpdated.textValue=value;
    updateProperty(toBeUpdated);
  }


  const onAutoTextChange = (value) => {
    setProperty(property => ({...property, textValue:value}));
    var toBeUpdated = property;
    toBeUpdated.textValue=value;
    updateProperty(toBeUpdated);
  }

  const updateProperty = (prop) => {
    props.onUpdatedCb(prop);
  }


  const initialOptions = function(caption) {
    return (
      <option key={0} value={0}>{caption}</option>
    )
  }

  const getText = (txt) => {
    if (txt == null || txt == undefined) {
      return "";
    }

    return txt;
  }

  const onSelectChanged = function(e){
    if (!e){
      return;
    }

    let { value } = e.target;
    value = parseInt(value);

    if (value && value !== 0){
      setProperty(property => ({...property, numericValue:value}));
      var toBeUpdated = property;
      toBeUpdated.numericValue=value;
      updateProperty(toBeUpdated);
    }
  }

  const getSelectItems = function(property) {
    var options = [];

    if (property.items)
    {
      options.push(initialOptions(property.caption));
      var newOptions =  property.items.map((option) => {
        return <option key={option.id} value={option.id}>{option.name}</option>
      });
      options.push(newOptions);
    }
   
    return (
      <select 
        className={['form-control', 'form-control-large', (property.required && property.numericValue <=0)?'calculation-required-error':''].join(' ')} 
        value={property.numericValue} 
            onChange={(e) => onSelectChanged(e)}>
        {options}
      </select>
    );
  }

  let propertyHtml = null;
  if (property) {
      if (!property.hidden && !(property.propertyTypeId === primaryAreaProperty || property.propertyTypeId === secondaryAreaProperty)) {
       
        const floorsWithoutItems = property.propertyTypeId === CostFactorCategory.ResidenceFloors && (property.items && property.items.length <= 0)? true: false;

          // do not show floors without items. For special case such as: 'leileghet'
        if (property.controlTypeId === 1 && !floorsWithoutItems) {

          // Select
          propertyHtml =  (
            <div className="display-flex calculation-row" key={"TC" +props.index}>
              <div className="flex-6 calculation-cell">{property.name}</div>
              <div className="flex-3 calculation-cell calculation-input-frame">
                <div className="input-icon input-icon-right inline-block">
                  <i>{property.suffix}</i>
                  {getSelectItems(property)}
                </div>
              </div>
              <div className="flex-1">
                <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"Help"} data-for={"tooltip" + props.index} data-tip={property.helpText}/>
                <ReactTooltip id={"tooltip" + props.index} type="info" place="right"  html={true} effect="solid" className="helptext-custom"/>
              </div>
            </div>
          );

        }
        else if (property.controlTypeId === 2 || property.controlTypeId === 3 || property.controlTypeId === 5 || property.controlTypeId === 12) {
          // numeric
          propertyHtml =  (
            <div className="display-flex calculation-row" key={"TC" +props.index}>
              <div className="flex-6 calculation-cell">{property.name}</div>
              <div className="flex-3 calculation-cell calculation-input-frame">
                <div className="input-icon area-table-header-input-icon-right inline-block area-table-header-input-container">
                  <i>{property.suffix}</i>
                  <input className={['form-control', 'form-control-large','area-table-header-input', (property.required && property.numericValue <=0)?'calculation-required-error':'',].join(' ')} placeholder="0" name={"TC" +props.index} type="text" value={displayNumericValue(property.numericValue)} onChange={onNumericChange} onBlur={onNumericBlur} />
                </div>
              </div>
              <div className="flex-1">
                <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"Help"} data-for={"tooltip" + props.index} data-tip={property.helpText}/>
                <ReactTooltip id={"tooltip" + props.index} place="right" type="light" html={true} effect="solid"  className="helptext-custom"/>                
              </div>
            </div>
          );
        }
        else if (property.controlTypeId === 4) {
          // text
          propertyHtml =  (
            <div className="display-flex calculation-row" key={"TC" +props.index}>
              <div className="flex-6 calculation-cell">{property.name}</div>
              <div className="flex-3 calculation-cell calculation-input-frame">
                <div className="input-icon input-icon-right inline-block">
                  <textarea className="form-control form-control-large-text " rows={3} cols={30} placeholder="Kort Forklaring" value={property.textValue || ''} onChange={onTextChange} onBlur={onTextBlur}></textarea>
                  {/* <img className="question-image" src="/Content/images/font-awsome/question.png" data-tip={property.helpText}/>
                  <ReactTooltip place="right" type="light" html={true} effect="solid"/> */}
                </div>
              </div>
              <div className="flex-1"></div>
            </div>
          );
        }      
        else if (property.controlTypeId === 6) {
          // note
          propertyHtml =  (
            <div className="display-flex calculation-row" key={"TC" +props.index}>
              <div className="flex-9 calculation-note-row">
                <div className="calculation-cell">{property.name}</div>
                <div className="calculation-cell">
                  <div className="input-icon input-icon-right">
                    <textarea className="form-control form-control-note" rows={6}  placeholder="Notat" value={property.textValue || ''} onChange={onTextChange} onBlur={onTextBlur}></textarea>
                  </div>
                </div>
              </div>
              <div className="flex-1"></div>
            </div>
          );
        }
        else if (property.controlTypeId === 11) {
          // text
          propertyHtml =  (
            <div className="display-flex calculation-row" key={"TC" +props.index}>
              <div className="flex-2 calculation-cell">{property.name}</div>
              <div className="flex-4 calculation-cell">
                <AutoTextBox defaultRows={1} text={getText(property.textValue)} lineHeight={25} class={'calculation-auto-textbox'} onValueChangeCb={onAutoTextChange}></AutoTextBox>
              </div>
              <div className="flex-3 calculation-cell calculation-input-frame">
                <div className="input-icon area-table-header-input-icon-right inline-block area-table-header-input-container">
                  <i>{property.suffix}</i>
                  <input className={['form-control', 'form-control-large','area-table-header-input',].join(' ')} placeholder="0" name={"TC" +props.index} type="text" value={displayNumericValue(property.numericValue)} onChange={onNumericChange} onBlur={onNumericBlur} />
                </div>
              </div>
              <div className="flex-1">
                <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"Help"} data-for={"tooltip" + props.index} data-tip={property.helpText}/>
                <ReactTooltip id={"tooltip" + props.index} place="right" type="light" html={true} effect="solid"  className="helptext-custom"/>                
              </div>
            </div>
          );
        }    
      }
  }



  return (
    <>      
      
        {propertyHtml}  
    </>
  )
}


export default React.memo(CalculationProperty);