import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';
import { AreaSpecification, AreaSpecificationCategory, AreaSpecificationCategoryRow } from '../containers/Modules/AreaSpecification/types';


class AreaSpecificationService extends BaseService {
  private static _areaSpecificationService: AreaSpecificationService;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): AreaSpecificationService {
    return this._areaSpecificationService || (this._areaSpecificationService = new this('areaSpecification'));
  }

  public async getAreaSpecification(areaSpecId:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    if (!areaSpecId){
      this.handleAllErrors(` AreaSpecId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.get('get/' + areaSpecId, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }

  public async addAreaSpecification(data: {moduleId:number}): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for addAreaSpecification.`); 
      Promise.reject();
    }
    
    await this.$http.post('AddAreaSpecification',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw (error);
    });

    return axiosResponse;
  }

  public async updateAreaSpecification(data: AreaSpecification): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for updateAreaSpecification.`); 
      Promise.reject();
    }
    
    await this.$http.post('UpdateAreaSpecification',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  }

  public async addCategory(data: {areaSpecificationId: number, type: number}): Promise<AxiosResponse> {
    var axiosResponse;

    if (!data){
      this.handleAllErrors(` Data was not provided for addCategory.`); 
      Promise.reject();
    }
    
    await this.$http.post('AddCategory',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw (error);
    });

    return axiosResponse;
  }


  public async updateAreaSpecificationCategory(data: AreaSpecificationCategory): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateCategory',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error); throw(error)});

    return axiosResponse;
  }

  public async updateAreaSpecificationCategoryRow(data: AreaSpecificationCategoryRow): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('UpdateRow',data, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error);});

    return axiosResponse;
  }

  public async addNewAreaSpecificationCategoryRow(data: {areaSpecificationCategoryId: number, buildingType: number}): Promise<AxiosResponse> {
    var axiosResponse;
    
    await this.$http.post('AddRow', data, 
    {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {this.handleAllErrors(error);  throw (error); });

    return axiosResponse;
  }
  
  
  public async deleteCategory(categoryId:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    if (!categoryId){
      this.handleAllErrors(`categoryId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.post('deleteCategory', {categoryId}, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }

    
  public async deleteRow(categoryRowId:number): Promise<AxiosResponse | null> {
    var axiosResponse;
    
    
    if (!categoryRowId){
      this.handleAllErrors(`categoryRowId arg. was not provided.`); 
      Promise.reject();
    }
    
    await this.$http.post('deleteRow', {categoryRowId}, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }

      
  public async changeOrderOfCategories(args: {categoryId1:number, order1:number, categoryId2:number, order2:number}): Promise<AxiosResponse | null> {
    var axiosResponse;
    
   
    if (!args){
      this.handleAllErrors(`arg. was not provided for changeOrderOfCategory.`); 
      Promise.reject();
    }
    
    await this.$http.post('changeOrderOfCategories', args, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }

  public async changeOrderOfRows(args: {itemId1:number, order1:number, itemId2:number, order2:number}): Promise<AxiosResponse | null> {
    var axiosResponse;
    
   
    if (!args){
      this.handleAllErrors(`arg. was not provided for changeOrderOfRows.`); 
      Promise.reject();
    }
    
    await this.$http.post('changeOrderOfRows', args, {withCredentials: true})
    .then((response) => {
      axiosResponse = response;
    }, 
    (error) => {
        this.handleAllErrors(error); 
        throw(error);
    });
   
    return axiosResponse;
  }

}

export const AreaSpecificationApi = AreaSpecificationService.Instance;