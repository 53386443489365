import React, {useState, useEffect} from 'react'
import {CategoryHeader} from './'


type SelectSectionProps = {
   readonly title:string;
   readonly numberOfOptions: number;
   readonly firstCaption?: string;
   readonly lastCaption?: string;
   readonly selected?:number;
   readonly onValueChangeCb?: (value: number) => void;
};

function SelectSection(props:SelectSectionProps) {

   const [selected, setSelected] = useState(0);

   useEffect(() => {
      if (props.selected != null) {
         setSelected(props.selected);
      }    
   }, [props]);


   const onValueChange = (number) => {
      if (selected == number) {
         //reset
         setSelected(0);
         props.onValueChangeCb(0);
      }
      else {
         setSelected(number);
         props.onValueChangeCb(number);
      }
      return;
   }

   const getCaption = (number) => {
      if (number==1) {
         return props.firstCaption;
      }
      if (number==props.numberOfOptions) {
         return props.lastCaption;
      }
      return '';
   }


   var optionsHtml = null;
   if (props.numberOfOptions > 0) {
      optionsHtml = Array.from({length: props.numberOfOptions}, (_, index) => index + 1).map((number, index) => {
         return <>
            <div>
               <div className="select-section-caption mb-4">{getCaption(number)}</div>
               <div className="select-box-item mb-1">
                  <div className="select-box"><div className={number==selected?"selected-box the-box":"the-box"} onClick={() => onValueChange(number)}></div></div>
                  <div className="select-box-caption">{number}</div>
               </div>
            </div>
         </>
      })
   }
   for (let i = 1; i <= props.numberOfOptions; i++) {
      
    }
    
   return <>
      <CategoryHeader title={props.title}></CategoryHeader>
      <div className='content-frame'> 
      <div className="select-section-frame">
         {optionsHtml}
      </div>
      </div>

     
   </>
}
  
export default SelectSection;