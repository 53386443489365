import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class GenericCalculationModuleService extends BaseService {
   private static _genericCalculationModuleService: GenericCalculationModuleService;
 
   private constructor(controllerName: string) {
     super(controllerName);
   }
 
   public static get Instance(): GenericCalculationModuleService {
     return this._genericCalculationModuleService || (this._genericCalculationModuleService = new this('GenericCalculationModule'));
   }
 

   public async getGenericCalculationModule(moduleId:number): Promise<AxiosResponse | null> {
      var axiosResponse;
      
      if (!moduleId){
        this.handleAllErrors(` moduleId arg. was not provided.`); 
        Promise.reject();
      }
      
      await this.$http.get('get/' + moduleId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {
          this.handleAllErrors(error); 
          throw(error);
      });
     
      return axiosResponse;
   }

   public async addGenericCalculationModule(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addGenericCalculationModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('Add',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async addCalculation(data: {moduleId:number}): Promise<AxiosResponse> {
      var axiosResponse;

      if (!data){
         this.handleAllErrors(` Data was not provided for addGenericCalculationModule.`); 
         Promise.reject();
      }
      
      await this.$http.post('AddCalculation',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {
            this.handleAllErrors(error); 
            throw (error);
      });

      return axiosResponse;
   }

   public async deleteCalculation(moduleId:number, subId:number): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.delete('DeleteCalculation/'+ moduleId + '/' + subId, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
  
      return axiosResponse;
    }


  
}

export const GenericCalculationModuleApi = GenericCalculationModuleService.Instance;