import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Aos from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faArrowLeft, faFilePdf} from '@fortawesome/free-solid-svg-icons'


const Examples: React.FC = () => {

  const openPdf = (url) => {
    const win = window.open(url, "_blank");
    win.focus();
 }

  return (
    <div className='container'>
      <div className='row bk-row-header mb-3'>
        <div className='col-lg-10'> <div className=" bk-row-header-title "> Rapport Eksempel</div></div>
        <div className='col-lg-2'>
          <a href="/documents" className='dashboard-back-btn mx-auto'>
            <FontAwesomeIcon icon={faArrowLeft} className=" mt-3 me-3" />
            Hovedmeny </a></div>
      </div>
      
      <div className='btn_grid'>
     
               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Arealrapport.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Arealrapport </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Arealrapport-Enkel.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Arealrapport - Enkel </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Brevtakst.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Brevtakst </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/_Forhåndstakst.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Forhåndstakst </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Landbrukstakst.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Landbrukstakst </div>
               </div>
               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Næringstakst.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Næringstakst </div>
               </div>
     
               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Reklamasjonsrapport.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Reklamasjonsrapport </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Skaderapport.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Skaderapport </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Skaderapport_Enkel.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Skaderapport - Enkel </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Tilbudsbrev.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Tilbudsbrev </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Tilstandsrapport-NS3424.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Tilstandsrapport - NS3424 </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Tilstandsrapport-NS3600.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'>Tilstandsrapport - NS3600 </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Tomtetakst.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'>Tomtetakst </div>
               </div>

               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/_Verditakst.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Verditakst </div>
               </div>
     
               <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/_Verdivurdering.pdf')}>
                  <FontAwesomeIcon icon={faLink} className="fa-3x mt-3" />
                  <div className='dashboard-btn-text mt-3 mb-2'> Verdivurdering </div>
               </div>
     
        </div>
    </div>


  );
}
export default Examples;